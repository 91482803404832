export enum UtrvConfigValue {
  Default = 'default',
  Optional = 'optional',
  Required = 'required',
}

// initiativeUtr's isPrivate: 'optional' -> utr's isPrivate: false
// initiativeUtr's isPrivate: 'required' -> utr's isPrivate: true
export const UTRV_CONFIG_CODES = ['verificationRequired', 'evidenceRequired', 'noteRequired', 'isPrivate'] as const;

export type UtrvConfigCode = (typeof UTRV_CONFIG_CODES)[number];

export type UtrvConfigType = Record<UtrvConfigCode, UtrvConfigValue>;
