const FIXED_COLUMNS: string[] = ['Access', 'Company name'];

const FixedColumns = () => {
  return (
    <div className='d-flex flex-column'>
      {FIXED_COLUMNS.map((item) => (
        <p key={item} className='fixed-column'>
          <i className='fa-light fa-lock text-ThemeIconSecondary'></i>
          <span className='ml-2'>{item}</span>
        </p>
      ))}
    </div>
  );
};

export default FixedColumns;
