import { Progress } from 'reactstrap';
import NumberFormat from '../../utils/number-format';
import { DashboardSectionTitle } from '../dashboard';

interface SdgContributionHeaderProps {
  title: string;
  score?: number;
}

export const SdgContributionHeader = ({ title, score }: SdgContributionHeaderProps) => {
  return (
    <DashboardSectionTitle title={title} headingStyle={2} className='text-ThemeHeadingLight'
      buttons={[
        <div key='SDG-total-contribution' className={'dashboard-section__header me-3'}>
          <span className='sdg-contribution__total text-ThemeInfoDark'>
            SDG total contribution
            <NumberFormat
              className='h3 pl-1 sdg-contribution__percentage text-ThemeInfoDark'
              value={score}
              decimalPlaces={0}
              suffix='%'
            />
          </span>
          <Progress className='sdg-chart__progress' max={100} value={score} />
        </div>
      ]}
    />
  );
};
