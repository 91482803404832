import { useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const CreateCustomOptionModal = ({
  toggle,
  addOption,
}: {
  toggle: () => void;
  addOption: (option: string) => void;
}) => {
  const [value, setValue] = useState<string>('');
  const handleSubmit = () => {
    if (value) {
      addOption(value);
      toggle();
    }
  };

  return (
    <Modal isOpen={true} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Create a new option</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>New option name</Label>
          <Input onChange={(e) => setValue(e.target.value)} placeholder='Type your option here...' />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color='link' onClick={toggle}>
          Cancel
        </Button>
        <Button disabled={value === ''} onClick={() => handleSubmit()}>
          Add option
        </Button>
      </ModalFooter>
    </Modal>
  );
};
