import { useState } from 'react';
type History = {
  [key: string]: string;
};

export const useSearchHistory = () => {
  const [history, setHistory] = useState<History>({});

  const addSearchHistory = (search: string, page: string) =>
    setHistory((prevHistory) => ({ ...prevHistory, [page]: search }));

  const getSearchHistory = (page: string) => history[page] || '';

  const removeHistory = (page: string) => {
    setHistory((prevHistory) => {
      Object.keys(history)
        .filter((key) => key.startsWith(page))
        .forEach((key) => delete prevHistory[key]);
      return prevHistory;
    });
  };

  return { addSearchHistory, getSearchHistory, removeHistory };
};
