import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ROUTES } from '@constants/routes';
import { useAppDispatch, useAppSelector } from '@reducers/index';
import { generateUrl } from '@routes/util';
import { isUserManagerByInitiativeId } from '../../../selectors/user';
import { SettingStorage } from '@services/SettingStorage';
import { reloadPortfolioCompanies } from '../../../slice/portfolioCompanySlice';
import { InitiativeCompany } from '@g17eco/types/initiative';
import { DATE, TIME_RANGE_YEARLY, formatDateUTC } from '@utils/date';
import Avatar from '../../avatar';
import Dashboard, { DashboardSection } from '../../dashboard';
import { BlockingLoader } from '../../loader/BlockingLoader';
import { getDateRange, SimpleTooltip } from '@g17eco/molecules';
import { DateRangeType } from '@g17eco/types/common';
import { AddCompanyModal } from '../add-company-modal';
import { CustomiseColumnsModal, KEY_PORTFOLIO_EXCHANGE_COLUMNS_SETTING } from './customise-columns-modal';
import { ExchangeFilters, Filters } from './exchange-filters';
import { ExchangeTable } from './exchange-table';
import './styles.scss';
import {
  Column,
  GENERAL_COLUMNS,
  WeightedInitiativeCompany,
  getExportRowHeaders,
  getExportRowValues,
  getPackColumns,
} from './utils';
import { exportToExcel } from '../../downloads/util/exportToExcel';
import { Portfolio } from '../../../types/portfolio';
import { reloadPortfolioById } from '../../../slice/portfolioSlice';
import { SURVEY } from '@constants/terminology';

interface ApplyFilterCompanies {
  companies: InitiativeCompany[];
  searchText: string;
  filters: Filters;
  portfolio: Portfolio;
}

const applyFilterCompanies = ({
  companies,
  searchText,
  filters,
  portfolio,
}: ApplyFilterCompanies): WeightedInitiativeCompany[] => {
  const nameCondition = (company: InitiativeCompany) =>
    searchText ? company.name.toLowerCase().includes(searchText.trim().toLowerCase()) : true;
  const sectorCondition = (company: InitiativeCompany) =>
    filters.sector ? company.sectorText === filters.sector : true;
  const industryCondition = (company: InitiativeCompany) =>
    filters.industry ? company.industryText === filters.industry : true;

  return companies.reduce((acc, company) => {
    if (nameCondition(company) && sectorCondition(company) && industryCondition(company)) {
      const { weight } = portfolio.scorecard.children.find((child) => child.initiativeId === company._id) ?? {
        weight: 0,
      };
      acc.push({
        ...company,
        weight,
      });
    }
    return acc;
  }, [] as WeightedInitiativeCompany[]);
};

interface ExchangeViewProps {
  portfolio: Portfolio;
}

export const ExchangeView = ({ portfolio }: ExchangeViewProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { data: companies, loaded: loadedPortfolioCompanies, id } = useAppSelector((state) => state.portfolioCompany);
  const isUserManager = useAppSelector((state) => isUserManagerByInitiativeId(state, portfolio._id));

  // Filters state
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState<Filters>({});
  const [dateRange, setDateRange] = useState<DateRangeType>(getDateRange(TIME_RANGE_YEARLY));

  const hasFilters = Boolean(searchText || Object.values(filters).some((item) => Boolean(item)) || Object.keys(dateRange).length);

  const filteredCompanies = useMemo(() => {
    return id !== portfolio._id ? [] : applyFilterCompanies({ companies, searchText, filters, portfolio });
  }, [companies, filters, id, portfolio, searchText]);

  // Columns setting state
  const [columnsSetting, setColumnsSetting] = useState<Column[]>(() => {
    const currentColumnsSetting = SettingStorage.getItem(KEY_PORTFOLIO_EXCHANGE_COLUMNS_SETTING);
    return currentColumnsSetting ? JSON.parse(currentColumnsSetting) : GENERAL_COLUMNS;
  });
  const updateColumnsSetting = (columns: Column[]) => {
    setColumnsSetting(columns);
    SettingStorage.setItem(KEY_PORTFOLIO_EXCHANGE_COLUMNS_SETTING, JSON.stringify(columns));
  };

  const companiesWithCompletedSurvey = useMemo(() => {
    return filteredCompanies.filter((company: WeightedInitiativeCompany) => {
      return (company.exchangeSurveys ?? []).some((survey) => survey.completedDate);
    });
  }, [filteredCompanies]);

  const companiesWithCompletedSurveyPercent = filteredCompanies.length
    ? Number((companiesWithCompletedSurvey.length / filteredCompanies.length) * 100)
    : 0;

  const totalWeight = filteredCompanies.reduce((acc, h) => acc + h.weight, 0);

  const [openAddCompany, setOpenAddCompany] = useState(false);
  const toggleAddCompany = () => setOpenAddCompany((prev) => !prev);

  const [openCustomiseColumns, setOpenCustomiseColumns] = useState(false);
  const toggleCustomiseColumns = () => setOpenCustomiseColumns((prev) => !prev);

  const reloadCompany = useCallback(
    () =>
      dispatch(
        reloadPortfolioCompanies(portfolio._id, false, {
          dateRange,
          period: filters.reportType,
          metrics: getPackColumns(columnsSetting).map((item) => item.code),
        })
      ),
    [dispatch, portfolio._id, dateRange, filters.reportType, columnsSetting]
  );

  const handleReload = useCallback(() => {
    reloadCompany();
    dispatch(reloadPortfolioById(portfolio._id, true));
  }, [dispatch, portfolio._id, reloadCompany]);

  useEffect(() => {
    reloadCompany();
  }, [reloadCompany]);

  const handleSettingCompany = () => {
    const redirectUrl = generateUrl(ROUTES.PORTFOLIO_TRACKER_COMPANY_SETTINGS, { portfolioId: portfolio?._id })
    history.push(redirectUrl);
  }

  const handleDownload = () => {
    const headers = getExportRowHeaders({ columnsSetting });
    const values = getExportRowValues({ companies, columnsSetting });
    const fileName = `${formatDateUTC(new Date(), DATE.FILE_NAME)}_${portfolio?.name}.csv`;

    exportToExcel({ headers, values, fileName, sheetName: 'Portfolios' });
  };

  return (
    <Dashboard className='portfolio-exchange'>
      {!loadedPortfolioCompanies ? <BlockingLoader /> : null}
      <DashboardSection
        className='portfolio-exchange__info'
        mb={0}
        title={
          <div role='button' onClick={() => handleSettingCompany()} className='d-flex align-items-center m-0'>
            {portfolio.profile ? (
              <span className='mr-3'>
                <Avatar width='40px'>
                  <img alt='avatar' src={portfolio.profile} />
                </Avatar>
              </span>
            ) : (
              <></>
            )}
            <span data-testid={'exchange-view-pt-name'}>{portfolio.name}</span>
          </div>
        }
        buttons={[
          isUserManager ? (
            <Button outline onClick={() => toggleAddCompany()}>
              <i className='fa fa-plus mr-2' />
              Add company to portfolio
            </Button>
          ) : (
            <SimpleTooltip text={'You must be an administrator to do this'}>
              <Button disabled={true} outline onClick={() => toggleAddCompany()}>
                <i className='fa fa-plus mr-2' />
                Add company to portfolio
              </Button>
            </SimpleTooltip>
          ),
        ]}
      />
      <DashboardSection>
        <AddCompanyModal
          isOpen={openAddCompany}
          toggle={toggleAddCompany}
          portfolio={portfolio}
          handleReload={handleReload}
        />
        <ExchangeFilters
          searchText={searchText}
          filters={filters}
          dateRange={dateRange}
          changeSearchText={(value) => setSearchText(value)}
          changeFilters={(value) => setFilters(value)}
          changeDateRange={(value) => setDateRange(value)}
          toggleCustomiseColumns={toggleCustomiseColumns}
          handleDownload={handleDownload}
        />
        <ExchangeTable
          portfolio={portfolio}
          companies={filteredCompanies}
          hasFilters={hasFilters}
          columnsSetting={columnsSetting}
          handleReload={handleReload}
        />
        <div className='row align-items-center mt-3 pt-6 companies-total'>
          <span className='col-4 text-HeadingLight'>NUMBERS OF COMPANIES IN PORTFOLIO</span>
          <span className='col-1 companies-completed-survey__percent dont_translate'>{companies.length}</span>
        </div>
        <div className='row align-items-center mt-2 companies-completed-survey'>
          <span className='col-4 text-HeadingLight text-uppercase'>% COMPANIES WITH COMPLETED {SURVEY.SINGULAR}</span>
          <span className='col-1 companies-completed-survey__percent dont_translate'>{`${
            companiesWithCompletedSurveyPercent > 0 ? companiesWithCompletedSurveyPercent.toFixed(1) : 0
          }%`}</span>
        </div>
        <div className='row align-items-center mt-2 text-strong'>
          <span className='col-4'>TOTAL WEIGHT OF PORTFOLIO</span>
          <span className='col-1 companies-completed-survey__percent dont_translate'>{`${
            totalWeight > 0 ? totalWeight.toFixed(1) : 0
          }%`}</span>
          {totalWeight > 100 ? (
            <span className='col-4 text-danger'>Portfolio currently exceeds 100%</span>
          ) : (
            <span className='col-4'>{`${(100 - totalWeight).toFixed(1)}% invested in cash/other`}</span>
          )}
        </div>
        <CustomiseColumnsModal
          portfolioId={portfolio._id}
          columnsSetting={columnsSetting}
          updateColumnsSetting={updateColumnsSetting}
          isOpen={openCustomiseColumns}
          toggle={toggleCustomiseColumns}
        />
      </DashboardSection>
    </Dashboard>
  );
};
