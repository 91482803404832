import { useParams } from 'react-router-dom';
import { isPortfolioTrackerExchange } from '../selectors/portfolio';
import { useAppSelector } from '../reducers';

export const useIsPortfolioTrackerExchangeView = () => {
  const { portfolioId } = useParams<{ portfolioId?: string }>();
  const isExchangeView = useAppSelector(isPortfolioTrackerExchange);
  const isPortfolioTracker = !!portfolioId;

  return isExchangeView && isPortfolioTracker;
};
