import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import './ScrollToTop.scss';

const HIDDEN_THRESHOLD = 200;

export const ScrollToTop = (props: { style?: object }) => {
  const [isShowing, setIsShowing] = useState(false);
  const mainContainer = document.querySelector('.main-container');

  const updateScrollPosition = (event: any) => {
    if (event.target.scrollTop > HIDDEN_THRESHOLD) {
      if (isShowing) {
        return;
      }
      return setIsShowing(true);
    }
    if (isShowing) {
      return setIsShowing(false);
    }
  };

  useEffect(() => {
    if (mainContainer) {
      mainContainer.addEventListener('scroll', updateScrollPosition);
    }
    return () => {
      if (mainContainer) {
        mainContainer.removeEventListener('scroll', updateScrollPosition);
      }
    };
  });

  const goToTop = () => {
    if (!mainContainer) {
      return;
    }
    mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return isShowing ? (
    <div className={'scroll-top'} style={props.style}>
      <Button color='primary' className='scroll-top-btn icon-position icon-style' onClick={goToTop}>
        <i className='fa fa-arrow-up fa-lg'></i>
        <span>Scroll to top</span>
      </Button>
    </div>
  ) : null;
};
