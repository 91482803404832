import React from 'react';
import { useHistory } from 'react-router-dom';
import { reloadScorecards } from '../../actions/scorecard';
import { ROUTES } from '../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../reducers';
import { generateUrl } from '../../routes/util';
import { reloadPortfolioCompanies } from '../../slice/portfolioCompanySlice';
import { reloadPortfolioById } from '../../slice/portfolioSlice';
import { reloadPortfolioTree } from '../../slice/portfolioTreeSlice';
import { Portfolio as PortfolioModel } from '../../types/portfolio';
import { Tree } from '../../types/tree';
import Dashboard, { DashboardSection } from '../dashboard';
import InitiativeTreeBreadcrumbs from '../initiative-tree-breadcrumbs';
import { SearchBox } from '../settings-sidebar';
import { ActionButton } from './action-button';
import { AddHoldingForm } from './add-holding-form';
import AddPortfolioForm from './add-portfolio-form';
import PortfolioHoldingsTable from './portfolio-holdings-table';
import { PortfolioPermissions } from '../../services/permissions/PortfolioPermissions';

interface InvestorViewProps {
  initiativeTree: Tree;
  portfolio: PortfolioModel;
}

export const InvestorView = ({ initiativeTree, portfolio }: InvestorViewProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { data: companies } = useAppSelector((state) => state.portfolioCompany);
  const portfolioId = portfolio._id;
  const canManage = PortfolioPermissions.canManage(portfolio.userPermissions);
  const [searchText, setSearchText] = React.useState('');


  const handleReload = () => {
    dispatch(reloadScorecards());
    dispatch(reloadPortfolioCompanies(portfolioId));
    dispatch(reloadPortfolioById(portfolioId, true));
    dispatch(reloadPortfolioTree());
  };

  const companyReload = () => {
    dispatch(reloadPortfolioCompanies(portfolioId));
  };

  const onCreate = (newPortfolio: PortfolioModel) => {
    if (newPortfolio._id) {
      dispatch(reloadPortfolioTree());
      history.push(generateUrl(ROUTES.PORTFOLIO_TRACKER_PORTFOLIO, { portfolioId: newPortfolio._id }));
    }
  };

  const handleGoToPortfolioID = (id: string) => {
    history.push(generateUrl(ROUTES.PORTFOLIO_TRACKER_PORTFOLIO, { portfolioId: id }));
  };

  return (
    <div style={{ pointerEvents: 'all' }}>
      <Dashboard className='portfolio'>
        {canManage ?
          <DashboardSection>
            <div className='d-flex flex-column flex-md-row'>
              <div className='me-5 flex-grow-1 border-end border-ThemeNeutralsLight'>
                <AddPortfolioForm portfolioId={portfolioId} onCreate={onCreate} />
              </div>
              <div className='flex-grow-1 border-end border-ThemeNeutralsLight'>
                <AddHoldingForm handleReload={handleReload} portfolio={portfolio} />
              </div>
            </div>
          </DashboardSection>
          : null}
        <InitiativeTreeBreadcrumbs
          initiativeTree={initiativeTree}
          currentInitiative={portfolio}
          handleClick={handleGoToPortfolioID}
        />
        <DashboardSection title={portfolio.name} icon='fa-folder-open'>
          <div className='mb-4 d-flex justify-content-between align-items-baseline'>
            <div className='col-5 pl-0'>
              <SearchBox
                handleOnChange={(e) => setSearchText(String((e.target as HTMLInputElement).value))}
                value={searchText}
                placeholder={'Search for a company holding'}
              />
            </div>
            <ActionButton holdingId={portfolio._id} />
          </div>
          <PortfolioHoldingsTable
            searchText={searchText}
            portfolio={portfolio}
            handleReload={handleReload}
            companyReload={companyReload}
            companies={companies}
          />
        </DashboardSection>
      </Dashboard>
    </div>
  );
};
