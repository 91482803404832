import { Addons } from './InputProps';

export const getAddonSingleElement = (addons: Addons | undefined) => {
  const before = addons?.before || [];
  const after = addons?.after || [];

  // For utr valueType: Text, Number, Percentage, ValueList, MultiValueList, Date
  // The input will be only one field, so we need only one element from addons
  return { beforeElement: before[0]?.element, afterElement: after[0]?.element };
}

export const findAddon = (addons: Addons | undefined, code: string) => {
  const before = addons?.before || [];
  const after = addons?.after || [];

  // For utr valueType: Text, Number, Percentage, ValueList, MultiValueList, Date
  // The input will be only one field, so we need only one element from addons
  return { beforeAddon: before.find(a => a.code === code), afterAddon: after.find(a => a.code === code) };
}