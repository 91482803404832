import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from '../types/api';
import { axiosBaseQuery } from './axiosQuery';
import { DefaultSurveyConfig } from '../types/initiative';

export type DefaultSurveyConfigResponse = DefaultSurveyConfig & {
  isEnforced: boolean;
};

interface DefaultSurveyConfigMutationParams {
  initiativeId: string;
  config: DefaultSurveyConfig;
}

interface DefaultSurveyConfigQueryParams {
  initiativeId: string;
}

export const defaultSurveyConfigApi = createApi({
  reducerPath: 'defaultSurveyConfigApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['defaultSurveyConfig'],
  endpoints: (builder) => ({
    getDefaultSurveyConfig: builder.query<DefaultSurveyConfigResponse, DefaultSurveyConfigQueryParams>({
      transformResponse: (response: ApiResponse<DefaultSurveyConfigResponse>) => {
        return response.data;
      },
      query: ({ initiativeId }) => ({
        url: `/initiatives/${initiativeId}/default-survey-config`,
        method: 'get',
      }),
      providesTags: ['defaultSurveyConfig'],
    }),
    updateDefaultSurveyConfig: builder.mutation<ApiResponse<any>, DefaultSurveyConfigMutationParams>({
      invalidatesTags: ['defaultSurveyConfig'],
      query: ({ initiativeId, config }) => ({
        url: `/initiatives/${initiativeId}/default-survey-config`,
        method: 'post',
        data: config,
      }),
    }),
  }),
});

export const { useGetDefaultSurveyConfigQuery, useUpdateDefaultSurveyConfigMutation } = defaultSurveyConfigApi;
