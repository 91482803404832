import { CalculationUtrv, UniversalTrackerConnection } from '@g17eco/types/utrv-connections';
import { CurrentInputData, IntegratedConnection, SurveyConnection, UtrvConnection } from './types';
import { UniversalTrackerPlain, UtrValueType } from '@g17eco/types/universalTracker';
import { convertUnitNumberScale } from '@utils/units';
import { getNumberScale, getTableInputData, getUnitCode } from '@utils/universalTrackerValue';
import { isSingleRowTableType } from '@utils/universalTracker';

// Treat each calculation data as a utrv connection.
export const getUtrvConnections = (connections: UniversalTrackerConnection[]) => {
  const surveyIdToConnectionsMap = new Map<string, SurveyConnection[]>();
  const integratedConnections: IntegratedConnection[] = [];
  const allConnections: UtrvConnection[] = [];

  connections.forEach((connection) => {
    const { utrCode, valueListCode, calculationGroups } = connection;
    calculationGroups.forEach((calculationGroup) => {
      calculationGroup.calculations.forEach((calculation) => {
        const { data, variables, ...restCalculation } = calculation;
        data.forEach(({ surveyId, value, variables: variablesData }) => {
          if (!value) {
            return;
          }

          const combinedVariables = Object.keys(variables).reduce((acc, key) => {
            acc[key] = { ...variablesData[key], ...variables[key] };
            return acc;
          }, {} as SurveyConnection['variables']);

          if (!surveyId) {
            const integratedConnection: IntegratedConnection = {
              ...restCalculation,
              utrCode,
              valueListCode,
              value,
              variables: combinedVariables,
            };
            integratedConnections.push(integratedConnection);
            allConnections.push(integratedConnection);
            return;
          }

          const connections = surveyIdToConnectionsMap.get(surveyId) ?? [];

          const surveyConnection: SurveyConnection = {
            ...restCalculation,
            utrCode,
            valueListCode,
            surveyId,
            value,
            variables: combinedVariables,
          };

          // Maybe we can generate the value label here to compare and use it later in the UI?
          // This comparison works only for direct calculation since unit and numberScale only exist on direct calculation for now.
          const isDuplicated = connections.some(
            (item) =>
              surveyConnection.value === item.value &&
              (surveyConnection.unit ?? '') === (item.unit ?? '') &&
              (surveyConnection.numberScale ?? '') === (item.numberScale ?? '')
          );
          if (isDuplicated) {
            return;
          }

          surveyIdToConnectionsMap.set(surveyId, [...connections, surveyConnection]);
          allConnections.push(surveyConnection);
        });
      });
    });
  });

  return {
    surveyIdToConnectionsMap,
    integratedConnections,
    allConnections,
  };
};

type GetInputValueParams = {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'valueValidation'>;
  currentInputData: CurrentInputData;
  valueListCode?: string;
};

export const getInputValue = ({ currentInputData, utr, valueListCode }: GetInputValueParams) => {
  switch (utr.valueType) {
    case UtrValueType.Table: {
      const tableData = currentInputData.table.rows[0]?.data;
      if (!valueListCode || !tableData?.length || utr.valueValidation?.table?.validation?.maxRows !== 1) {
        return undefined;
      }

      const value = tableData.find((col) => col.code === valueListCode)?.value;
      return value === undefined ? undefined : Number(value);
    }
    case UtrValueType.Number:
    case UtrValueType.Percentage: {
      return currentInputData.value;
    }
    default: {
      return undefined;
    }
  }
};

type GetConnectionConvertedValueParams = {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'targetDirection' | 'valueValidation' | 'numberScale' | 'unit'>;
  utrv: CalculationUtrv;
  connection: UtrvConnection;
  currentInputData: CurrentInputData;
};
export const getConnectionConvertedValue = ({
  connection,
  utr,
  utrv,
  currentInputData,
}: GetConnectionConvertedValueParams) => {
  const {
    value = 0,
    unit: calculationUnit,
    numberScale: calculationNumberScale = 'single',
    valueListCode,
  } = connection;

  switch (utr.valueType) {
    case UtrValueType.Table: {
      // Support single row table only.
      if (!valueListCode || !isSingleRowTableType(utr)) {
        return value;
      }

      const inputColumn = currentInputData.table.rows[0]?.data.find((col) => col.code === valueListCode);
      const defaultColumn = utr.valueValidation?.table?.columns?.find((col) => col.code === valueListCode);

      const numberScaleInput = inputColumn?.numberScale ?? defaultColumn?.numberScale ?? 'single';
      const unitInput = inputColumn?.unit ?? defaultColumn?.unit;

      return convertUnitNumberScale({
        value,
        from: {
          unit: calculationUnit ?? defaultColumn?.unit, // TODO: Use calculationUnit as single of truth after having variables/mapping unit validation.
          numberScale: calculationNumberScale ?? defaultColumn?.numberScale,
        },
        to: { unit: unitInput, numberScale: numberScaleInput },
      });
    }
    case UtrValueType.Number:
    case UtrValueType.Percentage: {
      return convertUnitNumberScale({
        value,
        from: { unit: calculationUnit ?? utr.unit, numberScale: calculationNumberScale ?? utr.numberScale },
        to: {
          unit: currentInputData.unit ?? getUnitCode(utrv, utr),
          numberScale: currentInputData.numberScale ?? getNumberScale(utrv, utr),
        },
      });
    }
    default:
      return value;
  }
};
