import { DashboardRow, DashboardSection } from '@components/dashboard';
import { PPTXTemplateScheme } from '@g17eco/types/download';
import { useState } from 'react';
import { Button } from 'reactstrap';
import AspectLogo from '@g17eco/images/ai-reports/aspect-report.png';
import MinimalistLogo from '@g17eco/images/ai-reports/minimalist-report.png';
import { GeneratingReportPlaceholder } from '../partials/GeneratingReportPlaceholder';
import { ReportCard } from '../partials/ReportCard';

interface Props {
  isGeneratingReport: boolean;
  generateReport: (props: { scheme: PPTXTemplateScheme; debug?: boolean }) => void;
}

const PPTX_SCHEMES = [
  {
    label: 'Minimalist',
    value: PPTXTemplateScheme.Default,
    img: MinimalistLogo,
  },
  {
    label: 'Aspect',
    value: PPTXTemplateScheme.Aspect,
    img: AspectLogo,
  },
];

const title = 'AI Enhanced Sustainability Reports';

export const PPTXReportsMenu = (props: Props) => {
  const { isGeneratingReport, generateReport } = props;
  const [scheme, setScheme] = useState(PPTXTemplateScheme.Default);

  if (isGeneratingReport) {
    return (
      <DashboardRow title={title}>
        <GeneratingReportPlaceholder />
      </DashboardRow>
    );
  }

  return (
    <>
      <DashboardRow title={title}>
        <div className='d-flex gap-2 mx-auto mt-4 mb-2'>
          <Button className='px-4 py-2 text-lg fw-semibold' color='primary' onClick={() => generateReport({ scheme })}>
            <i className='fal fa-file mr-1 text-xl' /> Generate Report
          </Button>
          <Button
            className='px-4 py-2 text-lg'
            color='primary'
            outline
            onClick={() => generateReport({ scheme, debug: true })}
          >
            <i className='fal fa-file-powerpoint mr-1 text-xl' /> DEBUG
          </Button>
        </div>
      </DashboardRow>
      <DashboardSection title='Selected PowerPoint report theme' padding={0}>
        <div className='d-flex justify-content-center gap-4 mb-3'>
          {PPTX_SCHEMES.map(({ label, value, img }, idx) => (
            <ReportCard key={idx} text={label} img={img} onClick={() => setScheme(value)} active={value === scheme} />
          ))}
        </div>
      </DashboardSection>
    </>
  );
};
