import { g17ecoApi, transformResponse } from './g17ecoApi';
import { UtrMappingResponse } from '@g17eco/types/utr-external-mapping';


export interface MappingRequest {
  initiativeId: string;
  utrId: string;
}

const TAG = 'universal-trackers';

const getUrl = ({ initiativeId, utrId }: MappingRequest) =>
  `/universal-trackers/${utrId}/initiativeId/${initiativeId}`;

const universalTrackersMapping = 'universal-trackers-mapping';

export const universalTrackerApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: [TAG, universalTrackersMapping],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUniversalTrackerMapping: builder.query<UtrMappingResponse, MappingRequest>({
        transformResponse,
        query: (mappingRequest) => ({
          url: `${getUrl(mappingRequest)}/mapping`,
          method: 'get',
        }),
        providesTags: (_result, _error, args) => {
          return [{ type: universalTrackersMapping, id: args.initiativeId + args.utrId }];
        },
      }),
    }),
  });

export const {
  useGetUniversalTrackerMappingQuery,
} = universalTrackerApi;
