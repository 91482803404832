import { CHART_DEFAULTS, GenericChartOptions, GenericChartProps } from './constants'

export const chartStyleHorizontalBar: GenericChartOptions = {
    bar: {
        groupWidth: '100%',
        width: '100%'
    },
    legend: {
        position: 'none'
    },
    chartArea: {
        top: 20,
        bottom: 40,
        left: 0,
        right: 0,
    },
    hAxis: {
        gridlines: {
            color: 'transparent'
        },
        textPosition: 'none',
        minValue: 0,
    },
}

export const chartStylePieLegend: GenericChartOptions = {
    legend: {
        position: 'left'
    },
    chartArea: {
        top: 10,
        bottom: 10,
        left: 20,
        right: 10,
    },
}

export const chartStyleFullPie: GenericChartOptions = {
  pieHole: 0,
};

export const chartStyleStacked: GenericChartOptions = {
    isStacked: true
}

export const chartStyleNames: { [key: string]: string } = {
  chartStyleHorizontalBar: 'chartStyleHorizontalBar',
  chartStylePieLegend: 'chartStylePieLegend',
  chartStyleStacked: 'chartStyleStacked',
  chartStyleFullPie: 'chartStyleFullPie',
};

export const chartStyleMap: { [key: string]: GenericChartOptions } = {
  chartStyleHorizontalBar,
  chartStylePieLegend,
  chartStyleStacked,
  chartStyleFullPie,
};

const getOptionsByType = (
  chartType: GenericChartProps['chartType'],
  { vAxisTitle, vAxisMaxValue }: { vAxisTitle: string | undefined; vAxisMaxValue: number | undefined }
) => {
  const vAxisOptions = {
    title: vAxisTitle,
    format: 'short',
  };

  switch (chartType) {
    case 'PieChart':
      return {
        pieHole: 0.7,
        pieSliceText: 'label',
        legend: 'none',
        chartArea: {
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        },
        vAxis: vAxisOptions
      };
    case 'LineChart':
      // Vertical axis of line chart should have a minimum value of 0 to deal with big number
      return {
        vAxis: {
          ...vAxisOptions,
          minValue: 0,
          maxValue: vAxisMaxValue,
        },
      };
    case 'ColumnChart':
      // Vertical axis of column chart should have a minimum value of 0 to deal with big number
      // Eg: The issue happens when all data have same value with a big number
      return {
        vAxis: {
          ...vAxisOptions,
          minValue: 0
        }
      }
    default:
      return {
        vAxis: vAxisOptions
      };
  }
};

type ChartOptionMerge = {
  chartType: GenericChartProps['chartType'];
  options?: GenericChartProps['options'];
  vAxisTitle?: string;
  vAxisMaxValue?: number;
};

export const getChartOptions = ({ chartType, options, vAxisTitle, vAxisMaxValue }: ChartOptionMerge) => {
  const chartOptions = options ? chartStyleMap[options] : undefined;

  // @TODO - Should do a deep merge
  return {
    ...CHART_DEFAULTS.OPTIONS,
    ...getOptionsByType(chartType, { vAxisTitle, vAxisMaxValue }),
    ...chartOptions,
  };
};
