import { formDataHeaders } from '../services/G17Client';
import { BackgroundJob, CreateJobResult, ImportJobPlain } from '../types/background-jobs';
import { g17ecoApi, transformResponse } from './g17ecoApi';

interface CreateBulkImportJobParams {
  initiativeId: string;
  file: File;
}

interface RunBulkImportJobParams {
  initiativeId: string;
  jobId: string;
}

const tags = ['bulk-import-jobs'];
const getUrl = (initiativeId: string) => `/initiatives/${initiativeId}/bulk-import/jobs`

export const bulkImportApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: tags,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBulkImportJobs: builder.query<ImportJobPlain[], string>({
        transformResponse,
        query: (initiativeId: string) => {
          return {
            url: getUrl(initiativeId),
            method: 'get',
          };
        },
        providesTags: tags,
      }),
      createBulkImportJob: builder.mutation<CreateJobResult, CreateBulkImportJobParams>({
        transformResponse,
        query: ({ initiativeId, file }) => {
          return {
            url: getUrl(initiativeId),
            method: 'post',
            headers: formDataHeaders,
            data: {
              file,
            }
          };
        },
        invalidatesTags: tags,
      }),
      runBulkImportJob: builder.mutation<BackgroundJob, RunBulkImportJobParams>({
        transformResponse,
        query: ({ initiativeId, jobId }) => {
          return {
            url: `${getUrl(initiativeId)}/${jobId}`,
            method: 'post'
          };
        },
        invalidatesTags: tags,
      }),
      deleteBulkImportJob: builder.mutation<BackgroundJob, RunBulkImportJobParams>({
        transformResponse,
        query: ({ initiativeId, jobId }) => {
          return {
            url: `${getUrl(initiativeId)}/${jobId}`,
            method: 'delete'
          };
        },
        invalidatesTags: tags,
      }),
    }),
  });

export const { useGetBulkImportJobsQuery, useCreateBulkImportJobMutation, useRunBulkImportJobMutation, useDeleteBulkImportJobMutation } = bulkImportApi;
