import {
  useGetInitiativeUniversalTrackersQuery,
  useOverrideUtrvConfigMutation,
} from '@api/initiative-universal-trackers';
import { addSiteError } from '@g17eco/slices/siteAlertsSlice';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { UtrvConfigType } from './contants';
import { MetricOverrideForm } from './MetricOverrideForm';
import { getInitialUtrvConfig } from './utils';
import { MetricOverrideProps } from '../types';
import { useMemo } from 'react';

export const MetricOverrideContainer = (props: MetricOverrideProps) => {
  const { handleReload, selectedQuestions, initiativeId, handleCloseModal } = props;
  const selectedUtrIds = selectedQuestions.map((q) => q._id);

  const { data: initiativeUtrs = [], isFetching } = useGetInitiativeUniversalTrackersQuery(initiativeId, {
    skip: !initiativeId || selectedQuestions.length === 0,
  });

  const initiativeUtrMap = useMemo(() => {
    return new Map<string, InitiativeUniversalTracker>(
      initiativeUtrs.map((utr: InitiativeUniversalTracker) => [utr.universalTrackerId, utr])
    );
  }, [initiativeUtrs]);

  const [overrideUtrvConfig, { isLoading: isUpdating }] = useOverrideUtrvConfigMutation();

  const initialUtrvConfig = getInitialUtrvConfig({ initiativeUtrMap, selectedQuestions });

  const isLoading = isFetching || isUpdating;

  const onClickUpdate = (utrvConfig: Partial<UtrvConfigType>) => {
    overrideUtrvConfig({ initiativeId, utrvConfig, utrIds: selectedUtrIds })
      .unwrap()
      .then(() => {
        handleReload({ reloadSurvey: true, closeModal: selectedQuestions.length === 1 });
      })
      .catch((err) => {
        addSiteError(err);
        handleReload();
      });
  };

  return (
    <MetricOverrideForm
      isLoading={isLoading}
      handleUpdate={onClickUpdate}
      handleCloseModal={handleCloseModal}
      initialUtrvConfig={initialUtrvConfig}
      isMultipleUpdate={selectedUtrIds.length > 1}
    />
  );
};
