import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { BenchmarkingTabCodes } from './BenchmarkingNavigation';
import LatestSurveyProgress from './latest-survey-progress';
import { PackUsage } from './PackUsage';
import { SubmissionInsights } from './submission-insights';
import './styles.scss';

const BenchmarkingComponent = ({ page }: { page?: string }) => {
  switch (page) {
    case BenchmarkingTabCodes.PackUsage:
      return <PackUsage />;
    case BenchmarkingTabCodes.SubmissionInsights:
      return <SubmissionInsights />;
    default:
    case BenchmarkingTabCodes.LatestSurveyProgress:
      return <LatestSurveyProgress />;
  }
};

export const Benchmarking = () => {
  const { path } = useRouteMatch();
  const { page } = useParams<{ portfolioId: string; page?: string }>();
  return (
    <Switch>
      <Route path={path}>
        <BenchmarkingComponent page={page} />
      </Route>
    </Switch>
  );
};
