import { HistoricalUtrs } from '@api/insights';
import { GridDashboardItem } from '@g17eco/types/insight-custom-dashboard';
import { UtrValueType } from '@g17eco/types/universalTracker';
import UniversalTrackerModalService from '@models/UniversalTrackerModalService';
import { getFirstValueListCode, hasUtrvHistoryModal } from '@routes/custom-dashboard/utils';
import { useState } from 'react';

export const useDashboardHistoryModal = () => {
  const [selectingUtrData, setSelectingUtrData] = useState<UniversalTrackerModalService | undefined>(undefined);
  const [firstValueListCode, setFirstValueListCode] = useState<string | undefined>(undefined);
  const isNumericValueList = selectingUtrData?.getUniversalTracker().getValueType() === UtrValueType.NumericValueList;
  const valueListId = selectingUtrData?.universalTracker.getValueValidation().valueList?.listId ?? '';

  const handleOpenUtrvHistoryModal = ({
    item,
    utrData,
    initiativeId,
  }: {
    item: GridDashboardItem;
    utrData?: HistoricalUtrs;
    initiativeId: string;
  }) => {
    if (!hasUtrvHistoryModal(item) || !utrData || !utrData.utrvs.length) {
      return;
    }

    const modalService = new UniversalTrackerModalService(utrData.utr);
    modalService.setUniversalTrackerValues(utrData.utrvs);
    modalService.setInitiativeId(initiativeId);
    setFirstValueListCode(getFirstValueListCode(item));
    setSelectingUtrData(modalService);
  };

  const resetSelectingUtrData = () => {
    setSelectingUtrData(undefined);
  };

  return {
    firstValueListCode,
    valueListId,
    isNumericValueList,
    selectingUtrData,
    handleOpenUtrvHistoryModal,
    resetSelectingUtrData,
  };
};
