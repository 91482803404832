import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { generateUrl } from '../util';
import { ROUTES } from '../../constants/routes';
import Dashboard, { DashboardSection, DashboardSectionTitle } from '../../components/dashboard';
import { Menu } from '../../components/menu/Menu';
import CompanyInvitationForm from './CompanyInvitationForm';
import { AdminBreadcrumbs } from '../admin-dashboard/AdminBreadcrumbs';
import { CompanyImport } from './CompanyImport';
import { searchInitiativeCompaniesByName } from '../../actions/initiative';
import { InitiativeSuggestion } from '../../constants/initiative';

export enum SponsorMode {
  Email = 'by-email',
  Import = 'by-import',
}

interface SponsorCompaniesContainerProps {
  portfolioId: string;
  view: SponsorMode | undefined;
  handleReload: () => void;
}

const getUrls = (portfolioId: string) => {
  const getUrl = (view: SponsorMode) =>
    generateUrl(ROUTES.PORTFOLIO_TRACKER_MANAGE_COMPANIES, {
      portfolioId,
      page: 'invite',
      view,
    });

  return {
    email: getUrl(SponsorMode.Email),
    import: getUrl(SponsorMode.Import),
  };
};

export const SponsorCompaniesContainer = ({ portfolioId, view, handleReload }: SponsorCompaniesContainerProps) => {
  const history = useHistory();
  const urls = getUrls(portfolioId);
  
  const [existingCompanies, setExistingCompanies] = useState<InitiativeSuggestion[] | undefined>();

  React.useEffect(() => {
    searchInitiativeCompaniesByName().then(setExistingCompanies);
  }, []);

  const renderView = () => {
    switch (view) {
      case SponsorMode.Import:
        return <CompanyImport portfolioId={portfolioId} handleReload={handleReload} existingCompanies={existingCompanies} />;
      case SponsorMode.Email:
      default:
        return <CompanyInvitationForm portfolioId={portfolioId} handleReload={handleReload} />;
    }
  };
  
  const breadcrumbs = [
    {
      label: 'Manage companies',
      url: generateUrl(ROUTES.PORTFOLIO_TRACKER_MANAGE_COMPANIES, {
        portfolioId,
      }),
    },
    {
      label: 'Create sponsored company'
    }
  ]

  return (
    <Dashboard className='companyManagement'>
      <div className='pl-2 mb-3'>
        <AdminBreadcrumbs breadcrumbs={breadcrumbs} initiativeId={portfolioId} isPortfolioTracker={true} />
      </div>
      <DashboardSectionTitle title='Sponsor new companies' className='-ms-2' />
      <DashboardSection>
        <Menu
          items={[
            {
              active: view === SponsorMode.Email,
              onClick: () => history.push(urls.email),
              label: 'Email invite',
            },
            {
              active: view === SponsorMode.Import,
              onClick: () => history.push(urls.import),
              label: 'CSV import',
            },
          ]}
        />
        {renderView()}
      </DashboardSection>
    </Dashboard>
  );
};
