import {
  PPTXReportItemParams,
  useDeletePPTXReportMutation,
  useGeneratePPTXReportMutation,
  useGetPPTXReportsQuery,
} from '@api/pptx-reports';
import { useLazyGetBackgroundReportUrlQuery } from '@api/survey-background-report';
import { DashboardSection } from '@components/dashboard';
import Loader from '@components/loader';
import { selectMostRecentSurveyByPeriodAndType } from '@g17eco/slices/initiativeSurveyListSlice';
import { PPTXTemplateScheme } from '@g17eco/types/download';
import { SurveyType } from '@g17eco/types/survey';
import { RootState, useAppSelector } from '@reducers/index';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import NotAuthorised from '@routes/not-authorised';
import { isStaff, isUserManagerByInitiativeId } from '@selectors/user';
import { FeaturePermissions } from '@services/permissions/FeaturePermissions';
import { PPTXReportsMenu } from './PPTXReportsMenu';
import { PPTXReportsTable } from './PPTXReportsTable';
import { useSiteAlert } from '@hooks/useSiteAlert';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BasicAlert } from '@g17eco/molecules';

interface Props {
  initiativeId: string;
}

export const PPTXReportsContainer = (props: Props) => {
  const { initiativeId } = props;
  const history = useHistory();
  const { addSiteAlert, addSiteError } = useSiteAlert();
  const mostRecentSurvey = useAppSelector((state: RootState) =>
    selectMostRecentSurveyByPeriodAndType(state, undefined, SurveyType.Default)
  );
  const userIsManager = useAppSelector((state) => isUserManagerByInitiativeId(state, initiativeId));
  const userIsStaff = useAppSelector(isStaff);
  const canUsePPTX = useAppSelector(FeaturePermissions.canAccessPPTXReportAI);
  const showPPTX = userIsStaff || canUsePPTX;

  const { data, isFetching, error: fetchError } = useGetPPTXReportsQuery(initiativeId ?? skipToken);
  const [getDownloadReportUrl, { error: downloadError }] = useLazyGetBackgroundReportUrlQuery();
  const [generateReport, { isLoading: isCreating }] = useGeneratePPTXReportMutation();
  const [deleteReport, { isLoading: isDeleting }] = useDeletePPTXReportMutation();

  const { generatingReport, latestReports = [], previousReports = [] } = data ?? {};
  const isLoading = isFetching || isCreating || isDeleting || !mostRecentSurvey;
  const error = fetchError || downloadError;

  const onDownload = useCallback(
    (downloadUrl: string | undefined) => {
      if (downloadUrl) {
        window.open(downloadUrl, '_blank', '');
        return;
      }
      addSiteError({ content: 'Unable to download report. If this occurs again, please generate a new report.' });
    },
    [addSiteError]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get('jobId');
    const taskId = searchParams.get('taskId');
    const surveyId = searchParams.get('surveyId');
    if (!jobId || !taskId || !surveyId) {
      return;
    }
    getDownloadReportUrl({ surveyId, jobId, taskId })
      .then((response) => {
        const { downloadUrl } = response.data || {};
        onDownload(downloadUrl);
      })
      .finally(() => history.replace(location.pathname));
  }, [getDownloadReportUrl, history, onDownload]);

  if (!showPPTX) {
    return <NotAuthorised />;
  }

  const handleGenerateReport = ({ scheme, debug = false }: { scheme: PPTXTemplateScheme; debug?: boolean }) => {
    if (!mostRecentSurvey) {
      return;
    }
    generateReport({ surveyId: mostRecentSurvey._id, scheme, debug: debug ? 'true' : undefined })
      .unwrap()
      .then(() => {
        addSiteAlert({
          content: 'Your report is being prepared. You will receive a notification when it is ready to download.',
        });
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  const handleDownload = ({ jobId, taskId, surveyId }: PPTXReportItemParams) => {
    getDownloadReportUrl({ surveyId, jobId, taskId }).then((response) => {
      const { downloadUrl } = response.data || {};
      onDownload(downloadUrl);
    });
  };

  const handleDeleteReport = ({ jobId, taskId, surveyId }: PPTXReportItemParams) => {
    if (!userIsManager) {
      return;
    }
    deleteReport({ jobId, taskId, surveyId })
      .unwrap()
      .then(() => {
        addSiteAlert({ content: 'Report is deleted successfully.' });
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  const TABLE_SECTIONS = [
    {
      title: 'Latest Reports (24 hours)',
      data: latestReports,
    },
    {
      title: 'Previous Reports (18 months)',
      data: previousReports,
    },
  ];

  return (
    <>
      {isLoading ? <Loader /> : null}
      {error ? <BasicAlert type='danger'>{error.message}</BasicAlert> : null}
      <PPTXReportsMenu isGeneratingReport={Boolean(generatingReport)} generateReport={handleGenerateReport} />
      {TABLE_SECTIONS.map(({ title, data }, idx) => (
        <DashboardSection key={idx} title={title} padding={0}>
          <PPTXReportsTable
            data={data}
            handleDownload={handleDownload}
            handleDelete={userIsManager ? handleDeleteReport : undefined}
          />
        </DashboardSection>
      ))}
    </>
  );
};
