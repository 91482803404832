import { Badge } from 'reactstrap';
import { SurveyModelMinimalUtrv } from '../../../model/surveyData';
import { assuranceStatusColumns } from '../overview/constants';
import { UtrvAssuranceStatus } from '@g17eco/types/universalTrackerValue';

interface QuestionStatusBadgeProps {
  utrv?: SurveyModelMinimalUtrv;
}

export const QuestionStatusBadge = ({ utrv }: QuestionStatusBadgeProps) => {
  if (!utrv || !utrv.assuranceStatus) {
    return null;
  }

  if (utrv.assuranceStatus === UtrvAssuranceStatus.Partial) {
    return (
      <div className='ml-2 d-inline-block'>
        <Badge color='primary' className={'questionContainer__partial-assurance-badge'} pill>
          partially assured
        </Badge>
      </div>
    );
  }

  return (
    <div className='ml-2 d-inline-block'>
      <Badge color='primary' className={'questionContainer__status-badge'} pill>
        {assuranceStatusColumns[utrv.assuranceStatus]?.text}
      </Badge>
    </div>
  );
};
