import { UserRoles } from '../../constants/user';
import { SurveyUserRoles } from '../../constants/users';
import { CurrentUserData } from '../../reducers/current-user';
import { InitiativePermissions } from '../../services/permissions/InitiativePermissions';
import { SurveyPermissions } from '../../services/permissions/SurveyPermissions';
import { UtrvPermissions } from '../../services/permissions/UtrvPermissions';
import { UniversalTrackerValuePlain } from '../../types/surveyScope';
import { DelegatedUsers, DelegationModalSurvey, RoleLevel, User } from './types';

export const hasAtLeastOneRoleLevel = (levels: RoleLevel[], userRole: SurveyUserRoles, user: User) => {
  const key = userRole === SurveyUserRoles.Stakeholder ? 'canContributeRoles' : 'canVerifyRoles';

  return levels.some((level) => {
    switch (level) {
      case RoleLevel.Initiative:
        return user.initiativeRoles?.some((r) => InitiativePermissions[key].includes(r as UserRoles));
      case RoleLevel.Survey:
        return user.surveyRoles?.some((r) => SurveyPermissions[key].includes(r as SurveyUserRoles));
      case RoleLevel.Utrv:
        return user.roles?.some((r) => SurveyPermissions[key].includes(r as SurveyUserRoles));
      default:
        return false;
    }
  });
};

export const isAssignedToAll = (userRole: SurveyUserRoles, user: User, utrvsLength: number) => {
  const isAssignedToAll =
    user.count?.[userRole === SurveyUserRoles.Stakeholder ? 'contributed' : 'verified'] === utrvsLength;
  return hasAtLeastOneRoleLevel([RoleLevel.Initiative, RoleLevel.Survey], userRole, user) || isAssignedToAll;
};

export const surveyRoles = [SurveyUserRoles.Stakeholder, SurveyUserRoles.Verifier];

export const filterUsersByRole = (users: DelegatedUsers, userRole: SurveyUserRoles) => {
  return userRole === SurveyUserRoles.Stakeholder ? users.contributors : users.verifiers;
};

export const filterExplicitUsers = (userRole: SurveyUserRoles, users: User[]) => {
  return users.filter((user) => hasAtLeastOneRoleLevel([RoleLevel.Utrv], userRole, user));
};

export const putCurrentUserToTop = (
  userRole: SurveyUserRoles,
  users: User[],
  currentUser: CurrentUserData | undefined
) => {
  const currentUserIndex = users.findIndex(
    (user) =>
      user._id === currentUser?._id && hasAtLeastOneRoleLevel([RoleLevel.Initiative, RoleLevel.Survey], userRole, user)
  );

  return currentUserIndex < 0
    ? users
    : [users[currentUserIndex], ...users.slice(0, currentUserIndex), ...users.slice(currentUserIndex + 1)];
};

export const hasUserRolePermission = (
  userRole: SurveyUserRoles,
  utrvs: Pick<UniversalTrackerValuePlain, '_id' | 'stakeholders' | 'initiativeId' | 'compositeData'>[],
  survey: DelegationModalSurvey,
  user: CurrentUserData | undefined,
  initiativeId: string
) => {
  if (!user) {
    return false;
  }

  if (SurveyPermissions.canManage(survey, user)) {
    return true
  }
  if (userRole === SurveyUserRoles.Stakeholder) {
    return UtrvPermissions.canContributeBulk(utrvs, survey, user)
  }

  if (userRole === SurveyUserRoles.Verifier) {
    return UtrvPermissions.canVerifyBulk(utrvs, survey, user)
  }
  return InitiativePermissions.canManageInitiative(user, initiativeId)
};
