import { getGroup, standards } from '@g17eco/core';
import { UniversalTrackerPlain } from '@g17eco/types/universalTracker';
import { CurrentUserData } from '@reducers/current-user';
import { InitiativeTree } from '@utils/initiative';
import { getBadge } from '../utils';

interface Props {
  question: UniversalTrackerPlain;
  user: CurrentUserData | undefined;
  initiativeId: string;
  isInGroup: boolean;
}

export const MetricCell = (props: Props) => {
  const { question, user, initiativeId, isInGroup } = props;
  const { _id: utrId, type, ownerId, alternatives } = question;
  let typeStr = type;
  if (type === 'custom_kpi') {
    typeStr = 'Custom Metric';
    const hasPermission = user && (!ownerId || InitiativeTree.isDescendant(user, initiativeId, ownerId));
    if (!hasPermission) {
      typeStr = 'Assigned Metric';
    }
  }
  if (type === 'metric') {
    typeStr = 'Standardized Metric';
  }
  const selectedBadgeColour =
    type === 'custom_kpi' ? 'background-ThemeSuccessDark' : 'background-ThemeNeutralsExtradark';
  const unselectedBadgeColour =
    type === 'custom_kpi' ? 'background-ThemeSuccessMedium' : 'background-ThemeNeutralsDark';
  const badgeColour = isInGroup ? selectedBadgeColour : unselectedBadgeColour;

  const badges = [
    getBadge(standards[type]?.shortName ?? typeStr, standards[type]?.name ?? typeStr, badgeColour, `${utrId}-${type}`),
  ];
  if (alternatives) {
    for (const key of Object.keys(alternatives)) {
      const group = getGroup('standards', key);
      if (group && key !== type) {
        badges.push(getBadge(group.shortName ?? group.name, group.name, badgeColour, `${utrId}-${key}`));
      }
    }
  }
  return <>{badges.map((badge) => badge)}</>;
};
