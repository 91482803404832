import { FormGroup, Input } from 'reactstrap';
import { generateDecimalExample } from '../survey/utils/input';
import { NumberInput } from './NumberInput';
import { GLOBAL_UPDATE_CODE, InputOverrideType } from '@features/question-configuration/utils';
import { DecimalType, ErrorDecimalType } from './utils';

export interface DecimalInputProps {
  decimal: DecimalType;
  error: ErrorDecimalType;
  columnCode?: string;
  onToggleSwitch: (props: { type: InputOverrideType; code: string }) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const generateExample = ({ value, hasError }: { value: number | undefined; hasError: boolean }) => {
  return hasError || value === undefined ? '-' : generateDecimalExample(value);
};

export const DecimalInput = ({ decimal, error, columnCode, onChange, onToggleSwitch }: DecimalInputProps) => {
  const code = columnCode ?? GLOBAL_UPDATE_CODE;
  const { value, isEnforced } = decimal[code];
  const exampleProps = { value, hasError: !!error[code] };

  return (
    <div className='row gx-0 mb-3'>
      <FormGroup switch className='col-6 mt-2' id={`decimal-${code}`}>
        <Input
          type='switch'
          className='mr-2'
          onChange={() => onToggleSwitch({ type: InputOverrideType.Decimal, code })}
          checked={isEnforced}
        />
        <label htmlFor={`decimal-${code}`}>Set decimals</label>
      </FormGroup>
      <div className='col-6'>
        <NumberInput name={code} disabled={!isEnforced} onChange={onChange} value={value} errorMessage={error[code]} />
        <div>Example: {generateExample(exampleProps)}</div>
      </div>
    </div>
  );
};
