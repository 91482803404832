import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';
import { defaultTheme } from './themes/defaultTheme';
import { handleRouteError } from '../../logger';

export const defaultConfig = {
  namespace: 'RichTextEditor',
  theme: defaultTheme,
  onError: (error: Error) => {
    handleRouteError(error);
  },
  nodes: [ListNode, ListItemNode, HeadingNode],
};
