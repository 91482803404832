import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SubmitButton } from '@components/button/SubmitButton';
import { useToggle } from '@hooks/useToggle';
import { RadioInput } from '@components/form/RadioInput';
import { useState } from 'react';
import { useUpdateAllAggregatedSurveysMutation } from '@api/aggregated-surveys';
import { useSiteAlert } from '@hooks/useSiteAlert';
import { SURVEY } from '@constants/terminology';
import { SimpleTooltip } from '@g17eco/molecules';

interface Props {
  initiativeId: string;
  canUpdate: boolean;
}

const options = [{ code: 'all', name: 'All levels' }];

export const UpdateCombinedSurveyButton = ({ initiativeId, canUpdate }: Props) => {
  const [open, toggle] = useToggle();
  const [option, setOption] = useState(options[0].code);

  const [updateAllAggregatedSurveys] = useUpdateAllAggregatedSurveysMutation();

  const { addSiteAlert, addSiteError } = useSiteAlert();

  const handleSubmit = async () => {
    return updateAllAggregatedSurveys({ initiativeId })
      .unwrap()
      .then(() => {
        addSiteAlert({
          content: `Combined ${SURVEY.PLURAL} are updating. Notification will be sent when the process is completed`,
        });
      })
      .catch((e) => {
        addSiteError(e.message);
      })
      .finally(() => {
        toggle();
      });
  };
  return (
    <>
      <div className='flex-grow-1 d-flex justify-content-center'>
        <SimpleTooltip
          text={canUpdate ? undefined : 'Combined report cannot be refreshed as at least one metric has been assured.'}
        >
          <Button color='secondary' onClick={toggle} disabled={!canUpdate}>
            Update combined {SURVEY.SINGULAR} data
          </Button>
        </SimpleTooltip>
      </div>
      <Modal isOpen={open} toggle={toggle} backdrop='static'>
        <ModalHeader toggle={toggle}>Update combined {SURVEY.SINGULAR} data</ModalHeader>
        <ModalBody>
          <p>Update data for all available combined {SURVEY.PLURAL}.</p>

          <RadioInput
            groupCode='update-combined-survey'
            value={option}
            options={options}
            onChange={({ value }) => setOption(value)}
            className='d-flex'
            formGroupClassName='mr-5'
          />
        </ModalBody>
        <ModalFooter>
          <Button color='link-secondary' className='mr-3' onClick={toggle}>
            Cancel
          </Button>
          <SubmitButton onClick={handleSubmit}>Update</SubmitButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
