import IconButton from '@components/button/IconButton';

interface Props {
  content: string;
}
export const CopyButton = ({ content }: Props) => {
  const handleClick = () => {
    navigator.clipboard.writeText(content);
  };
  return <IconButton color={'secondary'} icon={'fa-light fa-copy'} onClick={handleClick} className='border-0' />;
};
