import { ScopeGroups } from '../../../model/surveyData';
import { DownloadScopeType, VisibilityStatus } from '../../../types/download';
import { HistoricalReportData, ReportData } from '../../../types/reportData';
import { Tags } from '../../../types/universalTracker';
import { SelectedGroup } from '../../downloads/util/downloadReportHandler';
import { BlueprintContributions } from '../../../types/survey';
import { SCOPES } from '@utils/survey-scope';

export const getDownloadScope = (selectedScopes: SelectedGroup[]) => SCOPES.reduce((acc, current) => ({
  ...acc,
  [current]: selectedScopes
    .filter((item) => item.checked && item.type === current)
    .map((item) => item.code),
}), {} as DownloadScopeType);

// for word documents we want to exclude values only
export const getDocVisibility = (visibility: VisibilityStatus) => {
  return VisibilityStatus.Include === visibility ? VisibilityStatus.Include : VisibilityStatus.ExcludeValuesOnly;
};

const filterSdgByUtrCode = (contributions: BlueprintContributions, utrCode: string, code: string) =>
  contributions?.[utrCode]?.includes(`sdg/${code}`);

export const getScopeGroupData = (
  questionData: HistoricalReportData[],
  selectedScopes: SelectedGroup[],
  blueprintContribution: BlueprintContributions
) => {
  const effectiveDates = questionData.map((survey) => survey.effectiveDate);
  const unitConfigs = questionData.map((survey) => survey.unitConfig);
  const selectedScope = selectedScopes.filter((scope) => scope.checked);
  const selectedGroup = selectedScope.reduce((acc, scope) => {
    if (scope.type === ScopeGroups.Custom) {
      acc[scope.code] = questionData.map((report) => {
        return report.reportData.filter((q) => {
          return scope.universalTrackers?.includes(q.universalTracker._id);
        });
      });
      return acc;
    }

    if (scope.type === ScopeGroups.Sdg) {
      acc[scope.code] = questionData.map((report) => {
        return report.reportData.filter((q) =>
          filterSdgByUtrCode(blueprintContribution, q.universalTracker.code, scope.code)
        );
      });
      return acc;
    }

    acc[scope.code] = questionData.map((report) => {
      return report.reportData.filter((q) => {
        return (
          q.universalTracker.type === scope.code ||
          q.universalTracker.alternatives?.[scope.code] ||
          q.universalTracker.tags?.[scope.code as keyof Tags]?.length
        );
      });
    });

    return acc;
  }, {} as Record<string, ReportData[][]>);

  return selectedScope.map((scope) => {
    const code = scope.code;
    return {
      ...scope,
      questionData: selectedGroup[code].map((item, index) => ({
        reportData: item,
        effectiveDate: effectiveDates[index],
        unitConfig: unitConfigs[index],
      })),
    };
  });
};
