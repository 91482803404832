import React from 'react'
import Chart from 'react-google-charts';
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { DATE, formatDateUTC } from '../../../../utils/date';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';
import { getComments } from '../sgx-metric-report-generator';
import { getUtrvData } from '../../reportData';

const WIDTH = 500;
const HEIGHT = 300;

type ConsumptionRow = {
  date: string;
  consumption: number | string;
  intensity: number | string;
  notes?: {
    [key: string]: string;
  }
};

export interface EnergyConsumptionDataSource extends DataSource {
  rows?: ConsumptionRow[];
  comments?: string;
}

const EnergyConsumption = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData, visibility } = props;


  const consumptionCode = 'gri/2020/302-1/e';
  const intensityCode = 'gri/2020/302-3/a';
  const { rows, chartRows } = questionData.reduce((acc, { effectiveDate, reportData }) => {
    const consumption = getUtrvData({ visibility, reportData, utrCode: consumptionCode });
    const intensity = getUtrvData({ visibility, reportData, utrCode: intensityCode });

    const date = formatDateUTC(effectiveDate, DATE.MONTH_YEAR_SHORT);

    acc.chartRows.unshift([date, consumption.numericValue, intensity.numericValue])

    acc.rows.push({
      date: date,
      consumption: consumption.value ?? '',
      intensity: intensity.value ?? '',
      notes: {
        consumption: getComments(reportData, consumptionCode, visibility, ''),
        intensity: getComments(reportData, intensityCode, visibility, '')
      }
    })

    return acc;
  }, { rows: [] as ConsumptionRow[], chartRows: [] as [string, number, number][] });

    const data: EnergyConsumptionDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    rows,
    comments: getComments(questionData[0]?.reportData ?? [], consumptionCode, visibility)
  }

  const chartData = [
    ['Period', 'Consumption (MWh)', 'Intensity'],
    ...chartRows
  ];

  return <Chart
    chartType='ComboChart'
    data={chartData}
    width={WIDTH}
    height={HEIGHT}
    options={{
      ...CHART_DEFAULTS.OPTIONS,
      isStacked: false,
      chartArea: {
        top: 40,
        bottom: 20,
        left: 100,
        right: 100,
      },
      seriesType: 'bars',
      series: {
        1: {
          type: 'line',
          targetAxisIndex: 1
        }
      },
      vAxes: {
        0: {
          title: 'Consumption',
        },
        1: {
          title: 'Intensity',
          maxValue: 100
        }
      }
    }}
    chartEvents={
      updateData ?
        [
          {
            eventName: 'ready',
            callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
              data.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
              updateData('energyConsumption', data);
            }
          }
        ] : undefined}
  />
})

export default EnergyConsumption;
