import { SurveyTypeOptions, getSurveyTypeOptions } from './utils';
import { SurveyType } from '@g17eco/types/survey';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { SURVEY } from '@constants/terminology';

export interface SurveyTypeDropdownProps<T extends SurveyTypeOptions = SurveyType.Default | SurveyType.Aggregation> {
  surveyType: T;
  setSurveyType?: (surveyType: T) => void;
  hasAllOption?: boolean;
  disabled?: boolean;
  styleProps?: {
    dropdown?: string;
    toggle?: string;
    isTransparent?: boolean;
    isFlexibleSize?: boolean;
  };
}

export const SurveyTypeDropdown = <T extends SurveyTypeOptions = SurveyType.Default | SurveyType.Aggregation>({
  surveyType,
  setSurveyType = () => {},
  hasAllOption = false,
  disabled = false,
  styleProps = {
    dropdown: 'ml-3'
  },
}: SurveyTypeDropdownProps<T>) => {
  const options = getSurveyTypeOptions<T>(hasAllOption);

  return (
    <SelectFactory
      selectType={SelectTypes.SingleSelect}
      options={options}
      placeholder={`Select ${SURVEY.SINGULAR} type`}
      value={options.find(({ value }) => value === surveyType)}
      onChange={(op) => setSurveyType(op?.value ?? ('all' as T))}
      isDisabled={disabled}
      className={styleProps.dropdown}
      isSearchable={false}
      isTransparent={styleProps.isTransparent}
      isFlexibleSize={styleProps.isFlexibleSize}
    />
  );
};
