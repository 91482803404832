import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ValueCalculation from '@components/utr-modal/components/value-calculation';

interface Props {
  surveyId: string;
  isOpen: boolean;
  toggle: () => void;
  utrCode: string;
}

export const CalculationModal = (props: Props) => {
  const { surveyId, utrCode, isOpen, toggle } = props;

  return (
    <Modal className='modal-lg' isOpen={isOpen} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Calculation</ModalHeader>
      <ModalBody>
        <ValueCalculation surveyId={surveyId} utrCode={utrCode} isVisible={isOpen} />
      </ModalBody>
    </Modal>
  );
};
