import { useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { createMetricGroup } from '../../actions/initiative';
import { AnalyticsEvents } from '../../services/analytics/AnalyticsEvents';
import { getAnalytics } from '../../services/analytics/AnalyticsService';
import { MetricGroup } from '../../types/metricGroup';
import Loader from '../loader';
import { PACK } from '@constants/terminology';
import { BasicAlert } from '@g17eco/molecules';

export const useDuplicateMetricGroup = () => {
  const [metricGroup, setMetricGroup] = useState<MetricGroup>();

  return {
    isDuplicating: !!metricGroup,
    stopDuplicating: () => setMetricGroup(undefined),
    metricGroup,
    setMetricGroup,
  };
};

interface Props {
  isOpen: boolean;
  isPortfolioTracker: boolean;
  toggle: () => void;
  metricGroup: MetricGroup | undefined;
  onDuplicated: () => void;
  metricGroups: MetricGroup[];
}

export const DuplicateMetricGroupModal = ({
  isOpen,
  isPortfolioTracker,
  toggle,
  metricGroup,
  onDuplicated,
  metricGroups,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const title = `Duplicate ${isPortfolioTracker ? 'assigned' : 'custom'} metric`;
  const placeholder = `Copy of ${metricGroup?.groupName}`;
  const onClickDuplicate = async () => {
    const isNameDuplicate = metricGroups.some((group) => group.groupName === name);
    if (isNameDuplicate) {
      setError(`Group name "${name}" already exists`);
      return;
    }

    setLoading(true);
    const initiativeId = metricGroup?.initiativeId;
    try {
      const newGroup = await createMetricGroup(initiativeId, {
        ...metricGroup,
        groupName: name,
      });
      const analytics = getAnalytics();
      await analytics.track(AnalyticsEvents.CustomMetricGroupCreated, {
        initiativeId,
        _id: newGroup._id,
      });

      onClose();
      onDuplicated();
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };

  const onClose = () => {
    setLoading(false);
    setError('');
    setName('');
    toggle();
  };

  if (loading) return <Loader />;

  return (
    <Modal isOpen={isOpen || !!metricGroup} toggle={onClose} backdrop='static'>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        <h5>Original {PACK.SINGULAR} name:</h5>
        <p className='mb-1'>{metricGroup?.groupName}</p>
        <h5>Duplicate {PACK.SINGULAR} name:</h5>
        <Input
          id='duplicate-name'
          type='text'
          placeholder={placeholder}
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
          bsSize='sm'
          className='text-md'
          data-testid='duplicate-metric-group-input'
        />
        {error ? <BasicAlert type={'danger'}>{error}</BasicAlert> : null}
      </ModalBody>
      <ModalFooter>
        <Button color='link' onClick={onClose}>
          Cancel
        </Button>
        <Button color='primary' onClick={onClickDuplicate} disabled={!name} data-testid='duplicate-metric-group-submit-btn'>
          {isPortfolioTracker ? 'Duplicate without assigned companies' : 'Duplicate'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
