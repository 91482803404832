import { createSelector } from '@reduxjs/toolkit';
import { isCTL } from '../utils/permission-groups';
import { getDefaultConfig } from '../config/app-config';
import { OnboardingToken } from '../types/onboarding';
import { AppConfig } from '../types/app';
import { getRootAppConfig } from './initiative';


const ctlApp = getDefaultConfig().branding.ctlApp;

export const getOnboardingAppPath = createSelector(
  [state => state.onboardingToken],
  (obState) => {
    const onboardingToken = obState.data as Partial<OnboardingToken>

    if (isCTL(onboardingToken)) {
      return onboardingToken.appConfig?.rootAppPath ?? ctlApp.rootAppPath
    }

    return 'company-tracker';
  })

export const getSurveyOverviewMode = createSelector(
  [state => state.globalData],
  (globalData) => {
    if (!globalData.loaded || !globalData.data.appConfig) {
      return ctlApp.defaultSurveyOverviewMode;
    }

    const appConfig = globalData.data.appConfig as AppConfig
    return appConfig.settings.defaultSurveyOverviewMode ?? ctlApp.defaultSurveyOverviewMode
  })

export const getAppSettings = createSelector(
  [getRootAppConfig],
  (appConfig) => {
    if (appConfig) {
      return appConfig.settings
    }
    return getDefaultConfig().branding.ctlApp
  })
