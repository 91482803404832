import { DashboardSectionCollapsible } from '@components/dashboard';
import { TableColumn } from '@components/survey/form/input/table/InputInterface';
import { DecimalInputProps } from '@components/utr-decimal/DecimalInput';
import { isDefined } from '@utils/index';
import { useState } from 'react';
import { UnitOverrideProps } from './OverrideUnitForm';
import { GLOBAL_UPDATE_CODE, InputOverrideDataType, InputOverrideType, UnitConfigType, UnitTypeMapValue } from '../utils';
import { InputOverrideField } from './InputOverrideField';
import { SimpleTooltip } from '@g17eco/molecules';

const hasSetUnitOverride = ({
  columnCode,
  unitConfig,
}: {
  columnCode: string;
  unitConfig: Record<string, Record<string, InputOverrideDataType>>;
}) => {
  const unitTypes = Object.keys(unitConfig[columnCode]);
  return unitTypes.some((unitType) => {
    const value = unitConfig[columnCode][unitType];
    return value.isEnforced;
  });
};

const getColumnName = (column: TableColumn, hasColumnOverrides: boolean) => {
  return (
    <SimpleTooltip text={column.name}>
      <div className='d-flex align-items-center justify-content-between'>
        <div
          className='text-ColourBlack text-md fw-semibold text-truncate'
          style={{ maxWidth: hasColumnOverrides ? '360px' : '400px' }}
        >
          {column.name}
        </div>
        {hasColumnOverrides ? <i className='fal fa-bell-exclamation text-ThemeWarningMedium' /> : null}
      </div>
    </SimpleTooltip>
  );
};

interface Props extends DecimalInputProps, Omit<UnitOverrideProps, 'unitTypeMap' | 'type'> {
  numericColumns: TableColumn[];
  unitTypeMap: Record<string, Map<string, UnitTypeMapValue>>;
  unitConfig: UnitConfigType;
}

export const InputOverrideGroup = (props: Props) => {
  const { numericColumns, unitTypeMap, unitConfig, decimal } = props;
  const [expandedColumns, setExpandedColumns] = useState<string[]>(
    numericColumns.length === 1 ? [numericColumns[0].code] : []
  );

  if (numericColumns.length === 0) {
    return (
      <>
        {Object.values(InputOverrideType).map((type) => (
          <InputOverrideField key={type} type={type} {...props} unitTypeMap={unitTypeMap[GLOBAL_UPDATE_CODE]} />
        ))}
      </>
    );
  }

  const hasColumnOverrides = (columnCode: string) => {
    return isDefined(decimal[columnCode].value) || hasSetUnitOverride({ columnCode, unitConfig });
  };

  const toggleExpandedColumns = (columnCode: string) => {
    return setExpandedColumns(
      expandedColumns.includes(columnCode)
        ? expandedColumns.filter((code) => code !== columnCode)
        : [...expandedColumns, columnCode]
    );
  };

  return (
    <>
      {numericColumns.map((col) => (
        <DashboardSectionCollapsible
          key={col.code}
          title={getColumnName(col, hasColumnOverrides(col.code))}
          className='border-0'
          classes={{ whiteBoxContainer: 'border-0', body: 'pe-1' }}
          mb={0}
          padding={0}
          paddingInternal={0}
          isCollapsed={!expandedColumns.includes(col.code)}
          toggleCollapse={() => toggleExpandedColumns(col.code)}
        >
          <div className='pe-1'>
            {Object.values(InputOverrideType).map((type) => (
              <InputOverrideField
                key={type}
                type={type}
                columnCode={col.code}
                {...props}
                unitTypeMap={unitTypeMap[col.code]}
              />
            ))}
          </div>
        </DashboardSectionCollapsible>
      ))}
    </>
  );
};
