import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBenchmarkingSurveys } from '@actions/portfolio-tracker';
import { InitiativeBenchmarking } from '@g17eco/types/initiative';
import Loader from '@components/loader';
import { getDateRange } from '@g17eco/molecules';
import { DateRangeType } from '@g17eco/types/common';
import { BenchmarkingTemplate } from '../BenchmarkingTemplate';
import LatestSurveyFilters, { Filters } from './latest-survey-filters';
import LatestSurveyTable from './latest-survey-table';
import { hasCompletedSurveys } from './utils';
import './styles.scss';
import { TIME_RANGE_YEARLY } from '@utils/date';
import { SURVEY } from '@constants/terminology';

interface ApplyFilterCompanies {
  companies: InitiativeBenchmarking[];
  checkedCompletedSurveys: boolean;
  filters: Filters;
}

const applyFilterCompanies = ({
  companies,
  checkedCompletedSurveys,
  filters,
}: ApplyFilterCompanies): InitiativeBenchmarking[] => {
  const completedSurveysCondition = (company: InitiativeBenchmarking) =>
    checkedCompletedSurveys ? hasCompletedSurveys(company) : true;

  const sectorCondition = (company: InitiativeBenchmarking) =>
    filters.sector ? company.sectorText === filters.sector : true;

  const industryCondition = (company: InitiativeBenchmarking) =>
    filters.industry ? company.industryText === filters.industry : true;

  return companies.filter(
    (company) => completedSurveysCondition(company) && sectorCondition(company) && industryCondition(company)
  );
};

const LatestSurveyProgress = () => {
  const { portfolioId } = useParams<{portfolioId: string}>();
  const [benchmarkingCompanies, setBenchmarkingCompanies] = useState<InitiativeBenchmarking[]>([]);
  const [loading, setLoading] = useState(false);

  // Filters state
  const [checkedCompletedSurveys, setCheckedCompletedSurveys] = useState(false);
  const [filters, setFilters] = useState<Filters>({});
  const [dateRange, setDateRange] = useState<DateRangeType>(getDateRange(TIME_RANGE_YEARLY));
  const hasFilters = Boolean(
    checkedCompletedSurveys || Object.values(filters).some((item) => Boolean(item)) || dateRange
  );

  const filteredCompanies = useMemo(() => applyFilterCompanies({
    companies: benchmarkingCompanies,
    checkedCompletedSurveys,
    filters,
  }), [benchmarkingCompanies, checkedCompletedSurveys, filters]);

  const companiesWithCompletedSurvey = useMemo(() => filteredCompanies.filter(hasCompletedSurveys), [filteredCompanies]);

  const companiesWithCompletedSurveyPercent = filteredCompanies.length
    ? Number((companiesWithCompletedSurvey.length / filteredCompanies.length) * 100)
    : 0;

  const loadBenchmarkingLatestSurveys = useCallback(() => {
    setLoading(true);
    getBenchmarkingSurveys(portfolioId ?? '', { dateRange, surveyPacks: filters.surveyPacks })
      .then((data) => {
        setBenchmarkingCompanies(data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [portfolioId, dateRange, filters.surveyPacks]);

  useEffect(() => {
    if (portfolioId) {
      loadBenchmarkingLatestSurveys();
    }
  }, [portfolioId, loadBenchmarkingLatestSurveys]);

  return (
    <BenchmarkingTemplate>
      <div className='latest-survey-progress'>
        <LatestSurveyFilters
          filteredCompanies={filteredCompanies}
          filters={filters}
          dateRange={dateRange}
          changeCheckedCompletedSurveys={(checked) => setCheckedCompletedSurveys(checked)}
          changeFilters={(value) => setFilters(value)}
          changeDateRange={(value) => setDateRange(value)}
        />
        <LatestSurveyTable portfolioId={portfolioId} companies={filteredCompanies} hasFilters={hasFilters} />
        {loading && <Loader />}
        <div className='mt-3 companies-completed-survey'>
          <span className='text-HeadingLight text-uppercase'>% COMPANIES WITH COMPLETED {SURVEY.SINGULAR}</span>
          <span className='companies-completed-survey__percent'>{`${
            companiesWithCompletedSurveyPercent > 0 ? companiesWithCompletedSurveyPercent.toFixed(1) : 0
          }%`}</span>
        </div>
      </div>
    </BenchmarkingTemplate>
  );
};

export default LatestSurveyProgress;
