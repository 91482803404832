import { InputOverrideType } from '@features/question-configuration';
import { UtrvConfigCode } from '@features/question-configuration/metric-override/contants';
import React, { useState } from 'react';

interface QuestionManagementContextProps {
  inputOverrideType: InputOverrideType | undefined;
  setInputOverrideType: (type: InputOverrideType | undefined) => void;
  utrvConfigCode: UtrvConfigCode | undefined;
  setUtrvConfigCode: (code: UtrvConfigCode | undefined) => void;
}

const initialState: QuestionManagementContextProps = {
  inputOverrideType: undefined,
  setInputOverrideType: () => {},
  utrvConfigCode: undefined,
  setUtrvConfigCode: () => {},
};

export const QuestionManagementContext = React.createContext(initialState);

export default function QuestionManagementContainer({ children }: { children: React.ReactNode }) {
  const [inputOverrideType, setInputOverrideType] = useState<InputOverrideType | undefined>(
    initialState.inputOverrideType
  );
  const [utrvConfigCode, setUtrvConfigCode] = useState<UtrvConfigCode | undefined>(initialState.utrvConfigCode);

  const contextValue: QuestionManagementContextProps = {
    inputOverrideType,
    setInputOverrideType,
    utrvConfigCode,
    setUtrvConfigCode,
  };

  return <QuestionManagementContext.Provider value={contextValue}>{children}</QuestionManagementContext.Provider>;
}
