export type RequiredTargetFlagType = {
  target: string;
  gri2020?: 'Y';
  blueprint2022?: 'Y';
};
export const REQUIRED_TARGET_FLAGS: RequiredTargetFlagType[] = [
  {
    target: '1.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '1.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '1.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '1.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '1.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '1.a',
    blueprint2022: 'Y',
  },
  {
    target: '1.b',
    blueprint2022: 'Y',
  },

  {
    target: '2.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '2.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '2.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '2.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '2.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '2.a',
    blueprint2022: 'Y',
  },
  {
    target: '2.b',
    blueprint2022: 'Y',
  },
  {
    target: '2.c',
    blueprint2022: 'Y',
  },

  {
    target: '3.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '3.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '3.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '3.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '3.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '3.6',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '3.7',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '3.8',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '3.9',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '3.a',
    blueprint2022: 'Y',
  },
  {
    target: '3.b',
    blueprint2022: 'Y',
  },
  {
    target: '3.c',
    blueprint2022: 'Y',
  },
  {
    target: '3.d',
    blueprint2022: 'Y',
  },

  {
    target: '4.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '4.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '4.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '4.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '4.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '4.7',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '4.a',
    blueprint2022: 'Y',
  },
  {
    target: '4.b',
    blueprint2022: 'Y',
  },
  {
    target: '4.c',
    blueprint2022: 'Y',
  },

  {
    target: '5.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '5.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '5.3',
    blueprint2022: 'Y',
  },
  {
    target: '5.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '5.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '5.6',
    blueprint2022: 'Y',
  },
  {
    target: '5.a',
    blueprint2022: 'Y',
  },
  {
    target: '5.b',
    blueprint2022: 'Y',
  },
  {
    target: '5.c',
    blueprint2022: 'Y',
  },

  {
    target: '6.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '6.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '6.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '6.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '6.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '6.6',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '6.a',
    blueprint2022: 'Y',
  },
  {
    target: '6.b',
    blueprint2022: 'Y',
  },

  {
    target: '7.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '7.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '7.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '7.a',
    blueprint2022: 'Y',
  },
  {
    target: '7.b',
    blueprint2022: 'Y',
  },

  {
    target: '8.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '8.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '8.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '8.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '8.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '8.6',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '8.7',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '8.8',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '8.9',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '8.10',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '8.a',
    blueprint2022: 'Y',
  },
  {
    target: '8.b',
    blueprint2022: 'Y',
  },

  {
    target: '9.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '9.2',
    blueprint2022: 'Y',
  },
  {
    target: '9.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '9.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '9.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '9.a',
    blueprint2022: 'Y',
  },
  {
    target: '9.b',
    blueprint2022: 'Y',
  },
  {
    target: '9.c',
    blueprint2022: 'Y',
  },

  {
    target: '10.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '10.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '10.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '10.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '10.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '10.6',
    blueprint2022: 'Y',
  },
  {
    target: '10.7',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '10.a',
    blueprint2022: 'Y',
  },
  {
    target: '10.b',
    blueprint2022: 'Y',
  },
  {
    target: '10.c',
    blueprint2022: 'Y',
  },

  {
    target: '11.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '11.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '11.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '11.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '11.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '11.6',
    blueprint2022: 'Y',
  },
  {
    target: '11.7',
    blueprint2022: 'Y',
  },
  {
    target: '11.a',
    blueprint2022: 'Y',
  },
  {
    target: '11.b',
    blueprint2022: 'Y',
  },
  {
    target: '11.c',
    blueprint2022: 'Y',
  },

  {
    target: '12.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '12.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '12.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '12.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '12.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '12.6',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '12.7',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '12.8',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '12.a',
    blueprint2022: 'Y',
  },
  {
    target: '12.b',
    blueprint2022: 'Y',
  },
  {
    target: '12.c',
    blueprint2022: 'Y',
  },

  {
    target: '13.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '13.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '13.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '13.a',
    blueprint2022: 'Y',
  },
  {
    target: '13.b',
    blueprint2022: 'Y',
  },

  {
    target: '14.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '14.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '14.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '14.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '14.5',
    blueprint2022: 'Y',
  },
  {
    target: '14.6',
    blueprint2022: 'Y',
  },
  {
    target: '14.7',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '14.a',
    blueprint2022: 'Y',
  },
  {
    target: '14.b',
    blueprint2022: 'Y',
  },
  {
    target: '14.c',
    blueprint2022: 'Y',
  },

  {
    target: '15.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '15.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '15.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '15.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '15.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '15.6',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '15.7',
    blueprint2022: 'Y',
  },
  {
    target: '15.8',
    blueprint2022: 'Y',
  },
  {
    target: '15.9',
    blueprint2022: 'Y',
  },
  {
    target: '15.a',
    blueprint2022: 'Y',
  },
  {
    target: '15.b',
    blueprint2022: 'Y',
  },
  {
    target: '15.c',
    blueprint2022: 'Y',
  },

  {
    target: '16.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '16.2',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '16.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '16.4',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '16.5',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '16.6',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '16.7',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '16.8',
    blueprint2022: 'Y',
  },
  {
    target: '16.9',
    blueprint2022: 'Y',
  },
  {
    target: '16.10',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '16.a',
    blueprint2022: 'Y',
  },
  {
    target: '16.b',
    blueprint2022: 'Y',
  },

  {
    target: '17.1',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '17.2',
    blueprint2022: 'Y',
  },
  {
    target: '17.3',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '17.4',
    blueprint2022: 'Y',
  },
  {
    target: '17.5',
    blueprint2022: 'Y',
  },
  {
    target: '17.6',
    blueprint2022: 'Y',
  },
  {
    target: '17.7',
    blueprint2022: 'Y',
  },
  {
    target: '17.8',
    blueprint2022: 'Y',
  },
  {
    target: '17.9',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '17.10',
    blueprint2022: 'Y',
  },
  {
    target: '17.11',
    blueprint2022: 'Y',
  },
  {
    target: '17.12',
    blueprint2022: 'Y',
  },
  {
    target: '17.13',
    blueprint2022: 'Y',
  },
  {
    target: '17.14',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '17.15',
    blueprint2022: 'Y',
  },
  {
    target: '17.16',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '17.17',
    gri2020: 'Y',
    blueprint2022: 'Y',
  },
  {
    target: '17.18',
    blueprint2022: 'Y',
  },
  {
    target: '17.19',
    blueprint2022: 'Y',
  },
];
