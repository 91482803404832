import { UtrvAssuranceStatus } from '../../../types/universalTrackerValue';

export const assuranceStatusColumns: Partial<Record<UtrvAssuranceStatus, { name: string; text: string }>> =
  {
    created: {
      name: 'text-ThemeTextMedium border-ThemeTextMedium',
      text: 'assurance pending',
    },
    rejected: {
      name: 'text-ThemeWarningMedium border-ThemeWarningMedium',
      text: 'assurance disputed',
    },
    completed: {
      name: 'text-ThemeSuccessMedium border-ThemeSuccessMedium',
      text: 'assured',
    },
  };

export enum MetricActionType {
  Update = 'update',
  Ignore = 'ignore',
}