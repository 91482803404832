import Loader from '../../components/loader';
import { useAppSelector } from '../../reducers';
import { getCurrentUser } from '../../selectors/user';
import { Tree } from '../../types/tree';
import { InitiativeStructureFolders } from './InitiativeStructureFolders';
import { InitiativeStructureArchived } from './InitiativeStructureArchived';
import { getRootOrg } from '../../selectors/initiative';
import './styles-folders.scss';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useGetArchivedSubsidiariesQuery } from '../../api/organisation-map';
import { InitiativePermissions } from '@services/permissions/InitiativePermissions';

export interface InitiativeStructureContainerProps {
  onClick?: (portfolioId?: string) => void;
  customTree: Tree;
  isPortfolioTracker: boolean;
}

export const InitiativeStructureContainer = (props: InitiativeStructureContainerProps) => {
  const currentUser = useAppSelector(getCurrentUser);
  const rootOrg = useAppSelector(getRootOrg);
  const { path } = useRouteMatch();
  const canManage = currentUser && rootOrg ? InitiativePermissions.canManageInitiative(currentUser, rootOrg._id) : false;

  const { data: archivedInitiatives = [], isLoading } = useGetArchivedSubsidiariesQuery(
    {
      initiativeId: rootOrg?._id || '',
    },
    { skip: !rootOrg || !canManage }
  );

  if (!currentUser || isLoading || !rootOrg) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route path={`${path}/archived`}>
        <InitiativeStructureArchived
          currentUser={currentUser}
          rootOrg={rootOrg}
          archivedInitiatives={archivedInitiatives}
        />
      </Route>
      <Route>
        <InitiativeStructureFolders
          rootOrg={rootOrg}
          currentUser={currentUser}
          archivedInitiatives={archivedInitiatives}
          {...props}
        />
      </Route>
    </Switch>
  );
};
