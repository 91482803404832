import { getGroup, Group } from '@g17eco/core';
import { SurveyModelMinimalUtrv } from '../../model/surveyData';
import { VisibilityStatus } from '../../types/download';
import { HistoricalReportData, ReportData } from '../../types/reportData';
import { Tags } from '../../types/universalTracker';
import { naturalSort } from '../../utils';
import { heading3 } from './document-structure';
import { getAltName, getTypeCode } from './group-builder';
import { multiDateRenderer } from './sgx-metric-report/table/multiDateRenderer';

interface ReportDataParams {
  data: ReportData[];
  dataASC: HistoricalReportData[];
  targetMap: Map<string, SurveyModelMinimalUtrv>;
  visibilityStatus: VisibilityStatus;
  scopeGroupCode: string;
  displayUserInput?: boolean;
}

interface ReportDataBySubGroupParams extends ReportDataParams {
  subGroups: Group[];
}

export const sortData = (data: ReportData[], scopeGroupCode: string) => {
  return data.sort((a, b) => {
    return (
      naturalSort(getTypeCode(a, scopeGroupCode), getTypeCode(b, scopeGroupCode)) ||
      naturalSort(getAltName(a, scopeGroupCode), getAltName(b, scopeGroupCode))
    );
  });
};

export const filterDataBySubGroupCode = (data: ReportData[], scopeGroupCode: string, subGroupCode: string) => {
  return data.filter(
    (question) =>
      question.universalTracker.typeTags?.includes(subGroupCode) ||
      question.universalTracker.alternatives?.[scopeGroupCode]?.typeTags?.includes(subGroupCode) ||
      question.universalTracker.tags?.[scopeGroupCode as keyof Tags]?.includes(subGroupCode)
  );
};

export const generateReportData = ({
  data,
  dataASC,
  targetMap,
  visibilityStatus,
  scopeGroupCode,
  displayUserInput,
}: ReportDataParams) => {
  return data
    .map((lastSurveyUtrvs) => {
      return multiDateRenderer({
        currentData: lastSurveyUtrvs,
        questionData: dataASC,
        targetMap,
        preferredAltCodes: [scopeGroupCode],
        visibilityStatus,
        displayUserInput,
      });
    })
    .flat();
};

export const generateSubGroups = ({ subGroups, data, scopeGroupCode, ...props }: ReportDataBySubGroupParams) => {
  return subGroups
    .map((subGroup: Group) => {
      const subGroupCode = subGroup.code;
      const filteredData = filterDataBySubGroupCode(data, scopeGroupCode, subGroupCode);
      const sortedData = sortData(filteredData, scopeGroupCode);
      const reportData = generateReportData({
        data: sortedData,
        scopeGroupCode,
        ...props,
      });
      return reportData.length > 0 ? [heading3(subGroup.name || ''), ...reportData] : [];
    })
    .flat();
};

export const groupReportDataBySubgroups = (data: ReportData[], scopeGroupCode: string | undefined) => {
  if (!scopeGroupCode) {
    return {};
  }
  const subGroups = getGroup('standards-and-frameworks', scopeGroupCode)?.subgroups;

  if (!subGroups || subGroups.length === 0) {
    return {};
  }

  return subGroups.reduce((acc, subGroup) => {
    const reportDataBySubGroup = filterDataBySubGroupCode(data, scopeGroupCode, subGroup.code);
    return { ...acc, [subGroup.code]: reportDataBySubGroup };
  }, {} as { [key: string]: ReportData[] });
};
