import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { TemplateHistory } from '../components/survey-templates/types';
import { ApiResponse } from '../types/api';
import { axiosBaseQuery } from './axiosQuery';

interface BulkSurveysUpdateMutationParams {
  historyId: string;
  templateId: string;
}

export const surveyTemplatesHistoryApi = createApi({
  reducerPath: 'surveyTemplatesHistoryApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['survey-templates-history'],
  endpoints: (builder) => ({
    getHistory: builder.query<TemplateHistory[], string>({
      transformResponse: (response: ApiResponse<TemplateHistory[]>) => response.data,
      query: (templateId) => ({
        url: `/survey-templates/${templateId}/history`,
        method: 'get',
      }),
      providesTags: ['survey-templates-history'],
    }),
    getHistoryById: builder.query<TemplateHistory, { templateId: string, historyId: string }>({
      transformResponse: (response: ApiResponse<TemplateHistory>) => response.data,
      query: ({ templateId, historyId }) => ({
        url: `/survey-templates/${templateId}/history/${historyId}`,
        method: 'get',
      }),
      providesTags: ['survey-templates-history'],
    }),
    bulkSurveysScopeUpdate: builder.mutation<ApiResponse<TemplateHistory>, BulkSurveysUpdateMutationParams>({
      invalidatesTags: ['survey-templates-history'],
      query: ({templateId, historyId}) => ({
        url: `/survey-templates/${templateId}/history/${historyId}/refresh`,
        method: 'post',
      }),
    }),
    bulkSurveysDelete: builder.mutation<ApiResponse<TemplateHistory>, BulkSurveysUpdateMutationParams>({
      invalidatesTags: ['survey-templates-history'],
      query: ({ templateId, historyId }) => ({
        url: `/survey-templates/${templateId}/history/${historyId}`,
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useGetHistoryQuery,
  useGetHistoryByIdQuery,
  useBulkSurveysScopeUpdateMutation,
  useBulkSurveysDeleteMutation,
} = surveyTemplatesHistoryApi;
