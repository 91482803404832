import { Input } from 'reactstrap';
import { Form, OnChangeForm } from './types';
import { ReportDataSource } from './ReportDataSource';
import { Filters } from './Filters';
import { useSelector } from 'react-redux';
import { RootState } from '@reducers/index';
import { getScopesDifferent, getScopeModules, mergeSurveysScopes } from '@utils/survey-scope';
import { useGetMetricGroupsQuery } from '@api/metric-groups';

interface Props {
  initiativeId: string;
  form: Form;
  onChangeForm: OnChangeForm;
}

export const Configuration = ({ initiativeId, form, onChangeForm }: Props) => {
  const { name, description, surveyIds, metricFilters } = form;
  const { data: surveys } = useSelector((state: RootState) => state.initiativeSurveyList);
  const { data: metricGroups = [] } = useGetMetricGroupsQuery(initiativeId);

  const getScope = (surveyIds: string[]) =>
    mergeSurveysScopes(
      surveys.filter((survey) => surveyIds.includes(survey._id)),
      { parentGroupsOnly: true }
    );
  const originalScope = getScope(surveyIds);
  const unSelectedScope = getScopesDifferent(form.metricFilters.scope, originalScope);

  const handleChangeSource: OnChangeForm = ({ surveyIds: newSurveyIds = [] }) => {
    onChangeForm({
      surveyIds: newSurveyIds,
      metricFilters: {
        ...metricFilters,
        scope: getScopesDifferent(unSelectedScope, getScope(newSurveyIds)),
      },
    });
  };

  return (
    <>
      <Input
        type='text'
        placeholder='Custom report name'
        value={name}
        onChange={(e) => onChangeForm({ name: e.target.value })}
      />
      <Input
        type='textarea'
        placeholder='Report description (optional)'
        value={description}
        onChange={(e) => onChangeForm({ description: e.target.value })}
        className='mt-3'
      />

      <ReportDataSource surveys={surveys} form={form} onChangeSource={handleChangeSource} />
      <Filters
        filters={metricFilters}
        onChangeForm={onChangeForm}
        scopeModules={getScopeModules({ scope: originalScope, metricGroups })}
      />
    </>
  );
};
