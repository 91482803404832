import { Modal, ModalBody, ModalHeader } from 'reactstrap';

interface Props {
  toggle: () => void;
  children: JSX.Element;
}

export const UtrvHistoryModalTemplate = ({ toggle, children }: Props) => {
  return (
    <Modal id='utrModal' fade={false} size='xl' isOpen={true} toggle={toggle} backdrop='static' keyboard={true}>
      <ModalHeader toggle={toggle} className='text-ellipsis' tag='h5'>
        Metric information
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};
