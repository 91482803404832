import { useState } from 'react';
import { SurveyStats } from '../../../api/initiative-stats';
import { UserMin } from '../../../constants/users';
import { useAppSelector } from '../../../reducers';
import G17Client from '../../../services/G17Client';
import { MessageFormField, MessageFormProps, QuestionStatusStats } from '../types';
import { QuestionsFilter } from './QuestionsFilter';
import { getFilterOptions } from './QuestionsOptions';
import { ContributorVerifierMessageForm } from './ContributorVerifierMessageForm';

interface BulkUsersMessageFormProps extends MessageFormProps {
  surveys: SurveyStats[];
}

const defaultSelection = 'created';

export const BulkSurveysMessageForm = (props: BulkUsersMessageFormProps) => {
  const { surveys, onChange } = props;
  const [selectedStatus, setSelectedStatus] = useState<QuestionStatusStats | undefined>(undefined);
  const [usersState, setUsers] = useState<{ contributors: UserMin[]; verifiers: UserMin[]; loaded: boolean; }>({
    contributors: [],
    verifiers: [],
    loaded: false,
  });
  const initiativeId = useAppSelector((state) => (state.initiative.loaded ? state.initiative.data._id : undefined));

  const populateContributorsVerifiers = (surveyIds: string[]) => {
    if (!initiativeId) {
      return;
    }

    G17Client.getStakeholdersStats({
      initiativeId,
      surveyIds: surveyIds,
    }).then((data) => {
      const verifiers = data.filter((user) => user.isVerifier);
      const contributors = data.filter((user) => user.isContributor);
      setUsers({ verifiers, contributors, loaded: true });
      onChange(MessageFormField.VerifierIds)(verifiers.map((verifier) => verifier._id));
      onChange(MessageFormField.ContributorIds)(contributors.map((contributor) => contributor._id));
    });
  };

  const onChangeQuestionsFilter = (value: QuestionStatusStats) => {
    if (value === selectedStatus) {
      return;
    }

    const getActivatedSurveys = ({ status }: Partial<SurveyStats>) => value !== 'all' && status && status[value] > 0;
    const filteredSurveys = value === 'all' ? surveys : surveys.filter(getActivatedSurveys);

    const surveyIds = filteredSurveys.reduce((accumulator, survey) => {
      accumulator.push(survey._id);
      return accumulator;
    }, [] as string[]);
    
    populateContributorsVerifiers(surveyIds);
    setSelectedStatus(value);
  };

  return (
    <>
      <div className='row'>
        <div className='col-7'>
          <QuestionsFilter
            defaultSelection={defaultSelection}
            label='Message users with'
            options={getFilterOptions}
            onChange={onChangeQuestionsFilter}
          />
        </div>
      </div>
      <ContributorVerifierMessageForm
        {...props}
        contributors={usersState.contributors}
        verifiers={usersState.verifiers}
      />
    </>
  );
};
