import { useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/routes';
import { generateUrl } from '@routes/util';
import { DashboardRow } from '@components/dashboard';
import { ReportSwitcherContainer } from '@components/initiative/ReportSwitcherContainer';
import { RefreshAggregatedSurveyButton } from './RefreshAggregatedSurveyButton';

export const AggregatedSurveyOverviewHeader = ({
  initiativeId,
  surveyId,
  lastUpdated,
}: {
  initiativeId: string;
  surveyId: string;
  lastUpdated: string;
}) => {
  const history = useHistory();

  const onReportChange = (surveyId: string) => {
    history.push(generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, { initiativeId, surveyId }));
  };

  return (
    <DashboardRow>
      <RefreshAggregatedSurveyButton initiativeId={initiativeId} lastUpdated={lastUpdated} />
      <ReportSwitcherContainer
        initiativeId={initiativeId}
        selectedSurveyId={surveyId}
        onChange={onReportChange}
        showAutomaticReport={false}
      />
    </DashboardRow>
  );
};
