import Dashboard, { DashboardRow } from '@components/dashboard';
import { ROUTES } from '@constants/routes';
import { Breadcrumbs } from '@g17eco/molecules';
import { generateUrl } from '@routes/util';
import { useParams } from 'react-router-dom';
import { PPTXReportsContainer } from './PPTXReportsContainer';

export const PPTXReportsRoute = () => {
  const { initiativeId } = useParams<{ initiativeId: string; surveyId: string }>();
  const rootUrl = generateUrl(ROUTES.DOWNLOADS, { initiativeId });

  const breadcrumbs = [{ label: 'AI Enhanced Sustainability Reports' }];

  return (
    <Dashboard>
      <DashboardRow>
        <Breadcrumbs breadcrumbs={breadcrumbs} rootLabel='Downloads' rootUrl={rootUrl} />
      </DashboardRow>
      <PPTXReportsContainer initiativeId={initiativeId} />
    </Dashboard>
  );
};
