import { Col, Row } from 'reactstrap';
import { InitiativeData, RootInitiativeData } from '../../types/initiative';
import { ProfileLogo } from '../profile/ProfileLogo';
import { companyEditOptions, EditOption, SETTING_INPUT_TYPES } from './EditOption';
import './style.scss';
import { RouteInterface } from '@g17eco/types/routes';
import { useHistory } from 'react-router-dom';
import { generateUrl } from '@routes/util';

interface InitiativeDetailsProps {
  rootOrg: RootInitiativeData;
  initiative: InitiativeData;
  bankingRoute: RouteInterface;
}

export const InitiativeDetails = ({ rootOrg, initiative, bankingRoute }: InitiativeDetailsProps) => {
  const history = useHistory();
  const goToBankSettings = () => {
    history.push(generateUrl(bankingRoute, { initiativeId: initiative._id }));
  };

  return (
    <Row>
      <Col className='col-md-8 col-12'>
        {companyEditOptions.map((option) => {
          if (option.type === SETTING_INPUT_TYPES.BANKING_WITH) {
            return (
              <EditOption
                key={option.label}
                {...option}
                rootOrg={rootOrg}
                initiative={initiative}
                onClick={goToBankSettings}
              />
            );
          }
          return <EditOption key={option.label} {...option} rootOrg={rootOrg} initiative={initiative} />;
        })}
      </Col>
      <Col className='col-md-4 col-12 d-flex justify-content-center'>
        <ProfileLogo defaultPicture={initiative?.profile} initiativeId={initiative._id} />
      </Col>
    </Row>
  );
};
