import { BasicAlert } from '@g17eco/molecules';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface ConfirmPopupType {
  isOpen: boolean;
  toggleModal: () => void;
  removeUser: () => void;
}

export const ConfirmPopup = (props: ConfirmPopupType) => {
  const { isOpen, toggleModal, removeUser } = props;
  const handleClick = () => {
    removeUser();
    toggleModal();
  }
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop='static' className='confirm-modal'>
      <ModalHeader toggle={toggleModal}>Confirmation</ModalHeader>
      <ModalBody>
        <p>You are about remove your access to this assurance tracker.</p>
        <BasicAlert type='danger'>You will not be able to view it after removing</BasicAlert>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleClick}>
          Confirm
        </Button>
        <Button color='primary' outline onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
