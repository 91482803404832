import { formDataHeaders } from '@services/G17Client';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { ApiResponse } from '@g17eco/types/api';
import { UserRoles } from '@constants/user';
import { TAG as MANAGE_USER_TAG } from './manage-users';
import { OnboardingListExtended } from '@g17eco/types/onboarding';

export interface UserRowData {
  email: string;
  initiativeCode: string;
  initiativeName: string;
  permissions: UserRoles[];
  initiativeId?: string;
  message?: string;
  validPermissions: UserRoles[];
}

export interface ValidatedResult {
  validatedData: UserRowData[];
}

interface Params {
  data: { file: File };
  initiativeId: string;
}

const TAGS = ['bulk-onboarding-import'];

const getUrl = (initiativeId: string) => `/initiatives/${initiativeId}/users`;

export const bulkOnboardingImportApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: [...TAGS, MANAGE_USER_TAG],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      validateImportFile: builder.mutation<ValidatedResult, Params>({
        transformResponse,
        query: ({ initiativeId, data }) => ({
          url: `${getUrl(initiativeId)}/import/validate`,
          headers: formDataHeaders,
          method: 'post',
          data: data,
        }),
        invalidatesTags: TAGS,
      }),
      bulkOnboardEmails: builder.mutation<ApiResponse, Params>({
        query: ({ initiativeId, data }) => ({
          url: `${getUrl(initiativeId)}/import`,
          headers: formDataHeaders,
          method: 'post',
          data: data,
        }),
        invalidatesTags: (_result, _error, { initiativeId }) => [...TAGS, { type: MANAGE_USER_TAG, id: initiativeId }],
      }),
      bulkOnboardLists: builder.query<OnboardingListExtended[], string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `${getUrl(initiativeId)}/import/list`,
          method: 'get',
        }),
        providesTags: TAGS,
      }),
    }),
  });

export const { useValidateImportFileMutation, useBulkOnboardEmailsMutation, useBulkOnboardListsQuery } = bulkOnboardingImportApi;
