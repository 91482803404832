import { UniversalTrackerBase } from '../../model/UniversalTracker';
import { canAddTarget } from '../../utils/universalTracker';

interface Props {
  utrPlain: UniversalTrackerBase;
}

const dataTypes = [
  {
    name: 'Actual',
    color: 'text-ChartActual',
  },
  {
    name: 'Baseline',
    color: 'text-ChartBaseline',
  },
  {
    name: 'Target',
    color: 'text-ChartTarget',
  },
];

export const AdditionalInfo = ({ utrPlain }: Props) => {
  const trendUp = utrPlain.targetDirection !== 'decrease';
  const canDrawChart = canAddTarget(utrPlain);

  return (
    <div className='text-center text-ThemeTextPlaceholder'>
      <div className='d-flex justify-content-center gap-2 mb-2'>
        {dataTypes.map(({ name, color }) => (
          <span key={name} className='d-flex align-items-center gap-1'>
            <i className={`fa fa-circle text-xs ${color}`} /> {name}
          </span>
        ))}
      </div>
      {canDrawChart ? (
        <span>
          <i className={`fal ${trendUp ? 'fa-arrow-up' : 'fa-arrow-down'} text-ThemeSuccessMedium me-1 text-lg`} />
          Trend expectation
        </span>
      ) : null}
      <i className='ms-3 mr-1 fal fa-user-check text-ThemeSuccessMedium' /> Verified values only
    </div>
  );
};
