
import { ValueListPlain } from '../types/valueList';
import { g17ecoApi, transformResponse } from './g17ecoApi';

export const valueListApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['value-list'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getValueListById: builder.query<ValueListPlain, string>({
        transformResponse,
        query: (listId) => {
          return {
            url: `value-list/${listId}`,
            method: 'get',
          };
        },
        providesTags: (result, error, agrs) => [{ type: 'value-list', id: agrs }],
      }),
      getSharableValueListById: builder.query<
        ValueListPlain,
        { valueListId: string; dashboardId: string; token: string }
      >({
        transformResponse,
        query: ({ valueListId, dashboardId, token }) => {
          return {
            url: `o/value-list/${valueListId}/insight-dashboards/${dashboardId}/token/${token}`,
            method: 'get',
          };
        },
        providesTags: ['value-list'],
      }),
    }),
  });

export const { useGetValueListByIdQuery, useGetSharableValueListByIdQuery } = valueListApi;
