import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { saveReportingLevelDetails } from '../../actions/initiative';
import { EditOptionProps } from '../../types/initiative';
import { MonthDay } from '../../types/onboarding';
import { MonthDayPicker } from '../onboarding-esgenome/MonthDayPicker';

const getDisplayDayMonth = (financialEndDate: MonthDay) => {
  return financialEndDate.month === '-' || financialEndDate.day === '-'
    ? ''
    : `${financialEndDate.day} ${financialEndDate.month.replace(/^\w/, (c) => c.toUpperCase())}`;
};

export const MonthDayRow = ({ label, initiative }: EditOptionProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [financialEndDate, setFinancialEndDate] = useState<MonthDay>(
    initiative.financialEndDate ?? { month: '-', day: '-' }
  );

  const toggleShowInput = () => setIsEditing(!isEditing);
  const handleSubmit = () => {
    const formData = { financialEndDate: financialEndDate };
    dispatch(saveReportingLevelDetails(initiative._id, formData));
    toggleShowInput();
  }

  const handleCancel = () => {
    setFinancialEndDate(initiative.financialEndDate ?? { month: '-', day: '-' });
    toggleShowInput();
  }

  const hasDate = financialEndDate.month !== '-' && financialEndDate.day !== '-';

  return (
    <Row className='ml-0 mb-3 mr-0 month-day-row__wrapper align-items-center'>
      <Col className='col-md-5 col-12 text-label-uppercase align-self-start'>{label}</Col>
      <Col className='col-md-5 col-10 d-flex align-self-right'>
        {isEditing ? (
          <div className='col-12'>
            <MonthDayPicker
              value={financialEndDate}
              onChange={(value) => {
                setFinancialEndDate(value);
              }}
            />
          </div>
        ) : (
          <div className={`align-self-center w-100 text-right ${hasDate ? '' : 'text-ThemeTextPlaceholder'}`}>
            {hasDate ? getDisplayDayMonth(financialEndDate) : 'not set'}
          </div>
        )}
      </Col>
      <Col className='col-2 d-flex align-self-end'>
        {isEditing ? (
          <>
            <Button color='link' className='mb-1' onClick={handleSubmit}>
              Save
            </Button>
            <Button color='link-secondary' className='mb-1' onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <Button color='link' onClick={toggleShowInput}>
            Edit
          </Button>
        )}
      </Col>
    </Row>
  );
};
