import { Input } from 'reactstrap';
import PartialAssuranceIconSvg from '../../images/partial-assurance.svg';
import './styles.scss';
import classNames from 'classnames';

interface PartialAssuranceIconProps {
  width?: string;
  height?: string;
  className?: string;
}
export const PartialAssuranceIcon = ({ width = 'auto', height = 'auto', className = ''}: PartialAssuranceIconProps) => {
  return <img src={PartialAssuranceIconSvg} alt='Partially assured' style={{ minWidth: width, height }} className={className} />
}

interface Props {
  isAssured?: boolean;
  classes?: Record<string, string>;
  checked?: boolean;
  handleChange?: (value: boolean) => void;
}

export const PartialAssurance = ({
  isAssured = false,
  classes,
  checked,
  handleChange = () => {},
}: Props) => {
  if (isAssured) {
    return (
      <div className={classes?.wrapper ?? 'partial-assurance-wrapper'}>
        <i className='fa fa-award text-ThemeSuccessMedium fs-4'></i>
      </div>
    );
  }

  return (
    <div className={classes?.wrapper ?? 'partial-assurance-wrapper'}>
      <Input
        type='checkbox'
        className={classNames('m-0 partial-assurance-checkbox', classes?.input)}
        checked={checked}
        onChange={(e) => handleChange(e.currentTarget.checked)}
      />
    </div>
  );
};
