import { Button } from 'reactstrap';
import { DashboardSection } from '../../dashboard';
import { DataShareWithRequester } from '../../../types/dataShare';

interface ReviewPendingRequestProps {
  pendingRequests: DataShareWithRequester[];
  handleOpenPendingRequest: (dataShare: DataShareWithRequester) => void;
}

export const ReviewPendingRequests = ({ pendingRequests, handleOpenPendingRequest }: ReviewPendingRequestProps) => {
  return (
    <>
      <DashboardSection
        padding={0}
        paddingInternal={0}
        classes={{
          whiteBoxContainer: 'border-0 bg-transparent',
        }}
      >
        <div className='w-100 d-flex flex-column gap-4'>
          {pendingRequests.map((req) => (
            <div
              key={req._id}
              className='d-flex align-items-center px-5 py-3 text-ThemeAccentDark background-ThemeAccentExtralight rounded-3'
            >
              <i className='fa-solid fa-circle-info'></i>
              <div className='d-flex align-items-center ml-3'>
                {req.title}
                <Button
                  color='link'
                  className='ml-1 text-ThemeAccentDark text-decoration-underline'
                  onClick={() => handleOpenPendingRequest(req)}
                >
                  Review
                </Button>
              </div>
            </div>
          ))}
        </div>
      </DashboardSection>
    </>
  );
};
