import { DATE, formatDate } from '../../utils/date';
import { CustomToastProps } from './CustomToast';
import { Button } from 'reactstrap';

export const ToastHeader = ({
  title,
  icon,
  hasDismissButton = true,
  currentToast,
  handleClose,
  date,
}: CustomToastProps) => {
  if (!title) {
    return null;
  }

  return (
    <div className='toast__header'>
      <span className='h6'>
        {icon ? <i className={`${icon} mr-2`} /> : null}
        <span className='toast__title'>{title}</span>
      </span>
      {hasDismissButton ? (
        <div>
          {date ? <span className='toast__timeout mr-2'>{formatDate(date, DATE.HUMANIZE)}</span> : null}
          <Button color={'transparent'} className='btn-icon' onClick={() => handleClose(currentToast?.id)}>
            <i className='fa fa-times' />
          </Button>
        </div>
      ) : null}
    </div>
  );
};
