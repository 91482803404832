import { InitiativeBenchmarking } from '../../../../types/initiative';
import { DATE, formatDate } from '../../../../utils/date';

const percentDisplay = (value: number): string => {
  if (!value) return '0%';
  return `${(value * 100).toFixed(1)}%`;
};

const percentSort = (value: number): string => `${(value * 100).toFixed(1)}%`;

const getPercentData = (company: InitiativeBenchmarking, percent: number) => {
  return {
    display: company.latestSurvey ? percentDisplay(percent) : '-',
    sort: percentSort(percent),
  }
}

export const hasCompletedSurveys = (company: InitiativeBenchmarking) => (company.benchmarkingSurveys ?? []).some((survey) => survey.completedDate);

export const getEffectiveDate = (company: InitiativeBenchmarking) => {
  if (!company.latestSurvey || !company.latestSurvey.effectiveDate) {
    return { display: '-', sort: '-' }
  }
  return {
    display: formatDate(company.latestSurvey.effectiveDate, DATE.MONTH_YEAR_SHORT),
    sort: formatDate(company.latestSurvey.effectiveDate, DATE.DATE_PICKER),
  }
}

export const getProgress = (company: InitiativeBenchmarking) => {
  const percent = company.latestSurvey ? (company.latestSurvey.answered / company.latestSurvey.total) : 0;
  return {
    percent: percent * 100,
    display: company.latestSurvey ? percentDisplay(percent) : '-',
    sort: percentSort(percent)
  }
};

export const getTotalQuestions = (company: InitiativeBenchmarking) => {
  return company.latestSurvey ? company.latestSurvey.total : '-';
};

export const getUnanswered = (company: InitiativeBenchmarking) => {
  return company.latestSurvey ? (company.latestSurvey.total - company.latestSurvey.answered) : '-';
};

export const getAnswered = (company: InitiativeBenchmarking) => {
  return company.latestSurvey ? (company.latestSurvey.answered) : '-';
};

export const getPercentPrivate = (company: InitiativeBenchmarking) => {
  const percent = company.latestSurvey ? Number(company.latestSurvey.private / company.latestSurvey.total) : 0;
  return getPercentData(company, percent);
};

export const getPercentNr = (company: InitiativeBenchmarking) => {
  const percent = company.latestSurvey ? Number(company.latestSurvey.nr / company.latestSurvey.total) : 0;
  return getPercentData(company, percent);
};

export const getPercentNa = (company: InitiativeBenchmarking) => {
  const percent = company.latestSurvey ? Number(company.latestSurvey.na / company.latestSurvey.total) : 0;
  return getPercentData(company, percent);
};
