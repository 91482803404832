import { MAX_SIZE_MB } from '@features/custom-dashboard/utils/media-utils';

export const DropZonePlaceholder = () => (
  <div className='w-100 d-flex p-1 align-items-center flex-column'>
    <i className='text-xl text-ThemeTextMedium fa-light fa-upload px-1' />
    <div className='w-100 text-center text-ThemeAccentMedium'>Upload media</div>
    <span className='w-100 text-center text-sm text-ThemeIconSecondary'>Supported png, jpg, gif, mp4</span>
    <span className='w-100 text-center text-sm text-ThemeIconSecondary'>{MAX_SIZE_MB}MB limit</span>
  </div>
);
