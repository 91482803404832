import { MergeTags } from './constants';
import { BulkAggregatedSurveysFormData, BulkSurveysFormData } from './types';

export const requiredFields: (keyof BulkSurveysFormData)[] = ['period', 'year', 'month', 'initiativeId'];

export const aggregatedRequiredFields: (keyof BulkAggregatedSurveysFormData)[] = ['period', 'year', 'month'];

export const hasEmptyFields = (form: Partial<BulkSurveysFormData>, requiredFields: (keyof BulkSurveysFormData)[]) => {
  return requiredFields.some((f) => undefined === form[f] || form[f] === '');
};

export const hasInvalidTags = (surveyName: string | undefined) => {
  if (!surveyName) {
    return false; // No tags, means it's valid
  }
  // extract merge tags
  const mergeTags = surveyName.match(/{{[^{}]+}}|{{}}/g);
  if (!mergeTags || mergeTags.length === 0) {
    return false;
  }
  return mergeTags.some((tag) => !Object.values(MergeTags).includes(`{{${tag.slice(2, -2).trim()}}}` as MergeTags));
};
