import { useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useGetMergeTagsQuery } from '../../../api/survey-templates';
import Loader from '../../loader';
import Table from '../../table/Table';
import { MergeTags } from '../constants';
import { MergeOption } from '../types';

interface ConfirmPopupType {
  surveyName: string | undefined;
  updateName: (value: string) => void;
}

export const MergeTagsButton = (props: ConfirmPopupType) => {
  const { surveyName = '', updateName } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(surveyName);
  const mergeTagsQuery = useGetMergeTagsQuery({});

  useEffect(() => {
    setName(surveyName);
  }, [surveyName, isOpen]);

  const toggleModal = () => setIsOpen((prevState) => !prevState);
  const addMergeTag = (tag: MergeTags) => {
    setName((prevName) => `${prevName} ${tag}`.trim());
  };

  const handleConfirm = () => {
    updateName(name ?? '');
    toggleModal();
  };

  return (
    <>
      <Button color='link' onClick={toggleModal} className='mt-1 position-absolute end-0'>
        <span>
          <i className='fas text-md fa-plus mr-2' />
          Add merge tag
        </span>
      </Button>
      <Modal isOpen={isOpen} toggle={toggleModal} backdrop='static' className='confirm-modal'>
        <ModalHeader toggle={toggleModal}>Add a merge tag</ModalHeader>
        <ModalBody>
          {mergeTagsQuery.isFetching || mergeTagsQuery.isLoading ? (
            <div style={{ minHeight: '150px' }}>
              <Loader />
            </div>
          ) : (
            <>
              <Table
                data={mergeTagsQuery.data ?? []}
                columns={[
                  {
                    Header: 'Name',
                    id: 'name',
                    accessor: (tag: MergeOption) => tag.name,
                    disableSortBy: true,
                  },
                  {
                    Header: 'Code',
                    id: 'code',
                    accessor: (tag: MergeOption) => tag.code,
                    disableSortBy: true,
                  },
                  {
                    Header: ' ',
                    id: 'actions',
                    cellProps: {
                      className: 'px-0',
                    },
                    disableSortBy: true,
                    accessor: (tag: MergeOption) => (
                      <Button color='link' className='p-0 mx-1' onClick={() => addMergeTag(tag.code)}>
                        <i className='fas text-md fa-plus mr-2' />
                        Add
                      </Button>
                    ),
                  },
                ]}
              />
              <Input
                className='mt-3'
                placeholder={'e.g. December 2022 annual report'}
                value={name}
                onChange={(el) => setName(el.target.value)}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleConfirm}>Confirm</Button>
          <Button color='primary' outline onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
