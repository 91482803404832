import React from 'react'
import { Chart } from 'react-google-charts'
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';

const WIDTH = 300;
const HEIGHT = 300;

export interface GenderSplitDataSource extends DataSource {
  femaleEmployees?: number;
  maleEmployees?: number;
}

const GenderSplit = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const employeesUtr = questionData.find(d => d.universalTracker.code === 'gri/2020/102-7/a');
  const employeesValueData = employeesUtr?.valueData?.table?.[0];

  const reportData: GenderSplitDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    femaleEmployees: Number(employeesValueData?.find(v => v.code === 'org_scale_employees_female')?.value ?? 0),
    maleEmployees: Number(employeesValueData?.find(v => v.code === 'org_scale_employees_male')?.value ?? 0),
  }

  const chartData = [
    ['Gender', 'Employees', { role: 'label' }],
    ['Female', reportData.femaleEmployees, reportData.femaleEmployees],
    ['Male', reportData.maleEmployees, reportData.maleEmployees],
  ];

  return <Chart
    chartType='PieChart'
    data={chartData}
    width={WIDTH}
    height={HEIGHT}
    options={{
      ...CHART_DEFAULTS.OPTIONS,
      title: 'Total staff',
      pieSliceText: 'value'
    }}
    chartEvents={[
      {
        eventName: 'ready',
        callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
          reportData.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
          updateData('genderSplit', reportData);
        }
      }
    ]}
  />
})

export default GenderSplit;
