import { SurveyPeriodDropdown } from '@components/survey-period-dropdown';
import { PeriodOption } from '@components/survey-period-dropdown/utils';
import { SurveyTypeDropdown } from '@components/survey-type-dropdown/SurveyTypeDropdown';
import { SurveyTypeOptions } from '@components/survey-type-dropdown/utils';
import { DataPeriods } from '@g17eco/types/universalTracker';

export interface Filters {
  surveyType: SurveyTypeOptions;
  period: PeriodOption;
}

export interface SurveyFiltersProps {
  filters: Filters;
  onChangeFilters: (filter: Filters) => void;
}

export const SurveyFilters = ({ filters, onChangeFilters }: SurveyFiltersProps) => {
  const handleChangeFilters = (key: keyof Filters, value: SurveyTypeOptions | PeriodOption) => {
    const newFilters = { ...filters, [key]: value };
    onChangeFilters(newFilters);
  };

  return (
    <>
      <SurveyTypeDropdown
        hasAllOption
        surveyType={filters.surveyType}
        setSurveyType={(surveyType) => handleChangeFilters('surveyType', surveyType)}
        styleProps={{ dropdown: 'col-12 col-sm-6 col-md-4' }}
      />
      <SurveyPeriodDropdown
        hasAllOption
        styleProps={{ dropdown: 'col-12 col-sm-6 col-md-4' }}
        period={filters.period}
        availablePeriods={['all', DataPeriods.Yearly, DataPeriods.Quarterly, DataPeriods.Monthly]}
        setPeriod={(period) => handleChangeFilters('period', period)}
      />
    </>
  );
};
