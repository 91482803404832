import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { saveReportingLevelDetails } from '../../../../actions/initiative';
import G17Client from '../../../../services/G17Client';
import { addSiteAlert, DEFAULT_TIMEOUT, SiteAlertColors } from '../../../../slice/siteAlertsSlice';


export const SubsidiaryModal = (props: { isOpen: boolean, isEdit: boolean, initiativeSelected?: {initiativeId: string, name?: string}, toggle: () => void, handleUpdate: () => void }) => {

  const { toggle, isOpen, initiativeSelected, isEdit, handleUpdate } = props;
  const [initiativeName, setInitiativeName] = useState('')
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEdit || !initiativeSelected?.name) {
      setInitiativeName('')
      return
    }
    setInitiativeName(initiativeSelected?.name)
    return
  }, [isEdit, initiativeSelected]);

  if (!initiativeSelected) {
    return null;
  }

  const handleEdit = async () => {
    if (!initiativeName) {
      return;
    }

    dispatch(saveReportingLevelDetails(initiativeSelected.initiativeId, { name: initiativeName }))
     .then(() => {
        dispatch(addSiteAlert({
          content: 'Success',
          color: SiteAlertColors.Success,
          timeout: DEFAULT_TIMEOUT
        }))
     }).catch((e) => {
      dispatch(addSiteAlert({
        content: 'Failed to edit subsidiary',
        color: SiteAlertColors.Danger,
        timeout: DEFAULT_TIMEOUT
      }))
    }).finally(() => {
        setInitiativeName('');
        toggle();
        handleUpdate();
      })
  };

  const handleAdd = async () => {
    if (!initiativeName) {
      return;
    }
    await G17Client.addSubsidiary(initiativeSelected.initiativeId, initiativeName).then(() => {
      dispatch(addSiteAlert({
        content: 'Success',
        color: SiteAlertColors.Success,
        timeout: DEFAULT_TIMEOUT
      }))
    }).catch(() => {
      dispatch(addSiteAlert({
        content: 'Failed to add subsidiary',
        color: SiteAlertColors.Danger,
        timeout: DEFAULT_TIMEOUT
      }))
    }).finally(() => {
      toggle();
      handleUpdate();
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop='static' className='initiative-modal'>
      <ModalHeader toggle={toggle}>{isEdit ? 'Update subsidiary' : 'Add subsidiary'}</ModalHeader>
      <ModalBody>
        <div>
          <FormGroup>
            <Input placeholder='Enter name' onChange={(e) => setInitiativeName(e.target.value)} value={initiativeName}></Input>
          </FormGroup>
          <div className='mt-3 text-right'>
            <Button className='mr-2' color='link-secondary' onClick={toggle}>
              Cancel
            </Button>
            <Button
              className='btn btn-sm'
              color='primary'
              onClick={() => (isEdit ? handleEdit() : handleAdd())}
              disabled={!initiativeName}
            >
              {isEdit ? 'Update' : 'Add'}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
