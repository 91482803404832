import { GroupBase } from 'react-select';
import BaseCreatableSelect, { CreatableProps } from 'react-select/creatable';
import { DropdownIndicator, Option, SelectStyleProps, getStyles } from './SelectFactory';

export type CreatableSelectProps<T = string | null> = CreatableProps<
  Option<T> | null,
  false,
  GroupBase<Option<T> | null>
> & SelectStyleProps;

export const CreatableSelect = <T = string | null>(props: CreatableSelectProps<T>) => {
  const {
    components,
    styles,
    isFlexibleSize,
    isTransparent,
    showDropdownIndicator = true,
    createOptionPosition = 'first',
    isMenuPortalTargetBody = false,
    showCount = false,
    ...rest
  } = props;

  return (
    <BaseCreatableSelect
      isMulti={false}
      components={{
        DropdownIndicator: () => (
          <DropdownIndicator
            current={props.value !== null ? 1 : 0}
            limit={rest.options?.length}
            showCount={showCount}
            showDropdownIndicator={showDropdownIndicator}
          />
        ),
        ...components,
      }}
      styles={{
        ...getStyles<Option<T> | null>({
          isMulti: false,
          isFlexibleSize,
          isTransparent,
          isMenuPortalTargetBody,
        }),
        ...styles,
      }}
      createOptionPosition={createOptionPosition}
      menuPortalTarget={isMenuPortalTargetBody ? document.body : null}
      {...rest}
    />
  );
};
