import React from 'react'
import { Chart } from 'react-google-charts'
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { DATE, formatDateUTC } from '../../../../utils/date';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';
import { getComments } from '../sgx-metric-report-generator';
import { getUtrvData } from '../../reportData';

const WIDTH = 550;
const HEIGHT = 350;

type GovernanceRow = {
  date: string;
  pc_female_boardmembers: number | string;
  notes?: {
    board_independence: string;
    pc_female_boardmembers: string;
  }
};

export interface GovernanceDataSource extends DataSource {
  rows?: GovernanceRow[];
  comments?: string;
}

const Governance = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData, visibility } = props;
  const femaleBoardMemberCode = 'gri/2020/405-1/a';
  const boardIndependenceCode = 'sgx-custom-42';

  const { rows, chartRows } = questionData.reduce((acc, { effectiveDate, reportData }) => {

    const pcFemale = getUtrvData({ visibility, reportData, utrCode: femaleBoardMemberCode, tableColumn: 'female' });
    const date = formatDateUTC(effectiveDate, DATE.MONTH_YEAR_SHORT);

    // Push to the front, reverse. (oldest, ...., latest)
    acc.chartRows.unshift([date, pcFemale.numericValue])

    acc.rows.push({
      date: date,
      pc_female_boardmembers: pcFemale.value ?? '',
      notes: {
        board_independence: getComments(reportData, boardIndependenceCode, visibility, ''),
        pc_female_boardmembers: getComments(reportData, femaleBoardMemberCode, visibility, '')
      }
    })

    return acc;
  }, { rows: [] as GovernanceRow[], chartRows: [] as [string, number][] });

  const data: GovernanceDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    rows,
    comments: getComments(questionData[0]?.reportData ?? [], boardIndependenceCode, visibility)
  }

  if (!data.rows) {
    return <></>;
  }

  const chartData = [
    ['Period', ...chartRows.map(row => row[0])],
    ['% Female Board Members', ...chartRows.map(row => row[1])],
  ];

  return <Chart
    chartType='ColumnChart'
    data={chartData}
    width={WIDTH}
    height={HEIGHT}
    options={{
      ...CHART_DEFAULTS.OPTIONS,
      isStacked: false,
      vAxis: {
        title: 'Percentage %',
      },
      chartArea: {
        top: 40,
        bottom: 20,
        left: 100,
        right: 20,
      },
    }}
    chartEvents={
      updateData ? [
        {
          eventName: 'ready',
          callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
            data.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
            updateData('governance', data);
          }
        }
      ] : undefined}
  />
})

export default Governance;
