import BankingSettings from '@features/banking-settings';
import { ROUTES } from '@constants/routes';
import { MTAdminBreadcrumbs } from '../components/breadcrumbs/MTAdminBreadcrumbs';

export const BankingSettingsRoute = () => {
  return (
    <BankingSettings
      baseRoute={ROUTES.MATERIALITY_TRACKER_COMPANY_SETTINGS}
      BreadcrumbsComponent={MTAdminBreadcrumbs}
    />
  );
};
