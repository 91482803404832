import { createContext, useReducer } from 'react';
import { useParams } from 'react-router';
import { RequestScope } from '@actions/api';
import { useGetSubmissionInsightsDataQuery } from '@api/benchmarking';
import { SubmissionInsightsData } from '@g17eco/types/portfolio';
import { TIME_RANGE_YEARLY } from '@utils/date';
import { getDateRange } from '@g17eco/molecules';
import { DateRangeType } from '@g17eco/types/common';
import { InitiativeCompany } from '@g17eco/types/initiative';

interface SubmissionInsightsContainerProps {
  children: any;
}

export enum SubmissionInsightsView {
  Sector = 'sector',
  Company = 'company',
}

export enum MetricView {
  Average = 'average',
  Total = 'total',
}

export interface SubmissionInsightsFilters {
  view: SubmissionInsightsView;
  metricView: MetricView;
  dateRange: DateRangeType;
  scopeGroup?: RequestScope;
}

interface SubmissionInsightsContextProps {
  isLoading: boolean;
  tableData: SubmissionInsightsData;
  companies: Pick<InitiativeCompany, '_id' | 'name' | 'type' | 'requestedDataShares'>[],
  handleApplyFilters: () => void;
  filters: SubmissionInsightsFilters;
  scopes: string[];
  error?: string;
  dispatch: React.Dispatch<any>;
}

const initialState: SubmissionInsightsContextProps = {
  isLoading: false,
  tableData: { utrs: [], utrValues: [] },
  companies: [],
  handleApplyFilters: () => { },
  filters: {
    view: SubmissionInsightsView.Company,
    metricView: MetricView.Total,
    dateRange: getDateRange(TIME_RANGE_YEARLY),
  },
  scopes: [],
  dispatch: () => { },
};

export const SubmissionInsightsContext = createContext<SubmissionInsightsContextProps>(initialState);

export const ACTION = {
  SET_FILTERS: 'filters',
};

const reducer = (state: any, action: any) => {
  if (!action.type) {
    return state;
  }
  return { ...state, [action.type]: { ...state[action.type], ...action.payload } };
};

export const SubmissionInsightsContainer = (props: SubmissionInsightsContainerProps) => {
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const [state, dispatch] = useReducer(reducer, initialState);

  const queryParams = {
    portfolioId,
    filters: state.filters,
  }

  const query = useGetSubmissionInsightsDataQuery(queryParams, { skip: !portfolioId });
  const { scopeGroup, companies, utrValues, utrs } = query.data ?? {};

  const contextValue: SubmissionInsightsContextProps = {
    ...state,
    companies,
    tableData: {
      utrs,
      utrValues,
    },
    filters: {
      scopeGroup,
      ...state.filters,
    },
    isLoading: query.isFetching || query.isLoading,
    error: query.error?.message,
    dispatch,
  };

  return <SubmissionInsightsContext.Provider value={contextValue}>{props.children}</SubmissionInsightsContext.Provider>;
};
