import {
  Calculation,
  CalculationType,
  ChartSubType,
  GridDashboardChartItem,
  GridDashboardItem,
  GridDashboardSDGChartItem,
  ValueRole,
} from '@g17eco/types/insight-custom-dashboard';
import { getPeriodName } from '@utils/universalTracker';
import { displayTimeRange, getMonthOfYear, getUTCEndOf } from '@utils/date';
import { HistoricalUtrs } from '@api/insights';
import { UtrVariables } from '@routes/summary/insights/utils/constants';
import { DataPeriods, UniversalTrackerPlain } from '@g17eco/types/universalTracker';
import { UniversalTrackerModalServiceUtrv } from '@reducers/universal-tracker-modal';
import { isChartType, isSDGTrackerType } from '@routes/custom-dashboard/utils';
import { MultiUtrsChartWidget } from '../multi-utrs-chart/MultiUtrsChartWidget';
import { DataFilters } from '@routes/custom-dashboard/types';
import { getSurveyTypeOptions } from '@utils/survey';
import './styles.scss';

type Props = DataFilters & {
  item: GridDashboardItem;
  questionReference?: JSX.Element | null;
  actionButton?: JSX.Element | null;
};

const mockUtr: UniversalTrackerPlain = {
  _id: '',
  name: '',
  code: '',
  type: '',
  valueType: '',
  valueLabel: '',
  typeCode: '',
  created: '',
};

const mockUtrv: UniversalTrackerModalServiceUtrv = {
  _id: '',
  universalTrackerId: '',
  initiativeId: '',
  effectiveDate: '',
  period: DataPeriods.Yearly,
  type: 'actual',
  lastUpdated: '',
  stakeholders: {
    stakeholder: [],
    verifier: [],
  },
  status: '',
};

const mockPieData = {
  utrsData: [
    {
      utr: {
        ...mockUtr,
        _id: '1',
        code: 'a',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 30,
        },
      ],
    },
    {
      utr: {
        ...mockUtr,
        _id: '2',
        code: 'b',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '2',
          value: 70,
        },
      ],
    },
  ],
  variables: {
    a: {
      code: 'a',
    },
    b: {
      code: 'b',
    },
  },
  calculation: {
    type: CalculationType.Formula,
    values: [
      { name: '', formula: '{a}' },
      { name: '', formula: '{b}' },
    ],
  },
};

const mockLineData = {
  utrsData: [
    {
      utr: {
        ...mockUtr,
        _id: '1',
        code: 'a',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 15,
          effectiveDate: getUTCEndOf('month', getMonthOfYear(1)).toISOString(),
        },
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 20,
          effectiveDate: getUTCEndOf('month', getMonthOfYear(2)).toISOString(),
        },
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 18,
          effectiveDate: getUTCEndOf('month', getMonthOfYear(3)).toISOString(),
        },
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 22,
          effectiveDate: getUTCEndOf('month', getMonthOfYear(4)).toISOString(),
        },
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 14,
          effectiveDate: getUTCEndOf('month', getMonthOfYear(5)).toISOString(),
        },
      ],
    },
  ],
  variables: {
    a: {
      code: 'a',
    },
  },
  calculation: {
    type: CalculationType.Formula,
    values: [{ name: '', formula: '{a}' }],
  },
};

const mockColumnData = {
  utrsData: [
    {
      utr: {
        ...mockUtr,
        _id: '1',
        code: 'a',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 70,
        },
      ],
    },
    {
      utr: {
        ...mockUtr,
        _id: '2',
        code: 'b',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '2',
          value: 100,
        },
      ],
    },
    {
      utr: {
        ...mockUtr,
        _id: '3',
        code: 'c',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '3',
          value: 50,
        },
      ],
    },
  ],
  variables: {
    a: {
      code: 'a',
    },
    b: {
      code: 'b',
    },
    c: {
      code: 'c',
    },
  },
  calculation: {
    type: CalculationType.Formula,
    values: [
      { name: '', formula: '{a}' },
      { name: '', formula: '{b}' },
      { name: '', formula: '{c}' },
    ],
  },
};

const mockBarData = {
  utrsData: [
    {
      utr: {
        ...mockUtr,
        _id: '1',
        code: 'a',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 70,
        },
      ],
    },
    {
      utr: {
        ...mockUtr,
        _id: '2',
        code: 'b',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '2',
          value: 100,
        },
      ],
    },
    {
      utr: {
        ...mockUtr,
        _id: '3',
        code: 'c',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '3',
          value: 50,
        },
      ],
    },
  ],
  variables: {
    a: {
      code: 'a',
    },
    b: {
      code: 'b',
    },
    c: {
      code: 'c',
    },
  },
  calculation: {
    headers: [
      {
        name: '',
      },
      {
        name: '',
      },
      {
        role: ValueRole.Annotation,
      },
      {
        role: ValueRole.Style,
      },
    ],
    type: CalculationType.Formula,
    values: [
      { name: '', formula: '{a}', options: { tooltip: { formula: '' }, style: '#2871c2' } },
      { name: '', formula: '{b}', options: { tooltip: { formula: '' }, style: '#2e9fd6' } },
      { name: '', formula: '{c}', options: { tooltip: { formula: '' }, style: '#32d2d9' } },
    ],
  },
};

const getChartPlaceholderData = (
  subType: ChartSubType
): { utrsData: HistoricalUtrs[]; variables: UtrVariables; calculation: Calculation } | undefined => {
  switch (subType) {
    case ChartSubType.FullPie:
    case ChartSubType.Pie:
    case ChartSubType.PieWithLegend:
      return mockPieData;
    case ChartSubType.Line:
      return mockLineData;
    case ChartSubType.Column:
    case ChartSubType.Stacked:
      return mockColumnData;
    case ChartSubType.Bar:
      return mockBarData;
    default:
      return;
  }
};

export const NoData = (props: Props) => {
  const { item, period, surveyType, timeRange, dateRange, questionReference = null, actionButton } = props;

  const displayDataFilter = () => {
    const displayFilters = [];
    if (period) {
      displayFilters.push(getPeriodName(period, false));
    }
    if (surveyType) {
      displayFilters.push(getSurveyTypeOptions().find(o => o.value === surveyType)?.label);
    }
    if (timeRange) {
      displayFilters.push(displayTimeRange({ timeRange, dateRange }));
    }
    return displayFilters.join(' | ');
  };

  const isChart = isChartType(item);
  const isSDGTracker = isSDGTrackerType(item);

  const getChartPlaceholder = () => {
    if (!isChart && !isSDGTracker) {
      return null;
    }

    const mockData = getChartPlaceholderData(item.subType);
    if (!mockData) {
      return null;
    }

    const mockItems: GridDashboardChartItem | GridDashboardSDGChartItem = {
      ...item,
      variables: mockData.variables,
      calculation: mockData.calculation,
    };

    const placeholderChartStyles = {
      legend: 'none',
      hAxis: {
        gridlines: {
          color: 'transparent',
        },
        textStyle: {
          color: 'transparent'
        }
      },
      vAxis: {
        gridlines: {
          color: 'transparent',
        },
        textStyle: {
          color: 'transparent'
        }
      },
    };

    return (
      <MultiUtrsChartWidget
        readOnly={true}
        item={mockItems}
        utrsData={mockData.utrsData}
        initiativeId={''}
        altUnitText={isSDGTracker ? 'contribution' : ''}
        overrideOptions={{
          pie: placeholderChartStyles,
          line: placeholderChartStyles,
          column: placeholderChartStyles,
          stacked: placeholderChartStyles,
          bar: placeholderChartStyles,
        }}
      />
    );
  };

  return (
    <div className='not-chart-data-container position-relative h-100 d-flex justify-content-center align-items-center text-ThemeTextMedium'>
      <div className='not-data-details text-center z-1'>
        {questionReference ? (
          <>
            <div className='fs-5 fw-bold'>To see data, answer:</div>
            {questionReference}
          </>
        ) : (
          <>
            <div className='fs-5 fw-bold'>No data available in the specified period</div>
            <div className='fs-6 fw-medium'>{displayDataFilter()}</div>
          </>
        )}
        {actionButton ? <div className='text-right'>{actionButton}</div> : null}
      </div>
      <div className='not-data-chart-placeholder w-100 h-100 pe-none'>{getChartPlaceholder()}</div>
    </div>
  );
};
