import { UniversalTrackerValuePlain } from '@g17eco/types/surveyScope';
import { UniversalTrackerPlain } from '../types/universalTracker';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { AccessType, CustomMetricsUsage } from '@g17eco/types/metricGroup';

export interface CustomMetric extends UniversalTrackerPlain {
  universalTrackerValues: Pick<UniversalTrackerValuePlain, 'status'>[];
  accessType: AccessType.Inherited | AccessType.Custom;
}
export interface CustomMetricsResponse {
  created: CustomMetric[];
  used: CustomMetric[];
}

export const customMetricApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['initiative-custom-metrics', 'initiative-custom-metrics-usage'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInitiativeCustomMetrics: builder.query<CustomMetricsResponse, string>({
        transformResponse,
        query: (initiativeId) => {
          return {
            url: `/initiatives/${initiativeId}/custom-metrics`,
            method: 'get',
          };
        },
        providesTags: ['initiative-custom-metrics'],
      }),
      checkIsCustomMetricAnswered: builder.query<{ isAnswered: boolean }, { initiativeId: string; utrId: string }>({
        transformResponse,
        query: ({ initiativeId, utrId }) => {
          return {
            url: `/initiatives/${initiativeId}/metric/${utrId}/check-is-answered`,
            method: 'get',
          };
        },
        providesTags: ['initiative-custom-metrics'],
      }),
      getInitiativeCustomMetricsUsage: builder.query<CustomMetricsUsage, string>({
        transformResponse,
        query: (initiativeId) => {
          return {
            url: `/initiatives/${initiativeId}/custom-metrics/usage`,
            method: 'get',
          };
        },
        providesTags: ['initiative-custom-metrics-usage'],
      }),
    }),
  });

export const { useGetInitiativeCustomMetricsQuery, useCheckIsCustomMetricAnsweredQuery, useGetInitiativeCustomMetricsUsageQuery } = customMetricApi;
