import React from 'react';
import { FormFeedback, FormText } from 'reactstrap';
import { generateDecimalHelperText } from '../../utils/input';
import UniversalTracker from '../../../../model/UniversalTracker';
import { hasNumericInput } from '@utils/universalTracker';

export type GenerateHelperTextParams = {
  universalTracker: Pick<UniversalTracker, 'getValueType' | 'getValueValidation'>;
  warningMessage?: JSX.Element;
  columnCode?: string;
};

const generateHelperText = (props: GenerateHelperTextParams) => {
  const { universalTracker, warningMessage, columnCode } = props;
  switch (true) {
    case hasNumericInput(universalTracker): {
      if (warningMessage) {
        return warningMessage;
      }
      return generateDecimalHelperText(universalTracker, columnCode);
    }
    default:
      return '';
  }
};

export const InputHelperText = (
  props: GenerateHelperTextParams & {
    invalidMessage: string | undefined;
  }
) => {
  const { invalidMessage, universalTracker } = props;
  if (invalidMessage) {
    return <FormFeedback>{invalidMessage}</FormFeedback>;
  }

  const helperText = universalTracker ? generateHelperText(props) : '';

  return helperText ? <FormText className='d-block w-100 ml-0 text-ThemeTextMedium'>{helperText}</FormText> : null;
};
