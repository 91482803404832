import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Props {
  handleCancel: () => void;
  toggle: () => void;
}

export const CancelConfirmationModal = ({ toggle, handleCancel }: Props) => {
  const handleClickConfirm = () => {
    toggle();
    handleCancel();
  };

  return (
    <Modal isOpen={true} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>
        <span className='text-ThemePrimary'>Cancel changes</span>
      </ModalHeader>
      <ModalBody>
        <div>Are you sure you want to cancel any edits?</div>
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='transparent' onClick={toggle}>
          Back to edit
        </Button>
        <Button color='primary' onClick={handleClickConfirm}>
          Cancel edits
        </Button>
      </ModalFooter>
    </Modal>
  );
};
