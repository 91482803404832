import React from 'react'
import Chart from 'react-google-charts';
import { DataSource } from '..';
import { DATE, formatDateUTC } from '../../../../utils/date';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';
import { getComments } from '../sgx-metric-report-generator';
import { getUtrvData, sumUtrvDataResults } from '../../reportData';

const WIDTH = 450;
const HEIGHT = 250;

type EmissionRow = {
  date: string;
  scope1: number | string;
  scope2: number | string;
  scope3: number | string;
  notes?: { [key: string]: string }
};

export interface GHGEmissionsDataSource extends DataSource {
  rows?: EmissionRow[]
}

const GHGEmissions = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData, visibility } = props;

  const { rows, chartRows } = questionData.reduce((acc, { effectiveDate, reportData }) => {
    const scope1 = getUtrvData({ visibility, reportData, utrCode: 'gri/2020/305-1/a' });
    const scope2a = getUtrvData({ visibility, reportData, utrCode: 'gri/2020/305-2/a' });
    const scope2b = getUtrvData({ visibility, reportData, utrCode: 'gri/2020/305-2/b' });
    const scope3 = getUtrvData({ visibility, reportData, utrCode: 'gri/2020/305-3/a' });

    const date = formatDateUTC(effectiveDate, DATE.MONTH_YEAR_SHORT);

    const scope2Num = scope2a.numericValue + scope2b.numericValue;
    acc.chartRows.push([
      date,
      scope1.numericValue,
      scope2a.numericValue + scope2b.numericValue,
      scope3.numericValue,
      (scope1.numericValue + scope2Num + scope3.numericValue)
    ])

    acc.rows.push({
      date: date,
      scope1: scope1.value ?? '',
      scope2: scope2a.value !== undefined || scope2b.value !== undefined ? sumUtrvDataResults([scope2a, scope2b], visibility) : '',
      scope3: scope3.value ?? '',
      notes: {
        scope1: getComments(reportData, 'gri/2020/305-1/a', visibility, ''),
        scope2: getComments(reportData, 'gri/2020/305-2/a', visibility, ''),
        scope3: getComments(reportData, 'gri/2020/305-3/a', visibility, '')
      }
    })

    return acc;
  }, { rows: [] as EmissionRow[], chartRows: [] as [string, ...number[]][] });

  const data: GHGEmissionsDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    rows: rows,
  };


  const chartData = [
    ['Period', 'Scope 1', 'Scope 2', 'Scope 3', { role: 'annotation' }],
    ...chartRows,
  ];

  return <Chart
    chartType='BarChart'
    data={chartData}
    width={WIDTH}
    height={HEIGHT}
    options={{
      ...CHART_DEFAULTS.OPTIONS,
      isStacked: true,
      chartArea: {
        left: 100,
        right: 20,
      },
      hAxis: {
        title: 'Absolute Emissions (Metric Tonnes)',
        minValue: 0,
      },
    }
    }
    chartEvents={
      updateData ?
        [
          {
            eventName: 'ready',
            callback: (ChartRef) => {
              data.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
              updateData('ghgEmissions', data);
            }
          }
        ] : undefined}
  />
})

export default GHGEmissions;
