import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { QuestionConfigurationModalProps } from '@features/question-configuration';
import { ConfigurationType, NON_NUMERIC_SELECTED_QUESTION_TOOLTIP } from './utils';
import { getNumericSelectedQuestions } from '@components/survey-question-list/utils';
import { SimpleTooltip } from '@g17eco/molecules';

type QuestionConfigurationProps = Pick<QuestionConfigurationModalProps, 'selectedQuestions'> & {
  hiddenOptions?: ConfigurationType[];
  changeConfigType: (type: ConfigurationType) => void;
  dropdownToggle?: JSX.Element;
  disabled?: boolean;
};

export const QuestionConfigurationDropdown = ({
  selectedQuestions,
  hiddenOptions = [],
  dropdownToggle = <i className='fas fa-bars' />,
  changeConfigType,
  disabled = false,
}: QuestionConfigurationProps) => {
  const numericSelectedQuestions = getNumericSelectedQuestions(selectedQuestions);
  const invalidInputOverrides = !numericSelectedQuestions.length;

  return (
    <UncontrolledDropdown>
      <DropdownToggle disabled={disabled} color='secondary' outline className='px-2'>
        {dropdownToggle}
      </DropdownToggle>
      <DropdownMenu className='w-100'>
        {hiddenOptions.includes(ConfigurationType.MetricOverrides) ? null : (
          <DropdownItem onClick={() => changeConfigType(ConfigurationType.MetricOverrides)}>
            <i className='fa-light fa-square-question fs-5 me-2'></i> {ConfigurationType.MetricOverrides}
          </DropdownItem>
        )}
        {hiddenOptions.includes(ConfigurationType.InputOverrides) ? null : (
          <SimpleTooltip text={invalidInputOverrides ? NON_NUMERIC_SELECTED_QUESTION_TOOLTIP : undefined}>
            <DropdownItem
              disabled={invalidInputOverrides}
              onClick={() => changeConfigType(ConfigurationType.InputOverrides)}
            >
              <i className='fa-light fa-input-numeric me-2'></i> {ConfigurationType.InputOverrides}
            </DropdownItem>
          </SimpleTooltip>
        )}
        {hiddenOptions.includes(ConfigurationType.Tags) ? null : (
          <DropdownItem onClick={() => changeConfigType(ConfigurationType.Tags)}>
            <i className='fa-light fa-tags fs-5 me-2'></i> {ConfigurationType.Tags}
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
