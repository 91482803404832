import { createApi } from '@reduxjs/toolkit/query/react'
import { ApiResponse } from '../types/api';
import { axiosBaseQuery } from './axiosQuery';
import { AppConfig } from '../types/app';
import { InitiativeMin } from '../types/initiative';
import { SurveyActionData } from '../model/surveyData';
import { SetupTaskRequest, TaskWithAction } from '../types/setup-task';
import { RequesterMessageRequest } from '../types/dataShare';

interface AppConfigData {
  appConfig?: AppConfig;
}

interface DataShareSurveyResponse {
  initiative: InitiativeMin;
  surveyData: SurveyActionData;
}

export const transformResponse = <T>(response: ApiResponse<T>) => response.data;

// Define a service using a base URL and expected endpoints
export const g17ecoApi = createApi({
  reducerPath: 'g17eco',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['setup-tasks'],
  endpoints: (builder) => ({
    getAppConfig: builder.query<AppConfigData, string>({
      transformResponse: (response: ApiResponse<AppConfigData>) => response.data,
      query: (onboardingPath: string) => ({
        url: `/direct/onboarding/${onboardingPath}/config`,
        method: 'get',
      }),
    }),
    getDataShareSurvey: builder.query<
      DataShareSurveyResponse,
      { initiativeId: string; surveyId: string; requesterType: string; requesterId: string }
    >({
      transformResponse: (response: ApiResponse<DataShareSurveyResponse>) => response.data,
      query: ({ initiativeId, surveyId, requesterType, requesterId }) => ({
        url: `/data-share/requester/${requesterType}/${requesterId}/initiative/${initiativeId}/survey/${surveyId}`,
        method: 'get',
      }),
    }),
    postRequesterMessage: builder.mutation<ApiResponse, RequesterMessageRequest>({
      query: ({ initiativeId, requesterType, requesterId, payload }) => ({
        url: `/data-share/requester/${requesterType}/${requesterId}/initiative/${initiativeId}/message`,
        method: 'post',
        data: {
          ...payload
        }
      }),
    }),
    getSetupTask: builder.query<TaskWithAction[], { initiativeId?: string }>({
      transformResponse: (response: ApiResponse<TaskWithAction[]>) => response.data,
      query: (params) => ({
        url: '/setup-tasks',
        method: 'get',
        params: params,
      }),
      providesTags: ['setup-tasks']
    }),

    setupTaskAction: builder.mutation<ApiResponse, SetupTaskRequest>({
      invalidatesTags: ['setup-tasks'],
      query: ({ taskId, action, initiativeId = '' }) => ({
        url: `/setup-tasks/${taskId}/${action}/${initiativeId}`,
        method: 'patch',
      }),
    }),
  }),
});

export const {
  useGetSetupTaskQuery,
  useSetupTaskActionMutation,
  useGetDataShareSurveyQuery,
  useGetAppConfigQuery,
  usePostRequesterMessageMutation,
} = g17ecoApi
