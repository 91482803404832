import { BasicAlert } from '@g17eco/molecules';
interface QuestionWarningProps {
  isMatchedUnit: boolean;
}

enum FilterMessage {
  MisMatchedUnit = 'The selected metric unit does not match the locked metric unit',
}

const QuestionMessage = ({ message }: { message: FilterMessage }) => (
  <BasicAlert className='mt-3' type={'danger'}>
    {message}
  </BasicAlert>
);

export const QuestionWarning = ({ isMatchedUnit }: QuestionWarningProps) => {
  return isMatchedUnit ? null : <QuestionMessage message={FilterMessage.MisMatchedUnit} />;
};
