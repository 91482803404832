import IconButton from '../../../button/IconButton';
import { Column, GENERAL_COLUMNS } from '../utils';

interface GeneralColumnsProps {
  columns: Column[],
  updateColumns: (columns: Column[]) => void;
}

const GeneralColumns = ({ columns, updateColumns }: GeneralColumnsProps) => {
  const handleAddColumn = (column: Column) => {
    const newColumns = [...columns, column];
    updateColumns(newColumns);
  }

  const handleRemoveColumn = (column: Column) => {
    const newColumns = columns.filter(col => col.code !== column.code);
    updateColumns(newColumns);
  }

  return (
    <div className='d-flex flex-column'>
      {GENERAL_COLUMNS.map((column) => {
        const isSelected = columns.some(col => col.code === column.code);
        return (
        <div key={column.code} className='d-flex w-100 align-items-center py-2'>
          <span className='ml-2'>{column.name}</span>

          <div className='ml-auto'>
            {isSelected ? (
              <IconButton
                tooltip='Remove this column from the show list'
                color='danger'
                onClick={() => handleRemoveColumn(column)}
                icon='fa-light fa-times'
                className='icon-button text-sm'
              />
            ) : (
              <IconButton
                tooltip='Add this column to the show list'
                onClick={() => handleAddColumn(column)}
                icon='fa-light fa-plus'
                className='icon-button'
              />
            )}
          </div>
        </div>
      )})}
    </div>
  );
};

export default GeneralColumns;
