import { useState } from 'react';
import { Filters, getInitialSurveyFilters } from '../util/filters';
import { SurveyType } from '@g17eco/types/survey';

export const useSurveyFilters = <T = SurveyType>(defaultFilters: Filters<T> | undefined) => {
  const [filters, setFilters] = useState<Filters<T>>(getInitialSurveyFilters(defaultFilters));

  const toggleFilter = (key: keyof Filters<T>, value: string) => {
    setFilters((state) => ({ ...state, [key]: value }));
  };
  
  return { filters, toggleFilter };
};
