import React from 'react';
import { ButtonGroup } from 'reactstrap';
import IconButton from '../../button/IconButton';
import { CardGridViewMode } from '../../survey-scope/CardGrid';
import { CustomMetricContext } from '../CustomMetricContainer';

export const CardGridViewModeButtons = () => {
  const { viewLayout, setViewLayout } = React.useContext(CustomMetricContext);

  return (
    <ButtonGroup>
      <IconButton
        active={viewLayout === CardGridViewMode.gallery}
        outline={viewLayout !== CardGridViewMode.gallery}
        color='secondary'
        className='px-2 d-none d-lg-inline'
        onClick={() => setViewLayout(CardGridViewMode.gallery)}
        icon='fa-th'
        tooltip='Gallery View'
      />
      <IconButton
        active={viewLayout === CardGridViewMode.list}
        outline={viewLayout !== CardGridViewMode.list}
        color='secondary'
        className='px-2 d-none d-lg-inline'
        onClick={() => setViewLayout(CardGridViewMode.list)}
        icon='fa-th-list'
        tooltip='List View'
      />
    </ButtonGroup>
  );
};
