import { UploadedDashboardFile } from '@api/insight-dashboards';
import { GridDashboardItem, MediaFile, UploadedMediaFile } from '@g17eco/types/insight-custom-dashboard';
import { isMediaType } from '@routes/custom-dashboard/utils';
import { checkIsVideo } from '@features/custom-dashboard/utils/dashboard-utils';

export const MAX_SIZE_MB = 50;
export const MEDIA_TITLE_HEIGHT = 32;

export const getFileRatio = async (file: Pick<MediaFile, 'type' | 'url'>) => {
  if (checkIsVideo(file)) {
    return 16 / 9;
  }

  const img = new Image();
  img.src = file.url;
  await img.decode();

  return img.width / img.height;
};

const checkIsUploadedFile = (file: MediaFile): file is UploadedMediaFile => {
  return Boolean('documentId' in file && file.documentId);
};

export const getNewFilesToUpload = (newItems: GridDashboardItem[]): { [key: string]: File[] } => {
  return newItems.reduce((newFiles, item) => {
    if (!isMediaType(item)) {
      return newFiles;
    }

    const filesToUpload = (item.files ?? []).reduce((files, file) => {
      // Only new file has originalFile to be uploaded.
      return !checkIsUploadedFile(file) ? files.concat(file.originalFile) : files;
    }, [] as File[]);

    if (!filesToUpload.length) {
      return newFiles;
    }

    return {
      ...newFiles,
      [item._id]: filesToUpload,
    };
  }, {} as { [key: string]: File[] });
};

export const mapFilesToItems = (uploadedFiles: UploadedDashboardFile[], items: GridDashboardItem[]) => {
  return items.map((item) => {
    if (!isMediaType(item)) {
      return item;
    }

    return {
      ...item,
      files: item.files?.reduce((files, itemFile) => {
        if (checkIsUploadedFile(itemFile)) {
          return [...files, { documentId: itemFile.documentId, ratio: itemFile.ratio }];
        }

        const uploadedFile = uploadedFiles.find((f) => f.name === itemFile.originalFile.name && f.itemId === item._id);

        // Only save files that are uploaded successfully.
        return uploadedFile ? [...files, { documentId: uploadedFile.documentId, ratio: itemFile.ratio }] : files;
      }, [] as { documentId: string; ratio?: number }[]),
    };
  });
};

const getDocumentIdsFromItems = (items: GridDashboardItem[]) => {
  return items.reduce((ids, item) => {
    if (isMediaType(item) && item.files) {
      item.files.forEach((file) => {
        if (checkIsUploadedFile(file)) {
          ids.push(file.documentId);
        }
      });
    }

    return ids;
  }, [] as string[]);
};

export const getDeletedDocumentIds = (oldItems: GridDashboardItem[], newItems: GridDashboardItem[]) => {
  const oldDocumentIds = getDocumentIdsFromItems(oldItems);
  const newDocumentIds = getDocumentIdsFromItems(newItems);

  return oldDocumentIds.filter((id) => !newDocumentIds.includes(id));
};

export const cloneFile = (file: File, customName: string) => {
  return new File([file], customName, { type: file.type, lastModified: file.lastModified });
}
