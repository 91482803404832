import { ApiResponse } from '../types/api';
import { RequesterDetails } from '../types/dataShare';
import { g17ecoApi } from './g17ecoApi';

const transformResponse = <T>(response: ApiResponse<T>) => response.data;

export const sponsorshipsApi = g17ecoApi
.enhanceEndpoints({
  addTagTypes: ['sponsorships'],
})
.injectEndpoints({
  endpoints: (builder) => ({
    getInitiativeSponsorships: builder.query<RequesterDetails[], string>({
      transformResponse,
      query: (initiativeId) => ({
        url: `/initiatives/${initiativeId}/sponsorships`,
        method: 'get',
      }),
      providesTags: ['sponsorships']
    })
  }),
});

export const { useGetInitiativeSponsorshipsQuery } = sponsorshipsApi;
