import classNames from 'classnames';
import { Input } from 'reactstrap';
import { Bank, BankingAction, BankingSetting, UpdateBankingSetting } from '@g17eco/types/banking-settings';
import { SimpleTooltip } from '@g17eco/molecules';

interface PopularBanksProps {
  isLoading: boolean;
  bankingSettings: BankingSetting[];
  popularBanks: Bank[];
  updateBankingSettings: (params: UpdateBankingSetting) => Promise<void>;
}

const PopularBanks = (props: PopularBanksProps) => {
  const { isLoading, bankingSettings, popularBanks, updateBankingSettings } = props;

  const isSelectedBank = (bank: Bank) => {
    return bankingSettings.some((item) => item.code === bank.code);
  };

  const selectPopularBank = (updateBank: Bank) => {
    if (isLoading) {
      return;
    }
    
    const updatedValues = isSelectedBank(updateBank)
      ? bankingSettings.filter((item) => item.code !== updateBank.code)
      : [...bankingSettings, { code: updateBank.code }];
      
    updateBankingSettings({
      bankingSettings: updatedValues,
      updateBank,
      action: isSelectedBank(updateBank) ? BankingAction.Remove : BankingAction.Add,
    });
  };

  return (
    <div className='d-flex w-100 flex-wrap mt-4 popular-banks'>
      {popularBanks.map((bank) => (
        <SimpleTooltip key={`${bank.name}_${bank.code}`} text={bank.name}>
          <div
            className={classNames(
              'position-relative d-flex justify-content-center align-items-center rounded-2 cursor-pointer bg-white popular-banks__item',
              { selected: isSelectedBank(bank) }
            )}
            onClick={() => selectPopularBank(bank)}
          >
            {bank.logo ? (
              <img className='popular-banks__logo' src={bank.logo} alt={bank.name} />
            ) : (
              <div className='w-100 h-100 d-flex justify-content-center align-items-center text-center p-3 text-black'>
                {bank.name}
              </div>
            )}
            <Input
              type='checkbox'
              className='position-absolute cursor-pointer popular-banks__checkbox'
              checked={isSelectedBank(bank)}
              readOnly
            />
          </div>
        </SimpleTooltip>
      ))}
    </div>
  );
};

export default PopularBanks;
