import { useMemo, useState } from 'react';
import { Col } from 'reactstrap';
import type { InputType } from 'reactstrap/types/lib/Input';
import { useToggle } from '@hooks/useToggle';
import { Bank, UpdateBankingSetting } from '@g17eco/types/banking-settings';
import { InitiativeData } from '@g17eco/types/initiative';
import { naturalSort } from '@utils/index';
import { getInitiativeManualBanks } from '@utils/banking-settings';
import { FormGenerator } from '@components/form/FormGenerator';
import BanksTable from './banks-table';
import { ManualBankModal, AddManualBankResponse } from './manual-bank-modal';

const searchFields = [
  {
    code: 'name',
    type: 'text' as InputType,
    required: false,
    label: 'Search for your bank name',
  },
];

const arrangeListOfBanks = (banks: Bank[], selectedBanks: Bank[] | undefined) => {
  const selectedCodes = (selectedBanks ?? []).map((bank) => bank.code);

  return banks.sort((bankA, bankB) => {
    if (selectedCodes.includes(bankA.code) && !selectedCodes.includes(bankB.code)) {
      return -1;
    }
    if (selectedCodes.includes(bankB.code) && !selectedCodes.includes(bankA.code)) {
      return 1;
    }
    return naturalSort(bankA.name ?? '', bankB.name ?? '');
  });
};

interface OtherBanksProps {
  initiative: InitiativeData;
  otherBanks: Bank[];
  isLoading: boolean;
  updateBankingSettings: (params: UpdateBankingSetting) => Promise<void>;
  addManualBank: (bankName: string) => Promise<AddManualBankResponse | undefined>;
}

const OtherBanks = (props: OtherBanksProps) => {
  const { initiative, otherBanks, isLoading, updateBankingSettings, addManualBank } = props;

  const [searchText, setSearchText] = useState('');
  const [openBanksTable, toggleOpenBanksTable] = useToggle(false);
  const [openManualBank, toggleOpenManualBank] = useToggle(false);

  // listOfOtherBanks includes: manual banks and not popular abs banks
  const listOfOtherBanks = useMemo(() => {
    if (initiative && otherBanks) {
      return [...getInitiativeManualBanks(initiative), ...otherBanks];
    }
    return otherBanks;
  }, [initiative, otherBanks]);

  const filteredBanks = useMemo(() => {
    const filterBanks = (bank: Bank) => {
      return (bank.name ?? '').toLowerCase().includes(searchText.toLowerCase());
    };
    const filteredResults = searchText ? listOfOtherBanks.filter(filterBanks) : listOfOtherBanks;
    return arrangeListOfBanks(filteredResults, initiative.bankingSettings);
  }, [listOfOtherBanks, initiative, searchText]);

  const onChange = (e: React.ChangeEvent<any>) => {
    setSearchText(e.target.value);
  };

  const hasSelectedBanks = listOfOtherBanks.some((bank) =>
    (initiative.bankingSettings ?? []).some((item) => item.code === bank.code)
  );
  const showTable = openBanksTable || searchText || hasSelectedBanks;

  return (
    <>
      <Col className='col-md-8 col-12'>
        <h4 className='text-ThemeTextDark mt-6'>Other banks</h4>
        <div className='other-banks mt-4'>
          <div className='col-sm-6 col-12 text-md'>
            <FormGenerator fields={searchFields} form={{ name: searchText }} updateForm={onChange} />
          </div>
          <div
            className='d-inline-block text-md text-ThemeHeadingLight cursor-pointer text-decoration-underline'
            onClick={() => toggleOpenBanksTable()}
          >
            Browse full bank list
          </div>
          <div className='text-md mt-4'>
            Bank not found?{' '}
            <span className='text-decoration-underline cursor-pointer' onClick={() => toggleOpenManualBank()}>
              Add it manually
            </span>
          </div>
        </div>
      </Col>
      {showTable ? (
        <BanksTable
          selectedBanks={initiative.bankingSettings ?? []}
          banks={filteredBanks}
          isLoading={isLoading}
          updateBankingSettings={updateBankingSettings}
          helperText='Search for a bank to add...'
        />
      ) : null}
      <ManualBankModal isOpen={openManualBank} handleClose={toggleOpenManualBank} addManualBank={addManualBank} />
    </>
  );
};

export default OtherBanks;
