import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadInitiativeQuestions } from '../../../actions/blueprints';
import UniversalTracker from '../../../model/UniversalTracker';
import { getUsedStandardAndFrameworkOptions } from '../../../constants/standards-frameworks';
import { filterStandardAndFramework } from '../../../components/survey/utils/filters';
import { QuestionData } from '@routes/custom-dashboard/utils';
import { getGroup, standards } from '@g17eco/core';
import { UniversalTrackerBlueprintMin, UniversalTrackerPlain, UtrValueType } from '../../../types/universalTracker';
import { ActionMeta, createFilter } from 'react-select';
import { MetricGroup } from '../../../types/metricGroup';
import { UsedScopes } from '@api/organization';
import { useGetValueListByIdQuery } from '@api/value-list';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ValueListPlain } from '@g17eco/types/valueList';
import { Option } from '@g17eco/molecules';
import { filterMetricGroups, getCustomGroupOptions } from '@utils/metricGroup';
import { ValidatingFunc, QuestionFilterType } from './utils';

interface UseQuestionFiltersProps {
  initiativeId: string;
  questionData?: QuestionData;
  blueprintQuestions: UniversalTrackerBlueprintMin[];
  usedPacks: UsedScopes;
  metricGroups: MetricGroup[];
  validatingFunc: ValidatingFunc;
}

enum MetaAction {
  Select = 'select-option',
  Clear = 'clear',
}

const getValueListOptions = (valueLists: ValueListPlain) => {
  const options = valueLists.options.map((v) => ({
    value: v.code,
    label: v.name,
    searchString: `${v.name} ${v.code}`,
    isDisabled: false,
  }));
  return [{ value: 'total', label: 'Total', searchString: 'Total', isDisabled: false }, ...options];
};

const getQuestionOptionLabel = ({
  label,
  disableReason,
}: { label: string } & Pick<ReturnType<ValidatingFunc>, 'disableReason'>) => {
  if (!disableReason) {
    return label;
  }

  return (
    <div className='d-flex align-items-center justify-content-between gap-2'>
      <div className='text-truncate'>{label}</div>
      <div className='text-xs text-nowrap text-ThemeDangerExtradark'>{disableReason}</div>
    </div>
  );
};

const getTableColumnOptions = (utrPlain: UniversalTrackerBlueprintMin, validatingFunc: ValidatingFunc) => {
  return (
    utrPlain.valueValidation?.table?.columns.reduce((options, c) => {
      const { isAllowed, disableReason } = validatingFunc(utrPlain, c.code);

      if (isAllowed) {
        options.push({
          value: c.code,
          label: getQuestionOptionLabel({ label: c.name, disableReason }),
          searchString: `${c.name} ${c.code}`,
          isDisabled: !!disableReason,
        });
      }
      return options;
    }, [] as Option[]) ?? []
  );
};

const createQuestionOptions = (questions: UniversalTrackerBlueprintMin[], validatingFunc: ValidatingFunc) =>
  questions.reduce((options, question) => {
    const { isAllowed, disableReason } = validatingFunc(question);

    if (isAllowed) {
      options.push({
        label: getQuestionOptionLabel({ label: question.name, disableReason }),
        searchString: `${question.name} ${question.typeCode || ''}`,
        value: question.code,
        isDisabled: !!disableReason,
      });
    }
    return options;
  }, [] as Option[]);

const getSelectingPack = ({
  utr,
  packs,
  metricGroups,
}: {
  utr?: UniversalTrackerBlueprintMin;
  packs: Option[];
  metricGroups: MetricGroup[];
}) => {
  if (!utr) {
    return { groupCode: '', subGroupCode: '' };
  }
  const groupCode = packs.find((p) => p.value === utr.type)?.value;
  if (!groupCode) {
    const customGroup = metricGroups.find((group) =>
      group.universalTrackers?.some((customMetricId) => customMetricId === utr._id)
    );
    return { groupCode: customGroup?._id ?? '', subGroupCode: '' };
  }
  const subGroups = getGroup('standards-and-frameworks', groupCode)?.subgroups ?? [];
  const subGroupCode = subGroups.find((s) => utr.typeTags?.includes(s.code))?.code ?? '';
  return { groupCode, subGroupCode };
};

type QuestionFilters = {
  [key in QuestionFilterType]?: string;
};
const getResetChildren = (key: keyof QuestionFilters): QuestionFilters => {
  switch (key) {
    case QuestionFilterType.Pack:
      return {
        [QuestionFilterType.Subpack]: '',
        [QuestionFilterType.Question]: '',
        [QuestionFilterType.Input]: '',
      };
    case QuestionFilterType.Subpack:
      return {
        [QuestionFilterType.Question]: '',
        [QuestionFilterType.Input]: '',
      };
    case QuestionFilterType.Question:
      return {
        [QuestionFilterType.Input]: '',
      };
    default:
      return {};
  }
};

export const useQuestionFilters = ({
  initiativeId,
  questionData,
  blueprintQuestions,
  metricGroups,
  usedPacks,
  validatingFunc,
}: UseQuestionFiltersProps) => {
  const [filters, setFilters] = useState<QuestionFilters>(() => {
    if (!questionData) {
      return {};
    }
    const { valueListCode, groupCode, subGroupCode, code } = questionData;
    return {
      pack: groupCode,
      subPack: subGroupCode,
      question: code,
      input: valueListCode,
    };
  });

  const selectedQuestion = blueprintQuestions.find((q) => q.code === filters.question);
  const isSupportedType = selectedQuestion && validatingFunc(selectedQuestion);
  const selectedValueListId = isSupportedType ? selectedQuestion.valueValidation?.valueList?.listId : undefined;

  const { data: valueLists } = useGetValueListByIdQuery(selectedValueListId ?? skipToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    dispatch(loadInitiativeQuestions(initiativeId));
  }, [dispatch, initiativeId]);

  const packOptions: Option[] = useMemo(() => {
    const customGroupOptions = getCustomGroupOptions(metricGroups);
    return [...customGroupOptions, ...getUsedStandardAndFrameworkOptions(usedPacks)];
  }, [metricGroups, usedPacks]);

  const subPacks: Option[] = useMemo(() => {
    const selectedPack = filters.pack;
    if (!selectedPack) {
      return [];
    }
    const subGroups = getGroup('standards-and-frameworks', selectedPack)?.subgroups ?? [];
    const usedPackType = standards[selectedPack] ? 'standards' : 'frameworks';
    const usedStandardSubGroups = subGroups.filter((group) =>
      usedPacks[usedPackType][selectedPack]?.includes(group.code)
    );
    return usedStandardSubGroups.map((group) => ({
      label: group.name,
      searchString: group.name,
      value: group.code,
    }));
  }, [filters.pack, usedPacks]);

  // TODO: Optimize this dropdown items rendering
  const filteredQuestions: Option[] = useMemo(() => {
    if (!blueprintQuestions) {
      return [];
    }
    // Currently subpack dropdown is disabled if pack is not selected
    // so just need to check if pack is selected
    if (!filters.pack) {
      return createQuestionOptions(blueprintQuestions, validatingFunc);
    }
    const scope = filters.subPack || filters.pack;
    if (!getGroup('standards-and-frameworks', filters.pack)) {
      return createQuestionOptions(
        blueprintQuestions.filter((question) =>
          filterMetricGroups({
            utrId: question._id,
            metricGroupId: scope,
            metricGroups,
          })
        ),
        validatingFunc
      );
    }
    return createQuestionOptions(
      blueprintQuestions.filter((question) =>
        filterStandardAndFramework({ universalTracker: new UniversalTracker(question as UniversalTrackerPlain) }, scope)
      ),
      validatingFunc
    );
  }, [blueprintQuestions, filters.pack, filters.subPack, metricGroups, validatingFunc]);

  const questionInputs: Option[] = useMemo(() => {
    const currentQuestion = filteredQuestions.find((question) => question.value === filters.question);
    const utrPlain = blueprintQuestions?.find((question) => question.code === currentQuestion?.value);

    if (!utrPlain) {
      return [];
    }

    if (utrPlain.valueType === UtrValueType.NumericValueList && valueLists) {
      return getValueListOptions(valueLists);
    }

    return getTableColumnOptions(utrPlain, validatingFunc);
  }, [blueprintQuestions, filteredQuestions, filters.question, validatingFunc, valueLists]);

  const filterOption = createFilter<Option | null>({
    stringify: ({ data }) => (data ? `${data.searchString ?? data.label}` : ''),
    ignoreAccents: false,
  });

  const handleChangeFilters = (key: keyof QuestionFilters, actionMeta: ActionMeta<Option | null>, option?: string) => {
    const reset = getResetChildren(key);
    switch (actionMeta.action) {
      case MetaAction.Select:
        // Populate pack and subpack, depending on metric types (standards, custom metrics) if not set
        if (key === QuestionFilterType.Question && !filters.pack) {
          const newQuestion = blueprintQuestions.find((q) => q.code === option);
          const { groupCode, subGroupCode } = getSelectingPack({ utr: newQuestion, packs: packOptions, metricGroups });
          setFilters({
            pack: groupCode,
            subPack: subGroupCode,
            question: option,
          });
          break;
        }
        setFilters({
          ...filters,
          ...reset,
          [key]: option,
        });
        break;
      case MetaAction.Clear:
        setFilters({
          ...filters,
          ...reset,
          [key]: '',
        });
        break;
      default:
        break;
    }
  };

  return {
    packOptions,
    subPacks,
    filteredQuestions,
    questionInputs,
    filters,
    filterOption,
    handleChangeFilters,
  };
};
