import { QUESTION } from '@constants/terminology';
import { CollapsibleSection } from './CollapsibleSection';
import { AIUtrvSuggestion } from '@api/ai';

const renderListItems = ({ key, list }: { key: string; list: string[] }) => {
  return (
    <ul>
      {list.map((item, index) => (
        <li className='text-ThemeTextMedium' key={`${key}-${index}`}>
          <span>{item}</span>
        </li>
      ))}
    </ul>
  );
};

type Props = Partial<
  Pick<AIUtrvSuggestion, 'questionExplanation' | 'keyInfo' | 'whereToFind' | 'suggestedEvidence' | 'bestPractice'>
>;

export const MetricBreakdown = ({
  questionExplanation = '',
  keyInfo,
  whereToFind,
  suggestedEvidence,
  bestPractice,
}: Props) => {
  return (
    <div>
      <CollapsibleSection title={`${QUESTION.CAPITALIZED_SINGULAR} Explanation`} defaultOpen>
        <p className='text-ThemeTextMedium'>{questionExplanation}</p>
      </CollapsibleSection>
      {keyInfo ? (
        <CollapsibleSection title={'Key Information Needed'}>
          {renderListItems({ key: 'keyInfo', list: keyInfo })}
        </CollapsibleSection>
      ) : null}
      {whereToFind ? (
        <CollapsibleSection title={'Where to Find This Data'}>
          {whereToFind.externalSource.length > 0 ? (
            <div>
              <span>1. External Source:</span>
              {renderListItems({ key: 'externalSource', list: whereToFind.externalSource })}
            </div>
          ) : null}
          {whereToFind.internalSource.length > 0 ? (
            <div>
              <span>2. Internal Source:</span>
              {renderListItems({ key: 'internalSource', list: whereToFind.internalSource })}
            </div>
          ) : null}
        </CollapsibleSection>
      ) : null}
      {suggestedEvidence ? (
        <CollapsibleSection title={'Suggested Evidence Files'}>
          {suggestedEvidence.primaryDocumentation.length > 0 ? (
            <div>
              <span>1. Primary Documentation:</span>
              {renderListItems({ key: 'primaryDocumentation', list: suggestedEvidence.primaryDocumentation })}
            </div>
          ) : null}
          {suggestedEvidence.supportingDocumentation.length > 0 ? (
            <div>
              <span>2. Supporting Documentation:</span>
              {renderListItems({ key: 'supportingDocumentation', list: suggestedEvidence.supportingDocumentation })}
            </div>
          ) : null}
        </CollapsibleSection>
      ) : null}
      {bestPractice ? (
        <CollapsibleSection title={'Best Practice Tips'}>
          <p className='text-ThemeTextMedium'>{renderListItems({ key: 'bestPractice', list: bestPractice })}</p>
        </CollapsibleSection>
      ) : null}
    </div>
  );
};
