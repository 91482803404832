import { Badge } from 'reactstrap';
import { Tag } from '../../../../types/metricGroup';

export const TagItem = ({ groupName }: Pick<Tag, 'groupName'>) => {
  return (
    <Badge className='mr-2 mb-2 bg-transparent text-ThemeInfoDark fw-normal border border-ThemeInfoDark'>
      {groupName}
    </Badge>
  );
};
