import { Group } from '@g17eco/core';

export const findChildGroupByCode = (group: Group, code: string): Group | undefined => {
  if (!group.subgroups) {
    return undefined;
  }

  for (const groupElement of group.subgroups) {
    if (groupElement.code === code) {
      return groupElement;
    }

    const childGroup = findChildGroupByCode(groupElement, code);
    if (childGroup) {
      return childGroup;
    }
  }
};
