import BankingSettings from '@features/banking-settings';
import { ROUTES } from '@constants/routes';
import { CTAdminBreadcrumbs } from '../components/breadcrumbs/CTAdminBreadcrumbs';

export const BankingSettingsRoute = () => {
  return (
    <BankingSettings
      baseRoute={ROUTES.ACCOUNT_SETTINGS}
      BreadcrumbsComponent={CTAdminBreadcrumbs}
    />
  );
};
