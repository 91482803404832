import { UtrValueType } from '@g17eco/types/universalTracker';
import { ExtendedHistoryData } from './types';
import { naturalSort } from '@utils/index';
import { conversionParams, getInputUnitAndNumberScaleText } from '@utils/universalTrackerValue';
import { ConversionData } from '@g17eco/types/universalTrackerValue';
import UniversalTracker from '@models/UniversalTracker';
import { HistoryData } from '@g17eco/types/disaggregation';

export const sortBySubsidiary = (a: ExtendedHistoryData, b: ExtendedHistoryData) => {
  return naturalSort(a.displaySubsidiary, b.displaySubsidiary);
};

const SUPPORTED_TYPES = [
  UtrValueType.Number,
  UtrValueType.Percentage,
  UtrValueType.Text,
  UtrValueType.Date,
  UtrValueType.NumericValueList,
];

export const getSupportedValueText = (utrv: HistoryData, utr: UniversalTracker) => {
  if (!SUPPORTED_TYPES.includes(utr.getValueType())) {
    return '';
  }
  const { value, valueData } = utrv;
  const inputValue = valueData?.input?.value ? valueData.input.value : value;
  return getValueString(inputValue, utrv, utr);
};

export const getValueString = (value: number | undefined, utrv: HistoryData, utr: UniversalTracker) => {
  const { valueData } = utrv;
  if (value === undefined) {
    return valueData &&
      typeof valueData === 'object' &&
      'notApplicableType' in valueData &&
      valueData.notApplicableType === 'not_reported'
      ? 'N/R'
      : 'N/A';
  }
  const data = conversionParams(utrv, utr, { value } as Partial<ConversionData>);

  const unitAndNumberScaleText = getInputUnitAndNumberScaleText(data);

  return `${value} ${unitAndNumberScaleText}`;
};
