
import React from 'react';
import { genericInputGroup, genericInputGroupNumberScale, getDropdownProps } from '../InputGroup';
import { ColumnInputProps } from './InputInterface';
import { UnitTypes } from '@utils/units';
import { InputGroupText } from 'reactstrap';

export function TableInputGroupAppend(props: ColumnInputProps) {

  const {
    updateColumn,
    column: { code, unitType, unit, numberScale },
    inputColumn,
    suffix,
    isInvalid,
    initiativeUtr,
  } = props;
  const overriddenColumn = initiativeUtr?.valueValidation?.table?.columns.find(col => col.code === code);

  const currentNumberScale = inputColumn.numberScale ?? numberScale;

  const { options: numberScaleOptions, isDisabled: isNumberScaleDisabled } = getDropdownProps({
    ...props,
    unitType: UnitTypes.numberScale,
    currentValue: currentNumberScale,
    overriddenValue: overriddenColumn?.numberScaleInput,
    isLocked: overriddenColumn?.numberScaleLocked,
  });

  if (unitType === UnitTypes.currency) {
    return genericInputGroupNumberScale({
      isDisabled: isNumberScaleDisabled,
      isInvalid,
      numberScale: currentNumberScale,
      options: numberScaleOptions,
      handleNumberScaleChange: (numberScale: string) => updateColumn({ code, numberScale }),
    });
  }

  const currentUnit = inputColumn.unit ?? unit;

  const { options: unitOptions, isDisabled: isUnitDisabled } = getDropdownProps({
    ...props,
    unitType,
    currentValue: currentUnit,
    overriddenValue: overriddenColumn?.unitInput,
    isLocked: overriddenColumn?.unitLocked,
  });

  return (
    <>
      {genericInputGroupNumberScale({
        isDisabled: isNumberScaleDisabled,
        isInvalid,
        numberScale: currentNumberScale,
        options: numberScaleOptions,
        handleNumberScaleChange: (numberScale: string) => updateColumn({ code, numberScale }),
      })}
      {genericInputGroup({
        options: unitOptions,
        isDisabled: isUnitDisabled,
        isInvalid,
        unit: currentUnit, // dropdown value
        suffix,
        value: inputColumn.value, // should use singular/plurar text
        handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
          updateColumn({ code, unit: e.target.value }),
      })}
    </>
  );
}

export const InputTableGroupPrePend = (props: ColumnInputProps) => {

  const { column: { unitType, unit }, isInvalid } = props;

  // Table question use default survey configuration unitConfig.currency value
  const currentUnit = props.unitConfig?.currency || unit;
  const prefixString = unitType === UnitTypes.currency ? currentUnit : '';
  if (!prefixString) {
    return null;
  }

  return (
    <InputGroupText className={`${isInvalid ? 'styled-readonly-invalid' : 'styled-readonly'} px-4`}>
      {prefixString}
    </InputGroupText>
  );
}
