import { useToggle } from '@hooks/useToggle';
import { Collapse } from 'reactstrap';

interface Props {
  title: string | JSX.Element;
  children: React.ReactNode;
  defaultOpen?: boolean;
}

export const CollapsibleSection = (props: Props) => {
  const { title, children, defaultOpen = false } = props;
  const [isOpen, toggle] = useToggle(defaultOpen);

  return (
    <div>
      <div onClick={toggle} className='d-flex justify-content-between align-items-center cursor-pointer'>
        <div className='h6 text-ThemeHeadingDark flex-fill'>{title}</div>
        <i className={`fa fal fa-caret-${isOpen ? 'down' : 'right'}`}></i>
      </div>
      <Collapse isOpen={isOpen} defaultOpen={defaultOpen}>
        {children}
      </Collapse>
    </div>
  );
};
