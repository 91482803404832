import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { DefaultSurveyConfig } from '@components/company-settings';
import { useAppSelector } from '@reducers/index';
import { currentInitiative, getRootOrg } from '@selectors/initiative';
import { InitiativeDetails } from '@components/company-settings/InitiativeDetails';
import { BlockingLoader } from '@components/loader/BlockingLoader';
import { Container } from '@components/company-settings/Container';
import { InitiativeData, RootInitiativeData } from '@g17eco/types/initiative';
import { ROUTES } from '@constants/routes';
import { CompanySettingsPages } from '../utils';
import { MTAdminBreadcrumbs } from '../components/breadcrumbs/MTAdminBreadcrumbs';
import { SURVEY } from '@constants/terminology';

interface PageProps {
  page: string;
  rootOrg: RootInitiativeData;
  initiative: InitiativeData;
}

const Page = ({ page, rootOrg, initiative }: PageProps) => {
  switch (page) {
    case CompanySettingsPages.DefaultSurveyConfig:
      return <DefaultSurveyConfig />;
    case CompanySettingsPages.Details:
    default:
      return <InitiativeDetails rootOrg={rootOrg} initiative={initiative} bankingRoute={ROUTES.MATERIALITY_TRACKER_BANKING_SETTINGS} />;
  }
};

export const CompanySettingsRoute = () => {
  const { path } = useRouteMatch();
  const { page = 'detail' } = useParams<{ page?: string }>();

  const rootOrg = useAppSelector(getRootOrg);
  const initiative = useAppSelector(currentInitiative);

  const navTabs: { code: string; label: string }[] = [
    {
      code: CompanySettingsPages.Details,
      label: 'Company details',
    },
    {
      code: CompanySettingsPages.DefaultSurveyConfig,
      label: `Default ${SURVEY.SINGULAR} configuration`,
    },
  ];

  return (
    <>
      <Switch>
        <Route path={path}>
          {!rootOrg || !initiative ? (
            <BlockingLoader />
          ) : (
            <Container
              BreadCrumbsComponent={MTAdminBreadcrumbs}
              initiative={initiative}
              navTabs={navTabs}
              page={page}
              settingRoute={ROUTES.MATERIALITY_TRACKER_COMPANY_SETTINGS}
            >
              <Page page={page} initiative={initiative} rootOrg={rootOrg} />
            </Container>
          )}
        </Route>
      </Switch>
    </>
  );
};
