import { CustomReport, CustomReportFormData, MetricSubmitData, MetricType } from '@g17eco/types/custom-report';
import { g17ecoApi, transformResponse } from './g17ecoApi';

enum Tag {
  CustomReports = 'custom-reports',
  CustomReport = 'custom-report',
}

interface Params {
  initiativeId: string;
  reportId: string;
  formData: CustomReportFormData;
}

interface MetricPrams {
  metricData: MetricSubmitData;
  metricType: MetricType;
  metricId: string;
}

const getUrl = (initiativeId: string, reportId?: string) =>
  `/initiatives/${initiativeId}/custom-reports${reportId ? `/${reportId}` : ''}`;

export const customReportsApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: Object.values(Tag),
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCustomReports: builder.query<CustomReport[], string>({
        transformResponse,
        query: (initiativeId) => ({
          url: getUrl(initiativeId),
          method: 'get',
        }),
        providesTags: (_result, _error, initiativeId) => {
          return [{ type: Tag.CustomReports, id: initiativeId }];
        },
      }),
      createCustomReport: builder.mutation<CustomReport, Pick<Params, 'initiativeId' | 'formData'>>({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: Tag.CustomReports, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, formData }) => ({
          url: getUrl(initiativeId),
          method: 'post',
          data: formData,
        }),
      }),
      getCustomReport: builder.query<CustomReport, Pick<Params, 'initiativeId' | 'reportId'>>({
        transformResponse,
        query: ({ initiativeId, reportId }) => ({
          url: getUrl(initiativeId, reportId),
          method: 'get',
        }),
        providesTags: (_result, _error, { reportId }) => {
          return [{ type: Tag.CustomReport, id: reportId }];
        },
      }),
      updateCustomReport: builder.mutation<CustomReport, Pick<Params, 'initiativeId' | 'reportId' | 'formData'>>({
        invalidatesTags: (_result, _error, { initiativeId, reportId }) => [
          { type: Tag.CustomReports, id: initiativeId },
          { type: Tag.CustomReport, id: reportId },
        ],
        transformResponse,
        query: ({ initiativeId, reportId, formData }) => ({
          url: getUrl(initiativeId, reportId),
          method: 'put',
          data: formData,
        }),
      }),
      cloneCustomReport: builder.mutation<CustomReport, Pick<Params, 'initiativeId' | 'reportId'>>({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: Tag.CustomReports, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, reportId }) => ({
          url: `${getUrl(initiativeId, reportId)}/clone`,
          method: 'post',
        }),
      }),
      deleteCustomReport: builder.mutation<boolean, Pick<Params, 'initiativeId' | 'reportId'>>({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: Tag.CustomReports, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, reportId }) => ({
          url: getUrl(initiativeId, reportId),
          method: 'delete',
        }),
      }),
      addMetricToCustomReport: builder.mutation<
        CustomReport,
        Pick<Params, 'initiativeId' | 'reportId'> & Pick<MetricPrams, 'metricType' | 'metricData'>
      >({
        invalidatesTags: (_result, _error, { reportId }) => [{ type: Tag.CustomReport, id: reportId }],
        transformResponse,
        query: ({ initiativeId, reportId, metricData: data, metricType }) => ({
          url: `${getUrl(initiativeId, reportId)}/metrics/${metricType}`,
          method: 'post',
          data,
        }),
      }),
      removeMetricFromCustomReport: builder.mutation<
        CustomReport,
        Pick<Params, 'initiativeId' | 'reportId'> & Pick<MetricPrams, 'metricId'>
      >({
        invalidatesTags: (_result, _error, { reportId }) => [{ type: Tag.CustomReport, id: reportId }],
        transformResponse,
        query: ({ initiativeId, reportId, metricId }) => ({
          url: `${getUrl(initiativeId, reportId)}/metrics/${metricId}`,
          method: 'delete',
        }),
      }),
    }),
  });

export const {
  useGetCustomReportsQuery,
  useCreateCustomReportMutation,
  useGetCustomReportQuery,
  useUpdateCustomReportMutation,
  useCloneCustomReportMutation,
  useDeleteCustomReportMutation,
  useAddMetricToCustomReportMutation,
  useRemoveMetricFromCustomReportMutation,
} = customReportsApi;
