export const QUESTION = {
  SINGULAR: 'metric',
  CAPITALIZED_SINGULAR: 'Metric',
  PLURAL: 'metrics',
  CAPITALIZED_PLURAL: 'Metrics',
  UPPERCASE_PLURAL: 'METRICS',
};

export const PACK = {
  SINGULAR: 'module',
  CAPITALIZED_SINGULAR: 'Module',
  PLURAL: 'modules',
  CAPITALIZED_PLURAL: 'Modules',
};

export enum SURVEY {
  SINGULAR = 'report',
  CAPITALIZED_SINGULAR = 'Report',
  PLURAL = 'reports',
  CAPITALIZED_PLURAL = 'Reports',
  ADJECTIVE = 'reporting',
  CAPITALIZED_ADJECTIVE = 'Reporting',
}
