import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { SerializedEditorState } from 'lexical/LexicalEditorState';

// 'empty' editor
const emptyEditorState =
'{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

const getGuardEditorState = (state: SerializedEditorState | undefined): SerializedEditorState | string => {
  return !state || !state.root.children || state.root.children.length === 0 ? emptyEditorState : state;
}

export const useGuardEditorState = (editorState?: SerializedEditorState) => {
  const [editor] = useLexicalComposerContext();
  return {
    editor,
    guardEditorState: editor.parseEditorState(getGuardEditorState(editorState)),
    getGuardEditorState: (state: SerializedEditorState) => editor.parseEditorState(getGuardEditorState(state)),
  }
}