import { CardsTotals } from '@g17eco/types/download';
import { g17ecoApi, transformResponse } from './g17ecoApi';

interface SurveyStatusesCountParams {
  initiativeId: string;
  surveyId: string;
}

enum Tags {
  MetricsDownload = 'metrics-download',
}

export const surveyMetricsDownloadApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: [Tags.MetricsDownload],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSurveyStatusesCountByGroup: builder.query<CardsTotals, SurveyStatusesCountParams>({
        transformResponse,
        query: ({ initiativeId, surveyId }) => ({
          url: `/initiatives/${initiativeId}/verified-count/${surveyId}`,
          method: 'get',
        }),
        providesTags: (_result, _error, agrs) => [{ type: Tags.MetricsDownload, id: agrs.surveyId }],
      }),
    }),
  });

export const { useGetSurveyStatusesCountByGroupQuery } = surveyMetricsDownloadApi;
