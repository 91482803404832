import { validateMinMax } from '@utils/universalTrackerValue';
import { useState } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';

export const MIN_WEIGHT = 0;
export const MAX_WEIGHT = 100;

interface Props {
  value: number;
  updateWeight: (value: number) => void;
  disabled?: boolean;
  initiativeId: string;
}

export const WeightColumn = ({ value, updateWeight, disabled, initiativeId }: Props) => {
  const [isInvalid, setIsInvalid] = useState(validateMinMax(value, MIN_WEIGHT, MAX_WEIGHT).errored);
  return (
    <InputGroup>
      <Input
        data-testid={`weight-${initiativeId}`}
        type={'number'}
        min={MIN_WEIGHT}
        max={MAX_WEIGHT}
        className='text-center'
        disabled={disabled}
        defaultValue={Math.round(value * 10) / 10}
        onBlur={(e) => updateWeight(Number(e.currentTarget.value))}
        onKeyDown={(e) => e.key === 'Enter' && updateWeight(Number(e.currentTarget.value))}
        onChange={(e) => setIsInvalid(validateMinMax(e.currentTarget.value, MIN_WEIGHT, MAX_WEIGHT).errored)}
        invalid={isInvalid}
      />
      <InputGroupText>%</InputGroupText>
    </InputGroup>
  );
}
