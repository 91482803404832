import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { SafeUser } from '../constants/users';
import { ApiResponse } from '../types/api';
import { axiosBaseQuery } from './axiosQuery';
import { SurveyGroupsData } from '../model/surveyData';
import { DataPeriods, UniversalTrackerBlueprintMin } from '../types/universalTracker';
import { DefaultBlueprintCode } from '../components/survey-configuration/types';
import { InitiativeUniversalTracker } from '../types/initiativeUniversalTracker';
import { Tag } from '../types/metricGroup';
import { transformResponse } from './g17ecoApi';

export enum ViewType {
  InScope = 'in-scope',
  Blueprint = 'blueprint',
}

interface BulkDelegateUserRequest {
  initiativeId: string,
  userId: string
}

export interface InitiativeQuestionManagementResponse {
  utrs: UniversalTrackerBlueprintMin[];
  utrTagMap: { [key: UniversalTrackerBlueprintMin['_id']]: Tag['groupName'][] };
  rootInitiativeUtrs: InitiativeUniversalTracker[];
}

export interface BulkDelegateQuestionRequest extends BulkDelegateUserRequest {
  datePeriods: { period: DataPeriods; effectiveDate: string; }[];
  initiativeIds: string[];
}

export interface BulkDelegateRequest extends BulkDelegateQuestionRequest {
  stakeholderUtrIds: string[];
  verifierUtrIds: string[];
}

export type UserDelegationData = Pick<BulkDelegateRequest, 'stakeholderUtrIds' | 'verifierUtrIds' | 'initiativeIds' | 'datePeriods'>

interface BulkDelegateResponse {
  userId: string[],
  roles: string[];
  count: number;
}

export const adminDashboardApi = createApi({
  reducerPath: 'adminDashboardApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['admin-dashboard'],
  endpoints: (builder) => ({
    getDelegatedUser: builder.query<SafeUser, BulkDelegateUserRequest>({
      transformResponse: (response: ApiResponse<SafeUser>) => response.data,
      query: ({ initiativeId, userId }) => ({
        url: `/initiatives/${initiativeId}/users/${userId}`,
        method: 'get',
      }),
    }),
    getSurveyGroups: builder.query<SurveyGroupsData, BulkDelegateQuestionRequest>({
      transformResponse: (response: ApiResponse<SurveyGroupsData>) => response.data,
      query: ({ initiativeId, userId, datePeriods, initiativeIds }) => ({
        url: `/initiatives/${initiativeId}/users/${userId}/delegation/questions`,
        method: 'post',
        data: {
          datePeriods,
          initiativeIds
        }
      }),
    }),
    delegateToUser: builder.mutation<BulkDelegateResponse, BulkDelegateRequest>({
      transformResponse: (response: ApiResponse<BulkDelegateResponse>) => response.data,
      query: ({ initiativeId, userId, ...data }) => ({
        url: `/initiatives/${initiativeId}/users/${userId}/delegation`,
        method: 'post',
        data: data
      }),
    }),
    getInitiativeBlueprintQuestions: builder.query<UniversalTrackerBlueprintMin[], { initiativeId: string }>({
      transformResponse: (response: ApiResponse<UniversalTrackerBlueprintMin[]>) => response.data,
      query: ({ initiativeId }) => ({
        url: `initiatives/${initiativeId}/blueprint/${DefaultBlueprintCode}/questions`,
        method: 'get'
      }),
    }),
    getInitiativeQuestions: builder.query<InitiativeQuestionManagementResponse, { initiativeId: string, viewType: ViewType }>({
      transformResponse,
      query: ({ initiativeId, viewType }) => ({
        url: `initiatives/${initiativeId}/questions/${viewType}`,
        method: 'get'
      }),
    }),
  }),
});

export const {
  useGetDelegatedUserQuery,
  useGetSurveyGroupsQuery,
  useDelegateToUserMutation,
  useGetInitiativeBlueprintQuestionsQuery,
  useGetInitiativeQuestionsQuery,
} = adminDashboardApi
