import { SingleValue } from 'react-select';
import { Col, Row } from 'reactstrap';
import { MonthDay } from '../../types/onboarding';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules';
import { capitalise } from '@utils/index';

interface Props {
  value: MonthDay;
  onChange: (value: MonthDay) => void;
}

const defaultOption = { value: '-', label: '-' };

const daysPerMonth: { [key: string]: number } = {
  january: 31,
  february: 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31,
};

const getDayOptions = (month: string) => {
  if (!month || !Object.keys(daysPerMonth).includes(month)) {
    return [defaultOption];
  }

  return Array.from({ length: daysPerMonth[month] }, (v, i) => ({ value: String(i + 1), label: String(i + 1) }));
};

const monthOptions = [defaultOption, ...Object.keys(daysPerMonth).map((month) => ({ value: month, label: month }))];


export const MonthDayPicker = ({ value, onChange }: Props) => {
  const { month, day } = value;

  const dayOptions = getDayOptions(month);

  const onChangeDay = (newValue: SingleValue<Option | null>) => {
    onChange({ ...value, day: newValue?.value || '1' });
  };

  const onChangeMonth = (newValue: SingleValue<Option | null>) => {
    const newMonth = newValue?.value;
    if (!newMonth || defaultOption.value === newMonth) {
      onChange({ month: defaultOption.value, day: defaultOption.value });
      return;
    }

    const days = daysPerMonth[newMonth];
    if (defaultOption.value !== day && Number(day) > days) {
      onChange({ month: newMonth, day: String(days) });
      return;
    }

    if (defaultOption.value === day) {
      onChange({ month: newMonth, day: '1' });
      return;
    }

    onChange({ ...value, month: newMonth });
  };

  const selectedMonth = monthOptions.find(({ value }) => value === month) ?? null;
  const selectedDay = dayOptions.find(({ value }) => value === day) ?? null;

  return (
    <Row className='mt-1'>
      <Col xs={7} className='px-2'>
        <div className='month-day-picker__label'>Month</div>
        <SelectFactory
          selectType={SelectTypes.SingleSelect}
          value={selectedMonth}
          options={monthOptions}
          onChange={onChangeMonth}
          getOptionLabel={(op) => capitalise(op?.label as string)}
          isFlexibleSize
          menuPlacement='top'
        />
      </Col>
      <Col xs={5} className='px-2'>
        <div className='month-day-picker__label'>Day</div>
        <SelectFactory
          selectType={SelectTypes.SingleSelect}
          value={selectedDay}
          options={dayOptions}
          onChange={onChangeDay}
          isFlexibleSize
          menuPlacement='top'
        />
      </Col>
    </Row>
  );
};
