import {
  useGetAggregatedSurveyStatusQuery,
  useUpdateAutoAggregatedSurveyMutation,
} from '@api/auto-aggregated-survey';
import { DATE, formatDate } from '@utils/date';
import { JobNotFoundStatus } from '@g17eco/types/background-jobs';
import { SubmitButton } from '@components/button/SubmitButton';
import { QueryWrapper } from '@components/query/QueryWrapper';
import { SURVEY } from '@constants/terminology';

const UPDATE_AGGREGATED_SURVEY_MESSAGE = `Auto-aggregated ${SURVEY.SINGULAR} is updating...`;

export const RefreshAggregatedSurveyButton = ({
  initiativeId,
  lastUpdated,
}: {
  initiativeId: string;
  lastUpdated: string;
}) => {
  const [updateAggregatedSurvey] = useUpdateAutoAggregatedSurveyMutation();
  const query = useGetAggregatedSurveyStatusQuery(initiativeId);

  const lastRefresh = lastUpdated ? formatDate(lastUpdated, DATE.HUMANIZE) : '-';

  const onClick = async () => {
    await updateAggregatedSurvey(initiativeId);
  };

  return (
    <QueryWrapper
      query={query}
      onError={() => null}
      onSuccess={(data) => {
        if (data.status === JobNotFoundStatus) {
          return (
            <div>
              <SubmitButton className='mr-2' onClick={onClick} color='secondary'>
                Refresh page data
              </SubmitButton>
              <span className='text-ThemeTextMedium'>Last refresh: {lastRefresh}</span>
            </div>
          );
        }

        return <div className='pt-1 text-ThemeTextMedium'>{UPDATE_AGGREGATED_SURVEY_MESSAGE}</div>;
      }}
    />
  );
};
