import { useMemo } from 'react';
import { Tag } from '../../../../types/metricGroup';
import { TagItem } from './TagItem';

interface TagListProps {
  tags: Tag[];
  utrId: string;
}

export const TagList = ({ tags = [], utrId }: TagListProps) => {
  const utrTags = useMemo(() => {
    return tags.filter((tag) => tag.universalTrackers?.includes(utrId));
  }, [tags, utrId]);

  if (!utrTags.length) {
    return null;
  }

  return (
    <div>
      <div className='d-flex align-items-center mt-6 mb-2'>
        <i className='fal fa-tag text-ThemeIconSecondary mr-1'></i>
        <span className='text-ThemeTextMedium text-label text-medium'>TAGS</span>
      </div>
      <div>
        {utrTags.map((tag) => {
          return <TagItem key={tag._id} groupName={tag.groupName} />;
        })}
      </div>
    </div>
  );
};
