import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useGoBack } from '../hooks/useGoBack';

export const TemplateSettingsHeader = () => {
  const { initiativeId = '' } = useParams<{ initiativeId: string }>();
  const goBack = useGoBack(initiativeId);
  return (
    <div className='w-100 ml-3 mb-4'>
      <Button color='link' onClick={goBack}>
        <i className='fa fa-arrow-circle-left mr-2' />
        Go back to template dashboard
      </Button>
    </div>
  );
};
