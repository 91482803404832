import React, { useEffect, useState } from 'react';
import { Animate } from 'react-move';

interface AnimatedProgressProviderProps {
  valueStart: number;
  valueEnd: number;
  duration?: number;
  children: (value: number) => JSX.Element;
}

export const AnimatedProgressProvider = ({
  valueStart = 0,
  valueEnd,
  duration = 1,
  children,
}: AnimatedProgressProviderProps) => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    setIsAnimated((prev) => !prev);
  }, []);

  return (
    <Animate
      start={() => ({
        value: valueStart,
      })}
      update={() => ({
        value: [isAnimated ? valueEnd : valueStart],
        timing: {
          duration: duration * 1000,
        },
      })}
    >
      {({ value }) => children(value)}
    </Animate>
  );
};
