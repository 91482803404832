import React from 'react'
import { InputGroupText } from 'reactstrap';
import { BaseInputProps } from './InputProps';

export const InputGroupPrePend = ({ prefix, universalTracker, unit, isInvalid }: Pick<BaseInputProps, 'prefix' | 'universalTracker' | 'unit' | 'isInvalid'>) => {

  const prefixString = universalTracker.isCurrency() ? unit : prefix;
  if (!prefixString) {
    return '';
  }

  return (
    <InputGroupText className={isInvalid ? 'styled-readonly-invalid' : 'styled-readonly'}>
      {prefixString}
    </InputGroupText>
  );
}
