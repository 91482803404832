import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../styles.scss';
import { useState } from 'react';
import G17Client from '@services/G17Client';
import { useSiteAlert } from '@hooks/useSiteAlert';
import Loader from '@components/loader';
import {
  FileDropzoneVariant,
  ImportFileDropzone,
  MINE_TYPES,
} from '@components/files/ImportFileDropZone';
import { useCreateBulkImportJobMutation } from '@api/bulk-import';
import { SURVEY } from '@constants/terminology';

interface ImportFileModalProps {
  isOpen: boolean;
  toggle: () => void;
  initiativeId: string;
}

export const ImportFileModal = ({ isOpen, toggle, initiativeId }: ImportFileModalProps) => {
  const { addSiteError } = useSiteAlert();
  const [createBulkImportJob, { isLoading }] = useCreateBulkImportJobMutation();
  const [importFile, setImportFile] = useState<File | undefined>();

  const getTemplate = () => window.open(G17Client.getBulkImportTemplate(initiativeId));

  const handleClose = () => {
    setImportFile(undefined);
    toggle();
  };

  const handleSubmit = async () => {
    if (!importFile) {
      return;
    }

    await createBulkImportJob({ initiativeId, file: importFile }).catch((e) => {
      addSiteError('Bulk import has failed');
    });

    handleClose();
  };

  const onFilesAdded = (files: File[]) => {
    const inputFile: File | undefined = files[0];
    if (!inputFile) {
      return;
    }
    setImportFile(inputFile);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      backdrop='static'
      className='confirm-modal'
      returnFocusAfterClose={false}
    >
      <ModalHeader toggle={handleClose}>Importing data</ModalHeader>
      <ModalBody>
        {isLoading ? <Loader relative /> : null}
        <div>
          Before uploading, ensure:
          <ul className='pl-4'>
            <li>{SURVEY.CAPITALIZED_PLURAL} have been created for required data</li>
            <li>Scope has been added to required {SURVEY.PLURAL}</li>
            <li>
              Data being uploaded is using the correct columns names (
              <Button color='link' className='p-0' onClick={getTemplate}>
                template
              </Button>
              )
            </li>
          </ul>
        </div>
        <ImportFileDropzone
          variant={FileDropzoneVariant.Icon}
          accept={MINE_TYPES.EXCEL}
          importFile={importFile}
          onFilesAdded={onFilesAdded}
          onFileRemove={() => setImportFile(undefined)}
          hint='xlsx file (max. 50 megabytes)'
          classes={{ wrapper: 'py-2' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' onClick={toggle}>
          Cancel
        </Button>
        <Button color='primary' disabled={isLoading || !importFile} onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};
