import { g17ecoApi, transformResponse } from './g17ecoApi';

const TAGS = ['aggregated-surveys'];

interface UpdateAllParams {
  initiativeId: string;
}

const getUrl = ({ initiativeId }: { initiativeId: string; dashboardId?: string }) =>
  `/initiatives/${initiativeId}/aggregated-survey`;

export const aggregatedSurveysApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      updateAllAggregatedSurveys: builder.mutation<any, UpdateAllParams>({
        invalidatesTags: TAGS,
        transformResponse,
        query: ({ initiativeId }) => ({
          url: `${getUrl({ initiativeId })}/update-all`,
          method: 'post',
          data: { initiativeId },
        }),
      }),
    }),
  });

export const { useUpdateAllAggregatedSurveysMutation } = aggregatedSurveysApi;
