import { SponsorshipConfig } from '@g17eco/types/sponsorship';
import { PortfolioCompaniesFilters } from '../actions/portfolio-tracker';
import { InitiativeCompany, SponsoredInitiative } from '../types/initiative';
import { g17ecoApi, transformResponse } from './g17ecoApi';

interface GetPortfolioCompanies {
  portfolioId: string;
  recursive?: boolean;
  filters?: PortfolioCompaniesFilters;
}

interface SponsorshipParams {
  portfolioId: string;
  code: string;
  initiativeId: string;
}

interface SponsorshipRenewParams extends SponsorshipParams {
  sponsorshipId: string;
}

const PORTFOLIO_COMPANIES_TAG = 'portfolio-companies';
const SPONSORSHIP_CODES_TAG = 'sponsorship-codes';
const SPONSORED_INITIATIVES_TAG = 'sponsored-initiatives';
const SPONSORSHIP_CONFIG_TAG = 'sponsorship-config';
const TAGS = [PORTFOLIO_COMPANIES_TAG, SPONSORSHIP_CODES_TAG, SPONSORED_INITIATIVES_TAG, SPONSORSHIP_CONFIG_TAG];

export const portfolioApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPortfolioCompanies: builder.query<InitiativeCompany[], GetPortfolioCompanies>({
        transformResponse,
        query: ({ portfolioId, recursive = true, filters }) => ({
          url: `portfolios/${portfolioId}/companies${recursive ? '/recursive' : ''}`,
          method: 'get',
          params: {
            ...filters,
          },
        }),
        providesTags: [PORTFOLIO_COMPANIES_TAG],
      }),
      getSponsorshipCodes: builder.query<string[], string>({
        transformResponse,
        query: (portfolioId) => ({
          url: `portfolios/${portfolioId}/sponsored-companies/referrer-codes`,
          method: 'get',
        }),
        providesTags: [SPONSORSHIP_CODES_TAG, SPONSORED_INITIATIVES_TAG],
      }),
      getSponsoredInitiatives: builder.query<SponsoredInitiative[], Pick<SponsorshipParams, 'portfolioId' | 'code'>>({
        transformResponse,
        query: ({ portfolioId, code }) => ({
          url: `/portfolios/${portfolioId}/sponsored-companies/referrer-codes/${code}`,
          method: 'get',
        }),
        providesTags: [SPONSORED_INITIATIVES_TAG],
      }),
      refreshSubscriptions: builder.query<void, Pick<SponsorshipParams, 'portfolioId' | 'initiativeId'>>({
        transformResponse,
        query: ({ portfolioId, initiativeId }) => ({
          url: `/portfolios/${portfolioId}/sponsored-companies/refresh/${initiativeId}`,
          method: 'get',
        }),
        providesTags: [SPONSORED_INITIATIVES_TAG],
      }),
      addSponsorship: builder.mutation<void, SponsorshipParams>({
        transformResponse,
        query: ({ portfolioId, code, initiativeId }) => ({
          url: `/portfolios/${portfolioId}/sponsored-companies/referrer-codes/${code}/sponsor/${initiativeId}`,
          method: 'post',
        }),
        invalidatesTags: [SPONSORED_INITIATIVES_TAG],
      }),
      cancelSponsorship: builder.mutation<void, SponsorshipParams>({
        transformResponse,
        query: ({ portfolioId, code, initiativeId }) => ({
          url: `/portfolios/${portfolioId}/sponsored-companies/referrer-codes/${code}/sponsor/${initiativeId}`,
          method: 'delete',
        }),
        invalidatesTags: [SPONSORED_INITIATIVES_TAG],
      }),
      getSponsorshipConfig: builder.query<SponsorshipConfig, Pick<SponsorshipParams, 'portfolioId' | 'code'>>({
        transformResponse,
        query: ({ portfolioId, code }) => ({
          url: `/portfolios/${portfolioId}/sponsored-companies/referrer-codes/${code}/config`,
          method: 'get',
        }),
        providesTags: (_result, _error, agrs) => [{ type: SPONSORSHIP_CONFIG_TAG, id: agrs.code }],
      }),
      autoRenewSubscriptions: builder.mutation<SponsorshipConfig, Pick<SponsorshipParams, 'portfolioId' | 'code'> & { autoRenew: boolean }>({
        transformResponse,
        query: ({ portfolioId, code, autoRenew }) => ({
          url: `/portfolios/${portfolioId}/sponsored-companies/referrer-codes/${code}/config`,
          method: 'post',
          data: {
            autoRenew
          }
        }),
        invalidatesTags: (_result, _error, agrs) => [{ type: SPONSORSHIP_CONFIG_TAG, id: agrs.code }],
      }),
      renewSponsorship: builder.mutation<{ message: string }, SponsorshipRenewParams>({
        transformResponse,
        query: ({ portfolioId, initiativeId, sponsorshipId }) => ({
          url: `/portfolios/${portfolioId}/sponsored-companies/${initiativeId}/sponsorships/${sponsorshipId}/renew`,
          method: 'post',
        }),
        invalidatesTags: (_result, _error, args) => [{ type: SPONSORSHIP_CONFIG_TAG, id: args.code }],
      }),
    }),
  });

export const {
  useGetPortfolioCompaniesQuery,
  useGetSponsorshipCodesQuery,
  useGetSponsoredInitiativesQuery,
  useLazyRefreshSubscriptionsQuery,
  useAddSponsorshipMutation,
  useCancelSponsorshipMutation,
  useGetSponsorshipConfigQuery,
  useAutoRenewSubscriptionsMutation,
  useRenewSponsorshipMutation,
} = portfolioApi;
