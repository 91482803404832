import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TemplateHistory } from '../../survey-templates/types';

export const useGetSelectedHistory = (data?: TemplateHistory[]) => {
  const { historyId = '' } = useParams<{ historyId: string }>();
  const selectedHistory = useMemo(() => {
    if (data) {
      return data.find((history) => history._id === historyId);
    }
  }, [data, historyId]);
  return selectedHistory;
};
