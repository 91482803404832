import { useState } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import { saveReportingLevelDetails } from '../../actions/initiative';
import { useDispatch } from 'react-redux';
import { Portfolio } from '../../types/portfolio';

interface PortfolioRowProps {
  portfolio: Portfolio;
}

export const PortfolioRow = ({ portfolio }: PortfolioRowProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(portfolio.name);
  const dispatch = useDispatch();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(e.target.value);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || e.shiftKey || !editValue) {
      return;
    }
    handleSubmit();
  };
  const toggleShowInput = () => setIsEditing(!isEditing);
  const handleSubmit = async () => {
    if (!editValue) {
      return;
    }
    const formData = { name: editValue };
    await dispatch(saveReportingLevelDetails(portfolio._id, formData));
    toggleShowInput();
  };
  const handleCancel = () => {
    setEditValue(portfolio.name);
    toggleShowInput();
  }

  return (
    <Row className='ml-0 mb-3 mr-0 text-row__wrapper'>
      <Col className={'col-md-4 col-12 align-self-start text-label-uppercase'}>Organisation:</Col>
      {isEditing ? (
        <Col className='col-md-6 col-10'>
          <Input
            invalid={!editValue}
            type='text'
            required={true}
            defaultValue={editValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
            className='text-row__input'
          />
        </Col>
      ) : (
        <Col className='col-md-6 col-10 text-right text-ellipsis__paragraph'>
          {editValue !== '' ? editValue : 'not set'}
        </Col>
      )}

      <Col className='col-2 d-flex align-self-end'>
        {isEditing ? (
          <>
            <Button color='link' onClick={handleSubmit} disabled={!editValue}>
              Save
            </Button>
            <Button color='link-secondary' onClick={handleCancel} disabled={!editValue}>
              Cancel
            </Button>
          </>
        ) : (
          <Button color='link' onClick={toggleShowInput}>
            Edit
          </Button>
        )}
      </Col>
    </Row>
  );
};
