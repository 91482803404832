import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { getSDGOptions, getSDGTargetOptions } from '@features/sdg';
import { useState } from 'react';
import { QuestionData } from '@routes/custom-dashboard/utils';
import { getGoalCodeFromSDG } from '@utils/sdg';
import { emptyUtrData } from '../utils/dashboard-utils';

interface Props {
  questionData: QuestionData;
  changeQuestionData: (questionData: QuestionData) => void;
}

const sdgGoals = getSDGOptions().filter((option) => option.value !== null);

export const SDGTargetDropdown = ({ questionData, changeQuestionData }: Props) => {
  const [goalCode, setGoalCode] = useState<string | undefined>(getGoalCodeFromSDG(questionData.code));

  const handleGoalChange = async (goalCode: string | undefined) => {
    setGoalCode(goalCode);
    changeQuestionData(emptyUtrData);
  };

  const targetOptions = getSDGTargetOptions(goalCode);

  return (
    <>
      <p className='mt-3 text-medium'>Select SDG</p>
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        placeholder='Select SDG'
        options={sdgGoals}
        value={goalCode ? sdgGoals.find((o) => o.value === goalCode) : null}
        onChange={(option) => handleGoalChange(option?.value)}
      />
      {goalCode ? (
        <>
          <p className='my-3 text-medium'>Select SDG Target</p>
          <SelectFactory
            selectType={SelectTypes.SingleSelect}
            key={goalCode}
            placeholder='Select SDG Target'
            options={targetOptions}
            value={questionData.code ? targetOptions.find((o) => o.value === questionData.code) : null}
            onChange={(option) => changeQuestionData({ ...emptyUtrData, code: option?.value })}
          />
        </>
      ) : null}
    </>
  );
};
