import { DashboardColumn } from '@components/dashboard';
import { SdgChart } from '@components/impact-performance/SdgChart';
import { ScorecardResponse } from '@g17eco/types/scorecard';

export const SDGContributionWidget = (props: { initiativeId: string; scorecard: ScorecardResponse }) => {
  return (
    <DashboardColumn className=' pt-3 h-100'>
      <SdgChart {...props} />
    </DashboardColumn>
  );
};
