import { Answer } from './Answer';
import { DashboardDivider } from '@g17eco/atoms';
import { AIDisclaimer } from '@g17eco/molecules';
import { FurtherNotes } from './FurtherNotes';
import { AnswerProps } from './types';
import './styles.scss';

export const AIAssistant = ({ utrv, utr }: AnswerProps) => {
  return (
    <div className='question-assistant__ai d-flex flex-column gap-3'>
      <Answer utrv={utrv} utr={utr} />
      <FurtherNotes utrv={utrv} />
      <DashboardDivider className='' />
      <AIDisclaimer />
    </div>
  );
};
