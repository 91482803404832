import { useState } from 'react';
import { DATE, formatDateUTC } from '@utils/date';
import { useBulkOnboardListsQuery } from '@api/bulk-onboarding-import';
import Loader from '@components/loader';
import { getUserFullName } from '@constants/user';
import { OnboardingListExtended } from '@g17eco/types/onboarding';
import { BulkOnboardingDetailsModal } from './BulkOnboardingDetailsModal';
import { useAppSelector } from '@reducers/index';
import { InitiativePlain } from '@g17eco/types/initiative';

interface Props {
  initiativeId: string;
}

export const OnboardingLists = ({ initiativeId }: Props) => {
  const globalDataState = useAppSelector((state) => state.globalData);

  const { data: onboardingLists = [], isFetching } = useBulkOnboardListsQuery(initiativeId);
  const [selectedList, setSelectedList] = useState<OnboardingListExtended>();

  if (isFetching || !globalDataState.loaded) {
    return <Loader />;
  }

  const initiativeMap = new Map<string, InitiativePlain>(
    globalDataState.data.initiativeTree.map((node) => [node._id, node])
  );

  if (onboardingLists.length === 0) {
    return <div className='text-center text-md mt-4 text-ThemeTextMedium'>There are no files imported.</div>;
  }

  return (
    <>
      <div className='d-flex flex-column gap-2'>
        {onboardingLists.map((list) => {
          const { _id, name, fileName, creator, created, onboardings } = list;
          return (
            <div
              key={_id}
              className='w-100 d-flex justify-content-between align-items-center p-3 border border-ThemeBorderDefault rounded fs-6 cursor-pointer'
              onClick={() => setSelectedList(list)}
            >
              <div className='flex-grow-1'>
                {fileName || name ? <div className='fw-semibold'>{fileName || name}</div> : null}
                <div className='text-ThemeTextMedium'>{formatDateUTC(created, DATE.DEFAULT_SPACES_WITH_TIME)}</div>
                <div className='text-ThemeTextMedium'>Created by: {getUserFullName(creator)}</div>
              </div>
              <div className='d-flex align-items-center gap-3'>
                <span className='text-ThemeTextMedium'>UPLOADED: {onboardings.length} users invited</span>
                <i className='fa-light fa-circle-check text-ThemeSuccessMedium fs-4'></i>
              </div>
            </div>
          );
        })}
      </div>
      <BulkOnboardingDetailsModal
        isOpen={!!selectedList}
        list={selectedList}
        initiativeMap={initiativeMap}
        toggle={() => setSelectedList(undefined)}
      />
    </>
  );
};
