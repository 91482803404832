import { Button, Col, Row } from 'reactstrap';
import { Bank } from '../../types/banking-settings';
import { EditOptionProps } from '../../types/initiative';
import { SimpleTooltip } from '@g17eco/molecules';

const displayBanks = (bankingSettings: Bank[]) => {
  return bankingSettings.map((bank) => bank.name).join(', ');
};

export const BankingSettingsRow = ({ label, initiative, onClick = () => {} }: EditOptionProps) => {
  const { bankingSettings = [] } = initiative;

  return (
    <Row className='ml-0 mb-3 mr-0 text-row__wrapper'>
      <Col className={'col-md-4 col-12 align-self-start text-label-uppercase'}>{label}</Col>
      <Col className='col-md-6 col-10 text-right text-ellipsis__paragraph'>
        {bankingSettings.length ? (
          <SimpleTooltip text={displayBanks(bankingSettings)}>
            <span>{displayBanks(bankingSettings)}</span>
          </SimpleTooltip>
        ) : (
          <span className='text-ThemeTextPlaceholder'>not set</span>
        )}
      </Col>
      <Col className='col-2 d-flex align-self-end'>
        <Button color='link' onClick={onClick}>
          Edit
        </Button>
      </Col>
    </Row>
  );
};
