import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from '../types/api';
import { axiosBaseQuery } from './axiosQuery';
import { Bookmark } from '../types/bookmark';

interface UtrvBookmarkMutationParams {
  utrvId: string;
  surveyId: string;
}

interface UtrvBookmarkQueryParams {
  surveyId: string;
}

export const bookmarksApi = createApi({
  reducerPath: 'bookmarksApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['bookmarks'],
  endpoints: (builder) => ({
    getUtrvBookmarks: builder.query<Bookmark['targetId'][], UtrvBookmarkQueryParams>({
      transformResponse: (response: ApiResponse<Bookmark[]>) => {
        return response.data.map(({ targetId }) => targetId);
      },
      query: ({ surveyId }) => ({
        url: `/bookmarks/universal-tracker-value/survey/${surveyId}`,
        method: 'get',
      }),
      providesTags: ['bookmarks'],
    }),
    createUtrvBookmark: builder.mutation<ApiResponse<Bookmark[]>, UtrvBookmarkMutationParams>({
      invalidatesTags: ['bookmarks'],
      query: ({ utrvId, surveyId }) => ({
        url: '/bookmarks/universal-tracker-value',
        method: 'post',
        data: {
          utrvId,
          surveyId,
        },
      }),
    }),
    deleteUtrvBookmark: builder.mutation<ApiResponse<Bookmark[]>, UtrvBookmarkMutationParams>({
      invalidatesTags: ['bookmarks'],
      query: ({ utrvId, surveyId }) => ({
        url: '/bookmarks/universal-tracker-value',
        method: 'delete',
        data: {
          utrvId,
          surveyId,
        },
      }),
    }),
  }),
});

export const { endpoints, useCreateUtrvBookmarkMutation, useDeleteUtrvBookmarkMutation, useGetUtrvBookmarksQuery } =
  bookmarksApi;
