import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';
import './ReportCard.scss';
import classNames from 'classnames';

interface Props {
  text: string;
  img: string;
  onClick: () => void;
  active?: boolean;
}

export const ReportCard = (props: Props) => {
  const { text, img, active = false, onClick } = props;

  return (
    <Card className={classNames('cursor-pointer border-0 rounded shadow-sm', { active: active })} onClick={onClick}>
      <div className='p-3 rounded-top background-ThemeBgMedium'>
        <img alt={text} src={img} width={145} className='rounded' />
      </div>
      <CardBody className='rounded-bottom'>
        <CardText className='text-center text-uppercase fw-semibold'>{text}</CardText>
      </CardBody>
    </Card>
  );
};
