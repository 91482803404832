import React from 'react'
import { DataSource } from '.';
import { ReportData } from '../../../types/reportData';
import GenderSplit from './data/GenderSplit';
import GenderSplitPc from './data/GenderSplitPc';
import LivingWage from './data/LivingWage';
import GHGEmissions from './data/GHGEmissions';
import PayGap from './data/PayGap';
import GenderPayGap from './data/GenderPayGap';
import Bonuses from './data/Bonuses';
import BoardGender from './data/BoardGender';
import BoardDiversity from './data/BoardDiversity';
import Policies from './data/Policies';

export const CHART_DEFAULTS = {
  OPTIONS: {
    colors: ['#A6CFE5', '#D6F5DF', '#1A5A87'],
    pieSliceTextStyle: {
      color: '#0c3866',
    },
    legend: {
      position: 'top'
    },
    chartArea: {
      top: 40,
      bottom: 20,
      left: 20,
      right: 20,
    },
    titleTextStyle: {
      color: 'a1a3a4'
    }
  }
};

export interface GenerateChartsProps {
  updateData: (key: string, data: DataSource) => void;
  questionData: ReportData[];
}

const GenerateCharts = React.memo((props: GenerateChartsProps) => {
  return <div
    style={{ height: 0, width: 0, overflow: 'hidden' }}
  >
    <GenderSplit {...props} />
    <GenderSplitPc {...props} />
    <LivingWage {...props} />
    <GHGEmissions {...props} />
    <PayGap {...props} />
    <GenderPayGap {...props} />
    <Bonuses {...props} />
    <BoardGender {...props} />
    <BoardDiversity {...props} />
    <Policies {...props} />
  </div>
})

export default GenerateCharts;
