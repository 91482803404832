import { capitalise } from '../../../../../utils';
import { DATE, formatDateUTC } from '../../../../../utils/date';

interface Props {
  effectiveDate?: string;
  period?: string;
}

export const TakenFromText = ({ effectiveDate, period }: Props) => {
  return (
    <div className='data-evidence text-xs mt-2 text-ThemeTextPlaceholder'>
      Taken from: {period ? `${capitalise(period)} ` : ''}
      {formatDateUTC(effectiveDate || '', DATE.MONTH_YEAR)}
    </div>
  );
};
