import { BasicAlert } from '@g17eco/molecules';
import { useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface AddManualBankResponse {
  error?: string;
}

interface ManualBankModalProps {
  isOpen: boolean;
  handleClose: () => void;
  addManualBank: (bankName: string) => Promise<AddManualBankResponse | undefined>;
}

export const ManualBankModal = (props: ManualBankModalProps) => {
  const { isOpen, handleClose, addManualBank } = props;
  const [bankName, setBankName] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // reset state
    setBankName('');
    setErrorMessage('');
  }, [isOpen])

  const handleSubmit = async () => {
    if (!bankName) {
      setErrorMessage('The bank name is required');
      return;
    }
    setIsSaving(true);
    try {
      const result = await addManualBank(bankName);
      setIsSaving(false);
      if (result?.error) {
        setErrorMessage(result.error);
        return;
      }
      handleClose();
    } catch (e) {
      setIsSaving(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} backdrop='static' className='manual-banks'>
      <ModalHeader toggle={handleClose}>Add bank name</ModalHeader>
      <ModalBody>
        <Input name='name' className='w-100 text-md' placeholder='Enter bank name' onChange={(e) => setBankName(e.currentTarget.value)} />
        <BasicAlert type='danger' className='mt-2'>{errorMessage}</BasicAlert>
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='link-secondary' className='mr-3' onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={isSaving || !bankName} color='primary' onClick={() => handleSubmit()}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
