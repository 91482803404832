import { useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { delegateQuestions } from '../../../actions/assurance';
import { Action } from '../../../constants/action';
import { useAppDispatch } from '../../../reducers';
import { addSiteAlert } from '../../../slice/siteAlertsSlice';
import {
  AssurancePermissionType,
  OrganizationAssurancePortfolio,
  UniversalTrackerValueAssurance,
  UtrvAssurancePermissions,
} from '../../../types/assurance';
import { UniversalTrackerValuePlain } from '../../../types/surveyScope';
import { BlockingLoader } from '../../loader/BlockingLoader';
import { AssignButton } from './AssignButton';
import './ToolbarButtons.scss';
import { QUESTION } from '@constants/terminology';
import { CurrentUserData } from '@reducers/current-user';

interface FollowButtonProps {
  assurancePortfolio: OrganizationAssurancePortfolio;
  currentUser: CurrentUserData;
  selectedQuestions: UniversalTrackerValuePlain<string>[];
  handleReload: (isBlocking: boolean) => void;
  canAssign?: boolean;
}

interface AssuranceUtrvWithPermissions extends UniversalTrackerValueAssurance {
  permissions: AssurancePermissionType<UtrvAssurancePermissions>[];
}

const isDelegated = (assurers: AssurancePermissionType<UtrvAssurancePermissions>[], currentUser: CurrentUserData) => {
  return assurers.some((assurer) => assurer.userId === currentUser._id);
};

const isAllDelegated = (
  assurancePortfolio: OrganizationAssurancePortfolio,
  currentUser: CurrentUserData,
  utrvAssuranceIds: string[]
) => {
  const selectedAssuranceUtrv = assurancePortfolio.universalTrackerValueAssurances.filter(
    (assuranceUtrv): assuranceUtrv is AssuranceUtrvWithPermissions =>
      !!assuranceUtrv.permissions && utrvAssuranceIds.includes(assuranceUtrv.utrvId)
  );
  if (!selectedAssuranceUtrv.length || selectedAssuranceUtrv.length !== utrvAssuranceIds.length) {
    return false;
  }
  return selectedAssuranceUtrv.every((assuranceUtrv) => isDelegated(assuranceUtrv.permissions, currentUser));
};

export const DelegateButton = ({
  assurancePortfolio,
  currentUser,
  selectedQuestions,
  handleReload,
  canAssign = false,
}: FollowButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const utrvAssuranceIds = useMemo(() => selectedQuestions.map((question) => question._id), [selectedQuestions]);
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!utrvAssuranceIds.length) {
      setIsFollowing(false);
      return;
    }
    setIsFollowing(isAllDelegated(assurancePortfolio, currentUser, utrvAssuranceIds));
  }, [assurancePortfolio, currentUser, utrvAssuranceIds]);

  const handleDelegateQuestions = ({ isFollowing }: { isFollowing: boolean }) => {
    setIsLoading(true);
    delegateQuestions(assurancePortfolio._id, {
      utrvAssuranceIds,
      action: isFollowing ? Action.Remove : Action.Add,
      userId: currentUser._id,
    })
      .then(() => {
        setIsLoading(false);
        setIsFollowing(!isFollowing);
        handleReload(false);
      })
      .catch((e: Error) => {
        setIsLoading(false);
        dispatch(
          addSiteAlert({
            content: e.message,
            timeout: 5000,
          })
        );
      });
  };

  if (canAssign) {
    return <AssignButton assurancePortfolio={assurancePortfolio} utrvAssuranceIds={utrvAssuranceIds} />;
  }

  return (
    <>
      {isLoading ? <BlockingLoader /> : null}
      <Button
        className='ml-2 px-3 btn'
        outline
        onClick={() => {
          handleDelegateQuestions({ isFollowing });
        }}
      >
        {isFollowing ? `Unfollow ${QUESTION.PLURAL}` : `Follow ${QUESTION.PLURAL}`}
      </Button>
    </>
  );
};
