import { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

const ANIMATE_DELAY_TIME = 1000;

type Props = {
  returnUrl: string;
  breadcrumbTitle: string;
};

export const CookieAcceptance = ({ returnUrl, breadcrumbTitle }: Props) => {
  const [isAnimated, setIsAnimated] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAnimated(true);
    }, ANIMATE_DELAY_TIME);

    return () => clearTimeout(timeout);
  }, []);

  const cookieStyle = {
    alignItems: 'center',
    padding: '16px 80px 16px 40px',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '0px -8px 20px rgba(0, 0, 0, 0.1)',
    color: '#26292b',
    transition: 'all .5s',
  };

  const buttonStyle = {
    color: '#242124',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1,
    padding: '12px 24px',
    marginLeft: '8px',
    backgroundColor: '#FFC000',
    borderRadius: '4px',
  };

  return (
    <CookieConsent
      buttonText={'Accept'}
      style={{ ...cookieStyle, transform: isAnimated ? 'translateY(0)' : 'translateY(100%)' }}
      buttonStyle={buttonStyle}
    >
      <p className='mb-0'>
        We use cookies to enhance user experience and analyse site usage. By continuing to browse this site, or clicking
        ‘Accept’, you agree to the use of cookies on your device. Read our{' '}
        <Link
          to={`/legal-privacy-policy?returnUrl=${returnUrl}&breadcrumbTitle=${breadcrumbTitle}`}
          className='text-dark'
        >
          <u>Privacy Policy</u>
        </Link>{' '}
        for more info.
      </p>
    </CookieConsent>
  );
};
