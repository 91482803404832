import React from 'react'
import Chart from 'react-google-charts';
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';

const WIDTH = 600;
const HEIGHT = 400;

export interface GHGEmissionsDataSource extends DataSource {
  totalEmissions?: number;
}

const GHGEmissions = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const scope1Utr = questionData.find(d => d.universalTracker.code === 'gri/2020/305-1/a');
  const scope2aUtr = questionData.find(d => d.universalTracker.code === 'gri/2020/305-2/a');
  const scope2bUtr = questionData.find(d => d.universalTracker.code === 'gri/2020/305-2/b');
  const scope3Utr = questionData.find(d => d.universalTracker.code === 'gri/2020/305-3/a');

  const scope1 = scope1Utr?.value ?? 0;
  const scope2 = (scope2aUtr?.value ?? 0) + (scope2bUtr?.value ?? 0);
  const scope3 = scope3Utr?.value ?? 0;

  const reportData: GHGEmissionsDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    totalEmissions: scope1 + scope2 + scope3
  }

  const chartData = [
    ['Scope', 'Emissions', { role: 'style' }],
    ['Scope 1', scope1, '#A6CFE5'],
    ['Scope 2', scope2, '#D6F5DF'],
    ['Scope 3', scope3, '#1A5A87'],
  ];

  return <Chart
    chartType='ColumnChart'
    data={chartData}
    width={WIDTH}
    height={HEIGHT}
    options={{
      ...CHART_DEFAULTS.OPTIONS,
      title: 'Scope 1, Scope 2, and Scope 3 emissions.',
      chartArea: {
        top: 40,
        bottom: 20,
        left: 100,
        right: 20,
      },
      legend: {
        position: 'none'
      },
    }}
    chartEvents={[
      {
        eventName: 'ready',
        callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
          reportData.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
          updateData('ghgEmissions', reportData);
        }
      }
    ]}
  />
})

export default GHGEmissions;
