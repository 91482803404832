import { Suggestion } from './SearchComponent';
import { ellipsis } from '@utils/index';
import { isOnboardingUser } from '@utils/user';

export const formatData = (suggestions: Suggestion[]) => {
  return suggestions.map((suggestion) => {
    const { status, firstName, surname } = suggestion;
    if (isOnboardingUser(status)) {
      suggestion.name = `${ellipsis(suggestion.email, 25)} (pending)`;
      return suggestion;
    }

    suggestion.name = `${firstName} ${surname}`;
    return suggestion;
  });
};
