import { frameworks, standards } from '@g17eco/core';
import { useCallback, useMemo } from 'react';
import { SelectFactory, SelectTypes, Option } from '@g17eco/molecules';
import { RequestScope } from '../../actions/api';
import { RequestScopeExtended, getRequestScopeExtended } from '../../utils/requestScope';

export type CustomSelectOption = Record<string, { name: string; src?: string; hidden?: boolean }>;
export interface SelectPackOption extends Option<string> {
  scopeType: string;
}

interface SelectPacksProps {
  selectedScopes?: string[];
  groupOptions?: CustomSelectOption;
  handleChangePacks: (scopes: RequestScope[]) => void;
  isDisabled?: boolean;
  displayIcon?: boolean;
  placeholder?: string;
  extraOptions?: RequestScopeExtended[];
  disabledOptions?: string[];
}

export const SelectPacks = (props: SelectPacksProps) => {
  const {
    selectedScopes = [],
    groupOptions,
    handleChangePacks,
    isDisabled,
    displayIcon = true,
    placeholder,
    extraOptions = [],
    disabledOptions = [],
  } = props;

  const allRequestScopes = useMemo(
    () => [...extraOptions, ...getRequestScopeExtended(groupOptions)],
    [extraOptions, groupOptions]
  );

  const getPackOptions = useCallback(
    (packs: CustomSelectOption, scopeType: string) => {
      return Object.keys(packs).reduce((acc, key: string) => {
        if (packs[key].hidden) {
          return acc;
        }
        const option = {
          value: key,
          searchString: `${key} ${packs[key]?.name ?? ''}`,
          scopeType,
          label: (
            <div className='d-flex align-items-center'>
              {displayIcon && packs[key].src ? (
                <img src={packs[key].src} alt={packs[key].name} height='26px' className='mr-2' />
              ) : null}
              <span>{packs[key].name}</span>
            </div>
          ),
          isDisabled: disabledOptions.includes(key),
        };
        return [...acc, option];
      }, [] as SelectPackOption[]);
    },
    [displayIcon, disabledOptions]
  );

  const getExtraPackOptions = useCallback(() => {
    return extraOptions.map<SelectPackOption>(({ code, scopeType, name }) => ({
      value: code,
      scopeType,
      label: <div className='d-flex align-items-center'>{name}</div>,
      isDisabled: disabledOptions.includes(code),
    }));
  }, [extraOptions, disabledOptions]);

  const onChange = (selectedOptions: string[]) => {
    handleChangePacks(allRequestScopes.filter((item) => selectedOptions.includes(item.code)));
  };

  const packs = useMemo(
    () => [
      ...getExtraPackOptions(),
      ...getPackOptions(standards, 'standards'),
      ...getPackOptions(frameworks, 'frameworks'),
      ...(groupOptions ? getPackOptions(groupOptions, 'custom') : []),
    ],
    [getExtraPackOptions, getPackOptions, groupOptions]
  );

  return (
    <SelectFactory
      selectType={SelectTypes.MultipleSelect}
      placeholder={placeholder}
      options={packs}
      onChange={onChange}
      isDisabled={isDisabled}
      values={selectedScopes}
    />
  );
};
