import axios, { AxiosRequestConfig } from 'axios';
import CONFIG from '../../../../config';
import { IRemoteNotification } from '@magicbell/magicbell-react';
import { processInBatches } from '@utils/batch';

const batchDeleteNotifications = async ({
  userKey,
  userExternalId,
  notifications,
}: {
  userKey: string;
  userExternalId: string | undefined;
  notifications: IRemoteNotification[];
}) => {
  return processInBatches({
    data: [...notifications].reverse() ?? [], // should delete from oldest to newest
    processFn: async (notification) => {
      const option: AxiosRequestConfig = {
        method: 'DELETE',
        baseURL: `${CONFIG.magicBellNotificationURL}${notification.id}`,
        headers: {
          'X-MAGICBELL-API-KEY': CONFIG.magicBellApiKey,
          'X-MAGICBELL-USER-HMAC': userKey,
          'X-MAGICBELL-USER-EXTERNAL-ID': String(userExternalId),
        },
      };
      return axios(option);
    },
    batchSize: 5,
    // Actual limit is 60 requests per minute for delete, but we want to be safe
    // lets make limit half of what we can process, not to impact other requests
    // Which means it take minimum 2 seconds to process 1 notification
    // 30 requests per minute => 5 requests per 10 seconds
    batchRateLimit: { minWaitTime: 10, unit: 'second' },
  });
};

export const deleteAllNotifications = async (userKey: string, userExternalId: string | undefined) => {
  const option: AxiosRequestConfig = {
    method: 'GET',
    baseURL: CONFIG.magicBellNotificationURL,
    headers: {
      'X-MAGICBELL-API-KEY': CONFIG.magicBellApiKey,
      'X-MAGICBELL-USER-HMAC': userKey,
      'X-MAGICBELL-USER-EXTERNAL-ID': String(userExternalId),
    },
    params: { per_page: 100 },
  };
  const {
    data: { notifications, total_pages },
  } = await axios<{ notifications: IRemoteNotification[]; total_pages: number }>(option);
  if (total_pages === 1) {
    return batchDeleteNotifications({ userKey, userExternalId, notifications });
  }

  for (let page = total_pages; page > 0; page--) {
    const option: AxiosRequestConfig = {
      method: 'GET',
      baseURL: CONFIG.magicBellNotificationURL,
      headers: {
        'X-MAGICBELL-API-KEY': CONFIG.magicBellApiKey,
        'X-MAGICBELL-USER-HMAC': userKey,
        'X-MAGICBELL-USER-EXTERNAL-ID': String(userExternalId),
      },
      params: { page, per_page: 100 },
    };
    const {
      data: { notifications },
    } = await axios<{ notifications: IRemoteNotification[]; total_pages: number }>(option);
    await batchDeleteNotifications({ userKey, userExternalId, notifications });
  }
};
