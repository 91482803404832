import { useContext } from 'react';
import { Button } from 'reactstrap';
import { QuestionConfigurationModal, QuestionConfigurationModalProps } from './modal/QuestionConfigurationModal';
import { ConfigurationType, InputOverrideType, NON_NUMERIC_SELECTED_QUESTION_TOOLTIP } from './utils';
import { QuestionManagementContext } from '../../apps/company-tracker/components/admin-dashboard/questions/QuestionManagementContainer';
import { useToggle } from '@hooks/useToggle';
import { getNumericSelectedQuestions } from '@components/survey-question-list/utils';
import { SimpleTooltip } from '@g17eco/molecules';

export type QuestionConfigurationButtonProps = {
  name?: string;
  disabled?: boolean;
} & Omit<QuestionConfigurationModalProps, 'isOpen' | 'toggleOpen'>;

export const QuestionConfigurationButton = (props: QuestionConfigurationButtonProps) => {
  const { name, disabled = false, ...modalProps } = props;
  const { setInputOverrideType, setUtrvConfigCode } = useContext(QuestionManagementContext);
  const [isOpen, toggleOpen] = useToggle(false);

  const numericSelectedQuestions = getNumericSelectedQuestions(modalProps.selectedQuestions);
  const invalidInputOverrides =
    modalProps.configType === ConfigurationType.InputOverrides && !numericSelectedQuestions.length;
  
  const iconMapping: Partial<Record<ConfigurationType, string>> = {
    [ConfigurationType.MetricOverrides]: 'fa-square-question',
    [ConfigurationType.InputOverrides]: 'fa-input-numeric',
    [ConfigurationType.Tags]: 'fa-tags',
  }

  return (
    <>
      <SimpleTooltip text={invalidInputOverrides ? NON_NUMERIC_SELECTED_QUESTION_TOOLTIP : undefined}>
        <Button
          onClick={() => {
            toggleOpen();
            setInputOverrideType(modalProps.selectedQuestions.length > 1 ? InputOverrideType.NumberScale : undefined);
            setUtrvConfigCode(modalProps.selectedQuestions.length > 1 ? 'verificationRequired' : undefined);
          }}
          disabled={disabled || invalidInputOverrides}
          data-testid={modalProps.configType === ConfigurationType.Tags ? 'bulk-action-add-tag-btn' : undefined}
        >
          {iconMapping[modalProps.configType] ? <i className={`fa-light ${iconMapping[modalProps.configType]} fs-5 me-2`}></i> : null}
          {name || modalProps.configType}
        </Button>
      </SimpleTooltip>
      <QuestionConfigurationModal isOpen={isOpen} toggleOpen={toggleOpen} {...modalProps} />
    </>
  );
};
