import { createApi } from '@reduxjs/toolkit/query/react';
import { Filters } from '../components/portfolio/benchmarking/partials/BenchmarkingFilters';
import { SubmissionInsightsFilters } from '../components/portfolio/benchmarking/submission-insights/SubmissionInsightsContainer';
import { ApiResponse } from '../types/api';
import { PackUsageApiResponse, SubmissionInsightsData } from '../types/portfolio';
import { axiosBaseQuery } from './axiosQuery';
import { RequestScope } from '../actions/api';
import { InitiativeCompany } from '../types/initiative';
import { DateRangeType } from '@g17eco/types/common';

export interface ScopeParams {
  group?: string;
  subGroup?: string;
  leafGroup?: string;
  leafChildGroup?: string;
}

interface PackUsageQueryParams extends ScopeParams {
  portfolioId: string;
  filters: Filters;
  dateRange: DateRangeType;
}

interface SubmissionInsightsQueryParams {
  portfolioId: string;
  filters: SubmissionInsightsFilters;
}

export interface SubmissionInsightsDataResponse extends SubmissionInsightsData {
  scopeGroup: RequestScope;
  companies: Pick<InitiativeCompany, '_id' | 'name' | 'type' | 'requestedDataShares'>[],
}

export const benchmarkingApi = createApi({
  reducerPath: 'benchmarkingApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['benchmarking'],
  endpoints: (builder) => ({
    getPortfolioPackUsage: builder.query<PackUsageApiResponse, PackUsageQueryParams>({
      transformResponse: (response: ApiResponse<PackUsageApiResponse>) => {
        return response.data;
      },
      query: ({ portfolioId, filters, dateRange, ...scopeParams }) => {
        return {
          url: `/portfolios/${portfolioId}/companies/pack-usage`,
          method: 'get',
          params: { ...filters, ...dateRange, ...scopeParams },
        };
      },
      providesTags: ['benchmarking'],
    }),
    getSubmissionInsightsData: builder.query<SubmissionInsightsDataResponse, SubmissionInsightsQueryParams>({
      transformResponse: (response: ApiResponse<SubmissionInsightsDataResponse>) => response.data,
      query: ({ portfolioId, filters: { metricView, dateRange, ...rest } }) => ({
        url: `/portfolios/${portfolioId}/benchmarking/submission-insights`,
        method: 'get',
        params: { ...rest, ...dateRange },
      }),
      providesTags: ['benchmarking'],
    }),
  }),
});

export const { useGetPortfolioPackUsageQuery, useGetSubmissionInsightsDataQuery } = benchmarkingApi;
