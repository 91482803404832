import {
  TableColumnType,
  UniversalTrackerPlain,
  UtrValueType,
  getTableColumnTypeNames,
} from '@g17eco/types/universalTracker';
import { useMemo } from 'react';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { QUESTION } from '@constants/terminology';

interface ColumnOption {
  value: string;
  label: string;
  disabled?: boolean;
}

interface Props {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'targetDirection' | 'valueValidation'>;
  selectedColumnCode?: string;
  handleChangeColumn: (column: string) => void;
  isDisabled?: boolean;
}

const allowedUtrColumnTypes = [
  TableColumnType.Number,
  TableColumnType.Text,
  TableColumnType.Percentage,
];

const allowedMultiInputTypes = [UtrValueType.TextValueList, UtrValueType.NumericValueList, UtrValueType.Table];

// Only available for numeric value list
const TOTAL_OPTION = { value: 'total', label: 'Total' };

// valueType must be one of Table, TextValueList, NumericValueList
const isValidValueList = (utr: Pick<UniversalTrackerPlain, 'valueType' | 'valueValidation'>) => {
  const { valueType, valueValidation } = utr;
  if (valueType === UtrValueType.Table) {
    // Table type UTR has nothing to do with value list
    return true;
  }
  return !!valueValidation?.valueList;
};

export const MultiInputSelect = ({ utr, selectedColumnCode, handleChangeColumn, isDisabled = false }: Props) => {
  const { valueType, valueValidation: { table, valueList } = {} } = utr;
  const isAllowedUtrValueType = allowedMultiInputTypes.includes(valueType as UtrValueType);

  const columnOptions = useMemo(() => {
    if (!isAllowedUtrValueType) {
      return [];
    }
    if (table) {
      const tableColumnsOptions = table?.columns.map((c) => {
        const isAllowed = allowedUtrColumnTypes.includes(c.type);
        const typeString = !isAllowed ? 'Not Supported' : getTableColumnTypeNames(c.type, Boolean(c.listId), c);
        return {
          value: c.code,
          label: `${c.name} (${typeString})`,
          isDisabled: !isAllowed || c.calculation?.formula,
        };
      });
      return tableColumnsOptions;
    }

    if (valueList?.list?.length) {
      const options = valueList?.list.map((o) => ({ value: o.code, label: o.name }));
      return [...(valueType === UtrValueType.NumericValueList ? [TOTAL_OPTION] : []), ...options];
    }
  }, [isAllowedUtrValueType, table, valueList?.list, valueType])

  if (!allowedMultiInputTypes.includes(valueType as UtrValueType) || !isValidValueList(utr)) {
    return (
      <div className='col-12'>
        <SelectFactory selectType={SelectTypes.SingleSelect} placeholder='Default input' isDisabled options={[]} />
      </div>
    );
  }

  return (
    <div className='col-12'>
      <SelectFactory
        isDisabled={isDisabled}
        isClearable
        selectType={SelectTypes.SingleSelect}
        placeholder={`${QUESTION.CAPITALIZED_SINGULAR} input`}
        options={columnOptions}
        value={columnOptions?.find((col) => col.value === selectedColumnCode)}
        onChange={(col) => handleChangeColumn(col?.value ?? '')}
      />
    </div>
  );
};
