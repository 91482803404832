import { UserMin } from '../constants/users';
import { UtrvComment } from '../types/universalTrackerValue';
import { g17ecoApi, transformResponse } from './g17ecoApi';

export interface UtrvCommentCount {
  _id: string;
  utrvId: string;
  commentCount: number;
}

export interface ExtendedUtrvComments {
  items: UtrvComment[];
  users: Pick<UserMin, '_id' | 'firstName' | 'surname'>[];
}

enum TAGS {
  UTRV_COMMENTS = 'utrv-comments',
  SURVEY_UTRVS_COMMENT_COUNT = 'survey-utrvs-comment-count',
}

const getUrl = (utrvId: string) => `/universal-tracker-values/${utrvId}/comments`;

export const utrvCommentsApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: Object.values(TAGS),
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUtrvsCommentCountBySurveyId: builder.query<UtrvCommentCount[], string>({
        transformResponse,
        query: (surveyId) => ({
          url: `/surveys/${surveyId}/comments/count`,
          method: 'get',
        }),
        providesTags: (result, error, surveyId) => {
          return [{ type: TAGS.SURVEY_UTRVS_COMMENT_COUNT, id: surveyId }];
        },
      }),
      getUtrvComments: builder.query<ExtendedUtrvComments, string>({
        transformResponse,
        query: (utrvId) => ({
          url: getUrl(utrvId),
          method: 'get',
        }),
        providesTags: (result, error, utrvId) => {
          return [{ type: TAGS.UTRV_COMMENTS, id: utrvId }];
        },
      }),
      addUtrvComment: builder.mutation<ExtendedUtrvComments, { surveyId: string; utrvId: string; text: string }>({
        invalidatesTags: (result, error, arg) => [
          { type: TAGS.UTRV_COMMENTS, id: arg.utrvId },
          { type: TAGS.SURVEY_UTRVS_COMMENT_COUNT, id: arg.surveyId },
        ],
        transformResponse,
        query: ({ utrvId, text }) => ({
          url: getUrl(utrvId),
          method: 'patch',
          data: { text },
        }),
      }),
      deleteUtrvComment: builder.mutation<ExtendedUtrvComments, { surveyId: string; utrvId: string; commentId: string }>({
        invalidatesTags: (result, error, arg) => [
          { type: TAGS.UTRV_COMMENTS, id: arg.utrvId },
          { type: TAGS.SURVEY_UTRVS_COMMENT_COUNT, id: arg.surveyId },
        ],
        transformResponse,
        query: ({ utrvId, commentId }) => ({
          url: `${getUrl(utrvId)}/${commentId}`,
          method: 'delete',
        }),
      }),
    }),
  });

export const {
  endpoints,
  useGetUtrvsCommentCountBySurveyIdQuery,
  useGetUtrvCommentsQuery,
  useAddUtrvCommentMutation,
  useDeleteUtrvCommentMutation,
} = utrvCommentsApi;
