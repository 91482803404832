import React from 'react';
import Chart from 'react-google-charts';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps, chartReadyEvent, getReportEffectiveDate } from '../GenerateCharts';
import { getUnitText } from '../../../../utils/units';
import { UtrValueType } from '../../../../types/universalTracker';

const WIDTH = 400;
const HEIGHT = 300;

export interface WaterConsumptionDataSource extends DataSource {
  consumption?: number;
  unit?: string;
}

export const WaterConsumption = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrCode = 'gri/2020/303-5/a';
  const utrv = questionData.find((d) => d.universalTracker.code === utrCode);
  const value = utrv?.value ?? 0;
  const unit = getUnitText({ valueType: UtrValueType.Number, unit: utrv?.universalTracker?.unit, unitType: utrv?.universalTracker?.unitType });

  const reportData: WaterConsumptionDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    consumption: value,
    unit,
  };

  const chartData = [
    ['Water Consumption', unit, { role: 'style' }, { role: 'annotation' }],
    [getReportEffectiveDate(questionData, utrCode), value, '#A6CFE5', value],
  ];

  return (
    <Chart
      chartType='ColumnChart'
      data={chartData}
      width={WIDTH}
      height={HEIGHT}
      options={{
        ...CHART_DEFAULTS.OPTIONS,
        title: 'Total Water Consumption.',
        vAxis: {
          title: unit,
          format: 'decimal',
          gridlines: { count: 4 },
          minValue: 0,
        },
      }}
      chartEvents={[chartReadyEvent({ key: 'waterConsumption', data: reportData, handler: updateData })]}
    />
  );
});
