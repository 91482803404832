import { CSSProperties } from 'react';
import { Column } from '@tanstack/react-table';

const borderColor = '#e6e9ed';

/**
 * These are the important styles to make sticky column pinning work!
 */
export const getCommonPinningStyles = <T>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    borderRight: isLastLeftPinnedColumn ? `1px solid ${borderColor}` : undefined,
    borderLeft: isFirstRightPinnedColumn ? `1px solid ${borderColor}` : undefined,
    position: isPinned ? 'sticky' : 'unset',
    zIndex: isPinned ? 1 : 0,
  };
};
