import { SurveyStats, useGetStatsQuery } from '@api/initiative-stats';
import { DATE, formatDateNonUTC, getMonth, getUTCDateRange, getYear } from '@utils/date';
import { DateRangeType } from '@g17eco/types/common';
import { DashboardSection, DashboardSectionTitle } from '@components/dashboard';
import { SurveyStatsFilters } from '../filters/SurveyStatsFilters';
import Loader from '@components/loader';
import Table, { TableColumn } from '@components/table/Table';
import { useMemo, useState } from 'react';
import { SurveyStatsFilterProps, SurveyStatsStatus } from '../types';
import { getPeriodName } from '@utils/universalTracker';
import { naturalSort } from '../../../utils';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { CompleteButton } from '@components/survey/button/CompleteButton';
import { BulkSurveysMessageModal, SurveyMessageModal } from '@components/message-modal/MessageModal';
import { Action, SurveysEditing } from '../surveys-editing/SurveysEditing';
import { generateSurveysFlexSearchMap } from '../utils';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import './SurveyStatsTable.scss';
import { SURVEY } from '@constants/terminology';
import { BasicAlert } from '@g17eco/molecules';

interface Props {
  initiativeId: string;
  dateRange: DateRangeType;
  url: string;
  isUserAdmin: boolean;
  handleReload: () => Promise<void>;
}

const defaultSurveyFilters: SurveyStatsFilterProps = {
  status: SurveyStatsStatus.Pending,
};

export const SurveyStatsTable = (props: Props) => {
  const { initiativeId, dateRange, url, isUserAdmin, handleReload } = props;
  const [isEditingSurveys, setIsEditingSurveys] = useState<boolean>(false);
  const [showMessageModal, setShowMessageModal] = useState<string | undefined>(undefined);
  const [showBulkSurveysMessage, setShowBulkSurveysMessage] = useState<boolean>(false);
  const [filter, setFilter] = useState(defaultSurveyFilters);
  const {
    isLoading,
    isFetching,
    isSuccess,
    data = [],
  } = useGetStatsQuery({
    initiativeId,
    ...getUTCDateRange(dateRange),
  });
  const history = useHistory();

  const isCompleteFilter = filter.status === SurveyStatsStatus.Completed;

  const columns: TableColumn<SurveyStats>[] = [
    {
      Header: 'Subsidiary name',
      accessor: (row) => {
        return (
          <Link to={`${url}/overview/${row._id}`}>
            <span className={'text-truncate'} style={{ display: 'block' }}>
              {row.initiativeName ?? '-'}
            </span>
          </Link>
        );
      },
      cellProps: {
        style: {
          maxWidth: 180,
        },
      },
    },
    {
      Header: 'Month',
      accessor: (row: SurveyStats) => formatDateNonUTC(row.effectiveDate, DATE.MONTH),
      sortType: (a, b) => naturalSort(a.original.lastUpdated, b.original.lastUpdated),
    },
    {
      Header: 'Year',
      accessor: (row: SurveyStats) => formatDateNonUTC(row.effectiveDate, DATE.YEAR_ONLY),
      sortType: (a, b) => naturalSort(a.original.lastUpdated, b.original.lastUpdated),
    },
    {
      Header: 'Duration',
      accessor: (row) => getPeriodName(row.period, false),
    },
    {
      Header: 'Updated',
      accessor: (row: SurveyStats) => formatDateNonUTC(row.lastUpdated, DATE.HUMANIZE),
      sortType: (a, b) => naturalSort(a.original.lastUpdated, b.original.lastUpdated),
    },
    {
      Header: () => <i className='fa fa-user-clock text-secondary' />,
      accessor: 'status.created',
      maxWidth: 60,
      cellProps: {
        className: 'status-cell text-center'
      }
    },
    {
      Header: () => <i className='fa fa-user-edit text-primary' />,
      accessor: 'status.updated',
      maxWidth: 60,
      cellProps: {
        className: 'status-cell text-center'
      }
    },
    {
      Header: () => <i className='fa fa-user-times text-danger' />,
      accessor: 'status.rejected',
      maxWidth: 60,
      cellProps: {
        className: 'status-cell text-center'
      }
    },
    {
      Header: () => <i className='fa fa-user-check text-success' />,
      accessor: 'status.verified',
      maxWidth: 60,
      cellProps: {
        className: 'status-cell text-center'
      }
    },
    {
      id: 'mark',
      Header: () =>
        isUserAdmin ? (
          <Button onClick={() => setIsEditingSurveys(true)} color={'link'} className='btn-xs'>
            Bulk {isCompleteFilter ? 'incomplete' : 'complete'}
          </Button>
        ) : null,
      cellProps: {
        style: {
          width: 100,
        },
      },
      accessor: (row: SurveyStats) => (
        <CompleteButton
          color='link'
          classes={{ btn: 'btn-xs text-muted mx-0 text-left complete-btn' }}
          isUserAdmin={isUserAdmin}
          survey={row}
          handleReload={handleReload}
        />
      ),
      disableSortBy: true,
    },
    {
      id: 'message',
      cellProps: {
        style: {
          width: 100,
        },
      },
      Header: () => (
        <Button size='sm' color={'link'} className={'btn-xs'} onClick={() => setShowBulkSurveysMessage(true)}>
          Bulk Message
        </Button>
      ),
      disableSortBy: true,
      accessor: (row: SurveyStats) => {
        return (
          <Button
            size={'sm'}
            color={'link'}
            className={'btn-xs text-muted '}
            onClick={() => setShowMessageModal(row._id)}
          >
            Message
          </Button>
        );
      },
    },
  ];

  const surveyStats: SurveyStats[] = useMemo(() => {
    if (!isSuccess) {
      return [];
    }
    return data.reduce<SurveyStats[]>((acc, current) => {
      acc.push(...current.surveys);
      return acc;
    }, []);
  }, [data, isSuccess]);

  const filteredRows: SurveyStats[] = useMemo(() => {
    let rows: SurveyStats[] = surveyStats;
    const { searchText, byUserId, byInitiativeId, byYear, byMonth } = filter;
    if (searchText) {
      const searchIndex = generateSurveysFlexSearchMap(rows);
      const result = searchIndex.search(searchText);
      const matchedIds = new Set(result.map((item) => item.result).flat());
      rows = rows.filter((survey) => matchedIds.has(survey._id));
    }

    if (byInitiativeId) {
      rows = rows.filter((s) => s.initiativeId === byInitiativeId);
    }

    if (byUserId) {
      rows = rows.filter((s) => s.creator?._id === byUserId);
    }

    if (byYear) {
      rows = rows.filter((s) => getYear(s.effectiveDate) === Number(byYear));
    }

    if (byMonth) {
      rows = rows.filter((s) => getMonth(s.effectiveDate) === Number(byMonth));
    }

    return rows.filter((s) => (isCompleteFilter ? s.completedDate : !s.completedDate));
  }, [filter, surveyStats, isCompleteFilter]);

  return (
    <>
      <DashboardSectionTitle
          title={SURVEY.CAPITALIZED_PLURAL}
          buttons={[
            <Button key='bulk-import-button' className='py-1 mr-3' onClick={() => history.push(generateUrl(ROUTES.BULK_IMPORTING, { initiativeId }))}>
              <span className='lh-base'><i className='fa-light fa-file-import mr-2 fs-5' />Bulk import data</span>
            </Button>,
            <Button key='survey-templates-button' className='py-1' onClick={() => history.push(generateUrl(ROUTES.SURVEY_TEMPLATES, { initiativeId }))}>
              <span className='lh-base'><i className='fa-light fa-layer-group mr-2 fs-5' />{SURVEY.CAPITALIZED_SINGULAR} templates</span>
            </Button>,
          ]}
        />
      <DashboardSection>
        <SurveyStatsFilters isCompleteFilter={isCompleteFilter} setFilter={setFilter} surveyStats={surveyStats} />
        {isLoading || isFetching ? (
          <Loader relative />
        ) : (
          <Table
            defaultSortIcon={null}
            sortBy={[{ id: 'date', desc: false }]}
            showRowCount={false}
            responsive={false}
            pageSize={10}
            columns={columns}
            data={filteredRows}
            noData={
              <BasicAlert type='secondary'>
                There are no {isCompleteFilter ? 'Completed' : 'In Progress'} {SURVEY.PLURAL}.
              </BasicAlert>
            }
          />
        )}
      </DashboardSection>
      {
        showMessageModal ? (
          <SurveyMessageModal surveyId={showMessageModal} toggle={() => setShowMessageModal(undefined)} />
        ) : null
      }
      {isEditingSurveys && (
        <SurveysEditing
          surveys={filteredRows}
          action={isCompleteFilter ? Action.Incomplete : Action.Complete}
          handleClose={() => setIsEditingSurveys(false)}
          handleReload={handleReload}
        />
      )}
      {showBulkSurveysMessage ? (
        <BulkSurveysMessageModal surveys={filteredRows} toggle={() => setShowBulkSurveysMessage(false)} />
      ) : null}
    </>
  );
};
