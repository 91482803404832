import { useCallback, useEffect, useState } from 'react';
import { DataPeriods } from '@g17eco/types/universalTracker';
import { DateRangeType } from '@g17eco/types/common';
import { DashboardSurveyType, InsightDashboard, TimeFrameType } from '@g17eco/types/insight-custom-dashboard';
import { SurveyType } from '@g17eco/types/survey';
import { DEFAULT_FILTERS, defaultTimeFrame } from '@routes/custom-dashboard/dashboard-settings/utils';

interface Filters {
  period?: DataPeriods;
  dateRange?: DateRangeType;
  timeFrame?: TimeFrameType;
  surveyType?: DashboardSurveyType;
  isPreset?: boolean; // This flag use to check if the filters are preset in the first dashboard load.
}

const DEFAULT_FILTERS_VALUE: Required<Filters> = {
  period: DataPeriods.Yearly,
  dateRange: {
    startDate: defaultTimeFrame.startDate,
    endDate: defaultTimeFrame.endDate,
  },
  timeFrame: defaultTimeFrame.type,
  surveyType: SurveyType.Default,
  isPreset: false,
};

export const useInsightDashboardFilters = (defaultValue?: Omit<Filters, 'isPreset'>) => {
  const [filters, setFilters] = useState<Required<Filters>>({ ...DEFAULT_FILTERS_VALUE, ...defaultValue });
  const updateFilters = useCallback((update: Partial<Filters>) => setFilters((prev) => ({ ...prev, ...update })), []);

  return { filters, setFilters: updateFilters };
};

interface UsePresetInsightDashboardFiltersProps {
  dashboard: InsightDashboard | undefined;
  filters: Filters;
  setFilters: (filters: Partial<Filters>) => void;
}

export const usePresetInsightDashboardFilters = ({
  filters,
  setFilters,
  dashboard,
}: UsePresetInsightDashboardFiltersProps) => {
  const { isPreset } = filters;

  useEffect(() => {
    if (dashboard?.filters && !isPreset) {
      const { type: timeFrame, startDate, endDate } = dashboard.filters.timeFrame ?? DEFAULT_FILTERS.timeFrame;
      setFilters({
        period: dashboard.filters.period ?? DEFAULT_FILTERS.period,
        timeFrame: timeFrame,
        dateRange: { startDate, endDate },
        surveyType: dashboard.filters.surveyType ?? DEFAULT_FILTERS.surveyType,
        isPreset: true,
      });
    }
  }, [dashboard, isPreset, setFilters]);

  return { isPreset };
};
