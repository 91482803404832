import { SurveyQuestionListToolbarProps } from '..';
import { Toggle } from '@g17eco/molecules';

export const ShowingBookmarksToggle = (props: SurveyQuestionListToolbarProps) => {
  const { toggleShowingBookmarks, isShowingBookmarks } = props;

  if (!toggleShowingBookmarks) {
    return null;
  }

  return (
    <Toggle
      className={{ form: 'd-flex align-items-center pl-0 mb-0 justify-content-end', label: 'text-md ms-1' }}
      checked={!!isShowingBookmarks}
      onChange={() => toggleShowingBookmarks?.(!isShowingBookmarks)}
      label='Bookmarks'
    />
  );
};
