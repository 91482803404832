import { TableColumnType, UtrValueType } from '@g17eco/types/universalTracker';

export const SUPPORTED_COLUMN_TYPES = [TableColumnType.Number, TableColumnType.Percentage, TableColumnType.Text];

export const SUPPORTED_UTR_TYPES = [
  UtrValueType.Number,
  UtrValueType.Percentage,
  UtrValueType.Text,
  UtrValueType.NumericValueList,
  UtrValueType.TextValueList,
  UtrValueType.Table,
];
