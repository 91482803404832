import { Button } from 'reactstrap';
import RefreshImage from '../../images/refresh-image.png';

interface Props {
  handleReport: () => void;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const LazyLoadErrorContent = ({handleReport, handleClick}: Props) => {
  return (
    <>
      <div className='section col-12 col-md-6'>
        <div>
          <h1 className='my-3'>
            New code...
            <br />
            Please refresh the page!
          </h1>
          <p>We’ve hit a small issue, but we think it will rectify itself if you refresh this page.</p>
          <p>
            (We think we have deployed some new code while you were away and a browser refresh - see button below - will
            fix things. If it doesn’t please get in touch using the chat bubble below or the report issue button).
          </p>
        </div>
        <div className='text-center text-md-left'>
          <button type='button' className='btn btn-secondary mt-2' onClick={handleReport}>
            Report
          </button>
          <Button color='primary' className='ml-3 mt-2' onClick={handleClick}>
            <i className='fa-light fa-arrows-rotate mr-2' />
            Refresh
          </Button>
        </div>
      </div>
      <div className='section col-12 col-md-6'>
        <img draggable={false} className='logo' src={RefreshImage} alt='G17Eco' />
      </div>
    </>
  );
};
