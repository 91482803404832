import { useGetCarbonCalculatorsListQuery } from './api';

export const useGetCarbonCalculatorsListByUtrCode = (utrCode?: string) => {
  const list = useGetCarbonCalculatorsListQuery(undefined, { skip: !utrCode });
  if (!utrCode || !list.isSuccess) {
    return [];
  }

  const { data: calculators } = list;
  return calculators.filter(calculator => calculator.utrCodes?.includes(utrCode));
}
