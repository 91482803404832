import { saveAs } from 'file-saver';
import { Packer } from 'docx';
import { TCFDReportGenerator } from './tcfd-report-generator';
import { UNGCReportGenerator } from './ungc-report-generator';
import { SDGReportGenerator } from './sdg-report-generator';
import { GRIReportGenerator } from './gri-report-generator';
import { VigeoEIRISReportGenerator } from './vigeo-eiris-report-generator';
import { DJSIReportGenerator } from './djsi-report-generator';
import { SDGImpactReportGenerator } from './sdg-impact-report/sdg-impact-report-generator';
import { SurveyInitiative } from '../../types/survey';
import G17Client from '../../services/G17Client';
import { getFilename } from '../../utils/file/getFilename';
import { DataSources as SDGImpactDataSources } from './sdg-impact-report';
import { SASBReportGenerator } from './sasb-report-generator';
import {
  SGXMetricReportGenerator,
  SGXMetricReportGeneratorParams
} from './sgx-metric-report/sgx-metric-report-generator';
import {
  SustainabilityReportGenerator,
  SustainabilityReportGeneratorParams,
} from './sustainability-report/sustainability-report-generator';
import { SimpleReportGenerator } from './simple-report-generator';
import { ReportOptions } from '../../types/reportData';
import { DownloadMultiScope, DownloadScopeType, VisibilityStatus } from '../../types/download';
import { convertToUtrvStatuses, DownloadSettingsType } from '../downloads/util/downloadReportHandler';
import { ScopeGroups } from '../../model/surveyData';
import { getScopeType } from '../survey-scope/scopeSelection';
import { UtrvStatus } from '../../constants/status';
import { UtrvAssuranceStatus } from '../../types/universalTrackerValue';
import { reportMultiScopeData, SDGReport, sdgReportData } from './reportApi';
import { loggerMessage } from '../../logger';
import { ReportGeneratorFn } from './types';
import { ESG30ReportGenerator, ESG30ReportGeneratorData } from './esg30-report/esg30-report-generator';
import { convertImgToBase64URL } from './document-structure';
import { standards } from '@g17eco/core';


const generateFilename = (survey: Pick<SurveyInitiative, '_id' | 'initiative' | 'effectiveDate'>, type: string): string => {
  return getFilename({
    initiativeName: survey.initiative.name,
    effectiveDate: survey.effectiveDate,
    _id: survey._id,
    type: type,
  });
};

const getTCFD: ReportGeneratorFn = async (survey, reportData, downloadScope) => {
  const doc = await TCFDReportGenerator(survey, reportData, downloadScope);

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'TCFD')}.docx`);
  });
};

const getUNGC: ReportGeneratorFn = async (survey, reportData, downloadScope) => {
  const doc = await UNGCReportGenerator(survey, reportData, downloadScope);

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'UNGC')}.docx`);
  });
};

const getIOGP: ReportGeneratorFn = async (survey, reportData, downloadScope) => {
  const iogpLogoBase64 = await convertImgToBase64URL(standards.iogp.src);
  const generator = await SimpleReportGenerator({
    survey,
    reportData,
    reportType: 'iogp',
    reportLogo: iogpLogoBase64,
    color: '801F72',
    downloadScope,
  });
  const doc = generator.generate(generator);

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'IOGP')}.docx`);
  });
};

const getISSB: ReportGeneratorFn = async (survey, reportData, downloadScope) => {
  const issbLogoBase64 = await convertImgToBase64URL(standards.issb.src);
  const generator = await SimpleReportGenerator({
    survey,
    reportData,
    reportType: 'issb',
    reportLogo: issbLogoBase64,
    color: '801F72',
    downloadScope,
  });
  const doc = generator.generate(generator);

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'ISSB')}.docx`);
  });
};

export const getIPIECA: ReportGeneratorFn = async (survey, reportData, downloadScope) => {
  const ipiecaLogoBase64 = await convertImgToBase64URL(standards.ipieca.src);
  const generator = await SimpleReportGenerator({
    survey,
    reportData,
    reportType: 'ipieca',
    reportLogo: ipiecaLogoBase64,
    color: '2D2D6A',
    downloadScope,
  });
  const doc = generator.generate(generator);

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'IPIECA')}.docx`);
  });
};

const getGRI: ReportGeneratorFn = async (survey, reportData, downloadScope) => {
  const doc = await GRIReportGenerator(survey, reportData, downloadScope);

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'GRI')}.docx`);
  });
};

const getSASB: ReportGeneratorFn = async (survey, reportData, downloadScope) => {
  const doc = await SASBReportGenerator(survey, reportData, downloadScope);

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'SASB')}.docx`);
  });
};

const getVigeoEIRIS: ReportGeneratorFn = async (survey, reportData, downloadScope) => {
  const doc = await VigeoEIRISReportGenerator(survey, reportData, downloadScope);

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'Vigeo-EIRIS')}.docx`);
  });
};

const getDJSI: ReportGeneratorFn = async (survey, reportData, downloadScope) => {
  const doc = await DJSIReportGenerator(survey, reportData, downloadScope);

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'DJSI')}.docx`);
  });
};

// Essentialy SDG goal and targets, similar to SDG Impact
export const getSDG = async (survey: SurveyInitiative, data: SDGReport) => {
  const doc = await SDGReportGenerator(survey, data);

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'SDG')}.docx`);
  });
};

export const getSDGImpact = async (
  survey: SurveyInitiative,
  dataSources: SDGImpactDataSources,
  options?: ReportOptions
) => {
  const doc = await SDGImpactReportGenerator(survey, dataSources, options);

  return Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(survey, 'CTL')}.docx`);
  });
};

export const getESG30 = async (generatorData: ESG30ReportGeneratorData) => {
  const doc = await ESG30ReportGenerator(generatorData);

  return Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(generatorData.survey, 'ESG Essentials')}.docx`);
  });
};

export const getSGXMetric = async (params: SGXMetricReportGeneratorParams) => {
  const doc = await SGXMetricReportGenerator(params);

  return Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(params.survey, 'SGX')}.docx`);
  });
};

export const getSustainabilityReport = async (params: SustainabilityReportGeneratorParams) => {
  const doc = await SustainabilityReportGenerator(params);

  return Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${generateFilename(params.survey, 'Sustainability')}.docx`);
  });
};

const reportMap = {
  tcfd_standard: getTCFD,
  tcfd: getTCFD,
  ungc: getUNGC,
  gri: getGRI,
  gri2021: getGRI, // Just get GRI... for now
  sasb: getSASB,
  vigeo_eiris: getVigeoEIRIS,
  sam_csa: getDJSI,
  ipieca: getIPIECA,
  iogp: getIOGP,
  issb: getISSB,
};

export type SupportedReportCodes = keyof typeof reportMap | 'sdg';
export const getRenderedReport = (code: keyof typeof reportMap) => reportMap[code];

export const isSupportedReportCode = (code: string): code is SupportedReportCodes => {
  return code === 'sdg' || code in reportMap;
}

// Generate report based on setup for Initiative.
export const getMultiScopeReport = async (
  surveyId: string,
  code: SupportedReportCodes,
  downloadScope: DownloadMultiScope
) => {

  if (code === 'sdg') {
    return Promise.all([
      G17Client.getSurveyListItem(surveyId),
      sdgReportData(surveyId, downloadScope),
    ]).then(([survey, sdgReport]) => getSDG(survey, sdgReport));
  }

  const generateReportDoc = reportMap[code];
  if (generateReportDoc) {
    return Promise.all([
      G17Client.getSurveyListItem(surveyId),
      reportMultiScopeData(surveyId, downloadScope),
    ]).then(([survey, reportData]) => generateReportDoc(survey, reportData, downloadScope));
  }
  loggerMessage(`Failed to generate multi scope report for "${code}"`, {
    code,
    surveyId,
  })
};

export type DownloadScopeParams = Omit<DownloadSettingsType, 'selectedScopes'> & { targets?: boolean };
export interface DownloadScope extends Omit<DownloadScopeParams, 'assuranceStatus'> {
  scope: DownloadScopeType;
  assuranceStatus?: UtrvAssuranceStatus[];
}

export const getDefaultDownloadScope = (codes: string[], params: DownloadScopeParams): DownloadScope => {
  const statuses = convertToUtrvStatuses(params.assuranceStatus ?? (params.status || UtrvStatus.Verified));
  const scope = codes.reduce(
    (acc, code) => {
      const type = getScopeType(code);
      acc[type].push(code);
      return acc;
    },
    {
      [ScopeGroups.Frameworks]: [],
      [ScopeGroups.Standards]: [],
      [ScopeGroups.Custom]: [],
      [ScopeGroups.Sdg]: [],
    } as DownloadScopeType
  );
  return {
    scope,
    visibility: params.visibility ?? VisibilityStatus.ExcludeValuesOnly,
    targets: params.targets === undefined ? true : params.targets,
    ...statuses,
  };
};

