import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UrlParams } from '../PackUsage';
import { useAppSelector } from '../../../../reducers';
import { loadCustomMetricGroupsByInitiativeId } from '../../../../actions/initiative';
import { useDispatch } from 'react-redux';
import { RequestScope } from '../../../../actions/api';
import { CustomSelectOption, SelectPacks } from '../../../select-packs';
import { PACK, SURVEY } from '@constants/terminology';
import { RequestScopeExtended } from '@utils/requestScope';

interface FilterSurveyPackProps {
  selectedScopes?: string[];
  handleSurveyPackFilter: (scopes: RequestScope[]) => void;
  extraOptions?: RequestScopeExtended[];
}

export const FilterSurveyPacks = (props: FilterSurveyPackProps) => {
  const { selectedScopes, handleSurveyPackFilter, extraOptions } = props;
  const { group } = useParams<UrlParams>();
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const dispatch = useDispatch();

  const { data: metricGroups } = useAppSelector((state) => state.customMetricGroups);
  const groupOptions = metricGroups.reduce((acc, cur) => {
    acc[cur._id] = { name: cur.groupName, src: cur.groupData?.icon };
    return acc;
  }, {} as CustomSelectOption);

  useEffect(() => {
    dispatch(loadCustomMetricGroupsByInitiativeId(portfolioId));
  }, [dispatch, portfolioId]);

  return (
    <SelectPacks
      placeholder={`${SURVEY.CAPITALIZED_ADJECTIVE} ${PACK.SINGULAR}`}
      selectedScopes={selectedScopes}
      groupOptions={groupOptions}
      handleChangePacks={handleSurveyPackFilter}
      isDisabled={!!group}
      extraOptions={extraOptions}
    />
  );
};
