import { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { generateFlexSearchMap } from '../selectors/blueprint';
import { UniversalTrackerBlueprintMin } from '../types/universalTracker';

export const useSearchDebounceWithFlexMap = <T extends UniversalTrackerBlueprintMin = UniversalTrackerBlueprintMin>(
  questions: T[] | undefined,
  timeout = 500
) => {
  const [search, setSearch] = useState('');
  const onSearch = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, timeout);
  const index = useMemo(() => generateFlexSearchMap(questions), [questions]);

  const matchedQuestions = useMemo(() => {
    if (!questions) {
      return [];
    }
    if (!search) {
      return questions;
    }
    const matchedIds = new Set(
      index
        .search(search)
        .map((question) => question.result)
        .flat()
    );
    return questions.filter((question) => matchedIds.has(question._id));
  }, [index, search, questions]);

  return { search, onSearch, matchedQuestions };
};
