import { Tag } from '../../types/metricGroup';
import { CheckboxState } from '../common/Checkbox';

export const getTagStatus = (tag: Tag, selectedUtrIds: string[]) => {
  const includedUtrIds = selectedUtrIds.filter((utrId) => tag.universalTrackers?.includes(utrId));
  if (includedUtrIds.length === 0) {
    return CheckboxState.Unchecked;
  }
  if (includedUtrIds.length === selectedUtrIds.length) {
    return CheckboxState.Checked;
  }
  return CheckboxState.Indeterminate;
};
