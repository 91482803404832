import { SURVEY } from '@constants/terminology';
import React from 'react';

interface TemplateDashboardHeaderProps {
  title?: string;
  buttons?: JSX.Element[];
}

export const TemplateDashboardHeader = ({ title = `${SURVEY.CAPITALIZED_SINGULAR} templates`, buttons = [] }: TemplateDashboardHeaderProps) => {
  return (
    <div className='d-flex justify-content-between'>
      <div className='d-flex align-items-center'>
        <i className='fa-light fa-layer-group fa-xl mr-2' />
        <span className='h4 text-ThemeTextDark my-1'>{title}</span>
      </div>
      <div>
        {buttons.map((button, i) => (
          <React.Fragment key={`btn-${button.key}-${i}`}>{button}</React.Fragment>
        ))}
      </div>
    </div>
  );
};
