import {
  ChartSubType,
  GridDashboardChartItem,
  GridDashboardSDGChartItem,
} from '../../../../../types/insight-custom-dashboard';

interface Props {
  item: GridDashboardChartItem | GridDashboardSDGChartItem;
  questionReference?: JSX.Element | null;
  actionButton?: JSX.Element | null;
}

export const MultiUtrsChartFooter = (props: Props) => {
  const { item, questionReference = null, actionButton } = props;
  if (item.subType === ChartSubType.Table) {
    return actionButton ? <div className='text-right'>{actionButton}</div> : null;
  }

  if (!questionReference && !actionButton) {
    return null;
  }

  return (
    <div className='w-100 d-flex justify-content-between'>
      {questionReference}
      {actionButton}
    </div>
  );
};
