export enum SurveyStatsStatus {
  Pending = 'pending',
  Completed = 'completed',
}

export enum UserStatsPermissionView {
  Explicit = 'explicit',
  Permission = 'permission',
}

export interface SurveyStatsFilterProps {
  status: SurveyStatsStatus;
  searchText?: string;
  byUserId?: string;
  byInitiativeId?: string;
  byYear?: string;
  byMonth?: string;
}

export interface UserStatsFiltersProps {
  view: UserStatsPermissionView;
  searchText?: string;
}
