import { Menu } from '../menu/Menu';

export interface NavTab {
  code: string;
  label: string;
  hidden?: boolean;
  disabled?: boolean;
}

interface NavigationProps {
  navTabs: NavTab[];
  page: string;
  onClick: (page: string) => void;
}

export const Navigation = ({ navTabs, page, onClick }: NavigationProps) => {
  return (
    <Menu
      items={navTabs.map((tab) => ({
        active: tab.code === page,
        label: tab.label,
        onClick: () => onClick(tab.code),
        hidden: tab.hidden,
        disabled: tab.disabled,
      }))}
    />
  );
};
