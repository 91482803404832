import { Document, Header, ImageRun } from 'docx';
import { declarativeStyles, numberingStyles } from './styles'
import {
  commentBox,
  letterList,
  paragraphWithBoldText,
  paragraph,
  imageWrapper,
  heading2,
  convertImgToBase64URL,
  getWWGLogo
} from './document-structure';
import TCFDLogo from '../../images/reports/tcfd_logo.png';
import { formatDate } from '../../utils/date';
import { renderQuestionGroup } from './questionView';
import { TEXT } from './constants';
import { SurveyInitiative } from '../../types/survey';
import { DownloadMultiScope } from '../../types/download';
import { groupReportDataBySubgroups } from './utils';
import { ReportData } from '../../types/reportData';
import { ReportGeneratorFn } from './types';
import { QUESTION, SURVEY } from '@constants/terminology';

export const TCFDReportGenerator: ReportGeneratorFn<Document> = async (
  survey: SurveyInitiative,
  data: ReportData[],
  downloadScope: DownloadMultiScope
) => {

  const wwgLogo = await getWWGLogo();

  const tcfdLogoBase64  = await convertImgToBase64URL(TCFDLogo);

  const tcfdLogo = new ImageRun({
    data: Uint8Array.from(atob(tcfdLogoBase64), c => c.charCodeAt(0)),
    transformation: {
      width: 180,
      height: 70
    }
  });
  const { displayUserInput, scope } = downloadScope;

  const isTCFDStandard = !!scope.standards.length;

  const [scopeGroupCode] = isTCFDStandard ? scope.standards : scope.frameworks;

  const questionMap = groupReportDataBySubgroups(data, scopeGroupCode);

  const periodCovered = formatDate(survey.effectiveDate, 'MMMM YYYY')

  const document = new Document({
    styles: {
      paragraphStyles: declarativeStyles
    },
    numbering: {
      config: numberingStyles
    },
    sections: [
      {
        headers: {
          default: new Header({
            children: [
              imageWrapper(tcfdLogo),
              imageWrapper(wwgLogo)
            ]
          })
        },

        children: [
          paragraphWithBoldText('Company Name: ', survey.initiative.name),
          paragraphWithBoldText('Company Sector: ', survey.initiative.industryText ?? ''),
          paragraphWithBoldText('Report Date: ', periodCovered),
          paragraph(''), // Spacer

          heading2('Description of actions'),
          paragraphWithBoldText('Governance:', ' Disclose the organization’s governance around climate-related risks and opportunities'),
          paragraph(`Once you have filled in data aligned to TCFD Governance you can then give context to it and use this data to build your own TCFD report. The following recommended disclosures will help give context to the metrics and ${QUESTION.PLURAL} that you answered in your ${SURVEY.SINGULAR} that align with TCFD Governance.`),

          paragraph('TCFD Governance Recommended Disclosures'),
          letterList('numbering-letter-one', 'Describe the board’s oversight of climate-related risks and opportunities.'),
          letterList('numbering-letter-one', 'Describe management’s role in assessing and managing climate-related risks and opportunities.'),

          paragraph('Selected TCFD Governance data from the platform:'),

          ...renderQuestionGroup(questionMap[isTCFDStandard ? 'tcfd_standard_governance' : 'governance'], 'numbering-list-one', survey.unitConfig, displayUserInput),

          paragraphWithBoldText(TEXT.CONTEXTUAL_COMMENTS_TITLE, TEXT.CONTEXTUAL_COMMENTS_BODY),
          commentBox(''),

          paragraphWithBoldText('Strategy:', ' Disclose the actual and potential impacts of climate-related risks and opportunities on the organization’s businesses, strategy, and financial planning where such information is material.'),
          paragraph(`Once you have filled in data aligned to TCFD Strategy you can then give context to it and use this data to build your own TCFD report. The following recommended disclosures will help give context to the metrics and ${QUESTION.PLURAL} that you answered in your ${SURVEY.SINGULAR} that align with TCFD Strategy.`),
          paragraph('TCFD Strategy Recommended Disclosures'),
          letterList('numbering-letter-two', 'Describe the climate-related risks and opportunities the organization has identified over the short, medium, and long term.'),
          letterList('numbering-letter-two', 'Describe the impact of climate-related risks and opportunities on the organization’s businesses, strategy, and financial planning.'),
          letterList('numbering-letter-two', 'Describe the resilience of the organization’s strategy, taking into consideration different climate-related scenarios, including a 2°C or lower scenario.'),
          paragraph('Selected TCFD Strategy data from the platform:'),

          ...renderQuestionGroup(questionMap[isTCFDStandard ? 'tcfd_standard_strategy' : 'strategy'], 'numbering-list-two', survey.unitConfig, displayUserInput),

          paragraphWithBoldText(TEXT.CONTEXTUAL_COMMENTS_TITLE, TEXT.CONTEXTUAL_COMMENTS_BODY),
          commentBox(''),

          paragraphWithBoldText('Risk Management:', ' Disclose how the organization identifies, assesses, and manages climate-related risks.'),
          paragraph(`Once you have filled in data aligned to TCFD Risk Management you can then give context to it and use this data to build your own TCFD report. The following recommended disclosures will help give context to the metrics and ${QUESTION.PLURAL} that you answered in your ${SURVEY.SINGULAR} that align with TCFD Risk Management.`),
          paragraph('TCFD Risk Management Recommended Disclosures:'),
          letterList('numbering-letter-three', 'Describe the organization’s processes for identifying and assessing climate-related risks.'),
          letterList('numbering-letter-three', 'Describe the organization’s processes for managing climate-related risks'),
          letterList('numbering-letter-three', 'Describe how processes for identifying, assessing, and managing climate-related risks are integrated into the organization’s overall risk management.'),

          paragraph('Selected TCFD Risk Management data from the platform:'),

          ...renderQuestionGroup(
            questionMap[isTCFDStandard ? 'tcfd_standard_risk_management' : 'risk-management'],
            'numbering-list-three',
            survey.unitConfig,
            displayUserInput
          ),

          paragraphWithBoldText(TEXT.CONTEXTUAL_COMMENTS_TITLE, TEXT.CONTEXTUAL_COMMENTS_BODY),
          commentBox(''),

          paragraphWithBoldText('Metrics and Targets:', ' Disclose the metrics and targets used to assess and manage relevant climate-related risks and opportunities where such information is material.'),
          paragraph(`Once you have filled in data aligned to TCFD Metrics and Targets you can then give context to it and use this data to build your own TCFD report. The following recommended disclosures will help give context to the metrics and ${QUESTION.PLURAL} that you answered in your ${SURVEY.SINGULAR} that align with TCFD Metrics and Targets.`),
          paragraph('TCFD Metrics and Targets Recommended Disclosures:'),
          letterList('numbering-letter-four', 'Disclose the metrics used by the organization to assess climate-related risks and opportunities in line with its strategy and risk management process.'),
          letterList('numbering-letter-four', 'Disclose Scope 1, Scope 2, and, if appropriate, Scope 3 greenhouse gas (GHG) emissions, and the related risks.'),
          letterList('numbering-letter-four', 'Describe the targets used by the organization to manage climate-related risks and opportunities and performance against targets.'),
          paragraph('Selected TCFD Metrics and Targets data from the platform:'),

          ...renderQuestionGroup(questionMap[isTCFDStandard ? 'tcfd_standard_metrics_and_targets' : 'metrics-and-targets'], 'numbering-list-four', survey.unitConfig, displayUserInput),

          paragraphWithBoldText(TEXT.CONTEXTUAL_COMMENTS_TITLE, TEXT.CONTEXTUAL_COMMENTS_BODY),
          commentBox(''),
        ]
      }
    ]
  });

  return document;
}
