import { useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { ArchiveSubsidiaryModalContent } from './ArchiveSubsidiaryModalContent';
import { InitiativePlain } from '../../../../types/initiative';
import { OrgMapUser } from '../../../../api/organisation-map';
import { MinReportingLevel } from '../../types';

export type UserChanges = {
  reassignedUsers: OrgMapUser[];
  removedUsers: OrgMapUser[];
  reassignedInitiative: MinReportingLevel | undefined;
};

export enum ArchiveStep {
  Warning = 1,
  UserManagement = 2,
  Confirmation = 3,
}

export interface ArchiveProgress {
  step: ArchiveStep;
  userChanges: UserChanges;
}

const defaultProgress: ArchiveProgress = {
  step: ArchiveStep.Warning,
  userChanges: {
    reassignedInitiative: undefined,
    reassignedUsers: [],
    removedUsers: [],
  },
};

interface ArchiveSubsidiaryModalProps {
  isOpen: boolean;
  selectedInitiative?: MinReportingLevel;
  toggle: () => void;
  initiativeTreeList: InitiativePlain[];
  handleUpdate: () => void;
}

export const ArchiveSubsidiaryModal = (props: ArchiveSubsidiaryModalProps) => {
  const { toggle, isOpen, selectedInitiative, handleUpdate, initiativeTreeList } = props;
  const [archiveProgress, setArchiveProgress] = useState<ArchiveProgress>(defaultProgress);

  const handleChange = (changes: Partial<ArchiveProgress>) => {
    setArchiveProgress((prev) => {
      return {
        ...prev,
        ...changes,
      };
    });
  };

  const handleToggle = () => {
    setArchiveProgress(defaultProgress);
    toggle();
  };

  if (!selectedInitiative) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} backdrop='static' className='archive-subsidiary-modal'>
      <ModalHeader toggle={handleToggle}>
        <span className='text-ThemeDangerDark'>Archive business unit</span>
      </ModalHeader>
      <ArchiveSubsidiaryModalContent
        archiveProgress={archiveProgress}
        handleChange={handleChange}
        selectedInitiative={selectedInitiative}
        initiativeTreeList={initiativeTreeList}
        handleToggle={handleToggle}
        handleUpdate={handleUpdate}
      />
    </Modal>
  );
};
