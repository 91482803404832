import { useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Props {
  title: string;
  text?: string;
  handleConfirm: () => void;
  toggle: () => void;
}

const CONFIRMATION = 'DELETE';

export const DeleteConfirmation = (props: Props) => {
  const { title, text = 'Are you sure? This cannot be undone.', handleConfirm, toggle } = props;
  const [input, setInput] = useState('');
  const handleClickConfirm = () => {
    toggle();
    handleConfirm();
  };

  return (
    <Modal isOpen={true} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>
        <span className='text-ThemeDangerDark'>{title}</span>
      </ModalHeader>
      <ModalBody>
        <p>{text}</p>
        <p>If you want to proceed, type DELETE into the field below to continue</p>
        <Input type='text' placeholder={CONFIRMATION} value={input} onChange={(e) => setInput(e.currentTarget.value)} />
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' onClick={toggle}>
          Cancel
        </Button>
        <Button color='danger' onClick={handleClickConfirm} disabled={CONFIRMATION !== input}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
