import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

interface Props {
  hasUnsavedChanges: boolean;
}

export const UnsavedChangesWarningPrompt = ({ hasUnsavedChanges }: Props) => {
  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return <Prompt when={hasUnsavedChanges} message={() => 'Changes you made may not be saved.'} />;
};
