interface GenerateGridBackgroundProps {
  cellSize: { width: number; height: number };
  margin: [number, number];
  cols: number;
  gridWidth: number;
}

export function generateGridBackground({ cellSize, margin, cols, gridWidth }: GenerateGridBackgroundProps) {
  const XMLNS = 'http://www.w3.org/2000/svg';
  const [horizontalMargin, verticalMargin] = margin;
  const rowHeight = cellSize.height + verticalMargin;
  const cellStrokeColor = '#D0D2D6';

  const y = 0;
  const w = cellSize.width;
  const h = cellSize.height;

  const rectangles = Array(cols)
    .fill(1)
    .map((_, i) => {
      const x = i * (cellSize.width + horizontalMargin);
      return `<rect stroke='${cellStrokeColor}' stroke-width='1' fill='#F9FAFC' rx='2' ry='2' x='${x}' y='${y}' width='${w}' height='${h}'/>`;
    });

  const svg = [`<svg xmlns='${XMLNS}' width='${gridWidth}' height='${rowHeight}'>`, ...rectangles, '</svg>'].join('');

  return `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`;
}
