import { PartialAssuranceField } from '@g17eco/types/assurance';
import { g17ecoApi, transformResponse } from './g17ecoApi';

const TAGS = ['assurance'];

interface UpdatePartialAssuranceParams {
  portfolioId: string;
  utrvId: string;
  partialFields: PartialAssuranceField[];
}

export const assuranceApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      updatePartialAssurance: builder.mutation<any, UpdatePartialAssuranceParams>({
        invalidatesTags: TAGS,
        transformResponse,
        query: ({ portfolioId, utrvId, partialFields }) => ({
          url: `/assurers/portfolios/${portfolioId}/partial-assurance/${utrvId}`,
          method: 'post',
          data: { partialFields },
        }),
      }),
    }),
  });

export const { useUpdatePartialAssuranceMutation } = assuranceApi;
