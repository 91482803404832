import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DataShareActions } from '../../../types/dataShare';
import { InitiativeDataShare, RequestReviewMethod } from '../../../types/initiative';

export const NoDataShareConfirmModal = (props: {
  isOpen: boolean;
  dataShareTemplate: InitiativeDataShare | undefined;
  actions: DataShareActions;
  toggle: () => void;
}) => {
  const { isOpen, dataShareTemplate, actions, toggle } = props;

  const handleSubmit = async () => {
    await actions.updateTemplate({ ...dataShareTemplate, requestReview: RequestReviewMethod.NoAccess });
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>
        <span className='text-ThemeWarningExtradark'>Turn off sharing</span>
      </ModalHeader>
      <ModalBody>
        <div>
          This will turn off all current sharing and refuse all future data sharing requests. Are you sure you want to
          proceed?
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' className='mr-2' onClick={toggle}>
          Cancel
        </Button>
        <Button color='warning' onClick={handleSubmit}>
          <span>Proceed</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
