import { useRouteMatch } from 'react-router-dom';
import { GridLayoutDashboard } from '../GridLayoutDashboard';
import { GRID_WIDTH, hasSDGContributionItem } from '../utils';
import { useGetSharableInsightDashboardByIdQuery } from '../../../api/insight-dashboards';
import { NotFound } from '../../not-found/NotFound';
import { QueryWrapper } from '../../../components/query/QueryWrapper';
import G17EcoLogo from '../../../images/g17Eco.svg';
import './styles.scss';
import { CompanyProfile } from '../../../components/company-profile';
import { UtrvHistoryModalTemplate } from '../../../components/utr-modal/UtrvHistoryModalTemplate';
import { UtrModalBody } from './UtrModalBody';
import { SdgChart } from '../../../components/impact-performance/SdgChart';
import { DashboardSection } from '@components/dashboard';
import { useGetSharableValueListByIdQuery } from '@api/value-list';
import { useDashboardHistoryModal } from '@features/custom-dashboard/hooks/useDashboardHistoryModal';

export const SharedDashboard = () => {
  const { params } = useRouteMatch<{ dashboardId?: string; token?: string }>();
  const { dashboardId = '', token = '' } = params;
  const {
    firstValueListCode,
    valueListId,
    isNumericValueList,
    selectingUtrData,
    handleOpenUtrvHistoryModal,
    resetSelectingUtrData,
  } = useDashboardHistoryModal();
  const query = useGetSharableInsightDashboardByIdQuery({ dashboardId, token }, { skip: !dashboardId || !token });

  const { data: valueList } = useGetSharableValueListByIdQuery(
    { valueListId, dashboardId, token },
    {
      skip: !isNumericValueList || !valueListId || !dashboardId || !token,
    }
  );

  return (
    <div className='main-container'>
      <QueryWrapper
        query={query}
        onError={() => <NotFound />}
        onSuccess={({ dashboard, initiative }) => (
          <>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='p-0 mt-3 d-flex flex-column' style={{ maxWidth: `${GRID_WIDTH}px` }}>
                  {dashboard.filters.initiativeInfo?.enabled ? (
                    <CompanyProfile initiative={initiative} readOnly />
                  ) : null}
                  <h3 className='text-ThemeHeadingLight dashboard__title'>{dashboard.title}</h3>
                  {dashboard.filters.sdgContribution?.enabled &&
                  dashboard.scorecard &&
                  !hasSDGContributionItem(dashboard.items) ? (
                    <DashboardSection>
                      <SdgChart initiativeId={dashboard.initiativeId} scorecard={dashboard.scorecard} />
                    </DashboardSection>
                  ) : null}
                  <GridLayoutDashboard
                    isEditing={false}
                    gridItems={dashboard.items}
                    utrsData={dashboard.utrsData}
                    handleOpenUtrvHistoryModal={({ item, utrData }) =>
                      handleOpenUtrvHistoryModal({ item, utrData, initiativeId: dashboard.initiativeId })
                    }
                    readOnly
                    hideQuestionReference
                    initiativeId={dashboard.initiativeId}
                    scorecard={dashboard.scorecard}
                    classes={{ container: '' }}
                  />
                  {selectingUtrData ? (
                    <UtrvHistoryModalTemplate toggle={resetSelectingUtrData}>
                      <UtrModalBody
                        utr={selectingUtrData.getUniversalTracker()}
                        utrvs={selectingUtrData.getUniversalTrackerValues()}
                        firstValueListCode={firstValueListCode}
                        valueList={valueList}
                      />
                    </UtrvHistoryModalTemplate>
                  ) : null}
                </div>
              </div>
            </div>
            <footer className='position-relative m-0'>
              <div className='d-flex flex-column justify-content-center align-items-center text-ThemeTextPlaceholder'>
                <div className='powerByText text-ThemeTextMedium'>Powered by</div>
                <div>
                  <img src={G17EcoLogo} height={35} width={135} alt='G17Eco' />
                </div>
              </div>
            </footer>
          </>
        )}
      />
    </div>
  );
};
