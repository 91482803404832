import React from 'react'
import { Chart } from 'react-google-charts'
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';

const WIDTH = 400;
const HEIGHT = 400;

export interface BonusesDataSource extends DataSource {
  bonusesPc?: number;
}

const Bonuses = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrv = questionData.find(d => d.universalTracker.code === 'survey/sdg/4.7/pc-bonuses-based-on-ees-topics');
  const bonusesPc = utrv?.value ?? 0;

  const reportData: BonusesDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    bonusesPc: bonusesPc,
  }

  const chartData = [
    ['Bonuses', 'Percentage'],
    ['Sustainable Goals', bonusesPc],
    ['Other goals', 100 - bonusesPc],
  ];

  return <Chart
    chartType='PieChart'
    data={chartData}
    width={WIDTH}
    height={HEIGHT}
    options={CHART_DEFAULTS.OPTIONS}
    chartEvents={[
      {
        eventName: 'ready',
        callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
          reportData.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
          updateData('bonuses', reportData);
        }
      }
    ]}
  />
})

export default Bonuses;
