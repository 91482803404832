import { Badge } from 'reactstrap';

interface VerifiedBadgeProps {
  isVerified: boolean;
}

export const VerifiedBadge = ({ isVerified }: VerifiedBadgeProps) => {
  return isVerified ? (
    <div className={'ml-2'}>
      <Badge
        className='fw-normal background-ThemeBgExtralight text-ThemeSuccessLight border border-ThemeSuccessLight'
        pill
      >
        verified
      </Badge>
    </div>
  ) : null;
};
