import { Button } from 'reactstrap';
import { CustomToastProps } from './CustomToast';

export const ToastBody = ({
  title,
  hasDismissButton = true,
  message,
  currentToast,
  buttonProps,
  color: themeColor,
  handleClose,
}: CustomToastProps) => {
  return (
    <div className='toast__body'>
      {!title && hasDismissButton ? (
        <div className='toast__message d-flex justify-content-between align-items-center'>
          <span>{message}</span>
          <Button color={'transparent'} className='btn-icon' onClick={() => handleClose(currentToast?.id)}>
            <i className='fa fa-times' />
          </Button>
        </div>
      ) : (
        <div className='toast__message mb-2'>{message}</div>
      )}

      {buttonProps
        ? buttonProps.map(({ color = themeColor, enforceRemove = false, text, handler, outline }, index) => {
            const clickHandler = () => {
              handler();
              if (enforceRemove && currentToast) {
                handleClose(currentToast?.id);
              }
            };
            return (
              <Button outline={outline} color={color} key={`${text}-${index}`} onClick={clickHandler}>
                {text}
              </Button>
            );
          })
        : null}
    </div>
  );
};
