import { useState } from 'react';
import { Input } from 'reactstrap';
import IconButton from '../../components/button/IconButton';
import { InsightDashboard } from '../../types/insight-custom-dashboard';

interface Props {
  title: InsightDashboard['title'];
  handleSave: (title: InsightDashboard['title']) => void;
  handleCancel: () => void;
}

export const TitleEditing = ({ title, handleSave, handleCancel }: Props) => {
  const [input, setInput] = useState(title);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    return setInput(e.currentTarget.value);
  }
  return (
    <>
      <Input type='text' value={input} onChange={handleChange} maxLength={50} />
      <IconButton
        disabled={input.length === 0}
        tooltip='Save'
        color='transparent'
        outline={false}
        onClick={() => handleSave(input)}
        icon='fa-light fa-check'
        className='text-ThemeIconSecondary ml-2'
      />
      <IconButton
        tooltip='Save'
        color='transparent'
        outline={false}
        onClick={handleCancel}
        icon='fa-light fa-xmark'
        className='text-ThemeIconSecondary ml-2'
      />
    </>
  );
};
