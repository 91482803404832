import { UniversalTrackerBlueprintMin, UtrValueType } from '@g17eco/types/universalTracker';

// types that doesn't include value list, table.
export const SIMPLE_TYPES: string[] = [
  UtrValueType.Number,
  UtrValueType.Percentage,
  UtrValueType.Date,
  UtrValueType.Text,
  UtrValueType.Sample,
];

export enum QuestionFilterType {
  Pack = 'pack',
  Subpack = 'subPack',
  Question = 'question',
  Input = 'input',
}

export type ValidatingFunc = (
  question: UniversalTrackerBlueprintMin,
  valueListCode?: string
) => {
  isAllowed: boolean;
  disableReason?: string;
};
