import React, { useEffect } from 'react'
import { DataSource } from '..';
import { GenerateChartsProps } from '../GenerateCharts';

export interface PoliciesDataSource extends DataSource {
  diversityPolicy?: boolean;
  mentalHealthPolicy?: boolean;
  briberyCorruptionPolicy?: boolean;
  sustainabilityReporting?: boolean;
  bonusesLinkedToSustainability?: boolean;
  humanTrafficingPolicy?: boolean;
}

const Policies = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  useEffect(() => {
    const utrv1 = questionData.find(d => d.universalTracker.code === 'survey/sdg/10.3/discrimination-policy');
    const utrv2 = questionData.find(d => d.universalTracker.code === 'survey/sdg/3.4/pc-investment-inform-public-risks-product');
    const utrv3 = questionData.find(d => d.universalTracker.code === 'survey/sdg/16.4/illicit-flows');
    const utrv4 = questionData.find(d => d.universalTracker.code === 'survey/generic/does-company-report-sustainable-development-risks');
    const utrv5 = questionData.find(d => d.universalTracker.code === 'survey/sdg/4.7/pc-bonuses-based-on-ees-topics');
    const utrv6 = questionData.find(d => d.universalTracker.code === 'survey/sdg/10.7/human-trafficking');


    const reportData: PoliciesDataSource = {
      loaded: true,
      diversityPolicy: utrv1?.valueData?.data === 'yes',
      mentalHealthPolicy: utrv2?.value === 100,
      briberyCorruptionPolicy: utrv3?.valueData?.data === 'yes',
      sustainabilityReporting: utrv4?.valueData?.data === 'yes',
      bonusesLinkedToSustainability: (utrv5?.value ?? 0) > 0,
      humanTrafficingPolicy: utrv6?.valueData?.data === 'yes',
    }
    updateData('policies', reportData);
  }, [questionData, updateData]);

  return <></>;
})

export default Policies;
