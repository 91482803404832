import { CustomToast, CustomToastProps } from './CustomToast';
import { toast } from 'react-hot-toast';
import { DURATION } from './constants';

interface ToastProps extends Omit<CustomToastProps, 'handleClose'> {
  duration?: number;
}
export const generateToast = (props: ToastProps) => {
  const handleClose = (id?: string) => toast.remove(id);
  return toast.custom((t) => <CustomToast {...props} currentToast={t} handleClose={handleClose} />, {
    duration: props.duration || DURATION.DEFAULT,
  });
};

export const generateErrorToast = ({ message }: { message: string }) => {
  return generateToast({
    color: 'danger',
    message,
  });
};
