import { createApi } from '@reduxjs/toolkit/query/react';
import { RequestScope } from '../actions/api';
import {
  BulkSurveysFormData,
  MergeOption,
  SurveyTemplate,
  SurveyTemplateMinData,
  TemplateHistory,
  TemplateTableStats,
} from '../components/survey-templates/types';
import { Action } from '../constants/action';
import { ApiResponse } from '../types/api';
import { axiosBaseQuery } from './axiosQuery';

type TemplateCreateMutationParams = Omit<
  SurveyTemplateMinData,
  '_id' | 'evidenceRequired' | 'noteRequired' | 'verificationRequired' | 'type'
> & {
  evidenceRequired?: boolean;
  noteRequired?: boolean;
  verificationRequired?: boolean;
};

type TemplateUpdateMutationParams = Partial<Omit<SurveyTemplateMinData, 'scope' | 'initiativeId'>>;

interface TemplateScopeMutationParams {
  templateId: string;
  action: Action;
  scopeGroups: RequestScope[];
}

interface BulkSurveysCreateMutationParams extends BulkSurveysFormData {
  effectiveDate: Date;
}

export const surveyTemplatesApi = createApi({
  reducerPath: 'surveyTemplatesApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['survey-templates'],
  endpoints: (builder) => ({
    getTemplates: builder.query<TemplateTableStats[], string>({
      transformResponse: (response: ApiResponse<TemplateTableStats[]>) => {
        return response.data;
      },
      query: (initiativeId) => ({
        url: `/initiatives/${initiativeId}/survey-templates/`,
        method: 'get',
      }),
      providesTags: ['survey-templates'],
    }),
    getTemplateById: builder.query<TemplateTableStats, string>({
      transformResponse: (response: ApiResponse<TemplateTableStats>) => response.data,
      query: (templateId) => ({
        url: `/survey-templates/${templateId}`,
        method: 'get',
      }),
      providesTags: ['survey-templates'],
    }),
    createTemplate: builder.mutation<ApiResponse<SurveyTemplate>, TemplateCreateMutationParams>({
      invalidatesTags: ['survey-templates'],
      query: (data) => ({
        url: '/survey-templates/',
        method: 'post',
        data,
      }),
    }),
    updateTemplate: builder.mutation<ApiResponse<SurveyTemplate>, TemplateUpdateMutationParams>({
      invalidatesTags: ['survey-templates'],
      query: (data) => {
        const { _id: templateId, ...rest } = data;
        return {
          url: `/survey-templates/${templateId}`,
          method: 'patch',
          data: rest,
        };
      },
    }),
    deleteTemplate: builder.mutation<ApiResponse<SurveyTemplate>, string>({
      invalidatesTags: ['survey-templates'],
      query: (templateId) => {
        return {
          url: `/survey-templates/${templateId}`,
          method: 'delete',
        };
      },
    }),
    duplicateTemplate: builder.mutation<ApiResponse<SurveyTemplate>, string>({
      invalidatesTags: ['survey-templates'],
      query: (templateId) => {
        return {
          url: `/survey-templates/${templateId}/duplicate`,
          method: 'post',
          data: { templateId },
        };
      },
    }),
    updateTemplateScope: builder.mutation<ApiResponse<SurveyTemplate>, TemplateScopeMutationParams>({
      invalidatesTags: ['survey-templates'],
      query: (data) => {
        const { templateId, ...rest } = data;
        return {
          url: `/survey-templates/${templateId}/scope`,
          method: 'patch',
          data: rest,
        };
      },
    }),
    bulkSurveysCreate: builder.mutation<ApiResponse<TemplateHistory>, BulkSurveysCreateMutationParams>({
      invalidatesTags: ['survey-templates'],
      query: (data) => ({
        url: `/survey-templates/${data._id}/bulk-surveys-create-job`,
        method: 'post',
        data,
      }),
    }),
    getMergeTags: builder.query<MergeOption[], any>({
      transformResponse: (response: ApiResponse<MergeOption[]>) => response.data,
      query: () => ({
        url: '/survey-templates/merge-tags',
        method: 'get',
      }),
      providesTags: ['survey-templates'],
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useGetTemplateByIdQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  useDuplicateTemplateMutation,
  useUpdateTemplateScopeMutation,
  useBulkSurveysCreateMutation,
  useGetMergeTagsQuery,
} = surveyTemplatesApi;
