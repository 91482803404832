import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules';
import { Filters } from './types';
import { DelegationRoles, SurveyDelegationUser, UserMin } from '@constants/users';
import { naturalSort } from '@utils/index';
import { QuestionStatuses } from '@constants/status';

const STATUS_OPTIONS = [
  { label: 'All metrics', value: 'all' },
  { label: 'Unanswered metrics only', value: QuestionStatuses.Created },
  { label: 'Answered metrics only', value: QuestionStatuses.Verified },
];

const explicitDelegationRoles = [DelegationRoles.UtrvStakeholder, DelegationRoles.UtrvVerifier];

const isExplicitDelegated = (delegationRoles: DelegationRoles[]) =>
  delegationRoles.some((role) => explicitDelegationRoles.includes(role));

const getDelegationOptions = (users: SurveyDelegationUser[]) => {
  const options: Option<string>[] = [
    { label: 'Metrics with explicit delegation', value: 'con-a' },
    { label: 'Metrics without explicit delegation', value: 'con-u' },
  ];

  if (users.length) {
    options.push(
      {
        label: 'Users',
        value: '',
        isDisabled: true,
      },
      ...users
        .filter((user) => user._id && isExplicitDelegated(user.delegationRoles))
        .sort((a, b) => naturalSort(`${a.firstName} ${a.surname}`, `${b.firstName} ${b.surname}`))
        .map((u) => {
          const fullName = `${u.firstName} ${u.surname}`;
          return {
            value: u._id,
            searchString: fullName,
            label: fullName,
          };
        })
    );
  }

  return options;
};
interface Props {
  filters: Filters;
  onChangeFilters: (key: keyof Filters, value?: string | string[]) => void;
  users: SurveyDelegationUser[];
}

export const AssessmentFilters = ({ filters, onChangeFilters, users }: Props) => {
  return (
    <div className='row'>
      <div className='col-6'>
        <SelectFactory
          className='w-100'
          selectType={SelectTypes.SingleSelect}
          options={STATUS_OPTIONS}
          placeholder='Status'
          value={STATUS_OPTIONS.find(({ value }) => value === filters.status)}
          onChange={(op) => onChangeFilters('status', op?.value)}
          isSearchable={false}
        />
      </div>
      <div className='col-6'>
        <SelectFactory<string>
          selectType={SelectTypes.MultipleSelect}
          placeholder='Delegation'
          options={getDelegationOptions(users)}
          onChange={(values) => onChangeFilters('delegation', values)}
          values={filters.delegation ?? []}
        />
      </div>
    </div>
  );
};
