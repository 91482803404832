import { DataPeriods } from '@g17eco/types/universalTracker';
import { getPeriodName } from '@utils/universalTracker';
import { Option } from '@g17eco/molecules';

export type PeriodOption = 'all' | DataPeriods;

export const getDefaultPeriod = (availablePeriods: DataPeriods[], fallback: DataPeriods | undefined = undefined) => {
  if (availablePeriods.length === 0) {
    return fallback;
  }
  switch (true) {
    case availablePeriods.includes(DataPeriods.Yearly):
      return DataPeriods.Yearly;
    case availablePeriods.includes(DataPeriods.Quarterly):
      return DataPeriods.Quarterly;
    case availablePeriods.includes(DataPeriods.Monthly):
      return DataPeriods.Monthly;
    default:
      return fallback;
  }
};

export const getPeriodOptionLabel = (period: PeriodOption) => {
  return period === 'all' ? 'All periods' : getPeriodName(period);
};

export const getSurveyPeriodOptions = <T extends PeriodOption>(hasAllOption: boolean): Option<T>[] => {
  const defaultOptions = [DataPeriods.Yearly, DataPeriods.Quarterly, DataPeriods.Monthly].map((period) => ({
    value: period,
    label: getPeriodOptionLabel(period),
  }));

  return (
    hasAllOption ? [{ value: 'all', label: getPeriodOptionLabel('all') }, ...defaultOptions] : defaultOptions
  ) as Option<T>[];
};
