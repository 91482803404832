export const isValidUrl = (value: string) => {
  if (!value) {
    return false;
  }
  const urlRegex = new RegExp(
    /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/g
  );
  const result = value && value.match(urlRegex);
  return result !== null;
};
