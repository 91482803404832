import { JobStatus } from '../types/background-jobs';
import { g17ecoApi, transformResponse } from './g17ecoApi';

enum AutoAggregatedSurveyTags {
  CanCreate = 'can-create-auto-aggregated-survey',
  Create = 'create-auto-aggregated-survey',
  Update = 'update-auto-aggregated-survey',
}

export const autoAggregatedSurveyApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: Object.values(AutoAggregatedSurveyTags),
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCreateAggregatedSurveyStatus: builder.query<{ canCreateAggregatedSurvey: boolean } | { surveyId: string }, string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `initiatives/${initiativeId}/aggregated-survey/auto-aggregate`,
          method: 'get',
        }),
        providesTags: [AutoAggregatedSurveyTags.CanCreate],
      }),
      createAutoAggregatedSurvey: builder.mutation<{ surveyId: string } | { jobId: string }, string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `initiatives/${initiativeId}/aggregated-survey/auto-aggregate`,
          method: 'post',
        }),
        invalidatesTags: [AutoAggregatedSurveyTags.Create],
      }),
      updateAutoAggregatedSurvey: builder.mutation<{ jobId: string }, string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `initiatives/${initiativeId}/aggregated-survey/auto-aggregate`,
          method: 'patch',
        }),
        invalidatesTags: [AutoAggregatedSurveyTags.Update],
      }),
      getAggregatedSurveyStatus: builder.query<{ status: JobStatus | string, jobId?: string }, string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `initiatives/${initiativeId}/aggregated-survey/auto-aggregate/status`,
          method: 'get',
        }),
        providesTags: [AutoAggregatedSurveyTags.Update],
      }),
    }),
  });

export const {
  useCreateAutoAggregatedSurveyMutation,
  useGetCreateAggregatedSurveyStatusQuery,
  useUpdateAutoAggregatedSurveyMutation,
  useGetAggregatedSurveyStatusQuery,
} = autoAggregatedSurveyApi;
