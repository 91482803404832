import { Button } from 'reactstrap';
import { getAnsweredUtrvs, getPackUsagePercentage } from '../../exchange-view/utils';
import { PACK, SURVEY } from '@constants/terminology';
import { BasicAlert, ColumnDef, Table } from '@g17eco/molecules';
import { ScopePackRowData } from '@g17eco/types/portfolio';
import { naturalSort } from '@utils/index';
import NumberFormat from '@utils/number-format';

interface PackUsageTableProps {
  packUsage: ScopePackRowData[] | undefined;
  handleClickScope: (scope: string) => void;
  isQuestionPage: boolean;
}

type ColumnProps = Pick<PackUsageTableProps, 'handleClickScope' | 'isQuestionPage'>;

const sortPercentageColumn = (a: ScopePackRowData, b: ScopePackRowData, type: keyof ScopePackRowData) => {
  const aValue = getPackUsagePercentage(a, type);
  const bValue = getPackUsagePercentage(b, type);
  return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
};

const getTableColumns = ({ handleClickScope, isQuestionPage }: ColumnProps): ColumnDef<ScopePackRowData>[] => [
  {
    id: 'code',
    accessorKey: 'code',
    header: () => (isQuestionPage ? 'Code' : `${SURVEY.CAPITALIZED_ADJECTIVE} ${PACK.SINGULAR}`),
    meta: {
      headerProps: {
        style: {
          left: 0,
          width: 200,
          minWidth: 200,
        },
      },
      cellProps: {
        className: 'text-left',
        style: {
          left: 0,
          width: 200,
          minWidth: 200,
        },
      },
      sticky: 'left'
    },
    cell: (c) => {
      const columnValue = c.row.original.name;
      const value = c.getValue() as string;
      return isQuestionPage ? (
        columnValue
      ) : (
        <Button color='link' className='text-left' onClick={() => handleClickScope(value)}>
          {columnValue}
        </Button>
      );
    },
  },
  {
    accessorKey: 'surveyCount',
    header: () => 'Uses',
    meta: {
      cellProps: {
        className: 'text-center',
      },
    }
  },
  {
    accessorKey: 'initiativeUniqueCount',
    header: () => 'Unique uses',
    meta: {
      cellProps: {
        className: 'text-center',
      },
    }
  },
  {
    accessorKey: 'created',
    header: () => 'Unanswered',
    meta: {
      cellProps: {
        className: 'text-center',
      },
    }
  },
  {
    accessorKey: 'updated',
    header: () => 'Answered',
    meta: {
      cellProps: {
        className: 'text-center',
      },
    },
    cell: (c) => {
      return getAnsweredUtrvs(c.row.original);
    },
    sortingFn: (a, b) => naturalSort(getAnsweredUtrvs(a.original).toString(), getAnsweredUtrvs(b.original).toString()),
  },
  {
    accessorKey: 'verified',
    header: () => '% Verified',
    meta: {
      cellProps: {
        className: 'text-center',
      },
    },
    cell: (c) => (
      <NumberFormat value={getPackUsagePercentage(c.row.original, 'verified')} decimalPlaces={1} suffix='%' />
    ),
    sortingFn: (a, b) => sortPercentageColumn(a.original, b.original, 'verified'),
  },
  {
    accessorKey: 'isPrivate',
    header: () => 'Private',
    meta: {
      cellProps: {
        className: 'text-center',
      },
    },
    cell: (c) => (
      <NumberFormat value={getPackUsagePercentage(c.row.original, 'isPrivate')} decimalPlaces={1} suffix='%' />
    ),
    sortingFn: (a, b) => sortPercentageColumn(a.original, b.original, 'isPrivate'),
  },
  {
    accessorKey: 'nr',
    header: () => 'NA/NR',
    meta: {
      cellProps: {
        className: 'text-center',
      },
    },
    cell: (c) => <NumberFormat value={getPackUsagePercentage(c.row.original, 'nr')} decimalPlaces={1} suffix='%' />,
    sortingFn: (a, b) => sortPercentageColumn(a.original, b.original, 'nr'),
  },
];

export default function PackUsageTable(props: PackUsageTableProps) {
  const { packUsage = [], handleClickScope, isQuestionPage = false } = props;

  return (
    <div className='table-wrapper'>
      <Table
        columns={getTableColumns({ handleClickScope, isQuestionPage })}
        data={packUsage}
        sticky
      />
      {packUsage?.length === 0 ? (
        <BasicAlert type='secondary' className='mt-2'>
          No data available for the selected {SURVEY.ADJECTIVE} {PACK.PLURAL}
        </BasicAlert>
      ) : null}
    </div>
  );
}
