export const DEFAULT_EXPAND_DEPTH = 0;
export const ANIMATION_EXPAND_DEPTH = 4;
export const REM_TO_PIXEL = 16;
export const SPACING = 5;
export const BUTTON_WIDTH = 30;
export const MINIMUM_DISTANCE = 50;

export const DURATION = 250;
export const MAX_CHAR = 25;
export const NODE_HEIGHT = '3rem';
export const HEIGHT_CENTRE_OFFSET = 20;
export const DISTANCE_TO_BUTTON = 50;

const NODE_SIZE_WIDTH = 70;
const NODE_SIZE_HEIGHT = MAX_CHAR * 15;
export const NODE_SIZE = [NODE_SIZE_WIDTH, NODE_SIZE_HEIGHT] as [number, number];

const NODE_INNER_SPACING = 2.5;
const WIDTH_PER_CHAR = 0.5;
// styled wrapper not the svg node width
export const NODE_HTML_WIDTH = `${MAX_CHAR * WIDTH_PER_CHAR + NODE_INNER_SPACING}rem`;

export const DROPDOWN_SIZE = {
  WIDTH: 100,
  OPTION_HEIGHT: 23,
};

export const LEVELCOLOURS = [
  '#2159B5',
  '#0E9381',
  '#C5D86D',
  '#E3B505',
  '#48BEFF',
  '#F1E8B8',
  '#A4B0F5',
  '#F2C57C',
];

export const ARCHIVED_COLOUR = '#DC3545';
export const ARCHIVED_BG = '#FCEBED';

export const SCALE_EXTENT = [0.2, 2] as [number, number];
