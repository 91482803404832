import IconButton from '@components/button/IconButton';
import React from 'react';
import { MediaData, MediaFile } from '@g17eco/types/insight-custom-dashboard';
import { checkIsVideo } from '@features/custom-dashboard/utils/dashboard-utils';

export const Preview = ({
  files,
  handleClickDelete,
}: Pick<MediaData, 'files'> & { handleClickDelete: (file: MediaFile) => void }) => {
  if (!files.length) {
    return null;
  }

  return (
    <>
      {files.map((file) => (
        <React.Fragment key={file.name}>
          {checkIsVideo(file) ? (
            <video key={file.name} src={file.url} controls className='rounded-1' />
          ) : (
            <img key={file.name} src={file.url} alt={file.name} className='rounded-1' />
          )}
          <div className='d-flex justify-content-between align-items-center mt-1'>
            <span className='text-ThemeIconSecondary text-truncate'>{file.name}</span>
            <IconButton
              color='transparent'
              outline={false}
              icon='fal fa-trash text-ThemeDangerMedium'
              onClick={() => handleClickDelete(file)}
              style={{ minWidth: '1rem' }}
            />
          </div>
        </React.Fragment>
      ))}
    </>
  );
};
