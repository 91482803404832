import { ScheduledDate } from '../../model/surveyData';
import { getDateTime, getFutureDate, isAfter, isBefore, isSame } from '../../utils/date';
import { ConfigFormData } from './types';

export const getScheduledDates = (items: ScheduledDate[] = []) => items.map((item) => item.date);

export const compareScheduledDates = (
  dates: ScheduledDate[] | undefined = [],
  compareDates: ScheduledDate[] | undefined = []
) => {
  if (dates.length === 0 && compareDates.length === 0) {
    return true; // Both empty therefore equal;
  }

  if (dates.length !== compareDates.length) {
    return false; // Different amount of items, must be different
  }

  const currentItems = getScheduledDates(dates);
  const compareItems = getScheduledDates(compareDates);
  return currentItems.every((date) => {
    const matchedCurrentItems = currentItems.filter((currentDate) => isSame(date, currentDate, 'day'));
    const matchedCompareItems = compareItems.filter((compareDate) => isSame(date, compareDate, 'day'));
    return matchedCurrentItems.length === matchedCompareItems.length // every date in currentItems must have same number of its exist in compareItems;
  });
};

export const isValidDeadlineScheduledDates = (form: Pick<ConfigFormData, 'deadlineDate' | 'scheduledDates'>) => {
  // Reminder earliest day should be tomorrow
  if (form.deadlineDate) {
    const minReminderDate = getDateTime(getFutureDate(1), { hour: 0, minute: 0, second: 0 });
    const maxReminderDate = getDateTime(form.deadlineDate, { hour: 24, minute: 59, second: 0 });
    if (form.scheduledDates && form.scheduledDates.length > 0) {
      return getScheduledDates(form.scheduledDates).every(
        (date) => !isBefore(minReminderDate, date) && !isAfter(maxReminderDate, date)
      );
    }
    return !isBefore(minReminderDate, form.deadlineDate);
  }

  return !form.scheduledDates || form.scheduledDates.length === 0;
};
