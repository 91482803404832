import { Button } from 'reactstrap';

interface TopMessageProps {
  canManage: boolean;
  isPortfolioTracker: boolean;
  showOrgMapAnimation: boolean;
  isLoading: boolean;
  handleInterestButton: () => Promise<void>;
}

export const TopMessage = (props: TopMessageProps) => {
  const {
    canManage,
    isPortfolioTracker,
    showOrgMapAnimation,
    isLoading,
    handleInterestButton,
  } = props;

  if (!canManage || isPortfolioTracker || !showOrgMapAnimation) {
    return null;
  }

  return (
    <div className='contact-us__wrapper'>
      <p className='text-center text-md'>Organisation map</p>
      <Button
        color='primary'
        className='d-flex align-items-center'
        onClick={() => handleInterestButton()}
        disabled={isLoading}
      >
        {isLoading ? <div className='spinner-border spinner-border-sm text-secondary mr-2' role='status'></div> : null}
        Contact us to find out more
      </Button>
    </div>
  );
};
