import { ChartSubType } from '@g17eco/types/insight-custom-dashboard';
import { ExtendedUtrVariable } from '@routes/custom-dashboard/types';
import { ChartData, isDashboardItem, isTempChartType } from '@routes/custom-dashboard/utils';
import { extractVariables } from '@utils/formula';
import { ChartItem } from '../types';

const isMultipleUtrsItem = (item: ChartItem) => {
  if ('calculation' in item && item.calculation && Object.values(item.calculation.values).length > 1) {
    return true;
  }
  return Boolean('variables' in item && item.variables && Object.values(item.variables).length > 1);
};

export const getChartData = (item: ChartItem): ChartData => {
  const subType = isMultipleUtrsItem(item) ? ChartSubType.Line : ChartSubType.SingleValue;
  if (isTempChartType(item)) {
    return { metrics: [], subType };
  }

  const commonFields = {
    type: item.type,
    metrics: [],
    subType,
  };

  if (!isDashboardItem(item)) {
    return commonFields;
  }

  if (!item.calculation) {
    return {
      ...commonFields,
      metrics: Object.values(item.variables),
      subType: item.subType,
      title: item.title ?? '',
    };
  }

  const metrics = item.calculation.values.reduce<ExtendedUtrVariable[]>((acc, value) => {
    let variables: ExtendedUtrVariable[] = [];
    if (value.formula) {
      variables = extractVariables(value.formula).reduce((acc, variableLetter) => {
        if (item.variables?.[variableLetter]) {
          acc.push({ ...item.variables[variableLetter], name: value.name });
        }
        return acc;
      }, [] as ExtendedUtrVariable[]);
    }
    // currently expect only one utr variable per calculation
    return acc.concat(variables);
  }, []);

  return {
    ...commonFields,
    metrics,
    subType: item.subType,
    title: item.title ?? '',
  };
};
