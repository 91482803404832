export const replaceItem = <T>(items: T[], newItem: T, index: number) => [
  ...items.slice(0, index),
  newItem,
  ...items.slice(index + 1),
];

export const isArraysEqual = <T extends string | number>(a: T[], b: T[]) => {
  if (a.length !== b.length) {
    return false;
  }

  const setA = new Set(a);
  const setB = new Set(b);
  if (setA.size !== setB.size) {
    return false;
  }

  return [...setA].every((item) => setB.has(item));
};