import React, { useEffect } from 'react'
import { DataSource } from '..';
import { GenerateChartsProps } from '../GenerateCharts';

export interface LivingWageDataSource extends DataSource {
  employeesPc?: number;
}

const LivingWage = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  useEffect(() => {
    const employeesUtr = questionData.find(d => d.universalTracker.code === 'survey/generic/pc-company-workforce-paid-national-living-wage');

    const reportData: LivingWageDataSource = {
      loaded: true,
      employeesPc: employeesUtr?.value ?? 0,
    }
    updateData('livingWage', reportData);
  }, [questionData, updateData]);

  return <></>;
})

export default LivingWage;
