import { AssessmentData, MaterialTopicCategories } from './api/materiality-assessment';

export interface AssessmentDataRow {
  name?: string;
  score: number;
  relativeScore: number | undefined;
  utrMapping: string;
  esgs: string;
  sdgs: string;
  materialPillars: string;
  materialBoundaries: string;
}

export enum CompanySettingsPages {
  Details = 'details',
  DefaultSurveyConfig = 'default-survey-config',
}

type AssessmentDataRowKey = keyof AssessmentDataRow;

const defaultCategories: MaterialTopicCategories = { boundary: [], esg: [], materialPillar: [], sdg: [] };

export const getResultByScoreDesc = (data: AssessmentData[]): AssessmentDataRow[] =>
  data
    .slice()
    .map((row) => {
      const { categories = defaultCategories, utrMapping = [], name, score } = row;
      return {
        name,
        score,
        relativeScore: row.relativeScore,
        utrMapping: utrMapping.map((utr) => utr.code).join(', '),
        esgs: (categories.esg ?? []).join(', '),
        sdgs: (categories.sdg ?? []).join(', '),
        materialPillars: (categories.materialPillar ?? []).join(', '),
        materialBoundaries: (categories.boundary ?? []).join(', '),
      };
    })
    .sort((a, b) => b.score - a.score);

export const assessmentDataColumnMap: { [key in AssessmentDataRowKey]: string } = {
  name: 'Material topic',
  score: 'Materiality',
  relativeScore: 'Materiality',
  utrMapping: 'Mapped UTR codes',
  esgs: 'ESGs',
  sdgs: 'SDGs',
  materialPillars: 'Material pillars',
  materialBoundaries: 'Material boundaries',
}
