import { FormGroup, Input, Label } from 'reactstrap';
import { TableEditorProps , TableData } from '../../types';
import { useState } from 'react';
import {
  MAX_LENGTH_TABLE_TITLE,
  getCalculationValues,
  getTableData,
  getUniqueUtrVariables,
  hasValidVariables,
} from '../../utils/table-utils';
import { CalculationType, TableSubType, TableWidgetColumnType } from '@g17eco/types/insight-custom-dashboard';
import { MultiRowTableInput } from './MultiRowTableInput';
import { transformVariables } from '@routes/custom-dashboard/utils';
import { EditorTemplate } from '../EditorTemplate';

type HiddenColumnType = TableWidgetColumnType.Unit | TableWidgetColumnType.Sparkline;

export const TableEditor = (props: TableEditorProps) => {
  const { editingItem, handleCancel, updateItem, initiativeId } = props;
  const [tableData, setTableData] = useState<TableData>(getTableData(editingItem));
  const [title, setTitle] = useState<string>('title' in editingItem && editingItem.title ? editingItem.title : '');
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    'config' in editingItem && editingItem.config?.hiddenColumns
      ? editingItem.config.hiddenColumns
      : [TableWidgetColumnType.Sparkline]
  );

  const isDisabled = tableData.rowData.length === 0 || !hasValidVariables(tableData.rowData);

  const getChecked = (key: HiddenColumnType) => !hiddenColumns.includes(key);

  const toggleDisplay = (key: HiddenColumnType) => {
    if (hiddenColumns.includes(key)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== key));
    } else {
      setHiddenColumns([...hiddenColumns, key]);
    }
  };

  const handleSubmit = async () => {
    const variables = transformVariables(getUniqueUtrVariables(tableData.rowData));
    const calculation = {
      type: CalculationType.Formula,
      values: getCalculationValues({ rowData: tableData.rowData, variables }),
    };
    const config = {
      hiddenColumns,
    };
    return updateItem({ ...editingItem, subType: TableSubType.Table, title, variables, calculation, config });
  };

  return (
    <EditorTemplate isDisabled={isDisabled} handleCancel={handleCancel} handleSubmit={handleSubmit}>
      <p className='text-medium mt-4 mb-1'>Table title</p>
      <Input
        type='text'
        className='text-md'
        maxLength={MAX_LENGTH_TABLE_TITLE}
        value={title ?? ''}
        onChange={(e) => setTitle(e.target.value)}
        placeholder='Table title (optional)'
      />
      <div className='mt-4'>
        <Label className='text-md text-ThemeTextDark' check>
          Display
        </Label>
        <div className='d-flex align-items-center mt-2 gap-4'>
          <FormGroup switch className='d-flex align-items-center text-muted gap-2'>
            <Input
              type='switch'
              role='switch'
              checked={getChecked(TableWidgetColumnType.Unit)}
              onChange={() => toggleDisplay(TableWidgetColumnType.Unit)}
            />
            <Label className='text-md text-ThemeTextDark' check>
              Units
            </Label>
          </FormGroup>
          <FormGroup switch className='d-flex align-items-center text-muted gap-2'>
            <Input
              type='switch'
              role='switch'
              checked={getChecked(TableWidgetColumnType.Sparkline)}
              onChange={() => toggleDisplay(TableWidgetColumnType.Sparkline)}
            />
            <Label className='text-md text-ThemeTextDark' check>
              Sparkline
            </Label>
          </FormGroup>
        </div>
      </div>
      <MultiRowTableInput initiativeId={initiativeId} tableData={tableData} setTableData={setTableData} />
    </EditorTemplate>
  );
};
