import { useDispatch } from 'react-redux';
import { SiteAlertColors, addSiteAlert as addSiteAlertAction, addSiteError as addSiteErrorAction } from '../slice/siteAlertsSlice';
import { useCallback } from 'react';

interface SiteAlert {
  content: string | JSX.Element;
  color?: SiteAlertColors;
  timeout?: number;
}

export const useSiteAlert = () => {
  const dispatch = useDispatch();

  const addSiteAlert = useCallback(({ content, color = SiteAlertColors.Success, timeout = 3000 }: SiteAlert) => {
    dispatch(
      addSiteAlertAction({
        content,
        color,
        timeout,
      })
    );
  }, [dispatch]);

  const addSiteError = useCallback((content: Pick<SiteAlert, 'content'> | Error | string) => {
    return dispatch(addSiteErrorAction(content))
  }, [dispatch]);

  return { addSiteAlert, addSiteError };
}
