import config from '../../../../config';

export const KyndrylFeatureImg = `${config.media.imagesBaseUrl}/home/feature-4.png`;
export const CyberQFeatureImg = `${config.media.imagesBaseUrl}/home/feature-5.png`;
export const KyndrylLogo = `${config.media.imagesBaseUrl}/home/kyndryl.png`;
export const CyberQLogo = `${config.media.imagesBaseUrl}/home/cyberq.png`;
export const OneClickLogo = `${config.media.imagesBaseUrl}/home/oneclick.png`;
export const WorldWideTechnologyLogo = `${config.media.imagesBaseUrl}/home/worldwidetechnology.png`;
export const CodeEngineStudioLogo = `${config.media.imagesBaseUrl}/home/codeenginestudio.png`;
export const AnchorImg1 = `${config.media.imagesBaseUrl}/home/anchor-1.png`;
export const AnchorImg2 = `${config.media.imagesBaseUrl}/home/anchor-2.1.png`;
export const AnchorImg3 = `${config.media.imagesBaseUrl}/home/anchor-3.png`;
export const AnchorImg4 = `${config.media.imagesBaseUrl}/home/anchor-4.png`;
export const AnchorImg5 = `${config.media.imagesBaseUrl}/home/anchor-5.png`;
export const CavendishLogo = `${config.media.imagesBaseUrl}/home/cavendish.png`;
export const FusangLogo = `${config.media.imagesBaseUrl}/home/fusang_logo.png`;
