import { SafeUser } from '../constants/users';
import { MinReportingLevel } from '../routes/initiative-structure/types';
import { ApiResponse } from '../types/api';
import { InitiativePlain } from '../types/initiative';
import { g17ecoApi, transformResponse } from './g17ecoApi';

export type OrgMapUser = Pick<SafeUser, '_id' | 'surname' | 'firstName'>;

interface OrgMapQueryParams {
  initiativeId: string;
}
export interface ArchiveSubsidiaryMutationParams {
  initiativeId: string;
  reassignedInitiativeId?: string;
  reassignedUserIds: string[];
  removedUserIds: string[];
}

export interface ReactivateSubsidiaryMutationParams {
  initiativeId: string;
  archivedInitiativeId: string;
}

export const orgMapApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['org-map-users', 'org-map-archived-initiatives'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCurrentAndDescendantUsers: builder.query<OrgMapUser[], OrgMapQueryParams>({
        transformResponse,
        query: ({ initiativeId }) => {
          return {
            url: `initiatives/${initiativeId}/map/users`,
            method: 'get',
          };
        },
        providesTags: ['org-map-users'],
      }),
      archiveSubsidiary: builder.mutation<ApiResponse<MinReportingLevel>, ArchiveSubsidiaryMutationParams>({
        invalidatesTags: ['org-map-users', 'org-map-archived-initiatives'],
        query: ({ initiativeId, ...data }) => ({
          url: `initiatives/${initiativeId}/archive`,
          method: 'patch',
          data,
        }),
      }),
      getArchivedSubsidiaries: builder.query<InitiativePlain[], OrgMapQueryParams>({
        transformResponse,
        query: ({ initiativeId }) => {
          return {
            url: `initiatives/${initiativeId}/map/archived-initiatives`,
            method: 'get',
          };
        },
        providesTags: ['org-map-archived-initiatives'],
      }),
      reactivateSubsidiary: builder.mutation<ApiResponse<MinReportingLevel>, ReactivateSubsidiaryMutationParams>({
        invalidatesTags: ['org-map-archived-initiatives'],
        query: ({ initiativeId, ...data }) => ({
          url: `initiatives/${initiativeId}/reactivate`,
          method: 'patch',
          data,
        }),
      }),
    }),
  });

export const {
  useGetCurrentAndDescendantUsersQuery,
  useArchiveSubsidiaryMutation,
  useGetArchivedSubsidiariesQuery,
  useReactivateSubsidiaryMutation,
} = orgMapApi;
