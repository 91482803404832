import { useEffect, useState } from 'react';
import UniversalTracker from '@models/UniversalTracker';
import { Addon } from '@components/survey/form/input/InputProps';
import { AssuranceStatus, PartialAssuranceField, UniversalTrackerValueAssurance } from '@g17eco/types/assurance';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { PartialAssurance } from '@g17eco/molecules';
import { UtrValueType } from '@g17eco/types/universalTracker';
import { getTableConfiguration, isNumberOrTextValueListType, isSingleRowTableType } from '@utils/universalTracker';
import { UtrvStatus } from '@constants/status';

export const usePartialAssurance = (
  utr: UniversalTracker,
  utrv: SurveyModelMinimalUtrv,
  assuranceUtrv: UniversalTrackerValueAssurance | undefined
) => {
  const [inputPartialFields, setInputPartialFields] = useState<PartialAssuranceField[]>([]);
  const columns = getTableConfiguration(utr)?.columns ?? [];

  // Preset selected fields
  useEffect(() => {
    setInputPartialFields(assuranceUtrv?.partialFields ?? []);
  }, [assuranceUtrv]);

  const handlePartiallyAssuredValueList = (code: string) => {
    const isExisted = inputPartialFields.some((f) => f.code === code);

    if (isExisted) {
      setInputPartialFields((currentFields) => currentFields.filter((f) => f.code !== code));
      return;
    }
    setInputPartialFields((currentFields) => [...currentFields, { code }]);
  }

  const handlePartiallyAssuredSingleRowTable = (code: string) => {
    const isExisted = inputPartialFields.some((f) => f.code === code && f.rowIndex === 0);

    if (isExisted) {
      setInputPartialFields((currentFields) => currentFields.filter((f) => f.code !== code));
      return;
    }
    setInputPartialFields((currentFields) => [...currentFields, { code, rowIndex: 0 }]);
  }

  const isMultiRowTableSelected = (rowIndex: number) => {
    return columns.every((column) => inputPartialFields.some((f) => f.rowIndex === rowIndex && f.code === column.code));
  }

  const handlePartiallyAssuredMultiTable = (rowIndex: number) => {
    if (isMultiRowTableSelected(rowIndex)) {
      setInputPartialFields((currentFields) => currentFields.filter((f) => f.rowIndex !== rowIndex));
      return;
    }
    const newFields = columns.map((column) => ({ code: column.code, rowIndex }));
    setInputPartialFields((currentFields) => [...currentFields, ...newFields]);
  }

  const getAddons = (): Addon[] => {
    if (utrv.status !== UtrvStatus.Verified || assuranceUtrv?.status === AssuranceStatus.Completed) {
      return [];
    }

    if (isNumberOrTextValueListType(utr)) {
      const data = utrv.valueData?.data ?? {};
      return Object.keys(data).map((code: string) => {
        const checked = inputPartialFields.some((f) => f.code === code);
        return {
          code,
          element: PartialAssurance({
            checked,
            handleChange: () => handlePartiallyAssuredValueList(code),
          }),
        };
      });
    }

    if (isSingleRowTableType(utr)) {
      return columns.map(({ code }) => {
        const checked = inputPartialFields.some((f) => f.code === code);
        return {
          code,
          rowIndex: 0,
          element: PartialAssurance({
            checked,
            handleChange: () => handlePartiallyAssuredSingleRowTable(code),
          }),
        };
      });
    }

    if (utr.getValueType() === UtrValueType.Table) {
      const rows = utrv.valueData?.table ?? [];
      return rows.map((_, rowIndex) => {
        return {
          // Dont need code because we already checked all columns
          code: '',
          rowIndex: rowIndex,
          element: PartialAssurance({
            checked: isMultiRowTableSelected(rowIndex),
            classes: { wrapper: '' },
            handleChange: () => handlePartiallyAssuredMultiTable(rowIndex),
          }),
        };
      });
    }

    return [];
  };

  const addons = getAddons();
  const checkSelectingAll = () => {
    // MultiRowTable check for every cells;
    if (utr.getValueType() === UtrValueType.Table && !isSingleRowTableType(utr)) {
      const rows = utrv.valueData?.table ?? [];
      return rows.every((_, rowIndex) => isMultiRowTableSelected(rowIndex));
    }

    return inputPartialFields.length === addons.length;
  };
  const checkSelectedSameFields = () => {
    if (!assuranceUtrv?.partialFields || inputPartialFields.length !== assuranceUtrv.partialFields.length) {
      return false;
    }

    return inputPartialFields.every(inputField => {
      return assuranceUtrv.partialFields?.some(f => {
        // if the partial field belong to table then compare both code and rowIndex
        const sameRowIndex = inputField.rowIndex ? inputField.rowIndex === f.rowIndex : true;
        return inputField.code === f.code && sameRowIndex;
      });
    })
  }

  return {
    partialFields: inputPartialFields,
    addons,
    isSelectingAll: checkSelectingAll(),
    isSelectedSameFields: checkSelectedSameFields(),
    handlePartiallyAssuredValueList,
    handlePartiallyAssuredSingleRowTable,
    handlePartiallyAssuredMultiTable,
  };
};
