export function isDefinedNumber<T = number>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null && value !== '' && !isNaN(value as number);
}

export const generateArrayOfNumbers = (start: number, length: number) => Array.from({ length }, (_, i) => i + start);

export const isNumeric = (n: unknown): n is number => !isNaN(parseFloat(String(n))) && isFinite(Number(n));

export const getDecimalNumber = (value: string | number | undefined | null, decimal: number = 0) => {
  if (!isDefinedNumber(value)) {
    return '';
  }
  return Number.parseFloat(String(value)).toFixed(decimal);
};

export const getDecimalAsNumber = (value: string | number | undefined | null, decimal: number = 0) => {
  const decimalValue = getDecimalNumber(value, decimal);
  return decimalValue ? Number.parseFloat(decimalValue) : decimalValue;
}

const DEFAULT_DECIMAL_PLACES = 2;

const DIGITS_ONLY_PATTERN = '^\\d+$';

export const getDisplayDecimalNumber = (value: number | undefined | null, decimal: number = DEFAULT_DECIMAL_PLACES) => {
  if (!isDefinedNumber(value)) {
    return '';
  }
  return value.toLocaleString('en-GB', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
};

export const getDecimalPattern = (decimal: number | undefined) => (decimal ? `^\\d+\\.\\d{${decimal}}$` : DIGITS_ONLY_PATTERN);
