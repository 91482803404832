import * as d3 from 'd3';
import { FlextreeNode } from 'd3-flextree';
import { ChildrenTree } from '../../types/tree';

export interface InitiativeDatum extends Pick<ChildrenTree, 'id' | 'disabled' | 'name' | 'tags' | 'parentId'> {
  x?: number;
  y?: number;
  x0?: number;
  y0?: number;
  children?: InitiativeDatum[];
}

export interface HierarchyNode extends d3.HierarchyNode<InitiativeDatum> {
  x?: number;
  y?: number;
  x0?: number;
  y0?: number;
  _children?: HierarchyNode[];
}

export interface TreeNode extends FlextreeNode<InitiativeDatum> {
  x0: number;
  y0: number;
  _children?: TreeNode[];
}

export type SelectionTreeNode = d3.Selection<SVGGElement, TreeNode, SVGElement, InitiativeDatum>;
export type SelectionTreeNodePath = d3.Selection<SVGPathElement, TreeNode, SVGElement, InitiativeDatum>;
export type SelectionSVG = d3.Selection<SVGElement, InitiativeDatum, null, undefined>;

export type StepPathNode = Pick<TreeNode, 'x' | 'y'> & Partial<Pick<TreeNode, 'children' | 'data'>>;

export interface SelectedInitiative {
  initiativeId: string;
  name?: string;
}

export enum OrgMapModal {
  AddSubsidiary = 'add',
  EditSubsidiary = 'edit',
  MoveSubsidiary = 'move',
  ArchiveSubsidiary = 'archive',
}

export interface MinReportingLevel {
  initiativeId: string;
  name: string;
}
