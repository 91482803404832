import React, { useState } from 'react';
import { ButtonProps } from 'reactstrap';
import ButtonWithLoader from './ButtonWithLoader';


type ClickEvent = React.MouseEvent<any>;
interface SubmitButtonProps extends ButtonProps {
  onClick?: (e: ClickEvent) => Promise<any>;
  children?: JSX.Element | string;
}

const defaultProps: Partial<SubmitButtonProps> = {
  outline: false,
  color: 'primary',
  block: false,
};

export function SubmitButton(props: SubmitButtonProps) {
  const { onClick, children, ...rest } = { ...defaultProps, ...props };

  const [isLoading, setLoading] = useState(false);

  const onClickHandler = async (e: ClickEvent) => {
    if (isLoading || !onClick) {
      return;
    }
    setLoading(true);
    await onClick(e);
    setLoading(false)
  };

  return <ButtonWithLoader loading={isLoading} onClick={onClickHandler} color={rest.color ?? 'primary'} {...rest}>
    {children || 'Submit'}
  </ButtonWithLoader>
}


