import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadIndustrySectors } from '../../actions/common';
import { useAppSelector } from '../../reducers';
import { getIndustryOptions, getSectorOptions } from './utils';

export const useIndustrySector = (selectedSectorText: string | undefined) => {
  const dispatch = useDispatch();
  const industrySectors = useAppSelector((state) => state.industrySectors.data) || [];

  useEffect(() => {
    dispatch(loadIndustrySectors());
  }, [dispatch]);

  const sectorOptions = getSectorOptions(industrySectors);
  const industryOptions = getIndustryOptions(industrySectors, selectedSectorText);

  return { sectorOptions, industryOptions };
}