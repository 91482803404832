import { RouteErrorBoundary } from '@features/error';

export const RouteContainer = ({ children }: React.PropsWithChildren<any>) => {
  return (
    <div className={'root-container h-100 position-relative overflow-hidden'}>
      <div className='main-container d-flex flex-column'>
        <RouteErrorBoundary>{children}</RouteErrorBoundary>
      </div>
    </div>
  );
};
