import { useRouteMatch } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ROUTES } from '@constants/routes';
import { generateUrl } from '@routes/util';
import { runConfetti } from '@utils/confetti';
import { SURVEY } from '@constants/terminology';

interface CompleteSurveyModalProps {
  isOpen: boolean;
  toggle: () => void;
  completeSurveyCallback: () => Promise<void> | undefined;
}

export const CompleteSurveyModal = ({ isOpen, toggle, completeSurveyCallback }: CompleteSurveyModalProps) => {
  const match = useRouteMatch<{ initiativeId?: string }>();
  const handleComplete = () => {
    runConfetti();
    toggle();
    return completeSurveyCallback();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Mark {SURVEY.SINGULAR} as complete</ModalHeader>
      <ModalBody>
        <p>
          Mark {SURVEY.SINGULAR} complete when all metrics are submitted and no further changes are expected. Locks{' '}
          {SURVEY.SINGULAR} to prevents accidental changes, or {SURVEY.SINGULAR} updates. Can be reversed if you need to
          make changes.
        </p>
        <p className='mb-0'>
          Note: Portfolio Tracker users, that you have approved, will have access to data. Check data sharing in Admin
          tab, or <a href={generateUrl(ROUTES.DATA_SHARE_INITIATIVE, match.params)}>here</a>.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' className='mr-3' onClick={toggle}>
          Cancel
        </Button>
        <Button color='success' onClick={handleComplete} data-testid='survey-confirm-complete-btn'>
          Mark as complete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
