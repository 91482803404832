import { DataScopeAccess } from '../../../../types/dataShare';
import { InitiativeCompany } from '../../../../types/initiative';
import {
  SubmissionInsightsUtr,
  SubmissionInsightsUtrv,
  SubmissionInsightsRowValues,
} from '../../../../types/portfolio';
import { getViewAccess } from '../../exchange-view/utils';
import { MetricView, SubmissionInsightsView } from './SubmissionInsightsContainer';

export const getViewOptions = () => {
  return [
    {
      value: SubmissionInsightsView.Company,
      searchString: 'Companies',
      label: <span>Companies</span>,
    },
    {
      value: SubmissionInsightsView.Sector,
      searchString: 'Sector',
      label: <span>Sector</span>,
    },
  ];
};

export const getDisplayColumn = ({
  utr,
  alternativeCode,
}: {
  utr: SubmissionInsightsUtr;
  alternativeCode?: string;
}) => {
  if (!alternativeCode || alternativeCode === utr.type) {
    return utr.name || '';
  }
  return utr.alternatives && utr.alternatives[alternativeCode] ? utr.alternatives[alternativeCode].name : utr.name || '';
};

export const getCellUtrv = (utrvs: SubmissionInsightsUtrv[], utr: SubmissionInsightsUtr) => {
  const utrv = utrvs.find((utrv) => utr._id === utrv.universalTrackerId);
  if (utrv) {
    return utrv;
  }
};

export const getDynamicRowValues = (
  rowValue: SubmissionInsightsRowValues,
  utrs: SubmissionInsightsUtr[],
  metricView: MetricView
) => {
  return utrs.map((utr) => {
    const utrv = getCellUtrv(rowValue.utrvs, utr);
    if (utrv) {
      return utrv[metricView];
    }
    return '-';
  });
};

export const getClassificationsTextArray = (rowValue: SubmissionInsightsRowValues) => {
  return [
    rowValue.industryText ?? '-',
    rowValue.subSectorText ?? '-',
    rowValue.sectorText ?? '-',
    rowValue.superSectorText ?? '-',
  ];
};

export const hasDataShare = (
  companies: Pick<InitiativeCompany, '_id' | 'name' | 'type' | 'requestedDataShares'>[]
) => {
  return companies.some((company) => getViewAccess(company) !== DataScopeAccess.None);
};
