import { frameworks, standards } from '@g17eco/core';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { RequestScope } from '../../../actions/api';
import { loadCustomMetricGroupsByInitiativeId } from '../../../actions/initiative';
import { useAppSelector } from '../../../reducers';
import { FilterSurveyPacks } from '../benchmarking/partials/FilterSurveyPacks';
import DraggableColumns from './partials/draggable-columns';
import FixedColumns from './partials/fixed-columns';
import GeneralColumns from './partials/general-columns';
import {
  addOffset,
  ALL_METRICS_CODE,
  Column,
  ColumnType,
  GENERAL_COLUMNS,
  getDynamicColumns,
  sortColumns,
} from './utils';
import { CustomSelectOption } from '../../select-packs';
import { PACK, SURVEY } from '@constants/terminology';
import { RequestScopeExtended } from '@utils/requestScope';

export const KEY_PORTFOLIO_EXCHANGE_COLUMNS_SETTING = 'portfolio_exchange_columns_setting';

interface CustomiseColumnsModalProps {
  portfolioId: string;
  columnsSetting: Column[];
  updateColumnsSetting: (columns: Column[]) => void;
  isOpen: boolean;
  toggle: () => void;
}

const allMetricOption: RequestScopeExtended = {
  code: ALL_METRICS_CODE,
  scopeType: ALL_METRICS_CODE,
  name: 'All Metrics',
};

export const CustomiseColumnsModal = (props: CustomiseColumnsModalProps) => {
  const { columnsSetting, updateColumnsSetting, isOpen, toggle, portfolioId } = props;

  const [selectedColumns, setSelectedColumns] = useState<Column[]>([]);
  const updateColumns = (columns: Column[]) => {
    setSelectedColumns(addOffset(columns));
  }

  useEffect(() => {
    setSelectedColumns(columnsSetting);
  }, [columnsSetting, isOpen]);

  const dispatch = useDispatch()
  const { data: metricGroups } = useAppSelector((state) => state.customMetricGroups);
  const groupOptions = metricGroups.reduce((acc, cur) => { acc[cur._id] = { name: cur.groupName, src: cur.groupData?.icon }; return acc }, {} as CustomSelectOption)
  useEffect(() => {
    dispatch(loadCustomMetricGroupsByInitiativeId(portfolioId))
  }, [dispatch, portfolioId]);

  const [openGeneralInsights, setOpenGeneralInsights] = useState(false);
  const toggleOpenGeneralInsights = () => setOpenGeneralInsights((prev) => !prev);
  const [openPacks, setOpenPacks] = useState(false);
  const toggleOpenPacks = () => setOpenPacks((prev) => !prev);

  const handleSubmit = () => {
    updateColumnsSetting(selectedColumns);
    toggle();
  }

  const handleResetDefault = () => {
    updateColumns(GENERAL_COLUMNS);
  };

  const handleSurveyPacks = (options: RequestScope[]) => {
    // new options will push to the last offsets
    const newOption = options.find((opt) => selectedColumns.every((item) => item.code !== opt.code))?.code;
    if (newOption) {
      const newColumn = newOption === ALL_METRICS_CODE ? {
        name: allMetricOption.name,
        code: allMetricOption.code,
        type: ColumnType.Custom,
      } : {
        name: standards[newOption]?.name ?? frameworks[newOption]?.name ?? groupOptions[newOption]?.name ?? newOption,
        code: newOption,
        type: ColumnType.SurveyPack,
      }
      updateColumns([...selectedColumns, newColumn]);
      return;
    }

    const newColumns = selectedColumns.reduce((acc, col) => {
      if (
        [ColumnType.SurveyPack, ColumnType.Custom].includes(col.type) &&
        options.every((opt) => opt.code !== col.code)
      ) {
        return acc;
      }
      return [...acc, col];
    }, [] as Column[]);
    updateColumns(newColumns);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop='static' className='modal-customise-columns'>
      <ModalHeader toggle={toggle}>Customise table view</ModalHeader>
      <ModalBody className='text-black'>
        <h5 className='mt-0 mb-4'>Columns</h5>
        <FixedColumns />

        {selectedColumns.length > 0 ? (
          <>
            <div className='divider' />
            <DraggableColumns columns={sortColumns(selectedColumns)} updateColumns={updateColumns} />
          </>
        ) : null}

        <div className='divider' />
        <div className='w-100'>
          <Button
            color='link-secondary'
            className={classNames('d-inline-flex align-items-center text-sm text-decoration-none', {
              'collapse-opened': openGeneralInsights,
            })}
            onClick={() => toggleOpenGeneralInsights()}
          >
            <span>General insights</span>
            <i className='fa-light fa-angle-right ml-2 text-sm'></i>
          </Button>
          <Collapse isOpen={openGeneralInsights} className='w-100'>
            <GeneralColumns columns={selectedColumns} updateColumns={updateColumns} />
          </Collapse>
        </div>
        <div className='mt-3'>
          <Button
            color='link-secondary'
            className={classNames('d-inline-flex align-items-center text-sm text-decoration-none mb-3', {
              'collapse-opened': openPacks,
            })}
            onClick={() => toggleOpenPacks()}
          >
            <span>Standards/{SURVEY.CAPITALIZED_ADJECTIVE} {PACK.PLURAL}</span>
            <i className='fa-light fa-angle-right ml-2 text-sm'></i>
          </Button>
          <Collapse isOpen={openPacks}>
            <div className='wrapper-survey-packs'>
              <FilterSurveyPacks
                selectedScopes={getDynamicColumns(selectedColumns).map((col) => col.code)}
                handleSurveyPackFilter={handleSurveyPacks}
                extraOptions={[allMetricOption]}
              />
            </div>
          </Collapse>
        </div>
      </ModalBody>
      <ModalFooter className='justify-content-between'>
        <Button className='btn-reset-default' onClick={handleResetDefault}>
          Reset to default
        </Button>
        <Button color='link-secondary' className='ml-auto' onClick={toggle}>
          Cancel
        </Button>
        <Button color='primary' className='ml-3' onClick={handleSubmit}>
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
