import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useMemo, useState } from 'react';
import G17Client from '@services/G17Client';
import { useSiteAlert } from '@hooks/useSiteAlert';
import Loader from '@components/loader';
import { FileDropzoneVariant, ImportFileDropzone, MINE_TYPES } from '@components/files/ImportFileDropZone';
import {
  useBulkOnboardEmailsMutation,
  useValidateImportFileMutation,
  ValidatedResult,
} from '@api/bulk-onboarding-import';
import { ReviewData, ReviewDataProps } from './ReviewData';
import { BasicAlert } from '@g17eco/molecules';

const defaultValidatedState: ValidatedResult = {
  validatedData: [],
};

const defaultUploadState = {
  data: defaultValidatedState,
  file: undefined,
};

const ReviewFileData = (props: ReviewDataProps) => {
  const { validatedData, userLimit } = props;
  if (validatedData.length) {
    return (
      <>
        {validatedData.length > userLimit ? (
          <BasicAlert type='warning' className='mb-2'>
            You have reached the user limit.
          </BasicAlert>
        ) : null}
        <ReviewData {...props} />
      </>
    );
  }

  return <p>No valid users found for import</p>;
};

interface Props {
  isOpen: boolean;
  toggle: () => void;
  initiativeId: string;
  userLimit: number;
}

export const BulkOnboardingModal = ({ isOpen, toggle, initiativeId, userLimit }: Props) => {
  const [validateImportFile, { isLoading: isUploading }] = useValidateImportFileMutation();
  const [bulkOnboardEmails, { isLoading: isProcessing }] = useBulkOnboardEmailsMutation();

  const { addSiteAlert, addSiteError } = useSiteAlert();
  const [uploadData, setUploadData] = useState<{ data: ValidatedResult; file: File | undefined }>(defaultUploadState);
  const { data, file } = uploadData;
  const validRowCount = useMemo(
    () => (data ? data.validatedData.filter((row) => row.validPermissions.length) : []),
    [data]
  ).length;

  const isLoading = isUploading || isProcessing;
  const canSubmit = !isLoading && validRowCount > 0;

  const handleClose = () => {
    setUploadData({ data: defaultValidatedState, file: undefined });
    toggle();
  };

  const onDownLoadTemplate = () => window.open(G17Client.getBulkOnboardingImportTemplate(initiativeId));

  const onFilesAdded = (files: File[]) => {
    const inputFile: File | undefined = files[0];
    if (!inputFile) {
      setUploadData(defaultUploadState);
      return;
    }
    validateImportFile({ initiativeId, data: { file: inputFile } })
      .unwrap()
      .then((result) => {
        setUploadData({ data: result, file: inputFile });
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  const onFilesRemoved = () => {
    setUploadData(defaultUploadState);
  };

  const handleImport = () => {
    if (!file) {
      return;
    }
    bulkOnboardEmails({ initiativeId, data: { file: file } })
      .unwrap()
      .then(() => {
        addSiteAlert({ content: `Invite ${validRowCount} users successfully` });
        handleClose();
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} backdrop='static' returnFocusAfterClose={false} size='lg'>
      <ModalHeader toggle={handleClose}>Invite multiple users</ModalHeader>
      <ModalBody>
        {isLoading ? <Loader /> : null}
        <div>
          <span>
            Bulk invite multiple users to the various reporting levels across your organisation. Before uploading please
            ensure:
          </span>
          <ul className='pl-4'>
            <li>
              You have the Excel template (
              <Button size='sm' color='link' className='p-0 mx-1 border-0' onClick={onDownLoadTemplate}>
                Download template here
              </Button>
              )
            </li>
            <li>You have filled it in with your user’s details</li>
          </ul>
        </div>
        <ImportFileDropzone
          variant={FileDropzoneVariant.Icon}
          accept={MINE_TYPES.EXCEL}
          importFile={file}
          onFilesAdded={onFilesAdded}
          onFileRemove={onFilesRemoved}
          hint='xlsx or csv file (max. 50 megabytes)'
          classes={{ wrapper: 'py-2' }}
        />
        <hr />
        {file && !isUploading ? (
          <ReviewFileData {...data} validRowCount={validRowCount} userLimit={userLimit} />
        ) : (
          <p>Once you have uploaded your file, a confirmation screen will be displayed for review.</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button color='primary' disabled={isLoading || !canSubmit} onClick={handleImport}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};
