import { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Label } from 'reactstrap';
import { CustomMetricOrderType, CustomMetricsUsage, MetricGroup } from '../../types/metricGroup';
import { DashboardDivider } from '../dashboard';
import { CustomMetricContext } from './CustomMetricContainer';
import { EditMetricModal, EditMetricModalProps } from './EditMetricModal';
import MetricForm, { MetricFormProps } from './SearchMetricsTable';
import { useSearchDebounceWithFlexMap } from '../../hooks/useSearchDebounceWithFlexMap';
import { PACK, QUESTION, SURVEY } from '@constants/terminology';
import { CustomControl, LoadingPlaceholder, Option, SelectFactory, SelectTypes } from '@g17eco/molecules';
import { SingleValue } from 'react-select';
import { DraggableMetricTable, DraggableMetricTableProps } from './DraggableMetricTable';
import { useUpdateMetricGroupOrderTypeMutation } from '@api/metric-groups';
import { OrderingDirection } from '@g17eco/types/common';
import { useDispatch } from 'react-redux';
import { reloadCustomMetricGroupsByInitiativeId } from '@actions/initiative';
import { BlockingLoader } from '@components/loader/BlockingLoader';
import { addSiteError } from '@g17eco/slices/siteAlertsSlice';
import { sortCustomGroupQuestionPlains } from '@utils/metricGroup';

interface QuestionsAssignmentTableProps {
  metricGroup: MetricGroup;
  readOnly: boolean;
  canEdit: boolean;
  editQuestionUrl: string;
  assignQuestionsUrl: string;
  handleReload: () => Promise<void>;
  customMetricsUsage: CustomMetricsUsage;
}

const orderTypeOptions = [
  {
    label: 'Title',
    value: CustomMetricOrderType.Name,
  },
  {
    label: 'Code',
    value: CustomMetricOrderType.TypeCode,
  },
  {
    label: 'Custom order',
    value: CustomMetricOrderType.Custom,
  },
];

const defaultMetricsOrder = {
  orderType: CustomMetricOrderType.Name,
  direction: OrderingDirection.Asc,
};

export const QuestionsAssignmentTable = (props: QuestionsAssignmentTableProps) => {
  const {
    metricGroup,
    readOnly,
    canEdit,
    editQuestionUrl,
    assignQuestionsUrl,
    handleReload,
    customMetricsUsage,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [editUtr, setEditUtr] = useState<string | undefined>();
  const [createMetric, setCreateMetric] = useState(false);
  const [orderType, setOrderType] = useState(metricGroup.metricsOrder?.orderType || CustomMetricOrderType.Name);
  const orderedUtrIds = metricGroup.universalTrackers;

  const { blueprintQuestions } = useContext(CustomMetricContext);
  const [updateOrderType, { isLoading }] = useUpdateMetricGroupOrderTypeMutation();

  const orderedQuestions = useMemo(() => {
    const { universalTracker, metricsOrder = defaultMetricsOrder } = metricGroup;
    return sortCustomGroupQuestionPlains(universalTracker, {
      metricsOrder,
      preferredAltCodes: metricGroup.groupData?.preferredAltCodes,
      universalTrackerIds: orderedUtrIds,
    });
  }, [metricGroup, orderedUtrIds]);

  const { search, onSearch, matchedQuestions } = useSearchDebounceWithFlexMap(orderedQuestions);

  const handleEditUtr = (utrId: string) => {
    setEditUtr(utrId);
    history.push(editQuestionUrl);
  };

  const handleCancelEditUtr = () => {
    setEditUtr('');
    setCreateMetric(false);
    history.push(assignQuestionsUrl);
  };

  const handleChangeOrderType = (option: SingleValue<Option<CustomMetricOrderType> | null>) => {
    if (!option || option.value === metricGroup.metricsOrder?.orderType) {
      return;
    }
    setOrderType(option.value);
    const isCustom = option.value === CustomMetricOrderType.Custom;

    return updateOrderType({
      groupId: metricGroup._id,
      initiativeId: metricGroup.initiativeId,
      orderType: option.value,
      ...(!isCustom
        ? // Default ascending (a-z)
          { direction: OrderingDirection.Asc }
        : {}),
    }).unwrap().then(() => {
      dispatch(reloadCustomMetricGroupsByInitiativeId(metricGroup.initiativeId));
    }).catch((error) => {
      dispatch(addSiteError(error));
    });
  };

  const handleArrange = async (newColumns: string[]) => {
    return updateOrderType({
      groupId: metricGroup._id,
      initiativeId: metricGroup.initiativeId,
      orderType: CustomMetricOrderType.Custom,
      utrIds: newColumns,
    }).unwrap().then(() => {
      dispatch(reloadCustomMetricGroupsByInitiativeId(metricGroup.initiativeId));
    }).catch((error) => {
      dispatch(addSiteError(error));
    });
  };

  const disabledDragging = Boolean(search) || orderType !== CustomMetricOrderType.Custom;

  const metricTableProps: DraggableMetricTableProps = {
    questions: matchedQuestions,
    metricGroup,
    handleReload,
    handleEditUtr,
    handleArrange,
    helperText: `No ${QUESTION.PLURAL} have been assigned to this custom ${SURVEY.ADJECTIVE} ${PACK.SINGULAR}.`,
    isReadOnly: !canEdit || readOnly,
  };

  const metricFormProps: MetricFormProps = {
    metricGroup,
    questions: blueprintQuestions,
    customMetricsUsage,
    handleReload,
    handleCancel: handleCancelEditUtr,
    handleCreateNewMetric: () => setCreateMetric(true),
  };

  const editMetricModalProps: EditMetricModalProps = {
    isOpen: Boolean(editUtr || createMetric),
    editUtrId: editUtr,
    questions: blueprintQuestions,
    metricGroup,
    handleReload,
    handleCancel: handleCancelEditUtr,
    isAddingToGroup: true,
  };

  return (
    <>
      <div className='position-relative'>
        {isLoading ? <BlockingLoader /> : null}
        <LoadingPlaceholder
          count={1}
          height={37}
          className='background-ThemeBgCanvas'
          isLoading={!blueprintQuestions}
        />
        <LoadingPlaceholder
          count={5}
          height={37}
          className='mt-1 background-ThemeTextWhite'
          isLoading={!blueprintQuestions}
        >
          <div className='position-relative d-flex flex-column'>
            <div className='d-flex mb-3 align-items-center'>
              <Input
                type='text'
                placeholder={`Search for a ${QUESTION.SINGULAR} in your custom ${PACK.SINGULAR}`}
                onChange={onSearch}
                data-testid='search-used-custom-metrics-input'
              />
              <div className='d-flex align-items-center justify-content-end gap-2 w-100 mb-0'>
                <Label className='text-medium m-0'>Order {PACK.SINGULAR} by:</Label>
                <SelectFactory
                  components={{
                    Control: (props) =>
                      CustomControl({
                        ...props,
                        text: 'Select the order that this custom metric pack displays on the report overview page',
                      }),
                  }}
                  selectType={SelectTypes.SingleSelect}
                  minWidth={240}
                  onChange={handleChangeOrderType}
                  value={orderTypeOptions.find((o) => o.value === orderType)}
                  options={orderTypeOptions}
                  isSearchable={false}
                />
              </div>
            </div>
            <DraggableMetricTable
              {...metricTableProps}
              isReadOnly={!canEdit || readOnly}
              disabledDragging={disabledDragging}
            />
          </div>
        </LoadingPlaceholder>
        <DashboardDivider />
        <EditMetricModal {...editMetricModalProps} />
        {canEdit && !readOnly ? <MetricForm {...metricFormProps} /> : null}
      </div>
    </>
  );
};
