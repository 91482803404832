import { useEffect } from 'react';
import { useConnectionContext } from './ConnectionContext';

export const usePopulateInputFromConnection = ({
  valueListCode,
  inputChangeHandler,
}: {
  valueListCode?: string;
  inputChangeHandler: ({ value }: { value: number }) => void;
}) => {
  const { inputData, setInputData, connection } = useConnectionContext();

  // Trigger input change in NumberInput when input data is set by clicking populate button in Connection.
  useEffect(() => {
    if (
      inputData !== undefined &&
      connection &&
      (!connection.valueListCode || connection.valueListCode === valueListCode)
    ) {
      inputChangeHandler({ value: inputData });
      setInputData(undefined);
    }
  }, [inputChangeHandler, inputData, setInputData, connection, valueListCode]);
};
