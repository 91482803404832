import { ApiResponse } from '../types/api';
import { g17ecoApi } from './g17ecoApi';

const transformResponse = <T>(response: ApiResponse<T>) => response.data;

export const backgroundReportApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['background-reports'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBackgroundReportUrl: builder.query<
        { downloadUrl: string },
        { surveyId: string; jobId: string; taskId: string }
      >({
        transformResponse,
        query: ({ surveyId, jobId, taskId }) => ({
          url: `/surveys/${surveyId}/background-report/${jobId}/${taskId}`,
          method: 'get',
        }),
        providesTags: ['background-reports'],
      }),
    }),
  });

export const { useLazyGetBackgroundReportUrlQuery } = backgroundReportApi;
