import UniversalTracker from '../../../model/UniversalTracker';
import { isDefined } from '../../../utils';
import { hasSimpleNumericColumnType, hasNumericInput, isNumericValueType } from '@utils/universalTracker';
import { getUtrDecimal } from '../../utr-decimal/utils';
import { BaseInputProps, ErrorMessageType } from '../form/input/InputProps';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { TableDataInfo } from '../question/questionInterfaces';
import { UtrValueType } from '@g17eco/types/universalTracker';
import { InputColumn } from '../form/input/table/InputInterface';
import { getDecimalPattern } from '@utils/number';

const DECIMAL_EXAMPLE = '83';
const DECIMAL_STRING = '12345';

const isValidValueWithDecimal = ({
  utr,
  value,
  name,
}: {
  utr: Pick<UniversalTracker, 'getValueType' | 'getValueValidation'>;
  value: string | number | undefined;
  name: string;
}) => {
  if (!value) {
    return true;
  }

  if (!hasNumericInput(utr)) {
    return true;
  }

  const decimal = getUtrDecimal(utr, name);

  if (decimal === undefined) {
    return true;
  }

  const pattern = getDecimalPattern(decimal);
  const decimalRegExp = new RegExp(pattern);
  return decimalRegExp.test(value.toString());
};

export const generateDecimalErrorMessage = ({
  utr,
  input,
}: {
  utr: Pick<UniversalTracker, 'getId' | 'getValueType' | 'getValueValidation'>;
  input: { [key: string]: string | number | undefined } | undefined;
}) => {
  if (!input) {
    return;
  }

  return Object.keys(input).reduce((acc, name) => {
    if (isValidValueWithDecimal({ utr, value: input[name], name })) {
      acc[name] = '';
    } else {
      acc[name] = generateDecimalHelperText(utr, name);
    }
    return acc;
  }, {} as ErrorMessageType);
};

export const generateDecimalExample = (decimal: number) => {
  if (decimal === 0) {
    return DECIMAL_EXAMPLE;
  }

  const fractionalPart = DECIMAL_STRING.substring(0, decimal);

  return DECIMAL_EXAMPLE.concat('.').concat(fractionalPart);
};

export const generateDecimalHelperText = (
  utr: Pick<UniversalTracker, 'getValueType' | 'getValueValidation'> | undefined,
  inputName?: string
) => {
  const decimal = getUtrDecimal(utr, inputName);
  return isDefined(decimal) ? `Requirement: ${decimal} decimal places (example ${generateDecimalExample(decimal)})` : '';
};

const handleUnitAndNumberScaleWarnings = (
  props: Pick<BaseInputProps, 'initiativeUtr' | 'unit' | 'numberScale'> & {
    columnCode?: string;
  }
) => {
  const unitWarning = getUnitHelperText(props);
  if (unitWarning) {
    return unitWarning;
  }
  return getNumberScaleHelperText(props);
};

export const getInputWarningMessage = (
  props: Pick<BaseInputProps, 'initiativeUtr' | 'unit' | 'numberScale'> & {
    utr: Pick<UniversalTracker, 'getValueType'> | undefined;
  }
) => {
  const { utr } = props;
  if (!utr || !isNumericValueType(utr.getValueType())) {
    return;
  }
  return handleUnitAndNumberScaleWarnings(props);
};

export const getTableInputColumnWarningMessage = ({
  initiativeUtr,
  inputColumn,
}: {
  initiativeUtr: InitiativeUniversalTracker | undefined;
  inputColumn: InputColumn;
}) => {
  const { code, unit, numberScale } = inputColumn;
  return handleUnitAndNumberScaleWarnings({ initiativeUtr, columnCode: code, unit, numberScale });
};

const getUnitHelperText = (
  props: Pick<BaseInputProps, 'initiativeUtr' | 'unit'> & { columnCode?: string }
) => {
  const { unit, initiativeUtr, columnCode } = props;

  if (!initiativeUtr) {
    return;
  }
  const { unitInput, unitLocked } = columnCode
    ? initiativeUtr.valueValidation?.table?.columns.find((col) => col.code === columnCode) ?? {}
    : initiativeUtr;
  if (!unitInput || !unitLocked || unit === unitInput) {
    return;
  }
  return (
    <div>
      Warning: New submissions require the unit <strong>{unitInput}</strong>
    </div>
  );
};

const getNumberScaleHelperText = (
  props: Pick<BaseInputProps, 'initiativeUtr' | 'numberScale'> & { columnCode?: string }
) => {
  const { numberScale, initiativeUtr, columnCode } = props;

  if (!initiativeUtr) {
    return;
  }
  const { numberScaleInput, numberScaleLocked } = columnCode
    ? initiativeUtr.valueValidation?.table?.columns.find((col) => col.code === columnCode) ?? {}
    : initiativeUtr;
  if (!numberScaleInput || !numberScaleLocked || numberScale === numberScaleInput) {
    return;
  }

  return (
    <div>
      Warning: New submissions require the number scale <strong>{numberScaleInput}</strong>
    </div>
  );
};

export const checkHasInputWarningMessage = (props: {
  utr: UniversalTracker | undefined;
  initiativeUtr: InitiativeUniversalTracker | undefined;
  table: TableDataInfo;
  unit: string | undefined;
  numberScale: string | undefined;
}) => {
  const { utr, table, initiativeUtr } = props;
  switch (utr?.getValueType()) {
    case UtrValueType.Number:
    case UtrValueType.Percentage:
    case UtrValueType.NumericValueList:
      return !!getInputWarningMessage(props);
    case UtrValueType.Table: {
      if (!hasSimpleNumericColumnType({ valueValidation: utr.getValueValidation() }) || table.rows.length === 0) {
        return false;
      }
      return table.rows.some((row) =>
        row.data.some((inputColumn) => getTableInputColumnWarningMessage({ initiativeUtr, inputColumn }))
      );
    }
    default:
      return false;
  }
}
