import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { Label } from 'reactstrap';
import { UTRV_CONFIG_CODES, UtrvConfigCode, UtrvConfigType, UtrvConfigValue } from './contants';
import { getLabelByCode, getOptionsByCode } from './utils';

interface Props {
  utrvConfig: UtrvConfigType;
  handleChange: (props: { code: UtrvConfigCode; value: UtrvConfigValue }) => void;
}

export const SingleMetricOverride = (props: Props) => {
  const { utrvConfig, handleChange } = props;

  return (
    <>
      {UTRV_CONFIG_CODES.map((code, index) => {
        const options = getOptionsByCode(code);
        return (
          <div key={code}>
            <Label for={code} className={index ? 'mt-3' : ''} style={{ fontWeight: 500 }}>
              {getLabelByCode(code)}
            </Label>
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              options={options}
              value={options.find((op) => op.value === utrvConfig[code]) ?? null}
              onChange={(op) => (op?.value ? handleChange({ code, value: op.value }) : undefined)}
              isMenuPortalTargetBody
              menuPlacement='top'
            />
          </div>
        );
      })}
    </>
  );
};
