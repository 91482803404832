import { createFilter } from 'react-select';
import { Option } from './SelectFactory';

export type WithString<T> = T | string;

export const getFilterOption = <T extends Option<any> | null>() =>
  createFilter<T>({
    stringify: ({ data }) => {
      if (!data) {
        return '';
      }
      return data.searchString ?? (typeof data.label === 'string' ? data.label : data?.value ?? '');
    },
  });
