import React from 'react';
import { DataShareWithRequester } from '../../../types/dataShare';
import { DashboardSection } from '../../dashboard';
import { DataShareForm } from './DataShareForm';
import { MESSAGE } from '../../../utils/dataShare';
import { DataShareCommonProps } from './PermissionsTemplate';

export const ShareNote = () => (
  <div className='text-center pt-4 mt-3 text-muted data-share-note'>{MESSAGE.SHARE_NOTE}</div>
);

interface DataSharePermissionsProps extends DataShareCommonProps {
  dataShares: DataShareWithRequester[];
}

export const DataSharePermissions = (props: DataSharePermissionsProps) => {
  const { dataShares, ...otherProps } = props;

  return (
    <DashboardSection
      className='data-share-permissions'
      classes={{
        whiteBoxContainer: 'border-0',
      }}
    >
      <React.Fragment>
        {dataShares.map((dataShare) => (
          <DataShareForm key={dataShare._id} dataShare={dataShare} {...otherProps} />
        ))}

        {dataShares.length > 0 ? <ShareNote /> : null}
      </React.Fragment>
    </DashboardSection>
  );
};
