import FileSaver from 'file-saver';
import { convertBinaryToOctetStream, getWorkbook } from '../../../utils/file/XlsxFile';

interface ExportToExcelProps {
  headers: string[];
  values: (string | number)[][];
  fileName: string;
  sheetName?: string;
}

export const exportToExcel = ({ headers, values, fileName, sheetName = '' }: ExportToExcelProps) => {
  const data = [[headers, ...values]];

  const workBook = getWorkbook(data, {
    title: fileName,
    subject: fileName,
    author: 'g17eco',
    createDate: new Date(),
    sheetNames: [sheetName || fileName],
  });
  const blob = new Blob([convertBinaryToOctetStream(workBook)], { type: 'application/octet-stream' });
  FileSaver.saveAs(blob, `${fileName}.xlsx`);
};
