import UniversalTracker from '../../model/UniversalTracker';
import { Value } from '../../selectors/universal-tracker-values';
import { UniversalTrackerPlain } from '../../types/universalTracker';
import { getSuffix } from '../../utils';
import NumberFormat from '../../utils/number-format';
import { MinimalUtrv, UniversalTrackerValueView } from '@features/universal-tracker-value';

interface UtrTableValueProps {
  universalTracker: UniversalTrackerPlain,
  utrv: Value;
}

const isUtrTypeValue = (value: Value): value is MinimalUtrv => {

  const requiredProperties = ['valueData', '_id', 'status'];
  return typeof value === 'object' && requiredProperties.every((key) => key in value);
};

const getSuffixText = (universalTracker: UniversalTrackerPlain) => {
  return getSuffix(universalTracker);
}

const getValue = (universalTrackerValue?: Value) => {
  if (typeof universalTrackerValue !== 'object') {
    return universalTrackerValue;
  }
  return 'value' in universalTrackerValue ? universalTrackerValue.value : undefined;
};

export const UtrTableValue = ({ universalTracker, utrv }: UtrTableValueProps) => {
  const utr = new UniversalTracker(universalTracker);

  return isUtrTypeValue(utrv) ? (
    <UniversalTrackerValueView utr={utr} utrv={utrv} valueType={utr.getValueType()} />
  ) : <NumberFormat value={getValue(utrv)} suffix={getSuffixText(universalTracker)} abbreviate={true} />;
};
