import { TableWidgetData } from './TableWidget';
import { getChartDataByValues } from '@utils/charts';
import { HistoricalUtrs } from '@api/insights';
import Chart from 'react-google-charts';
import { ChartType } from '@routes/summary/insights/utils/constants';
import { getSparklineChartProps } from '@routes/summary/insights/utils/helpers';

interface Props {
  rowOrginal: TableWidgetData;
  utrsData: HistoricalUtrs[];
}

const DATAPOINT_LIMIT = 12;

export const SparklineCell = (props: Props) => {
  const { utrsData, rowOrginal } = props;
  const utrvs = utrsData.find((utrData) => utrData.utr.code === rowOrginal.utrCode)?.utrvs;
  const chartData = getChartDataByValues(utrvs, rowOrginal.valueListCode);
  if (!chartData.length) {
    return null;
  }
  const headerRow = chartData.slice(0, 1);
  const dataRows = chartData.slice(1);
  const renderedRows = dataRows.length > DATAPOINT_LIMIT ? dataRows.slice(-DATAPOINT_LIMIT) : dataRows;
  const renderedData = [...headerRow, ...renderedRows];

  return (
    <Chart
      width={134}
      height={50}
      chartType={ChartType.LineChart}
      data={renderedData}
      options={{
        ...getSparklineChartProps({ isRestated: false }),
        pointSize: 0,
        chartArea: {
          left: 3,
          top: 3,
          bottom: 3,
          right: 3,
        },
      }}
    />
  );
};
