import React from 'react'
import Chart from 'react-google-charts';
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { ReportData } from '../../../../types/reportData';
import { DATE, formatDateUTC } from '../../../../utils/date';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';
import { getComments } from '../sgx-metric-report-generator';
import { VisibilityStatus } from '../../../../types/download';
import { defaultPrivateQuestionText, shouldDisplayPrivateText } from '../../reportData';

const WIDTH = 600;
const HEIGHT = 400;

export interface SocialDataSource extends DataSource {
  rows?: {
    date: string;
    gender_split: number | string;
    notes?: string
  }[];
  comments?: string;
}

const genderPcReducer = (reportData: ReportData[], utrCode: string, visibility: VisibilityStatus) => {
  const utrv = reportData.find(d => d.universalTracker.code === utrCode);
  if (!utrv) {
    return 0;
  }

  if (shouldDisplayPrivateText(utrv, visibility)) {
    return defaultPrivateQuestionText
  }

  const result = utrv.valueData?.table?.reduce((acc, row) => {
    const currentCount = Number(row.find(col => col.code === 'employees_per_category')?.value ?? 0);
    const currentPc = Number(row.find(col => col.code === 'female')?.value ?? 0);

    const newCount = acc.count + currentCount
    const newPc = 100 * ((acc.count * acc.pc / 100) + (currentCount * currentPc / 100)) / newCount;
    return {
      count: newCount,
      pc: newPc
    }
  }, {
    count: 0,
    pc: 0
  });
  return result?.pc ?? 0;
}

const utrCode = 'gri/2020/405-1/b';
const Social = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData, visibility } = props;

  const reportData: SocialDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    rows: questionData.map(row => ({
      date: formatDateUTC(row.effectiveDate, DATE.MONTH_YEAR_SHORT),
      gender_split: genderPcReducer(row.reportData, utrCode, visibility),
      notes: getComments(row.reportData, utrCode, visibility, '')
    })),
    comments: getComments(questionData[0]?.reportData ?? [], utrCode, visibility)
  };

  const chartData = [
    ['Period', 'Gender split: % Female'],
    ...reportData.rows?.reverse().map(row => {
      return ([row.date, typeof row.gender_split === 'number' && !isNaN(row.gender_split) ? row.gender_split : 0]);
    }) ?? []
  ];

  return <Chart
    chartType='LineChart'
    data={chartData}
    width={WIDTH}
    height={HEIGHT}
    options={{
      ...CHART_DEFAULTS.OPTIONS,
      isStacked: false,
      chartArea: {
        top: 40,
        bottom: 20,
        left: 100,
        right: 20,
      },
      vAxis: {
        title: 'Percentage %'
      }
    }}
    chartEvents={
      updateData ? [
        {
          eventName: 'ready',
          callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
            reportData.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
            updateData('social', reportData);
          }
        }
      ] : undefined}
  />
})

export default Social;
