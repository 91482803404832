import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ToggleButtonProps, ToggledButton } from './ToggledButton';
import { QUESTION, SURVEY } from '@constants/terminology';
import { SimpleTooltip } from '@g17eco/molecules';

interface ActionsMenuProps extends Pick<ToggleButtonProps, 'selectedQuestions' | 'handleBulkFlagChange' | 'rootInitiativeUtrMap'> {
  openMenu: boolean;
  toggleMenu: () => void;
  canAccessVerification: boolean;
  canManage: boolean;
}

export const ActionsMenu = (props: ActionsMenuProps) => {
  const commonProps = { ...props, component: DropdownItem, className: ' ' };

  const PrivacyButton = () =>
    ToggledButton({
      ...commonProps,
      field: 'isPrivate',
      action: 'Make public',
      reverseAction: 'Make private',
      icon: 'fa-fw fal fa-lock-keyhole-open',
      reverseIcon: 'fa-fw fal fa-lock-keyhole',
    });

  const EvidenceButton = () =>
    ToggledButton({
      ...commonProps,
      field: 'evidenceRequired',
      action: 'Evidence optional',
      reverseAction: 'Evidence required',
      icon: 'fa-fw fal fa-file',
      reverseIcon: 'fa-fw fas fa-file',
    });

  const VerificationButton = () =>
    ToggledButton({
      ...commonProps,
      field: 'verificationRequired',
      action: 'Verification optional',
      reverseAction: 'Verification required',
      icon: 'fa-fw fal fa-user-pen',
      reverseIcon: 'fa-fw fas fa-user-pen',
    });

  const ExplanationButton = () =>
    ToggledButton({
      ...commonProps,
      field: 'noteRequired',
      action: 'Explanation optional',
      reverseAction: 'Explanation required',
      icon: 'fa-fw fal fa-note',
      reverseIcon: 'fa-fw fas fa-note',
    });

  return (
    <Dropdown toggle={props.toggleMenu} isOpen={props.openMenu} className='d-inline-block more-actions'>
      <SimpleTooltip tooltip={'Evidence, verification, explanation, privacy options'}>
        <DropdownToggle color='secondary' className='px-2'>
          <i className='fa-light fa-gear mr-1' />
          {SURVEY.CAPITALIZED_SINGULAR} {QUESTION.SINGULAR} requirements
        </DropdownToggle>
      </SimpleTooltip>
      <DropdownMenu className='w-100'>
        <EvidenceButton />
        {props.canAccessVerification ? <VerificationButton /> : null}
        <ExplanationButton />
        {props.canManage ? <PrivacyButton /> :null}
      </DropdownMenu>
    </Dropdown>
  );
};
