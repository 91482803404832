import React from 'react';
import { Button, Input } from 'reactstrap';
import { isValidEmail } from '../../utils';

interface EmailChipsInputProps {
  emailInput: string,
  emails: string[],
  placeholder: string,
  onChangeEmailInput: (e: React.ChangeEvent<any>) => void,
  onKeyDown: (e: React.KeyboardEvent<any>) => void,
  handleDelete: (item: string) => void,
  handleAdd: () => void;
}

export const EmailChipsInput = ({
  emailInput,
  emails,
  placeholder,
  onChangeEmailInput,
  onKeyDown,
  handleDelete,
  handleAdd,
}: EmailChipsInputProps) => {
  const validEmail = isValidEmail(emailInput);

  return (
    <>
      {emails.map((email) => (
        <div className='email-chip' key={email}>
          {email}
          <button type='button' className='chip-delete-button' onClick={() => handleDelete(email)}>
            &times;
          </button>
        </div>
      ))}
     <div className='d-block d-md-flex'>
      <Input
        valid={validEmail}
        invalid={!!emailInput && !validEmail}
        type='email'
        value={emailInput}
        placeholder={placeholder}
        onChange={onChangeEmailInput}
        onKeyDown={onKeyDown}
        className='mr-2 mb-2'
        data-testid='add-user-email-input'
      ></Input>
      <Button className='mb-2 col-md-3' outline={true} disabled={!validEmail} onClick={handleAdd} data-testid='invite-additional-user-btn'>
        Invite additional user
      </Button>
     </div>
    </>
  );
};
