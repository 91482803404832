import { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { DashboardRow, DashboardSection } from '../../components/dashboard';
import {
  GridDashboardItem,
  GridDashboardItemBase,
  GridDashboardItemCreate,
  GridDashboardTempItem,
  InsightDashboard,
  InsightDashboardItemType,
  TextSubType,
} from '../../types/insight-custom-dashboard';
import { TitleEditing } from './TitleEditing';
import IconButton from '../../components/button/IconButton';
import { DashboardSettings } from './dashboard-settings';
import { GridLayoutDashboard } from './GridLayoutDashboard';
import { Layout } from 'react-grid-layout';
import { useDebouncedCallback } from 'use-debounce';
import {
  calculateItemHeight,
  getDisplayDashboardItemType,
  getGridSize,
  hasSDGContributionItem,
  isSDGContributionType,
} from './utils';
import { UnsavedChangesWarningPrompt } from '../../components/common/UnsavedChangesWarningPrompt';
import { GridItemProps } from './GridItem';
import { SiteAlertColors } from '../../slice/siteAlertsSlice';
import { DashboardItemFilters, HistoricalUtrs } from '../../api/insights';
import { replaceItem } from '../../utils/array';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import { CompanyProfile } from '../../components/company-profile';
import { SafeInitiativeFields } from '../../types/initiative';
import { SdgChart } from '../../components/impact-performance/SdgChart';
import { ScorecardResponse } from '../../types/scorecard';
import { DataPeriods } from '../../types/universalTracker';
import { CustomDashboardToolbar } from '../../components/custom-dashboard/action-toolbar/CustomDashboardToolbar';
import { CustomDashboardContext } from './context/CustomDashboardWrapper';
import { generateObjectId } from '@utils/object-id';
import { CancelConfirmationModal } from '@components/custom-dashboard/CancelConfirmationModal';
import { useToggle } from '@hooks/useToggle';
import { SurveyType } from '@g17eco/types/survey';
import { SurveyTypeDropdownProps } from '@components/survey-type-dropdown/SurveyTypeDropdown';
import { FeatureStability, Sidebar } from '@g17eco/molecules';
import { DashboardItemEditor } from '../../features/custom-dashboard/item-editor';
import { shouldRecalculateGrid } from '@features/custom-dashboard';
import { SharingButton } from './shared-dashboard/SharingButton';
import { EditingDashboardItem } from '@features/custom-dashboard/types';
import { getLatestMetricText } from '@features/custom-dashboard/utils/dashboard-utils';
import { useExportDashboard } from '@features/custom-dashboard/hooks/useExportDashboard';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';
import Loader from '@components/loader';

type Props = Pick<GridItemProps, 'handleOpenUtrvHistoryModal' | 'survey' | 'integrationsData'> &
  Partial<Pick<SurveyTypeDropdownProps, 'surveyType'>> & {
    dashboard: InsightDashboard;
    handleCancel: () => void;
    handleSave: (dashboard: Partial<InsightDashboard>, keepEditing?: boolean) => void;
    handleDelete: () => void;
    initiative: SafeInitiativeFields;
    scorecard?: ScorecardResponse;
    availablePeriods: DataPeriods[];
    period: DataPeriods | undefined;
  };

const getSidebarTitle = (item: EditingDashboardItem | undefined) => {
  if (!item) {
    return '';
  }

  const action = '_id' in item && item._id ? 'Edit' : 'Add';

  switch (item.type) {
    case InsightDashboardItemType.Table:
      return `${action} table`;
    case InsightDashboardItemType.Media:
      return `${action} media`;
    case InsightDashboardItemType.Headline:
    case InsightDashboardItemType.Text:
      return `${action} text`;
    case InsightDashboardItemType.Space:
      return `${action} space`;
    default:
      return `${action} chart`;
  }
};

const getItemToCalculateGridSize = ({
  updatingItem,
  dashboard,
}: {
  updatingItem: GridDashboardItemCreate;
  dashboard: InsightDashboard;
}) => {
  const isMetricTextItem = 'subType' in updatingItem && updatingItem.subType === TextSubType.Metric;

  if (!isMetricTextItem) {
    return updatingItem;
  }

  return { ...updatingItem, text: getLatestMetricText({ utrsData: dashboard.utrsData, item: updatingItem }) };
};

export const CustomDashboardEditing = ({
  dashboard,
  handleCancel,
  handleSave,
  handleDelete,
  handleOpenUtrvHistoryModal,
  initiative,
  survey,
  availablePeriods,
  period,
  surveyType,
}: Props) => {
  const initiativeId = initiative._id;
  const queryParams: DashboardItemFilters = {
    ...dashboard.filters,
    period,
    surveyType: surveyType ?? SurveyType.Default,
  };
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [dashboardChanges, setDashboardChanges] = useState(dashboard);
  const [editingItem, setEditingItem] = useState<EditingDashboardItem | undefined>(undefined);
  const { getHistoricalUtrsByCodes, hideOptions, hideQuestionReference, readOnly, hideShareButton } =
    useContext(CustomDashboardContext);
  const isStaffUser = useAppSelector(isStaff);
  const [isConfirmingCancel, toggleConfirmingCancelModal] = useToggle();

  const { addSiteAlert, addSiteError } = useSiteAlert();

  const {
    isLoading: isLoadingPageBreaks,
    dashboardRef,
    pageBreaks,
    toggleShowPageBreaks,
  } = useExportDashboard({
    dashboardTitle: dashboard.title,
  });

  const closeEdit = () => {
    setEditingItem(undefined);
  };

  const handleChangeTitle = (title: InsightDashboard['title']) => {
    setDashboardChanges((dashboard) => ({ ...dashboard, title }));
    setIsEditingTitle(false);
  };

  const onLayoutChange = (layout: Layout[]) => {
    // Explicitly extract only what needed to track changes in the layout
    const layoutMap = new Map(
      layout.map(({ i, x, y, w, h, minH, minW, maxH, maxW, isResizable }) => [
        i,
        { x, y, w, h, minW, minH, maxW, maxH, isResizable },
      ])
    );
    setDashboardChanges((dashboard) => ({
      ...dashboard,
      items: dashboard.items.map((item) => ({ ...item, gridSize: layoutMap.get(item._id) ?? item.gridSize })),
    }));
  };

  const onItemRemove = (item: GridDashboardItem) => {
    setDashboardChanges((dashboard) => ({
      ...dashboard,
      items: dashboard.items.filter((gridItem) => gridItem._id !== item._id),
    }));
  };

  const handleClickEdit = (item: GridDashboardItem) => {
    setEditingItem(item);
  };

  const onItemResize = useDebouncedCallback((newItemSize: Layout) => {
    const updated = dashboardChanges.items.map((gridItem) => {
      if (gridItem._id === newItemSize.i) {
        return { ...gridItem, gridSize: newItemSize };
      }
      return gridItem;
    });
    setDashboardChanges((dashboard) => ({ ...dashboard, items: updated }));
  }, 100);

  const handleToggleConfirmingCancelModal = () => {
    if (hasUnsavedChanges) {
      toggleConfirmingCancelModal();
      return;
    }

    handleCancel();
  };

  const saveAndCancelBtn = (
    <>
      <Button onClick={handleToggleConfirmingCancelModal} color='transparent'>
        Cancel
      </Button>
      <Button
        color='primary'
        onClick={() =>
          handleSave({
            items: dashboardChanges.items,
            title: dashboardChanges.title,
            utrsData: dashboardChanges.utrsData,
          })
        }
        className='ms-2'
      >
        <i className='fa-light fa-floppy-disk me-2' />
        Save
      </Button>
    </>
  );

  const updateItemData = (updatingItem: GridDashboardItemCreate) => {
    if (!('variables' in updatingItem) || !updatingItem.variables) {
      // skipping as no additional data to load
      return;
    }
    const utrCodes = Object.values(updatingItem.variables).reduce((acc, variable) => {
      // Skip Integration data.
      if (!variable.integrationCode) {
        acc.push(variable.code);
      }
      return acc;
    }, [] as string[]);
    const existingCodes = dashboardChanges.utrsData.map((utrData) => utrData.utr.code);

    const codesToLoad = utrCodes.filter((code) => !existingCodes.includes(code));

    if (codesToLoad.length === 0) {
      return;
    }

    return getHistoricalUtrsByCodes({ initiativeId, utrCodes: codesToLoad, queryParams })
      .then((result: HistoricalUtrs[]) => {
        setDashboardChanges((dashboard) => {
          return {
            ...dashboard,
            utrsData: [...dashboard.utrsData, ...result],
          };
        });
      })
      .catch((e) => {
        addSiteError(e.message);
      });
  };

  const updateItem = async (updatingItem: GridDashboardItemCreate) => {
    await updateItemData(updatingItem);

    if ('_id' in updatingItem && updatingItem._id) {
      setDashboardChanges((dashboard) => {
        const index = dashboard.items.findIndex((item) => item._id === updatingItem._id);
        if (index < 0) {
          return dashboard;
        }

        const gridSize = dashboard.items[index].gridSize;
        const itemHeights = shouldRecalculateGrid(updatingItem) ? calculateItemHeight(updatingItem, gridSize.w) : {};
        const item = {
          ...updatingItem,
          gridSize: { ...gridSize, ...itemHeights },
        };

        return {
          ...dashboard,
          items: replaceItem(dashboard.items, item, index),
        };
      });
      setEditingItem(undefined);
      return;
    }

    setDashboardChanges((dashboard) => {
      const item = {
        _id: generateObjectId(),
        ...updatingItem,
        gridSize: getGridSize(getItemToCalculateGridSize({ updatingItem, dashboard }), dashboard.items),
      } as GridDashboardItem;

      return {
        ...dashboard,
        items: [...dashboard.items, item],
      };
    });
    addSiteAlert({
      content: `${getDisplayDashboardItemType(updatingItem.type)} is added successfully`,
      timeout: 2000,
      color: SiteAlertColors.Success,
    });
    setEditingItem(undefined);
  };

  const hasUnsavedChanges =
    dashboard.title !== dashboardChanges.title ||
    JSON.stringify(dashboard.items) !== JSON.stringify(dashboardChanges.items);

  const sidebarTitle = getSidebarTitle(editingItem);

  const showStaticSDGContributionChart =
    dashboard.filters.sdgContribution?.enabled && !hasSDGContributionItem(dashboard.items);

  const processSDGContributionSetting = (dashboard: Partial<InsightDashboard>) => {
    const { filters, items } = dashboard;
    const { filters: oldFilters, items: oldItems } = dashboardChanges;
    const isPreviousEnabled = Boolean(oldFilters?.sdgContribution?.enabled);
    const isNowSDGEnabled = Boolean(filters?.sdgContribution?.enabled);

    if (isPreviousEnabled === isNowSDGEnabled) {
      return;
    }

    const dashboardItems = oldItems.filter((item) => !isSDGContributionType(item));
    const sdgContributionItem = items?.find((item) => isSDGContributionType(item));
    if (isNowSDGEnabled && sdgContributionItem) {
      dashboardItems.push(sdgContributionItem);
    }

    setDashboardChanges({
      ...dashboardChanges,
      filters: { ...oldFilters, sdgContribution: { enabled: isNowSDGEnabled } },
      items: dashboardItems,
    });
  };

  const onSaveSettings = (dashboard: Partial<InsightDashboard>, keepEditing?: boolean) => {
    processSDGContributionSetting(dashboard);
    handleSave(dashboard, keepEditing);
  };

  return (
    <div className='pb-6'>
      {isLoadingPageBreaks ? <Loader /> : null}
      <DashboardRow>
        <div className='d-flex w-100 justify-content-end'>
          <div className='d-flex'>
            {saveAndCancelBtn}
            <DashboardSettings
              key={dashboard._id}
              handleSave={onSaveSettings}
              handleDelete={handleDelete}
              dashboard={dashboard}
              hideOptions={hideOptions}
              availablePeriods={availablePeriods}
            />
            {isStaffUser ? (
              <Button color='secondary' disabled className='ms-2'>
                <i className='fa-light fa-file-arrow-down mr-2'></i>
                <span>Export dashboard</span>
                <FeatureStability color='inherit' className='ms-2' stability='internal' />
              </Button>
            ) : null}
            {hideShareButton ? null : <SharingButton dashboard={dashboard} disabled />}
          </div>
        </div>
      </DashboardRow>
      {dashboard.filters.initiativeInfo?.enabled ? <CompanyProfile initiative={initiative} readOnly={false} /> : null}
      <DashboardRow>
        <div className='d-flex align-items-center'>
          {isEditingTitle ? (
            <TitleEditing
              title={dashboardChanges.title}
              handleSave={handleChangeTitle}
              handleCancel={() => setIsEditingTitle(false)}
            />
          ) : (
            <>
              <h3 className='text-ThemeHeadingLight'>{dashboardChanges.title}</h3>
              <IconButton
                tooltip='Edit title'
                color='transparent'
                outline={false}
                onClick={() => setIsEditingTitle(true)}
                icon='fa-light fa-pencil'
                className='text-ThemeIconSecondary ml-2'
              />
            </>
          )}
        </div>
      </DashboardRow>
      {showStaticSDGContributionChart && dashboard.scorecard ? (
        <DashboardSection>
          <SdgChart initiativeId={initiativeId} scorecard={dashboard.scorecard} />
        </DashboardSection>
      ) : null}
      <GridLayoutDashboard
        isEditing
        hideQuestionReference={hideQuestionReference}
        gridItems={dashboardChanges.items}
        utrsData={dashboardChanges.utrsData}
        onLayoutChange={onLayoutChange}
        onItemResize={onItemResize}
        onItemRemove={onItemRemove}
        handleClickEdit={handleClickEdit}
        integrationsData={dashboardChanges.integrationsData}
        handleOpenUtrvHistoryModal={handleOpenUtrvHistoryModal}
        readOnly={readOnly || !dashboardChanges.filters.baselinesTargets?.enabled}
        initiativeId={initiativeId}
        survey={survey}
        scorecard={dashboard.scorecard}
        dashboardRef={dashboardRef}
        pageBreaks={pageBreaks}
      />
      <CustomDashboardToolbar
        openAddingModal={(item: GridDashboardItemBase | GridDashboardTempItem) => setEditingItem(item)}
        handleCancel={handleToggleConfirmingCancelModal}
        pageBreaks={pageBreaks}
        isLoadingPageBreaks={isLoadingPageBreaks}
        toggleShowHideBreakpoints={toggleShowPageBreaks}
        saveDashboardChanges={() =>
          handleSave({
            items: dashboardChanges.items,
            title: dashboardChanges.title,
            utrsData: dashboardChanges.utrsData,
          })
        }
        updateItem={updateItem}
      />
      <>
        <Sidebar
          header={sidebarTitle}
          isOpen={!!editingItem}
          toggle={closeEdit}
          direction='end'
          className='dashboard__editor-sidebar'
        >
          <DashboardItemEditor
            editingItem={editingItem}
            handleCancel={closeEdit}
            initiativeId={initiativeId}
            updateItem={updateItem}
            queryParams={queryParams}
            survey={survey}
            integrationsData={dashboardChanges.integrationsData}
          />
        </Sidebar>
      </>

      <UnsavedChangesWarningPrompt hasUnsavedChanges />
      {isConfirmingCancel ? (
        <CancelConfirmationModal toggle={toggleConfirmingCancelModal} handleCancel={handleCancel} />
      ) : null}
    </div>
  );
};
