import { TemplateOption } from '@g17eco/types/insight-custom-dashboard';

export interface InsightDashboardOption {
  value: string;
  label: string | JSX.Element;
  isCustom?: boolean;
  disabled?: boolean;
  tooltip?: string;
  isSharedByParent?: boolean;
  isStaffOnly?: boolean;
  color?: string;
  className?: string;
  id?: string;
  icon?: string;
  subOptions?: InsightDashboardOption[];
}

export const TOOLTIP_MESSAGE = {
  NOT_AVAILABLE_PLAN: 'Not available on your current plan',
  DISABLE_WHILE_EDITING: 'Unable to create new dashboards while in edit mode',
  IS_SHARED_BY_PARENT:
    'Dashboard template will be made available to subsidiaries, but will be populated with their data. Any changes to this master dashboard will be pushed to subsidiaries automatically',
};

export const ADD_DASHBOARD_OPTION = 'add-dashboard-btn';

export const getAddDashboardOption = ({
  canAccessCustomDashboards,
  isEditing,
  templates,
}: {
  canAccessCustomDashboards: boolean;
  isEditing?: boolean;
  templates?: TemplateOption[];
}): InsightDashboardOption => {
  const getTooltip = () => {
    if (!canAccessCustomDashboards) {
      return TOOLTIP_MESSAGE.NOT_AVAILABLE_PLAN;
    }
    if (isEditing) {
      return TOOLTIP_MESSAGE.DISABLE_WHILE_EDITING;
    }
    return undefined;
  }

  return {
    value: ADD_DASHBOARD_OPTION,
    label: (
      <span className='d-flex insights-dashboard__add-dashboard-btn'>
        <i className='fa-light fa-plus mr-2 text-md' />
        Add dashboard
      </span>
    ),
    disabled: isEditing || !canAccessCustomDashboards,
    tooltip: getTooltip(),
    className: 'insights-dashboard__add-dashboard-btn',
    id: ADD_DASHBOARD_OPTION, // Have id to be able to open TemplateMenu using reactstrap Popover.
    subOptions: templates,
  };
};

export const getTemplateOptions = (options: InsightDashboardOption[]): InsightDashboardOption[] => {
  return options.find((option) => option.value === ADD_DASHBOARD_OPTION)?.subOptions ?? [];
};
