import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { ArchiveProgress, ArchiveStep } from './ArchiveSubsidiaryModal';

interface WarningStepProps {
  handleToggle: () => void;
  handleChange: (changes: Partial<ArchiveProgress>) => void;
}

export const WarningStep = (props: WarningStepProps) => {
  const { handleChange, handleToggle } = props;

  return (
    <>
      <ModalBody>
        <p>
          Archived business units continue to contributor to your limit as seen on your plans subscription info page.
          Archived business units can be restored.
        </p>

        <p>
          If you archive this business unit, you will no longer be able to view the data it contains, any
          auto-aggregation will also be updated to exclude this business units data points.
        </p>

        <p>If you still want to proceed, select Next to continue the process.</p>
      </ModalBody>
      <ModalFooter>
        <Button color='transparent' onClick={handleToggle}>
          Cancel
        </Button>
        <Button color='primary' onClick={() => handleChange({ step: ArchiveStep.UserManagement })}>
          Next
        </Button>
      </ModalFooter>
    </>
  );
};
