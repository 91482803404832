import { Input } from 'reactstrap';
import { CustomMetric } from '../../api/custom-metrics';
import { CustomMetricTable } from './CustomMetricTable';
import { useSearchDebounceWithFlexMap } from '../../hooks/useSearchDebounceWithFlexMap';

interface CustomMetricManageTableProps {
  questions: CustomMetric[] | undefined;
  isUsed?: boolean;
  handleOpenEditUtr: (utrId: string) => void;
  handleReadOnlyUtr: (utrId: string) => void;
  handleOpenDeleteUtr: (utr: CustomMetric) => void;
}

export const CustomMetricManageTable = (props: CustomMetricManageTableProps) => {
  const { questions, isUsed = false, handleOpenEditUtr, handleReadOnlyUtr, handleOpenDeleteUtr } = props;
  const { onSearch, matchedQuestions } = useSearchDebounceWithFlexMap(questions);

  return (
    <div className='position-relative'>
      <Input
        type='text'
        className='metric-table__search position-absolute'
        bsSize='sm'
        placeholder='Search'
        onChange={onSearch}
        data-testid='search-unused-custom-metrics-input'
      />
      <CustomMetricTable
        handleOpenEditUtr={handleOpenEditUtr}
        handleReadOnlyUtr={handleReadOnlyUtr}
        handleOpenDeleteUtr={handleOpenDeleteUtr}
        questions={matchedQuestions}
        isUsed={isUsed}
      />
    </div>
  );
};
