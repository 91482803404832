import React, { useReducer } from 'react';
import { isValidEmail } from '../../utils';

interface UserInvitationFormSettingsProps {
  children: JSX.Element | JSX.Element[];
}

export type UserInvitationFormContextProps = {
  emailInput: string,
  emails: string[];
  errorMessage: string | undefined;
  dispatch: React.Dispatch<any>;
}

export const ACTION = {
  SET_EMAIL_INPUT: 'emailInput',
  ADD_EMAIL: 'addEmail',
  DELETE_EMAIL: 'deleteEmail',
  SET_ERROR_MESSAGE: 'errorMessage',
};

const initialState: UserInvitationFormContextProps = {
  emailInput: '',
  errorMessage: '',
  emails: [],
  dispatch: () => { },
}

export const UserInvitationFormContext = React.createContext<UserInvitationFormContextProps>(initialState);

function reducer(state: any, action: any) {
  if (!action.type) {
    return state;
  }

  const { emails = [], emailInput = '' }: Partial<UserInvitationFormContextProps> = state;

  switch (action.type) {
    case ACTION.ADD_EMAIL: {
      const value = emailInput.trim();
      const isDuplicated = !!emails.find(email => email === value);

      if (!isValidEmail(value)) {
        return state;
      }

      if (value && !isDuplicated) {
        return { ...state, emails: [...emails, emailInput] };
      }

      return state;
    }
    case ACTION.DELETE_EMAIL: {
      const { payload: deleteItem }: { payload: string } = action;
      return { ...state, emails: emails.filter((email) => email !== deleteItem) };
    }
    default:
      return { ...state, [action.type]: action.payload };
  }
}

export default function UserInvitationFormContainer({ children }: UserInvitationFormSettingsProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserInvitationFormContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </UserInvitationFormContext.Provider>
  );
}
