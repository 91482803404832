import Dashboard, { DashboardSection } from '../../dashboard';
import { BenchmarkingNavigation } from './BenchmarkingNavigation';

interface BenchmarkingTemplateProps {
  children: JSX.Element;
}

export const BenchmarkingTemplate = (props: BenchmarkingTemplateProps) => {
  return (
    <Dashboard className='benchmarking__container'>
      <DashboardSection>
        <BenchmarkingNavigation />
        {props.children}
      </DashboardSection>
    </Dashboard>
  );
};
