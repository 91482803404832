import { RadioInput } from '@components/form/RadioInput';
import { BaseOptions } from '@components/form/common';
import { QUESTION } from '@constants/terminology';
import { Option, SelectFactory, SelectTypes, SimpleTooltip } from '@g17eco/molecules';
import { useContext } from 'react';
import { Label } from 'reactstrap';
import { QuestionManagementContext } from '../../../apps/company-tracker/components/admin-dashboard/questions/QuestionManagementContainer';
import { UTRV_CONFIG_CODES, UtrvConfigCode, UtrvConfigType, UtrvConfigValue } from './contants';
import { getLabelByCode, getOptionsByCode } from './utils';

interface Props {
  hasAnythingChanged: boolean;
  utrvConfig: UtrvConfigType;
  handleChange: (props: { code: UtrvConfigCode; value: UtrvConfigValue }) => void;
}

const utrvConfigCodeOptions: Option<UtrvConfigCode>[] = UTRV_CONFIG_CODES.map((code) => ({
  label: getLabelByCode(code),
  value: code,
}));

export const MultipleMetricOverride = (props: Props) => {
  const { hasAnythingChanged, utrvConfig, handleChange } = props;
  const { utrvConfigCode, setUtrvConfigCode } = useContext(QuestionManagementContext);

  if (!utrvConfigCode) {
    return null;
  }

  const options = getOptionsByCode(utrvConfigCode).map(({ label, value }) => ({ name: label, code: value }));
  const value = utrvConfig[utrvConfigCode];

  return (
    <div>
      <Label className='mb-1'>
        <span className='fw-semibold'>Override</span>
        {hasAnythingChanged ? (
          <SimpleTooltip
            text={`Please save or cancel your change before making additional overrides to these ${QUESTION.PLURAL}`}
          >
            <i className='fal fa-bell-exclamation text-ThemeWarningMedium ml-2' />
          </SimpleTooltip>
        ) : null}
      </Label>
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        options={utrvConfigCodeOptions}
        onChange={(op) => op?.value && setUtrvConfigCode(op.value)}
        isSearchable={false}
        value={utrvConfigCodeOptions.find((op) => op.value === utrvConfigCode) ?? null}
        className='mb-3'
        isDisabled={hasAnythingChanged}
        isMenuPortalTargetBody
      />
      <RadioInput
        groupCode={utrvConfigCode}
        value={value}
        options={options}
        onChange={handleChange as BaseOptions['onChange']}
        formGroupClassName='mt-2'
      />
    </div>
  );
};
