import { NotificationCategory, NotificationCategoryGroup } from '@g17eco/types/notifications';
import { capitaliseFirstLetter, replaceUnderscores } from '.';
import { QUESTION, SURVEY } from '@constants/terminology';

const NOTIFICATION_CATEGORY_MAPPING: Record<string, { label: string }> = {
  [NotificationCategory.QuestionReject]: {
    label: 'Metric reject (User delegated)',
  },
  [NotificationCategory.QuestionRejectPermission]: {
    label: 'Metric reject (User permission)',
  },
  [NotificationCategory.QuestionUpdate]: {
    label: 'Metric update (User delegated)',
  },
  [NotificationCategory.QuestionUpdatePermission]: {
    label: 'Metric update (User permission)',
  },
  [NotificationCategory.BulkSurveys]: {
    label: `Bulk ${SURVEY.PLURAL}`,
  },
  [NotificationCategory.QuestionComment]: {
    label: `${QUESTION.CAPITALIZED_SINGULAR} comment`,
  },
  [NotificationCategory.QuestionDelegation]: {
    label: `${QUESTION.CAPITALIZED_SINGULAR} delegation`,
  },
  [NotificationCategory.QuestionNoteUpdate]: {
    label: `${QUESTION.CAPITALIZED_SINGULAR}  note update`,
  },
  [NotificationCategory.QuestionAssured]: {
    label: `${QUESTION.CAPITALIZED_SINGULAR}  assured`,
  },
  [NotificationCategory.QuestionDisputed]: {
    label: `${QUESTION.CAPITALIZED_SINGULAR}  disputed`,
  },
};

export const getCategoryLabel = (category: string) => {
  return NOTIFICATION_CATEGORY_MAPPING[category]
    ? NOTIFICATION_CATEGORY_MAPPING[category].label
    : capitaliseFirstLetter(replaceUnderscores(category));
};

export const getCategoryGroupLabel = (group: string) => {
  switch (group) {
    case NotificationCategoryGroup.SurveysAndQuestions:
      return `${SURVEY.CAPITALIZED_PLURAL} and ${QUESTION.PLURAL}`;
    default:
      return capitaliseFirstLetter(replaceUnderscores(group));
  }
};
