import { SURVEY } from '@constants/terminology';

export enum TemplateMessage {
  Created = `${SURVEY.CAPITALIZED_SINGULAR} template successfully created.`,
  Updated = `${SURVEY.CAPITALIZED_SINGULAR} template successfully updated.`,
  AggregatedCreated = `Aggregated ${SURVEY.SINGULAR} template successfully created.`,
  AggregatedUpdated = `Aggregated ${SURVEY.SINGULAR} template successfully updated.`,
}

export enum Actions {
  Delete = 'delete',
  Duplicate = 'duplicate',
}

export enum NavCode {
  Configuration = 'configuration',
  Scope = 'scope',
}

export enum MergeTags {
  Subsidiary = '{{subsidiary}}',
  SurveyDuration = '{{duration}}',
  Month = '{{month}}',
  Year = '{{year}}',
}
