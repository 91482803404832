import { useMemo } from 'react';
import { Input } from 'reactstrap';
import { QUESTION } from '@constants/terminology';
import { BasicAlert, SelectFactory, SelectTypes, Toggle } from '@g17eco/molecules';
import { noTagOption } from '@components/survey/utils/filters';
import { useGetCustomTagsQuery } from '@api/metric-groups';
import Loader from '@components/loader';
import { ViewType } from '@api/admin-dashboard';
import { ModuleDropdown } from '@components/module-dropdown';

export enum UnitOption {
  Default = 'default',
  Override = 'override',
  DefaultAndOverride = 'default-override',
}

export enum OverridesOption {
  NoSet = 'no-set',
  Unit = 'unit',
  NumberScale = 'number-scale',
  Decimal = 'decimal',
  Evidence = 'evidence',
  Verification = 'verification',
  FurtherExplanation = 'further-explanation',
  MetricPrivacy = 'metric-privacy',
}

const overridesOptions: { label: string; value: OverridesOption }[] = [
  {
    label: 'No overrides set',
    value: OverridesOption.NoSet,
  },
  {
    label: 'Unit set',
    value: OverridesOption.Unit,
  },
  {
    label: 'Number Scale set',
    value: OverridesOption.NumberScale,
  },
  {
    label: 'Decimal place set',
    value: OverridesOption.Decimal,
  },
  {
    label: 'Evidence set',
    value: OverridesOption.Evidence,
  },
  {
    label: 'Verification set',
    value: OverridesOption.Verification,
  },
  {
    label: 'Further explanation set',
    value: OverridesOption.FurtherExplanation,
  },
  {
    label: 'Metric privacy set',
    value: OverridesOption.MetricPrivacy,
  },
];

export interface QuestionFilters {
  packs: string[];
  tags: string[];
  overrides: OverridesOption[];
}

export interface QuestionFilterProps {
  viewType: ViewType;
  toggleViewType: (viewType: ViewType) => void;
  showFilters?: boolean;
  filters: QuestionFilters;
  handleQuestionFilter: (params: { name: string; value: string[] | OverridesOption[] }) => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rootInitiativeId: string;
  canAccessRootOrg: boolean;
}

export const QuestionFilter = ({
  showFilters = false,
  viewType,
  toggleViewType,
  filters,
  rootInitiativeId,
  handleQuestionFilter,
  onSearch,
  canAccessRootOrg,
}: QuestionFilterProps) => {
  const {
    data: tags = [],
    error,
    isFetching,
  } = useGetCustomTagsQuery(rootInitiativeId, { skip: !rootInitiativeId || !canAccessRootOrg });

  const showInScopeOnly = viewType === ViewType.InScope;

  const tagOptions = useMemo(() => {
    const options = [noTagOption];
    tags.forEach((tag) => {
      options.push({ value: tag._id, searchString: tag.groupName, label: tag.groupName });
    });
    return options;
  }, [tags]);

  return (
    <div className='d-flex align-items-center mb-3'>
      {isFetching ? <Loader /> : null}
      {error ? <BasicAlert type='danger'>{error.message}</BasicAlert> : null}
      {showFilters ? (
        <div className='w-100'>
          <div className='d-flex align-items-center justify-content-between'>
            <Input
              type='text'
              placeholder={`Search for ${QUESTION.SINGULAR}`}
              className='border-ThemeBorderDefault'
              onChange={onSearch}
            />
            <div style={{ minWidth: 'fit-content' }}>
              <Toggle
                className={{ form: 'd-flex align-items-center' }}
                checked={viewType === ViewType.Blueprint}
                onChange={() => toggleViewType(showInScopeOnly ? ViewType.Blueprint : ViewType.InScope)}
                label={`Show out of scope ${QUESTION.SINGULAR}`}
              />
            </div>
          </div>
          <div className='mt-3 d-flex align-items-center gap-3'>
            <div>Filters:</div>
            <ModuleDropdown
              initiativeId={rootInitiativeId}
              onChange={(options) => handleQuestionFilter({ name: 'packs', value: options })}
              modules={filters.packs}
            />
            <SelectFactory
              selectType={SelectTypes.MultipleSelect}
              placeholder={`Display ${QUESTION.SINGULAR} overrides`}
              options={overridesOptions}
              onChange={(options) => handleQuestionFilter({ name: 'overrides', value: options as OverridesOption[] })}
              values={filters.overrides}
              className='w-100'
            />
            <SelectFactory
              selectType={SelectTypes.MultipleSelect}
              placeholder='Display tags'
              options={tagOptions}
              onChange={(options) => handleQuestionFilter({ name: 'tags', value: options })}
              values={filters.tags}
              className='w-100'
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
