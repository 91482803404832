import { ManagementInvitation, ManagementUser } from '@constants/users';
import { ApiResponse } from '../types/api';
import { g17ecoApi, transformResponse } from './g17ecoApi';

export interface InitiativeUser {
  users: ManagementUser[];
  parentInitiativeIds: string[];
  invitations: ManagementInvitation[];
}

export const TAG = 'manage-user';

const TAGS = [TAG];

const getUrl = (initiativeId: string) => `initiatives/${initiativeId}/users`;

const getOnboardingUrl = (initiativeId: string, userId: string) => `initiatives/${initiativeId}/onboarding/${userId}`;

export const manageUserApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInitiativeUsers: builder.query<InitiativeUser, { initiativeId: string; includeSubsidiaries: boolean }>({
        providesTags: (_result, _error, { initiativeId }) => (_result ? [{ type: TAG, id: initiativeId }] : TAGS),
        transformResponse,
        query: ({ initiativeId, includeSubsidiaries }) => ({
          url: `${getUrl(initiativeId)}${includeSubsidiaries ? '/subsidiaries' : ''}`,
          method: 'get',
        }),
      }),
      removeUser: builder.mutation<ApiResponse, { initiativeId: string; userId: string }>({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: TAG, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, userId }) => ({
          url: `${getUrl(initiativeId)}/${userId}`,
          method: 'delete',
        }),
      }),
      removeOnboarding: builder.mutation<ApiResponse, { initiativeId: string; userId: string }>({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: TAG, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, userId }) => ({
          url: `${getOnboardingUrl(initiativeId, userId)}`,
          method: 'delete',
        }),
      }),
      removePermissionFromOnboarding: builder.mutation<ApiResponse, { initiativeId: string; userId: string }>({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: TAG, id: initiativeId }],

        transformResponse,
        query: ({ initiativeId, userId }) => ({
          url: `${getOnboardingUrl(initiativeId, userId)}/remove-permission`,
          method: 'delete',
        }),
      }),
    }),
  });

export const {
  useGetInitiativeUsersQuery,
  useRemoveUserMutation,
  useRemoveOnboardingMutation,
  useRemovePermissionFromOnboardingMutation,
} = manageUserApi;
