import { CustomMetric } from '@api/custom-metrics';
import { QUESTION, SURVEY } from '@constants/terminology';
import { CTAButtons } from './partials/CTAButtons';
import { NameCell } from './partials/NameCell';
import { Table, ColumnDef } from '@g17eco/molecules';
import { MetricDetail } from './partials/MetricDetail';
import { CustomMetricContext, getBaseRoute } from './CustomMetricContainer';
import React from 'react';

interface CustomMetricTableProps {
  questions: CustomMetric[];
  isUsed: boolean;
  handleOpenEditUtr: (utrId: string) => void;
  handleReadOnlyUtr: (utrId: string) => void;
  handleOpenDeleteUtr: (utr: CustomMetric) => void;
}

export const CustomMetricTable = ({
  questions,
  isUsed,
  handleOpenEditUtr,
  handleReadOnlyUtr,
  handleOpenDeleteUtr,
}: CustomMetricTableProps) => {
  const { metricGroups, initiativeId, isPortfolioTracker } = React.useContext(CustomMetricContext);

  const columns: ColumnDef<CustomMetric>[] = [
    {
      accessorKey: 'name',
      header: `${QUESTION.CAPITALIZED_SINGULAR} name`,
      meta: {
        headerProps: {
          style: {
            minWidth: 500,
          },
        },
        cellProps: {
          className: 'nameCol flex-grow-1 order-1',
        },
      },
      cell: ({ row }) => (
        <NameCell
          isInGroup={true}
          row={row}
          rowDetail={
            <MetricDetail utr={row.original} metricGroups={metricGroups} baseRoute={getBaseRoute(isPortfolioTracker)} initiativeId={initiativeId} />
          }
        />
      ),
    },
    {
      id: 'in-survey',
      enableSorting: false,
      header: isUsed ? `In a ${SURVEY.SINGULAR}` : `Added to ${SURVEY.SINGULAR}`,
      meta: {
        cellProps: {
          className: 'typeCol text-center order-2',
        },
      },
      cell: ({ row }) => (
        <h4 className='m-0'>
          {row.original.universalTrackerValues.length ? (
            <i className='fal fa-check text-ThemeSuccessMedium' />
          ) : (
            <i className='fal fa-dash text-ThemeNeutralsExtradark' />
          )}
        </h4>
      ),
    },
    {
      id: 'answered',
      enableSorting: false,
      header: 'Answered',
      meta: {
        cellProps: {
          className: 'typeCol text-center order-2',
        },
      },
      cell: () => (
        <h4 className='m-0'>
          {isUsed ? (
            <i className='fal fa-check text-ThemeSuccessMedium' />
          ) : (
            <i className='fal fa-dash text-ThemeNeutralsExtradark' />
          )}
        </h4>
      ),
    },
    {
      id: 'buttons',
      header: '',
      enableSorting: false,
      meta: {
        cellProps: {
          className: 'buttonsCol flex-shrink-1 order-3',
        },
      },
      cell: ({ row }) => {
        return (
          <CTAButtons
            handleOpenEditUtr={handleOpenEditUtr}
            handleReadOnlyUtr={handleReadOnlyUtr}
            universalTracker={row.original}
            handleOpenDeleteUtr={handleOpenDeleteUtr}
            isDisabled={isUsed}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className='metric-table-container' data-testid='table-custom-metrics'>
        <Table responsive={true} columns={columns} data={questions} pageSize={10} />
      </div>
      {!questions.length ? (
        <div className='metric-group-form--text-helper'>No custom {QUESTION.PLURAL} in this company</div>
      ) : null}
    </>
  );
};
