import { DataPeriods } from './universalTracker';
import { ValueHistory } from './universalTrackerValue';

export enum ValueAggregation {
  ValueSumAggregator = 'valueSumAggregator',
  ValueAverageAggregator = 'valueAverageAggregator',
  ValueListCountAggregator = 'valueListCountAggregator',
  TextCountAggregator = 'textCountAggregator',
  NumericValueListSumAggregator = 'numericValueListSumAggregator',
  TableColumnAggregator = 'tableAggregator',
  TableConcatenationAggregator = 'tableConcatenationAggregator',
  LatestAggregator = 'latestAggregator',
  EmptyAggregator = 'emptyAggregator',
}

export const valueAggregationMap: { [key in ValueAggregation]: string } = {
  [ValueAggregation.ValueSumAggregator]: 'Sum of all answers',
  [ValueAggregation.ValueAverageAggregator]: 'Average of all answers',
  [ValueAggregation.ValueListCountAggregator]: 'Count',
  [ValueAggregation.TextCountAggregator]: 'Count',
  [ValueAggregation.NumericValueListSumAggregator]: 'Sum of all answers',
  [ValueAggregation.TableColumnAggregator]: 'Table concaternation',
  [ValueAggregation.TableConcatenationAggregator]: 'Table concaternation',
  [ValueAggregation.LatestAggregator]: 'Data from the latest answered metric',
  [ValueAggregation.EmptyAggregator]: 'Empty',
};

export interface HistoryData extends Pick<ValueHistory, 'value' | 'valueData' | 'unit' | 'numberScale'> {
  utrvId: string;
  surveyId?: string;
  initiativeId: string;
  period: DataPeriods;
  date: string | undefined;
}
