import { ControlProps, GroupBase, components } from 'react-select';
import { SimpleTooltip } from '../../simple-tooltip';

export const CustomControl = <OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>>({
  children,
  text,
  ...props
}: ControlProps<OptionType, IsMulti, Group> & { text?: string }) => {
  if (!text) {
    return <components.Control {...props}>{children}</components.Control>;
  }
  return (
    <SimpleTooltip text={text}>
      <components.Control {...props}>{children}</components.Control>
    </SimpleTooltip>
  );
};
