import { getGroup } from '@g17eco/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Loader from '../../loader';
import { BasicAlert, getDateRange } from '@g17eco/molecules';
import { DateRangeType } from '@g17eco/types/common';
import { getAnsweredUtrvs, getPackUsagePercentageExportText } from '../exchange-view/utils';
import { BenchmarkingTemplate } from './BenchmarkingTemplate';
import { BenchmarkingFilters, Filters } from './partials/BenchmarkingFilters';
import PackUsageTable from './partials/PackUsageTable';
import { generateUrl } from '../../../routes/util';
import { ROUTES } from '../../../constants/routes';
import { BenchmarkingTabCodes } from './BenchmarkingNavigation';
import { ScopeParams, useGetPortfolioPackUsageQuery } from '../../../api/benchmarking';
import { AdminBreadcrumbs } from '../../../routes/admin-dashboard/AdminBreadcrumbs';
import { ViewValues } from '../../survey-overview-sidebar/viewOptions';
import { TIME_RANGE_YEARLY } from '@utils/date';
import { useAppSelector } from '../../../reducers';
import { useDispatch } from 'react-redux';
import { loadCustomMetricGroupsByInitiativeId } from '../../../actions/initiative';
import { exportToExcel } from '../../downloads/util/exportToExcel';
import { findChildGroupByCode } from '../../../utils/groups';
import { QUESTION, SURVEY } from '@constants/terminology';

enum PackUsageColumns {
  SurveyPack = `${SURVEY.CAPITALIZED_ADJECTIVE} module`,
  Uses = 'Uses',
  UniqueUses = 'Unique uses',
  Unanswered = 'Unanswered',
  Answered = 'Answered',
  Verified = '% Verified',
  Private = 'Private',
  NotReporting = 'NA/NR',
}

export interface UrlParams {
  portfolioId: string;
  group: string;
  subGroup?: string;
  leafGroup?: string;
  leafChildGroup?: string;
}

export const PackUsage = () => {
  const { portfolioId, group, subGroup, leafGroup, leafChildGroup } = useParams<UrlParams>();
  const history = useHistory();

  const [filters, setFilters] = useState<Filters>({});
  const [dateRange, setDateRange] = useState<DateRangeType>(getDateRange(TIME_RANGE_YEARLY));

  const { data: metricGroups } = useAppSelector((state) => state.customMetricGroups);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadCustomMetricGroupsByInitiativeId(portfolioId))
  }, [dispatch, portfolioId]);

  const { data, isLoading, isFetching, isError, error } = useGetPortfolioPackUsageQuery(
    { portfolioId, filters, dateRange, group, subGroup, leafGroup, leafChildGroup },
    {
      skip: !portfolioId,
    }
  );

  const getRowsData = () => {
    const rowHeaders = Object.values(PackUsageColumns);
    const rowValues = (data?.packs ?? []).map((pack) => {
      return [
        pack.name,
        pack.surveyCount,
        pack.initiativeUniqueCount,
        pack.created,
        getAnsweredUtrvs(pack),
        getPackUsagePercentageExportText(pack, 'verified'),
        getPackUsagePercentageExportText(pack, 'isPrivate'),
        getPackUsagePercentageExportText(pack, 'nr'),
      ];
    });
    return { rowHeaders, rowValues };
  };

  const downloadPackUsage = () => {
    const { rowHeaders, rowValues } = getRowsData();
    exportToExcel({ headers: rowHeaders, values: rowValues, fileName: 'benchmarking-pack-usage' });
  };

  const handleClickScope = (scope: string) => {
    const url = generateUrl(ROUTES.PORTFOLIO_TRACKER_BENCHMARKING, {
      portfolioId,
      page: BenchmarkingTabCodes.PackUsage,
    });

    const scopeUrl = [group, subGroup, leafGroup, leafChildGroup, scope].filter((scope) => !!scope).join('/');
    history.push(`${url}/${scopeUrl}`);
  };

  const getPackUsageUrl = useCallback(
    (scopeParams: ScopeParams) => {
      return generateUrl(ROUTES.PORTFOLIO_TRACKER_BENCHMARKING, {
        portfolioId,
        page: BenchmarkingTabCodes.PackUsage,
        ...scopeParams,
      });
    },
    [portfolioId]
  );

  const breadcrumbs = useMemo(() => {
    const path = [];
    const resolvedGroup = getGroup(ViewValues.StandardsAndFrameworks, group);

    if (group) {
      const matchCustomGroup = metricGroups.find((g) => g._id === group);
      const hasSubGroups = !matchCustomGroup && Boolean(resolvedGroup?.subgroups?.length);

      path.push({
        label: matchCustomGroup?.groupName ?? resolvedGroup?.shortName ?? group,
        url: !hasSubGroups ? '' : getPackUsageUrl({ group }),
      });
      if (!hasSubGroups) {
        path.push({ label: QUESTION.CAPITALIZED_PLURAL });
        return path;
      }
    }

    if (subGroup) {
      const subGroupObj = resolvedGroup ? findChildGroupByCode(resolvedGroup, subGroup) : undefined;
      const hasSubGroups = subGroupObj?.subgroups?.length;
      path.push({
        label: subGroupObj?.name ?? subGroup,
        url: !hasSubGroups ? '' : getPackUsageUrl({ group, subGroup }),
      });
      if (!hasSubGroups) {
        path.push({ label: QUESTION.CAPITALIZED_PLURAL });
        return path;
      }
    }

    if (leafGroup) {
      const leafGroupObj = resolvedGroup ? findChildGroupByCode(resolvedGroup, leafGroup) : undefined;
      const hasSubGroups = leafGroupObj?.subgroups?.length;
      path.push({
        label: leafGroupObj?.name ?? leafGroup,
        url: !hasSubGroups ? '' : getPackUsageUrl({ group, subGroup, leafGroup }),
      });
      if (!hasSubGroups) {
        path.push({ label: QUESTION.CAPITALIZED_PLURAL });
        return path;
      }
    }

    if (leafChildGroup) {
      path.push({
        label: resolvedGroup
          ? findChildGroupByCode(resolvedGroup, leafChildGroup)?.name ?? leafChildGroup
          : leafChildGroup,
      });
      path.push({ label: QUESTION.CAPITALIZED_PLURAL });
    }

    return path;
  }, [group, subGroup, leafGroup, leafChildGroup, metricGroups, getPackUsageUrl]);


  const canDrillDown = useMemo(() => {
    if (!group) {
      return true; // All top groups allow drilldown
    }

    const resolvedGroup = getGroup(ViewValues.StandardsAndFrameworks, group);
    if (!resolvedGroup || !resolvedGroup.subgroups?.length) {
      return false;
    }

    if (subGroup) {
      const subGroupObj = findChildGroupByCode(resolvedGroup, subGroup);
      if (!subGroupObj || !subGroupObj.subgroups?.length) {
        return false;
      }
    }

    if (leafGroup) {
      const leafGroupObj = findChildGroupByCode(resolvedGroup, leafGroup);
      if (!leafGroupObj || !leafGroupObj.subgroups?.length) {
        return false;
      }
    }

    return !leafChildGroup;  // Cannot go more than this
  }, [group, subGroup, leafGroup, leafChildGroup])

  return (
    <BenchmarkingTemplate>
      {isError ? (
        <BasicAlert type={'danger'} className='mt-2'>{error.message}</BasicAlert>
      ) : (
        <div className='mt-4 pack-usage__container'>
          <BenchmarkingFilters
            filters={filters}
            dateRange={dateRange}
            changeFilters={(value) => setFilters(value)}
            changeDateRange={(value) => setDateRange(value)}
            downloadPackUsage={downloadPackUsage}
          />

          <div className={group ? 'mt-4 mb-2' : 'mt-4'}>
            {group ? <AdminBreadcrumbs breadcrumbs={breadcrumbs} initiativeId={portfolioId} isBenchmarking /> : null}
          </div>

          {isLoading || isFetching ? (
            <Loader />
          ) : (
            <PackUsageTable
              packUsage={data?.packs}
              handleClickScope={handleClickScope}
              isQuestionPage={!canDrillDown}
            />
          )}
        </div>
      )}
    </BenchmarkingTemplate>
  );
};
