import {
  createSlice, PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers
} from '@reduxjs/toolkit';


export interface GenericState<T = any[]> {
  data: T,
  loaded: boolean,
  loading: boolean;
  errored?: boolean,
  lastUpdated?: number,
  errorMessage?: string;
}

export const createGenericSlice = <
  T,
  Reducers extends SliceCaseReducers<GenericState<T>> = SliceCaseReducers<GenericState<T>>
  >({
      name = '',
      initialState,
      reducers,
    }: {
  name: string
  initialState: GenericState<T>
  reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      load(state: GenericState<T>) {
        return {
          ...state,
          loading: true,
          loaded: false,
          errored: false,
          errorMessage: ''
        };
      },
      loaded(state: GenericState<T>, action: PayloadAction<T>) {
        return {
          ...state,
          loading: false,
          loaded: true,
          data: action.payload,
          errored: false,
          errorMessage: ''
        }
      },
      error(state, action: PayloadAction<Error>) {
        return {
          ...state,
          loading: false,
          errored: true,
          errorMessage: action.payload.message
        };
      },
      ...reducers,
    },
  })
}
