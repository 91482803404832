import { Paragraph, Table } from 'docx';
import { ReportData } from '../../types/reportData';
import { UtrValueTypes } from '../../utils/universalTracker';
import { getNaType } from '../../utils/universalTrackerValue';
import { DEFAULTS, paragraph, paragraphArray, spacer, Styles, table } from './document-structure';
import { NotApplicableTypes } from '../../constants/status';
import { getNumberScaleText, getUnitDescription, UnitTypes } from '../../utils/units';
import { TableColumnType } from '../../types/universalTracker';
import { getLatestNote } from './group-builder';
import { InputColumn, TableColumn } from '../survey/form/input/table/InputInterface';
import { formatCalculationNumber } from '../../utils/formula';
import { NO_ANSWER_SUBMITTED } from './constants';
import { UnitConfig } from '../../model/surveyData';
import { Option } from '../../types/valueList';
import {
  getNumberScaleCode,
  getTableNumberScaleCode,
  getTableUnitCode,
  getUnitCode,
  getUtrvDataProp,
  getUtrvTableProp,
  getUtrvValue
} from '../../utils/utrvData';

const getNumberScaleExportedText = (numberScale: string | undefined) => {
  const numberScaleText = getNumberScaleText(numberScale, '').toLowerCase();
  return numberScaleText ? ` ${numberScaleText}` : numberScaleText;
};

/** Non-table question numberScale + unit **/
export const getUnit = (q: ReportData, unitConfig?: UnitConfig, displayUserInput?: boolean) => {

  if (q.universalTracker.unitType === UnitTypes.currency) {
    const unit = unitConfig?.currency || '';
    const numberScale = getNumberScaleCode(q, q.universalTracker, displayUserInput);
    const numberScaleText = getNumberScaleExportedText(numberScale);
    return `${numberScaleText} ${unit}`;
  }

  const unit = getUnitCode(q, q.universalTracker, displayUserInput);
  if (!unit) {
    return '';
  }

  try {
    return ` ${getUnitDescription(unit).plural}`;
  } catch {
    return ` ${unit}`;
  }
}

/**
 * A bit weird, that we expect to return space + answers if available here...
 **/
export const getUnitNumberScaleColumn = (
  columnDefinition: TableColumn,
  unitConfig: UnitConfig | undefined,
  inputColumn: InputColumn,
  displayUserInput: boolean | undefined,
) => {
  if (columnDefinition.type === TableColumnType.Number && columnDefinition.unitType === UnitTypes.currency) {
    const numberScale = getTableNumberScaleCode(columnDefinition, inputColumn, displayUserInput);
    const numberScaleText = getNumberScaleExportedText(numberScale);
    return `${numberScaleText} ${unitConfig?.currency || columnDefinition.unit || ''}`;
  }

  const unit = getTableUnitCode(columnDefinition, inputColumn, displayUserInput);

  if (!unit) {
    return '';
  }
  try {
    return ` ${getUnitDescription(unit).plural}`;
  } catch {
    return ` ${unit}`;
  }
};

export function getValue(value: string | number) {
  if (typeof value === 'number') {
    return formatCalculationNumber(value)
  }
  return value;
}

function getColumnValue(tableColumn: TableColumn, value: string | number | string[], round = 2) {
  if (typeof value === 'number') {
    return formatCalculationNumber(value)
  }

  if (typeof value === 'string' && tableColumn.type === TableColumnType.Number) {
    try {
      return formatCalculationNumber(value)
    } catch (e) {
      console.error(e)
    }
  }

  return value;
}

const getValueListOption = (q: ReportData, key: string) => {
  const valueValidationValueList = q.universalTracker.valueValidation?.valueList;
  const optionsList: Option[] | undefined = valueValidationValueList?.custom ?? valueValidationValueList?.list
  const keyName = optionsList?.find(item => item.code === key);
  return keyName?.name ?? key;
}

const renderSimpleValues = (q: ReportData, styles?: Styles, unitConfig?: UnitConfig, displayUserInput?: boolean): Paragraph => {
  const unit = getUnit(q, unitConfig, displayUserInput);
  return renderSimpleValueUnit(q, unit, styles, displayUserInput);
}

const renderSimplePercentage = (q: ReportData, styles?: Styles, displayUserInput?: boolean): Paragraph => {
  return renderSimpleValueUnit(q, '%', styles, displayUserInput);
}

const renderSimpleValueUnit = (q: ReportData, unit: string, styles?: Styles, displayUserInput?: boolean): Paragraph => {
  const questionValueListData = getUtrvDataProp(q, displayUserInput);
  if (typeof questionValueListData === 'string' && questionValueListData) {
    const valueStr = getValueListOption(q, questionValueListData);
    return paragraphArray([`${valueStr}${unit}`], styles);
  }

  const value = getUtrvValue(q, displayUserInput);

  if (value === undefined) {
    return paragraphArray([NO_ANSWER_SUBMITTED]);
  }

  return paragraphArray([`${getValue(value)}${unit}`], styles);
}

const renderValueListMulti = (q: ReportData, styles?: Styles, displayUserInput?: boolean): Paragraph => {
  const multiValueData = getUtrvDataProp(q, displayUserInput);
  if (!Array.isArray(multiValueData)) {
    return paragraph(NO_ANSWER_SUBMITTED);
  }

  const values = multiValueData.map(key => getValueListOption(q, key));
  return paragraphArray(values, styles);
}

/** Actually rendering valueList, numericValueList, textValueList... **/
const renderTextValueList = (q: ReportData, styles?: Styles, unitConfig?: UnitConfig, displayUserInput?: boolean): Paragraph => {
  const textValueData = getUtrvDataProp(q, displayUserInput);
  if (typeof textValueData === 'string') {
    return renderSimpleValueUnit(q, '', styles, displayUserInput);
  }

  const unit = getUnit(q, unitConfig, displayUserInput);
  const options = [] as string[];
  if (textValueData) {
    for (const [key, value] of Object.entries(textValueData)) {
      const valueStr = getValueListOption(q, key);
      options.push(valueStr, `${value}${unit}`);
    }
  }
  return paragraphArray(options.length > 0 ? options : [NO_ANSWER_SUBMITTED], styles);
}

const getTableData = (q: ReportData, unitConfig: UnitConfig | undefined, displayUserInput?: boolean) => {
  const columns = q.universalTracker.valueValidation?.table?.columns ?? [];
  const tableHeader = columns.map(n => n.name);
  const questionData = getUtrvTableProp(q, displayUserInput);

  const tableData = questionData?.map(row => {
    const rowColumnValues = [] as any[];
    for (const columnMetadata of columns) {
      const code = columnMetadata.code;
      const column = row.find((column) => column.code === code);

      if (!column) {
        rowColumnValues.push('');
        continue; // nothing we can do without value column
      }

      if (columnMetadata.listId && q.tableValueList) {
        const tableValueList = q.tableValueList.find(l => l._id === columnMetadata.listId);
        if (tableValueList) {
          if (column.value && Array.isArray(column.value)) {
            const arrValues = column.value;
            const valueOptions = tableValueList.options.filter(v => arrValues.includes(v.code));
            rowColumnValues.push(valueOptions.map(o => o.name).join(', '));
          } else {
            const valueOption = tableValueList.options.find(v => v.code === column.value);
            rowColumnValues.push(valueOption?.name ?? column.value);
          }
        } else {
          rowColumnValues.push(column.value ?? '');
        }
        continue;
      }

      const unitColumn = getUnitNumberScaleColumn(columnMetadata, unitConfig, column, displayUserInput);
      const valueString = column.value ? `${getColumnValue(columnMetadata, column.value)}${unitColumn}` : '';
      rowColumnValues.push(valueString);
    }
    return rowColumnValues
  }
  );

  if (!tableData) {
    return;
  }

  tableData.unshift(tableHeader);
  return tableData;
}

// object params
interface TableParams {
  question: ReportData
  unitConfig: UnitConfig | undefined,
  returnEmpty?: boolean,
  width?: number,
  SGXHeaderStyle?: Styles,
  displayUserInput?: boolean,
}

export const renderTable = ({
  question,
  unitConfig,
  returnEmpty = true,
  width = 9000,
  SGXHeaderStyle,
  displayUserInput = false,
}: TableParams): Table | undefined => {
  const tableData = getTableData(question, unitConfig, displayUserInput);
  const isEmpty = !tableData || tableData.length === 1;

  if (!tableData || (isEmpty && !returnEmpty)) {
    return;
  }
  return table(tableData, width, SGXHeaderStyle);
};

interface Options {
  table?: {
    width: number,
    returnEmpty?: boolean;
  }
  displayUserInput?: boolean;
}

export const renderQuestionValue = (
  question: ReportData,
  unitConfig?: UnitConfig,
  styles?: Styles,
  options?: Options
) => {
  const naData = getNaType(question);
  if (naData) {
    if (naData === NotApplicableTypes.nr) {
      return paragraphArray(['Not Reporting'], styles);
    } else if (naData === NotApplicableTypes.na) {
      return paragraphArray(['Not Applicable'], styles);
    }
    return '';
  }
  const valueType = question.valueType ?? question.universalTracker.valueType;
  switch (valueType) {
    case UtrValueTypes.table:
      return renderTable({
        question,
        unitConfig,
        SGXHeaderStyle: styles,
        displayUserInput: options?.displayUserInput,
        ...options?.table,
      });
    case UtrValueTypes.text:
    case UtrValueTypes.number:
    case UtrValueTypes.date:
    case UtrValueTypes.sample:
      return renderSimpleValues(question, styles, unitConfig, options?.displayUserInput);
    case UtrValueTypes.percentage:
      return renderSimplePercentage(question, styles, options?.displayUserInput);
    case UtrValueTypes.textValueList:
    case UtrValueTypes.numericValueList:
    case UtrValueTypes.valueList:
      return renderTextValueList(question, styles, unitConfig, options?.displayUserInput)
    case UtrValueTypes.valueListMulti:
      return renderValueListMulti(question, styles, options?.displayUserInput);
    default:
      return paragraph('', styles); // Not supported question
  }
}

const styleValueLabel = {
  style: 'bold',
  indent: DEFAULTS.INDENT,
  spacing: {
    before: DEFAULTS.PARAGRAPH_BEFORE,
    after: 0
  }
};

const styleValue = {
  style: 'plain',
  spacing: {
    before: 0,
    after: DEFAULTS.PARAGRAPH_AFTER
  }
}

const tableNoteStyles = {
  style: 'plain',
  indent: { left: 0 },
  spacing: {
    before: DEFAULTS.PARAGRAPH_BEFORE,
    after: DEFAULTS.PARAGRAPH_AFTER,
  },
};

export const renderQuestionGroup = (questions: ReportData[], reference: string, unitConfig?: UnitConfig, displayUserInput?: boolean) => {
  const output: (Paragraph | Table)[] = [];
  questions.forEach(q => {
    const v = renderQuestionValue(q, unitConfig, styleValue, { displayUserInput });
    if (!v) {
      return;
    }
    output.push(
      paragraph(q.universalTracker.valueLabel, styleValueLabel),
    );
    const isTable = q.universalTracker.valueType === UtrValueTypes.table
    if (isTable) {
      output.push(spacer(DEFAULTS.PARAGRAPH_BEFORE));
    }
    output.push(v);

    const note = getLatestNote(q);
    if (note) {
      output.push(paragraph(`Additional notes: ${note}`, isTable ? tableNoteStyles : styleValue));
    }
  });
  return output;
}
