import { $getRoot, $getSelection, $isRangeSelection, $isTextNode, LexicalEditor } from 'lexical';

import { $isAtNodeEnd } from '@lexical/selection';
import { ElementNode, RangeSelection, TextNode } from 'lexical';

// This function is copied from /lexical/packages/lexical-playground/src/utils/getSelectedNode.ts
export function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
  }
}

export const putCursorAtTheEnd = (editor: LexicalEditor) => {
  editor.focus();
  editor.update(() => {
    const root = $getRoot();

    // After appending, move the selection to the end of the content
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Get the last descendant of the root, not just the last child
      const lastDescendant = root.getLastDescendant();

      // Check if the last descendant is a TextNode
      if ($isTextNode(lastDescendant)) {
        const textContentLength = lastDescendant.getTextContentSize();
        // Set the selection at the end of the last text node
        selection.setTextNodeRange(lastDescendant, textContentLength, lastDescendant, textContentLength);
      }
    }
  });
};
