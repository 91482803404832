import { getTableInputData } from './universalTrackerValue';
import { RowStatus, ValueData } from '../components/survey/question/questionInterfaces';


export const tableDataToView = (valueData: ValueData | undefined) => {
  const table = getTableInputData({ valueData })

  const tableData = Array.isArray(table) ? table : [];

  return {
    rows: tableData.map((row, i) => ({
      rowStatus: RowStatus.original,
      isRemoved: false,
      id: i,
      data: [...row],
    })),
    editRowId: -1,
  }
}
