import { useContext } from 'react';
import { ButtonGroup } from 'reactstrap';
import IconButton from '../../button/IconButton';
import { CardGridViewMode } from '../../survey-scope/CardGrid';
import { SurveyContext } from '../../survey-container/SurveyContainer';

export const CardGridViewModeButtons = () => {

  const {
    sidebarSettings,
    handleChangeSettings,
  } = useContext(SurveyContext);

  return (
    <ButtonGroup>
      <IconButton
        active={sidebarSettings.viewLayout !== CardGridViewMode.list}
        outline={sidebarSettings.viewLayout === CardGridViewMode.list}
        color='secondary'
        className='px-2 d-none d-lg-inline'
        onClick={() => handleChangeSettings('viewLayout', CardGridViewMode.gallery)}
        icon='fa-th'
        tooltip='Gallery View'
      />
      <IconButton
        outline={sidebarSettings.viewLayout !== CardGridViewMode.list}
        color='secondary'
        className='px-2 d-none d-lg-inline'
        active={sidebarSettings.viewLayout === CardGridViewMode.list}
        onClick={() => handleChangeSettings('viewLayout', CardGridViewMode.list)}
        icon='fa-th-list'
        tooltip='List View'
      />
    </ButtonGroup>
  );
}
