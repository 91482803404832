import { isNumericString } from '@utils/string';

export const extractPageNumbersFromReferences = (reference: string) => {
  if (isNumericString(reference)) {
    // this is a page number
    return Number(reference);
  }
  const regex = /Page (\d+)/i;
  const match = reference.match(regex);
  if (!match) {
    return undefined;
  }
  return Number(match[1]);
}

export const attachPageReferenceToPdf = (url: string, pageRef: number) => {
  if (!url.endsWith('.pdf')) {
    // url has already had some params
    return `${url}&page=${pageRef}`;
  }
  return `${url}#page=${pageRef}`;
}

export const isValidPdfUrl = (url?: string | null) => {
  if (!url) {
    return false;
  }
  const pdfRegex = /\.pdf(#.*)?$/;
  return pdfRegex.test(url);
};
