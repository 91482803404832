import React from 'react';
import { DataSource, Esg30DataSources } from '.';
import { ReportData } from '../../../types/reportData';
import { GenderSplit } from './data/GenderSplit';
import { GenderSplitPc } from './data/GenderSplitPc';
import { GenderPayGap } from './data/GenderPayGap';
import { BoardGender } from './data/BoardGender';
import { BoardDiversity } from './data/BoardDiversity';
import { GHGEmissions } from './data/GHGEmissions';
import { EnergyConsumption } from './data/EnergyConsumption';
import { EnergyIntensityRatio } from './data/EnergyIntensityRatio';
import { WaterConsumption } from './data/WaterConsumption';
import { WasteGenerated } from './data/WasteGenerated';
import { AverageTraining } from './data/AverageTraining';
import { GoogleChartWrapper, ReactGoogleChartEvent } from 'react-google-charts/dist/types';
import { DATE, formatDate } from '../../../utils/date';

export const CHART_DEFAULTS = {
  OPTIONS: {
    vAxis: {
      format: 'decimal',
      gridlines: { count: 4 },
    },
    colors: ['#A6CFE5', '#D6F5DF', '#1A5A87'],
    pieSliceTextStyle: {
      color: '#0c3866',
    },
    legend: {
      position: 'none',
    },
    chartArea: {
      top: 50,
      bottom: 20,
      left: 80,
      right: 20,
    },
    titleTextStyle: {
      color: 'a1a3a4',
    },
  },
};

interface ChartReadyHandler {
  key: keyof Esg30DataSources;
  data: DataSource;
  handler: (key: string, data: DataSource) => void;
}

export const chartReadyEvent = (params: ChartReadyHandler): ReactGoogleChartEvent => {
  const { key, data, handler } = params;
  return {
    eventName: 'ready',
    callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
    data.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
    handler(key, data);
  }};
};

export const getReportEffectiveDate = (questionData: ReportData[], utrCode: string) => {
  const utrv = questionData.find((d) => d.universalTracker.code === utrCode);
  const label = utrv?.effectiveDate ? formatDate(utrv.effectiveDate, DATE.MONTH_YEAR_SHORT, true) : '';
  return label;
}

export interface GenerateChartsProps {
  updateData: (key: string, data: DataSource) => void;
  questionData: ReportData[];
}

export const GenerateCharts = React.memo((props: GenerateChartsProps) => {
  return (
    <div style={{ height: 0, width: 0, overflow: 'hidden' }}>
      <GHGEmissions {...props} />
      <EnergyConsumption {...props} />
      <EnergyIntensityRatio {...props} />
      <WaterConsumption {...props} />
      <WasteGenerated {...props} />
      <GenderSplit {...props} />
      <GenderSplitPc {...props} />
      <GenderPayGap {...props} />
      <AverageTraining {...props} />
      <BoardGender {...props} />
      <BoardDiversity {...props} />
    </div>
  );
});
