import { Button } from 'reactstrap';
import { DashboardRow, DashboardSection } from '../../components/dashboard';
import { InsightDashboard } from '../../types/insight-custom-dashboard';
import { DashboardSettings } from './dashboard-settings';
import { GridLayoutDashboard } from './GridLayoutDashboard';
import { GridItemProps } from './GridItem';
import { SharingButton } from './shared-dashboard/SharingButton';
import { SdgChart } from '../../components/impact-performance/SdgChart';
import { SafeInitiativeFields } from '../../types/initiative';
import { CompanyProfile } from '../../components/company-profile';
import { CustomDashboardInfoIcon } from '@features/custom-dashboard';
import { InsightDashboardOption, TOOLTIP_MESSAGE } from '../summary/insights/utils/sidebar';
import { DataPeriods } from '../../types/universalTracker';
import { useContext } from 'react';
import { CustomDashboardContext } from './context/CustomDashboardWrapper';
import { SurveyPeriodDropdown } from '@components/survey-period-dropdown';
import { FeatureStability, TimeRangeSelector } from '@g17eco/molecules';
import { hasSDGContributionItem } from '@routes/custom-dashboard/utils';
import { DataFilters } from './types';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';
import { useExportDashboard } from '@features/custom-dashboard/hooks/useExportDashboard';
import { BlockingLoader } from '@components/loader/BlockingLoader';
import { CustomDashboardTitle } from './CustomDashboardTitle';

type Props = Pick<GridItemProps, 'handleOpenUtrvHistoryModal' | 'survey' | 'integrationsData'> & {
  dashboard: InsightDashboard;
  handleClickEdit: () => void;
  handleSave: (dashboard: Partial<InsightDashboard>) => void;
  handleDelete: () => void;
  canManage?: boolean;
  initiative: SafeInitiativeFields;
  isCurrentLevelDashboard: boolean;
  availablePeriods: DataPeriods[];
  currentPage: string;
  dashboardOptions: InsightDashboardOption[];
  handleClickOption: (item: InsightDashboardOption) => void;
} & DataFilters ;

export const CustomDashboard = (props: Props) => {
  const {
    dashboard,
    handleClickEdit,
    handleSave,
    handleDelete,
    handleOpenUtrvHistoryModal,
    canManage,
    initiative,
    isCurrentLevelDashboard,
    survey,
    availablePeriods,
    period,
    setPeriod,
    dateRange,
    timeRange,
    onChangeDateRange,
    currentPage = '',
    dashboardOptions = [],
    handleClickOption = () => {},
  } = props;
  const initiativeId = initiative._id;
  const { hideOptions, hideShareButton, hideQuestionReference, readOnly } = useContext(CustomDashboardContext);
  const isStaffUser = useAppSelector(isStaff);

  const showStaticSDGContributionChart =
    dashboard.filters.sdgContribution?.enabled && !hasSDGContributionItem(dashboard.items);

    const { isLoading: isLoadingExport, dashboardRef, exportToPDF } = useExportDashboard({
      dashboardTitle: dashboard.title,
    });

  return (
    <>
      {isLoadingExport ? <BlockingLoader /> : null}
      <DashboardRow>
        <div className='d-flex w-100 justify-content-end align-items-start'>
          <div className='d-flex'>
            {canManage && isCurrentLevelDashboard ? (
              <>
                <Button color='secondary' onClick={handleClickEdit} className='ms-2 px-2'>
                  <i className='fa-light fa-pencil fs-6 mr-1' /> Edit
                </Button>
                <DashboardSettings
                  key={`${Number(hasSDGContributionItem(dashboard.items))} ${dashboard._id}`}
                  handleSave={handleSave}
                  handleDelete={handleDelete}
                  dashboard={dashboard}
                  hideOptions={hideOptions}
                  availablePeriods={availablePeriods}
                />
                {isStaffUser ? (
                  <Button color='secondary' onClick={exportToPDF} className='ms-2'>
                    <i className='fa-light fa-file-arrow-down mr-2'></i>
                    <span>Export dashboard</span>
                    <FeatureStability color='inherit' className='ms-2' stability='internal' />
                  </Button>
                ) : null}
                {hideShareButton ? null : <SharingButton dashboard={dashboard} />}
              </>
            ) : null}
          </div>
        </div>
      </DashboardRow>
      {dashboard.filters.initiativeInfo?.enabled ? <CompanyProfile initiative={initiative} readOnly={false} /> : null}
      <DashboardRow>
        <div className='w-100 d-flex justify-content-between'>
          <div className='flex-grow-1 d-flex align-items-center'>
            <CustomDashboardTitle
              title={
                <>
                  <h3 className='text-ThemeHeadingLight'>{dashboard.title}</h3>
                  <CustomDashboardInfoIcon text={!isCurrentLevelDashboard ? TOOLTIP_MESSAGE.IS_SHARED_BY_PARENT : ''} />
                </>
              }
              currentPage={currentPage}
              options={dashboardOptions}
              handleClickOption={handleClickOption}
            />
          </div>
          <div className='d-flex align-items-center dashboard-filters-wrapper'>
            <SurveyPeriodDropdown
              key={availablePeriods.length}
              styleProps={{ dropdown: 'ms-2', isTransparent: true, isFlexibleSize: true }}
              period={period}
              availablePeriods={availablePeriods}
              setPeriod={setPeriod}
            />
            <TimeRangeSelector
              timeRange={timeRange}
              dateRange={dateRange}
              onChangeDateRange={onChangeDateRange}
              styleProps={{ wrapper: 'ms-2', isTransparent: true, isFlexibleSize: true }}
            />
          </div>
        </div>
      </DashboardRow>
      {showStaticSDGContributionChart && initiativeId && dashboard.scorecard ? (
        <DashboardSection>
          <SdgChart initiativeId={initiativeId} scorecard={dashboard.scorecard} />
        </DashboardSection>
      ) : null}
      <GridLayoutDashboard
        isEditing={false}
        gridItems={dashboard.items}
        utrsData={dashboard.utrsData}
        scorecard={dashboard.scorecard}
        integrationsData={dashboard.integrationsData}
        handleOpenUtrvHistoryModal={handleOpenUtrvHistoryModal}
        readOnly={readOnly || !dashboard.filters.baselinesTargets?.enabled || !canManage}
        initiativeId={initiativeId}
        survey={survey}
        hideQuestionReference={hideQuestionReference}
        dataFilters={{ period, dateRange, timeRange }}
        dashboardRef={dashboardRef}
      />
    </>
  );
};
