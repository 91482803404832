import Draggable, { DraggableEventHandler } from 'react-draggable';
import IconButton from '../../../button/IconButton';
import { Column } from '../utils';

const ROW_HEIGHT = 40;
const OVER_FIRST_ITEM_INDEX = 0.5;

// End index will get update when dragging over a item
// Moved position could be positive or negative
const getEndIndex = (startIndex: number, movedPosition: number) => {
  const movedPositionOverItem = movedPosition > 0 ? (movedPosition - ROW_HEIGHT / 2) : (movedPosition + ROW_HEIGHT / 2);
  const newIndex = startIndex + movedPositionOverItem / ROW_HEIGHT;
  if (newIndex <= OVER_FIRST_ITEM_INDEX) {
    return 0;
  }
  return Math.round(newIndex);
};

interface DraggableColumnsProps {
  columns: Column[];
  updateColumns: (columns: Column[]) => void;
}

const DraggableColumns = ({ columns, updateColumns }: DraggableColumnsProps) => {
  const handleDrop: DraggableEventHandler = (e, data) => {
    const startIndex = Number(data.node.dataset.index);
    const endIndex = getEndIndex(startIndex, data.y);

    const dragColumn = columns[startIndex];
    const newColumns = [...columns];
    newColumns.splice(startIndex, 1);
    newColumns.splice(endIndex, 0, dragColumn);

    updateColumns(newColumns);
  };

  const handleRemoveColumn = (column: Column) => {
    const newColumns = columns.filter(col => col.code !== column.code);
    updateColumns(newColumns);
  }

  return (
    <div className='position-relative d-flex flex-column'>
      {columns.map((column, index) => (
        <Draggable
          key={column.code}
          position={{ x: 0, y: 0 }}
          bounds='parent'
          axis='y'
          handle='strong'
          onStop={handleDrop}
        >
          <div
            id={`draggable-${column.code}`}
            data-index={index}
            className='position-relative d-flex w-100 align-items-center'
            style={{ height: ROW_HEIGHT }}
          >
            <strong className='fa-solid fa-grip-dots-vertical text-ThemeIconSecondary cursor-pointer p-1'></strong>
            <span className='ml-2 pr-3'>{column.name}</span>

            <div className='ml-auto'>
              <IconButton
                tooltip='Remove this column from the show list'
                color='danger'
                onClick={() => handleRemoveColumn(column)}
                icon='fa-light fa-times'
                className='icon-button'
              />
            </div>
          </div>
        </Draggable>
      ))}
    </div>
  );
};

export default DraggableColumns;
