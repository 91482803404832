import { createContext, useContext, useState } from 'react';
import { CurrentInputData, UtrvConnection } from './types';

export type SelectedConnection = UtrvConnection | undefined;
type InputData = number | undefined;

interface ConnectionContextProps {
  connection: SelectedConnection;
  setConnection: (connection: UtrvConnection) => void;
  setInputData: (data: InputData) => void;
  inputData?: InputData;
  removeConnection: () => void;
  currentInputData: CurrentInputData;
}

export const ConnectionContext = createContext<ConnectionContextProps>({
  setConnection: (_connection) => {},
  connection: undefined,
  setInputData: (_data) => {},
  inputData: undefined,
  removeConnection: () => {},
  currentInputData: { table: { editRowId: -1, rows: [] } },
});

export const useConnectionContext = () => {
  return useContext(ConnectionContext);
};

interface Props {
  children: JSX.Element;
  currentInputData: CurrentInputData;
}

export const ConnectionProvider = ({ children, currentInputData }: Props) => {
  const [connection, setConnection] = useState<SelectedConnection>();
  const [inputData, setInputData] = useState<InputData>();

  const removeConnection = () => {
    setConnection(undefined);
    setInputData(undefined);
  };

  return (
    <ConnectionContext.Provider
      value={{
        connection,
        setConnection: (connection: UtrvConnection) => setConnection(connection),
        setInputData: (data: InputData) => setInputData(data),
        inputData,
        removeConnection,
        currentInputData,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};
