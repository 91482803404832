import { useEffect, useMemo, useState } from 'react';
import { Option, SelectStyleProps, SelectFactory, SelectTypes } from '@g17eco/molecules';
import { DATE, TIME_RANGE_OPTIONS, TIME_RANGE_YEARLY, setFormat, TimeRangeOption } from '@utils/date';
import DateRangePicker, { getDateRange } from './DateRangePicker';
import { DateRangeType } from '@g17eco/types/common';
import './styles.scss';
import { TimeFrameType } from '../../types/insight-custom-dashboard';
import { FormatOptionLabelMeta } from 'react-select';

interface StyleProps extends SelectStyleProps {
  wrapper?: string;
  datePicker?: string;
}

export interface TimeRangeSelectorProps {
  timeRange?: string | number;
  dateRange?: DateRangeType;
  onChangeDateRange?: (dateRange: DateRangeType, period: string | number) => void;
  timeRangeOptions?: TimeRangeOption[];
  styleProps?: StyleProps;
}

export const TimeRangeSelector = ({
  timeRange = TIME_RANGE_YEARLY,
  dateRange = {},
  onChangeDateRange = () => {},
  timeRangeOptions = TIME_RANGE_OPTIONS,
  styleProps = {
    wrapper: '',
    datePicker: '',
    isFlexibleSize: false,
    isTransparent: false,
  },
}: TimeRangeSelectorProps) => {
  const [defaultTimeRange, setDefaultTimeRange] = useState<string | number>(timeRange);
  const [showDatePicker, setShowDateRangePicker] = useState(false);

  const defaultDateRange: { startDate: Date; endDate: Date } = useMemo(() => {
    return {
      startDate: dateRange.startDate ? new Date(dateRange.startDate) : new Date(),
      endDate: dateRange.endDate ? new Date(dateRange.endDate) : new Date(),
    };
  }, [dateRange]);

  useEffect(() => {
    if (timeRange) {
      setDefaultTimeRange(timeRange);
    }
  }, [timeRange]);

  const selectedOption = timeRangeOptions.find((o) => o.value === defaultTimeRange) ?? timeRangeOptions[0];

  const isSelectedOptionCustom =
    selectedOption.value === TimeFrameType.Custom && dateRange.startDate && dateRange.endDate;

  const formatOptionLabel = (
    option: Option<string | number> | null,
    meta: FormatOptionLabelMeta<Option<string | number> | null>
  ) => {
    if (!option) {
      return <></>;
    }

    if (meta.context === 'value' && isSelectedOptionCustom) {
      return (
        <span>
          {setFormat(DATE.DEFAULT_SPACES, dateRange.startDate)} - {setFormat(DATE.DEFAULT_SPACES, dateRange.endDate)}
        </span>
      );
    }
    return option.label;
  };

  const handleSelectTimeRange = (option: Option<string | number> | null) => {
    if (!option) {
      return;
    }
    setDefaultTimeRange(option.value);
    if (option.value === TimeFrameType.Custom) {
      setShowDateRangePicker(true);
    } else {
      onChangeDateRange(getDateRange(option.value), option.value);
      setShowDateRangePicker(false);
    }
  };

  const handleSubmitDateRange = (range: DateRangeType) => {
    onChangeDateRange(range, 'custom');
    setShowDateRangePicker(false);
  };

  return (
    <div className={`select-time-range ${styleProps.wrapper}`}>
      <SelectFactory<string | number>
        selectType={SelectTypes.SingleSelect}
        options={timeRangeOptions}
        onChange={(op) => handleSelectTimeRange(op)}
        formatOptionLabel={formatOptionLabel}
        value={selectedOption}
        isSearchable={false}
        isTransparent={!!styleProps.isTransparent}
        isFlexibleSize={!!styleProps.isFlexibleSize}
      />
      {showDatePicker ? (
        <DateRangePicker
          dateRange={defaultDateRange}
          callbackClose={() => setShowDateRangePicker(false)}
          callbackSubmit={handleSubmitDateRange}
          className={styleProps.datePicker}
        />
      ) : null}
    </div>
  );
};
