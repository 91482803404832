import Select, { Props as BaseSelectProps, GroupBase } from 'react-select';
import { DropdownIndicator, Option, getStyles, SelectStyleProps } from './SelectFactory';
import { FormGroup, FormText, Label, LabelProps } from 'reactstrap';

export type LabelSelectProps = BaseSelectProps<Option | null, false, GroupBase<Option | null>> &
  SelectStyleProps & {
    labelProps?: LabelProps;
    description?: string;
  };

export const LabelSelect = (props: LabelSelectProps) => {
  const {
    labelProps,
    description,
    isTransparent,
    isFlexibleSize,
    isMenuPortalTargetBody = false,
    showDropdownIndicator = true,
    showCount = false,
    ...rest
  } = props;

  return (
    <FormGroup>
      {labelProps ? (
        <Label for={labelProps.for} className={`text-black fw-medium ${labelProps.className}`}>
          {labelProps.title}
        </Label>
      ) : null}
      <Select
        components={{
          DropdownIndicator: () => (
            <DropdownIndicator
              current={props.value !== null ? 1 : 0}
              limit={rest.options?.length}
              showCount={showCount}
              showDropdownIndicator={showDropdownIndicator}
            />
          ),
        }}
        styles={getStyles({
          isMulti: false,
          isTransparent,
          isFlexibleSize,
          isMenuPortalTargetBody,
        })}
        menuPortalTarget={isMenuPortalTargetBody ? document.body : null}
        {...rest}
      />
      {description ? <FormText>{description}</FormText> : null}
    </FormGroup>
  );
};
