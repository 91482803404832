import { DashboardSectionCollapsible } from '../dashboard';
import { QuestionInformation } from '../survey/question/QuestionInformation';
import { TrackerInformation } from './components/TrackerInformation';
import UniversalTracker from '../../model/UniversalTracker';
import { useToggle } from '../../hooks/useToggle';

interface Props {
  utr: UniversalTracker;
  alternativeCode?: string;
}
export const CollapsibleQuestionInfo = ({ utr, alternativeCode }: Props) => {
  const [isCollapsed, toggle] = useToggle(true);

  const classes = {
    valueLabel: 'text-lg',
    instructions: 'text-sm',
    instructionsLink: 'xs',
  };

  return (
    <DashboardSectionCollapsible
      isCollapsed={isCollapsed}
      toggleCollapse={toggle}
      className={isCollapsed ? 'question-info-collapsed' : 'question-info'}
      title={utr.getName()}
    >
      <QuestionInformation utr={utr} alternativeCode={alternativeCode ?? ''} classes={classes} />
      <TrackerInformation utr={utr} />
    </DashboardSectionCollapsible>
  );
};
