import React from 'react';
import Chart from 'react-google-charts';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps, chartReadyEvent, getReportEffectiveDate } from '../GenerateCharts';

const WIDTH = 300;
const HEIGHT = 250;

export interface EnergyIntensityRatioDataSource extends DataSource {
  ratio?: number;
}

export const EnergyIntensityRatio = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrCode = 'gri/2020/302-3/a';
  const utrv = questionData.find((d) => d.universalTracker.code === utrCode);
  const value = utrv?.value ?? 0;

  const reportData: EnergyIntensityRatioDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    ratio: value,
  };

  const chartData = [
    ['Energy Intensity Ratio', 'Intensity Ratio', { role: 'style' }, { role: 'annotation' }],
    [getReportEffectiveDate(questionData, utrCode), value, '#A6CFE5', value],
  ];

  return (
    <Chart
      chartType='ColumnChart'
      data={chartData}
      width={WIDTH}
      height={HEIGHT}
      options={{
        ...CHART_DEFAULTS.OPTIONS,
        title: 'Energy Intensity Ratio.',
        vAxis: {
          format: 'decimal',
          gridlines: { count: 4 },
          minValue: 0,
        },
      }}
      chartEvents={[chartReadyEvent({ key: 'energyIntensityRatio', data: reportData, handler: updateData })]}
    />
  );
});
