import { Button } from 'reactstrap';
import { RequestScope } from '@actions/api';
import { useIndustrySector } from '@components/industry-sector-selector/useIndustrySector';
import { Option, SelectFactory, SelectTypes, TimeRangeSelector } from '@g17eco/molecules';
import { DateRangeType } from '@g17eco/types/common';
import { FilterSurveyPacks } from './FilterSurveyPacks';

export interface Filters {
  sector?: string;
  industry?: string | null;
  scopeGroups?: RequestScope[];
}

interface BenchmarkingFiltersProps {
  filters: Filters;
  dateRange: DateRangeType;
  changeFilters: (filters: Filters) => void;
  changeDateRange: (dateRange: DateRangeType) => void;
  downloadPackUsage: () => void;
}

export const BenchmarkingFilters = (props: BenchmarkingFiltersProps) => {
  const { filters, dateRange, changeFilters, changeDateRange, downloadPackUsage } = props;
  const { sectorOptions, industryOptions } = useIndustrySector(filters.sector);

  const handleChangeFilters = (name: string, scopeGroups: RequestScope[]) => {
    const newFilters = { ...filters, [name]: scopeGroups };
    changeFilters(newFilters);
  };

  const handleChangeSectorIndustryFilters = (name: string, option: Option | null) => {
    let newFilters = { ...filters };
    if (name === 'sector') {
      // reset industry if sector change
      newFilters = { ...newFilters, industry: null };
    }
    newFilters = { ...newFilters, [name]: option?.value ? option?.label : null };

    changeFilters(newFilters);
  };

  return (
    <div className='d-flex justify-content-between align-items-center gap-3'>
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        placeholder='Sector'
        isClearable
        options={sectorOptions}
        value={sectorOptions.find((item) => item.label === filters.sector) ?? null}
        onChange={(option) => handleChangeSectorIndustryFilters('sector', option)}
      />
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        placeholder='Industry'
        isClearable
        options={industryOptions}
        value={industryOptions.find((item) => item.label === filters.industry) ?? null}
        onChange={(option) => handleChangeSectorIndustryFilters('industry', option)}
        isDisabled={!filters.sector}
      />
      <div className='flex-grow-1'>
        <FilterSurveyPacks
          selectedScopes={filters.scopeGroups?.map((scope) => scope.code)}
          handleSurveyPackFilter={(option: RequestScope[]) => handleChangeFilters('scopeGroups', option)}
        />
      </div>
      <TimeRangeSelector dateRange={dateRange} onChangeDateRange={(range) => changeDateRange(range)} />
      <Button color='link' className='ml-2'>
        <i className='fal fa-file-excel' onClick={downloadPackUsage}></i>
      </Button>
    </div>
  );
};
