import { useEffect } from 'react';

export const useChatbotVisibilityControl = (shouldHide: boolean) => {
  useEffect(() => {
    const zE = window.zE;
    if (!zE) return;
    zE('messenger:set', 'zIndex', shouldHide ? -1 : 99999);

    return () => zE('messenger:set', 'zIndex', 99999);
  }, [shouldHide]);
};
