import { CompanyTagType } from '@g17eco/types/initiative';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { Action } from '@constants/action';

interface BetaFeatureTagsResponse {
  configTags: CompanyTagType[];
  defaultTags: CompanyTagType[];
}

export const initiativeBetaFeaturesApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['beta-features-tags'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBetaFeatureTags: builder.query<BetaFeatureTagsResponse, string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `/initiatives/${initiativeId}/beta-features-tags`,
          method: 'get',
        }),
        providesTags: (result, error, initiativeId) => {
          return [{ type: 'beta-features-tags', id: initiativeId }];
        },
      }),
      updateBetaFeatureTags: builder.mutation<CompanyTagType[], { tag: CompanyTagType, action: Action; initiativeId: string }>({
        invalidatesTags: (result, error, arg) => [{ type: 'beta-features-tags', id: arg.initiativeId }],
        transformResponse,
        query: ({ initiativeId, ...rest }) => ({
          url: `/initiatives/${initiativeId}/beta-features-tags`,
          method: 'patch',
          data: rest,
        }),
      }),
    }),
  });

export const { useGetBetaFeatureTagsQuery, useUpdateBetaFeatureTagsMutation } = initiativeBetaFeaturesApi;
