import { HistoricalUtrs } from '@api/insights';
import { UtrvType } from '@constants/status';
import { isNumericString } from '@utils/string';

export const getActualUtrsData = (utrsData: HistoricalUtrs[]): HistoricalUtrs[] => {
  return utrsData.map((utrData) => {
    return { ...utrData, utrvs: utrData.utrvs.filter((utrv) => utrv.type === UtrvType.Actual) };
  });
};

export const getDisplayNumericValue = (calculationValue: any) => {
  return isNumericString(calculationValue)
    ? calculationValue.toLocaleString('en-GB', {
        maximumFractionDigits: 2,
      })
    : calculationValue;
};
