import Dashboard, { DashboardRow, DashboardSection } from '@components/dashboard';
import { Button } from 'reactstrap';
import { useToggle } from '@hooks/useToggle';
import { BulkOnboardingModal } from './BulkOnboardingModal';
import { OnboardingLists } from './OnboardingLists';

interface Props {
  initiativeId: string;
  userLimit: number;
  BreadcrumbsComponent: React.ReactNode;
}

export const BulkOnboardingContainer = (props: Props) => {
  const { BreadcrumbsComponent, initiativeId, userLimit } = props;
  const [isOpen, toggleOpen] = useToggle();

  return (
    <Dashboard>
      <DashboardRow>{BreadcrumbsComponent}</DashboardRow>
      <DashboardSection
        title='Invite multiple users'
        paddingInternal={0}
        titleClass='mt-0'
        buttons={[
          <Button
            key='import-users'
            data-testid='import-users-btn'
            type='button'
            color='secondary'
            onClick={toggleOpen}
          >
            <i className='fal fa-upload mr-1' /> Import users
          </Button>,
        ]}
      >
        <OnboardingLists initiativeId={initiativeId} />
      </DashboardSection>
      <BulkOnboardingModal
        isOpen={isOpen}
        toggle={toggleOpen}
        initiativeId={initiativeId}
        userLimit={userLimit}
      />
    </Dashboard>
  );
};
