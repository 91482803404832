import { useEffect } from 'react';
import { RequestScope } from '../../../actions/api';
import G17Client from '../../../services/G17Client';
import { ReportTypes } from '../../../types/statsTypes';
import { parseUserStringToDate } from '../../../utils/date';
import { isValidDate } from '../utils';

export interface ExtraProps {
  [k: string]: string | boolean | undefined | RequestScope[] | string[];
}

interface ReportDataProps {
  dateParams: {
    fromDate?: string;
    toDate?: string;
  };
  reportType: ReportTypes;
  successCallback: (d?: any) => void;
  errorCallback: (e?: Error) => void;
  extraProps?: ExtraProps;
}

export const useReportData = ({ dateParams, reportType, successCallback, errorCallback, extraProps }: ReportDataProps) => {
  useEffect(() => {
    const newParams: { fromDate?: Date; toDate?: Date; extraProps?: ExtraProps } = { ...extraProps };
    if (dateParams.fromDate) {
      if (!isValidDate(dateParams.fromDate)) {
        return;
      }
      newParams.fromDate = parseUserStringToDate(dateParams.fromDate).toDate();
    }
    if (dateParams.toDate) {
      if (!isValidDate(dateParams.toDate)) {
        return;
      }
      newParams.toDate = parseUserStringToDate(dateParams.toDate).endOf('day').toDate();
    }

    // setRows(undefined);
    G17Client.getAdminStats(reportType, newParams).then(successCallback).catch(errorCallback);
  }, [dateParams.fromDate, dateParams.toDate, reportType, successCallback, errorCallback, extraProps]);
};
