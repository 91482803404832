import { createApi } from '@reduxjs/toolkit/query/react'
import { Referral } from '../types/initiative';
import { ApiResponse } from '../types/api';
import { axiosBaseQuery } from './axiosQuery';
import { ProductCodes } from '../services/SubscriptionService';

interface AddReferralParams {
  initiativeId: string;
  productCode: ProductCodes,
  referral: Referral
}

// Define a service using a base URL and expected endpoints
export const referralsApi = createApi({
  reducerPath: 'referralsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['referrals'],
  endpoints: (builder) => ({
    getReferrals: builder.query<Referral[], Pick<AddReferralParams, 'initiativeId' | 'productCode'>>({
      transformResponse: (response: ApiResponse<Referral[]>) => response.data,
      query: ({ initiativeId, productCode }) => ({
        url: `/initiatives/${initiativeId}/referrals/${productCode}`,
        method: 'get'
      }),
    }),
    addReferral: builder.mutation<ApiResponse<Referral[]>, AddReferralParams>({
      invalidatesTags: ['referrals'],
      query: ({ initiativeId, productCode, referral }) => ({
        url: `/initiatives/${initiativeId}/referrals/${productCode}`,
        method: 'post',
        data: referral,
      }),
    }),
  }),
})

export const { useAddReferralMutation, useGetReferralsQuery } = referralsApi
