import { InsightDashboardType } from '../../../types/insight-custom-dashboard';

type ESGDashboard =
  | InsightDashboardType.Social
  | InsightDashboardType.Governance
  | InsightDashboardType.Environmental
  | InsightDashboardType.SgxEnvironmental
  | InsightDashboardType.SgxSocial
  | InsightDashboardType.SgxGovernance;
export const isESGDashboard = (page: unknown): page is ESGDashboard => {
  return [
    InsightDashboardType.Environmental,
    InsightDashboardType.Social,
    InsightDashboardType.Governance,
    InsightDashboardType.SgxEnvironmental,
    InsightDashboardType.SgxSocial,
    InsightDashboardType.SgxGovernance,
  ].includes(page as ESGDashboard);
};
