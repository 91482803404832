import classNames from 'classnames';
import { DataScopeAccess, DataShareScopeView } from '../../../../types/dataShare';
import { InitiativeCompany } from '../../../../types/initiative';
import { getAcceptedDataShares, mergeScopeViews } from '../../../../utils/dataShare';
import { getViewAccess } from '../utils';
import { SURVEY } from '@constants/terminology';
import { SimpleTooltip } from '@g17eco/molecules';

const ACCESS_TEXT_MAP = {
  [DataShareScopeView.Insights]: 'Insights',
  [DataShareScopeView.Downloads]: 'Downloads',
  [DataShareScopeView.Survey]: `Full ${SURVEY.CAPITALIZED_SINGULAR} access`,
  [DataShareScopeView.Messaging]: 'Messaging',
};

export const getAccessHint = (company: InitiativeCompany) => {
  const shareScopeViewsAccess = getViewAccess(company);

  if (shareScopeViewsAccess === DataScopeAccess.None) {
    return (
      <div className='p-2'>
        <span>Not shared:</span>
        <br />
        <span>Insights, Downloads, Full {SURVEY.CAPITALIZED_SINGULAR} access, and Messaging.</span>
      </div>
    );
  }

  if (shareScopeViewsAccess === DataScopeAccess.Full) {
    return (
      <div className='p-2'>
        <span>Company is sharing:</span>
        <br />
        <span>Insights, Downloads, Full {SURVEY.CAPITALIZED_SINGULAR} access, and Messaging.</span>
      </div>
    );
  }

  const mergedScopeViews = mergeScopeViews(getAcceptedDataShares(company.requestedDataShares));

  if (shareScopeViewsAccess === DataScopeAccess.Partial) {
    const sharing = Object.values(DataShareScopeView)
      .filter((item) => mergedScopeViews.includes(item))
      .map((item) => ACCESS_TEXT_MAP[item])
      .join(', ');
    const notShared = Object.values(DataShareScopeView)
      .filter((item) => !mergedScopeViews.includes(item))
      .map((item) => ACCESS_TEXT_MAP[item])
      .join(', ');
    return (
      <div className='p-2'>
        <p className='mb-1'>
          <span>Company is sharing:</span>
          <br />
          <span>{sharing}</span>
        </p>
        <p className='mb-1'>
          <span>Not shared:</span>
          <br />
          <span>{notShared}</span>
        </p>
      </div>
    );
  }
};

interface AccessStatusProps {
  company: InitiativeCompany;
}
const AccessStatus = ({ company }: AccessStatusProps) => {
  return (
    <SimpleTooltip text={getAccessHint(company)}>
      <div className={classNames('access-status', getViewAccess(company))} />
    </SimpleTooltip>
  );
};

export default AccessStatus;
