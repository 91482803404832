import { SurveyOverviewMode } from '../../../../slice/surveySettings';
import { Toggle } from '@g17eco/molecules';

interface Props {
  overviewMode: SurveyOverviewMode;
  onChange: (isShowingMappedMetrics: boolean) => void;
  isChecked: boolean;
}

export const MappedMetricsToggle = (props: Props) => {
  const { overviewMode, onChange, isChecked } = props;

  // only show the toggle if view = Reporting packs
  if (overviewMode !== SurveyOverviewMode.ScopeGroups) {
    return null;
  }

  return (
    <Toggle
      className={{ form: 'd-flex align-items-center pl-0 mb-0 justify-content-end', label: 'text-md ms-1' }}
      checked={isChecked}
      onChange={() => onChange(!isChecked)}
      label='Reveal mapped metrics'
    />
  );
};
