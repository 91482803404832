import React from 'react';
import Chart from 'react-google-charts';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps, chartReadyEvent, getReportEffectiveDate } from '../GenerateCharts';
import { getDataAsNumberTableSum } from '../../../../routes/summary/insights/utils/helpers';

const WIDTH = 600;
const HEIGHT = 400;

export interface AverageTrainingDataSource extends DataSource {
  maleHours?: number;
  femaleHours?: number;
}

export const AverageTraining = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrCode = 'gri/2020/404-1/a';

  const maleHours = getDataAsNumberTableSum(questionData, utrCode, 'male');
  const femaleHours = getDataAsNumberTableSum(questionData, utrCode, 'female');

  const reportData: AverageTrainingDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    maleHours,
    femaleHours,
  };

  const chartData = [
    ['Training Hours', 'Male', { role: 'annotation' }, 'Female', { role: 'annotation' }],
    [getReportEffectiveDate(questionData, utrCode), maleHours, maleHours, femaleHours, femaleHours],
  ];

  return (
    <Chart
      chartType='ColumnChart'
      data={chartData}
      width={WIDTH}
      height={HEIGHT}
      options={{
        ...CHART_DEFAULTS.OPTIONS,
        title: 'Average hours of training.',
        chartArea: {
          top: 50,
          bottom: 50,
          left: 80,
          right: 20,
        },
        vAxis: {
          title: 'Hours',
          format: 'decimal',
          gridlines: { count: 4 },
          minValue: 0,
        },
        legend: {
          position: 'bottom'
        },
      }}
      chartEvents={[chartReadyEvent({ key: 'averageTraining', data: reportData, handler: updateData }),]}
    />
  );
});
