import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { companyEditOptions } from '../company-settings/EditOption';
import { useTextCollapse } from './useTextCollapse';
import { InitiativePlain } from '../../types/initiative';

type InitiativeProfile = Pick<InitiativePlain, 'description' | 'missionStatement'>;

interface ProfileParagraphProps {
  initiative: InitiativeProfile;
  field: keyof InitiativeProfile;
  isCollapsed: boolean;
}
export const ProfileParagraph = (props: ProfileParagraphProps) => {
  const { initiative, field, isCollapsed } = props;
  const [currentHeight, setHeight] = useState(0);
  const [isHidden, setHidden] = useState(true);

  useEffect(() => {
    setHeight(document.querySelector(`.${field}`)?.scrollHeight || 0);
  }, [isCollapsed, field]);

  const { height, instructionClass, isTruncated } = useTextCollapse({
    isHidden,
    height: currentHeight,
  });
  const HiddenBtn = () => {
    const text = isHidden ? 'Read more' : 'Read less';
    const icon = isHidden ? 'fa-angle-down' : 'fa-angle-up';
    return (
      <Button color='link-secondary' onClick={() => setHidden((prev) => !prev)}>
        <div className='text-sm py-1'>
          <i className={`fa ${icon} mr-1 text-sm`} />
          {text}
        </div>
      </Button>
    );
  };

  return initiative[field] ? (
    <>
      <div className='mt-4 mb-1 text-label text-medium'>
        {companyEditOptions.find((option) => option.accessor === field)?.label.toUpperCase()}
      </div>
      <div className={`${field} ${instructionClass}`} style={{ height }}>
        {initiative[field]}
      </div>
      {isTruncated ? <HiddenBtn /> : null}
    </>
  ) : null
};
