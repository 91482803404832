import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import './styles.scss';
import { defaultConfig } from './constants';
import { EditorState } from 'lexical';

const initialConfig = {
  ...defaultConfig,
  namespace: 'MyEditor',
  editable: false,
};

interface Props {
  editorState: EditorState;
}

export const ReadonlyRichTextEditor = ({ editorState }: Props) => {
  return (
    <LexicalComposer initialConfig={{ ...initialConfig, editorState }}>
      <div className='editor-container read-only position-relative'>
        <RichTextPlugin
          contentEditable={<ContentEditable className='' />}
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ListPlugin />
      </div>
    </LexicalComposer>
  );
};
