import Select from 'react-select';
import { TableColumnType, UniversalTrackerPlain, getTableColumnTypeNames } from '../../types/universalTracker';
import { UtrValueTypes } from '../../utils/universalTracker';
import UniversalTracker from '../../model/UniversalTracker';
import { useEffect, useState } from 'react';
import { ValueListPlain } from '../../types/valueList';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';

interface ColumnOption {
  value: string,
  label: string,
  disabled?: boolean,
}

interface Props {
  utr: UniversalTracker;
  selectedColumnCode?: string;
  valueList: ValueListPlain | undefined;
  handleChangeColumn: (column: string) => void;
}

const allowedUtrColumnTypes = [TableColumnType.Number];

export const TOTAL_OPTION = { value: 'total', label: 'Total' };

export const ColumnFilter = ({ utr, selectedColumnCode, valueList, handleChangeColumn }: Props) => {
  const utrPlain: UniversalTrackerPlain = utr.getRaw();
  const [columnOptions, setColumnOptions] = useState<ColumnOption[]>([]);

  const isTable = utr.getValueType() === UtrValueTypes.table;
  const isNumericValueList = utr.getValueType() === UtrValueTypes.numericValueList;

  useEffect(() => {
    if (isTable && utrPlain.valueValidation?.table) {
      const tableColumnsOptions = utrPlain.valueValidation?.table?.columns.map((c) => {
        const isAllowed = allowedUtrColumnTypes.includes(c.type);
        const typeString = !isAllowed ? 'Not Supported' : getTableColumnTypeNames(c.type, Boolean(c.listId), c);
        return {
          value: c.code,
          label: `${c.name} (${typeString})`,
          isDisabled: !isAllowed,
        };
      });
      setColumnOptions(tableColumnsOptions);
      return;
    }

    if (isNumericValueList && valueList?.options?.length) {
      const options = valueList.options.map(o => ({ value: o.code, label: o.name }))
      setColumnOptions([TOTAL_OPTION, ...options]);
      return;
    }
  }, [utrPlain, isTable, isNumericValueList, valueList]);

  if ((!isTable && !isNumericValueList) || (isNumericValueList && !valueList)) {
    return (
      <div className='col-12 col-md-4'>
        <SelectFactory selectType={SelectTypes.SingleSelect} placeholder='Default input' isDisabled options={[]} />
      </div>
    );
  }

  return (
    <div className='col-12 col-md-4'>
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        placeholder={'Metric input'}
        isClearable={true}
        options={columnOptions}
        value={columnOptions?.find((col) => col.value === selectedColumnCode)}
        onChange={(col) => handleChangeColumn(col?.value ?? '')}
      />
    </div>
  );
};
