import { ExistingEvidenceFile } from '@components/survey/question/questionInterfaces';
import { attachPageReferenceToPdf, extractPageNumbersFromReferences, isValidPdfUrl } from '@utils/file/pdf';
import { isValidUrl } from '@utils/url';

interface Props {
  file: ExistingEvidenceFile;
}

export const EvidenceUrl = (props: Props) => {
  const { file: { link, url, description } } = props;
  const fileUrl = link || url;

  if (!isValidUrl(fileUrl)) {
    return <span className='text-truncate'>{fileUrl}</span>;
  }

  if (isValidPdfUrl(fileUrl)) {
    const firstPage = description ? extractPageNumbersFromReferences(description) : 1;
    const urlWithPageRef = firstPage ? attachPageReferenceToPdf(fileUrl, firstPage) : fileUrl;
    return (
      <a href={urlWithPageRef} target={'_blank'} rel='noopener noreferrer' className='text-truncate'>
        {urlWithPageRef}
      </a>
    );
  }

  return (
    <a href={fileUrl} target={'_blank'} rel='noopener noreferrer' className='text-truncate'>
      {fileUrl}
    </a>
  );
};
