import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { generateUrl } from '../../../routes/util';

export const useGoBack = (initiativeId: string) => {
  const history = useHistory();
  return () => {
    return history.push(`${generateUrl(ROUTES.SURVEY_TEMPLATES, { initiativeId })}`);
  };
};
