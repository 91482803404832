import UniversalTracker from '@models/UniversalTracker';
import { Addon } from '@components/survey/form/input/InputProps';
import { AssurancePortfolio, PartialAssuranceField, UniversalTrackerValueAssurance } from '@g17eco/types/assurance';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { UtrValueType } from '@g17eco/types/universalTracker';
import { getTableConfiguration, isNumberOrTextValueListType, isSingleRowTableType } from '@utils/universalTracker';
import { UtrvAssuranceStatus } from '@g17eco/types/universalTrackerValue';
import { PartialAssurance } from '@g17eco/molecules';

export const usePartialAssuranceQuestionView = (
  utr: UniversalTracker | undefined,
  utrv: SurveyModelMinimalUtrv | undefined,
  assurancePortfolio: { assurances?: Pick<AssurancePortfolio, 'universalTrackerValueAssurances'>[] } | undefined
) => {
  if (!utr || !utrv || !utrv.assuranceStatus || ![UtrvAssuranceStatus.Partial, UtrvAssuranceStatus.Completed].includes(utrv.assuranceStatus)) {
    return { addons: [] };
  }

  const combinedUtrvAssurances: UniversalTrackerValueAssurance[] = (assurancePortfolio?.assurances ?? []).reduce(
    (acc, assurance) => {
      const { universalTrackerValueAssurances = [] } = assurance;
      return [...acc, ...universalTrackerValueAssurances];
    },
    [] as UniversalTrackerValueAssurance[]
  );

  const combinedUtrvPartialFields: PartialAssuranceField[] = combinedUtrvAssurances
    .filter((utrvAssurance) => utrvAssurance.utrvId === utrv?._id)
    .reduce((acc, utrvAssurance) => {
      const { partialFields = [] } = utrvAssurance;
      return [...acc, ...partialFields];
    }, [] as PartialAssuranceField[]);

  const columns = getTableConfiguration(utr)?.columns ?? [];
  const isCompletedAssurance = utrv.assuranceStatus === UtrvAssuranceStatus.Completed;

  const getAddons = (): Addon[] => {
    if (isNumberOrTextValueListType(utr)) {
      const data = utrv.valueData?.data ?? {};
      return Object.keys(data).reduce((acc, code: string) => {
        const isAssured = combinedUtrvPartialFields.some((f) => f.code === code);
        if (isCompletedAssurance || isAssured) {
          acc.push({
            code,
            element: PartialAssurance({ isAssured: isCompletedAssurance || isAssured }),
          });
        }
        return acc;
      }, [] as Addon[]);
    }

    if (isSingleRowTableType(utr)) {
      return columns.reduce((acc, column) => {
        const { code } = column;
        const isAssured = combinedUtrvPartialFields.some((f) => f.code === code);
        if (isCompletedAssurance || isAssured) {
          acc.push({
            code,
            rowIndex: 0,
            element: PartialAssurance({ isAssured: isCompletedAssurance || isAssured }),
          });
        }
        return acc;
      }, [] as Addon[]);
    }

    if (utr.getValueType() === UtrValueType.Table) {
      const rows = utrv.valueData?.table ?? [];
      return rows.reduce((acc, _, rowIndex) => {
        const isAssured = columns.every((column) => combinedUtrvPartialFields.some((f) => f.rowIndex === rowIndex && f.code === column.code));
        if (isCompletedAssurance || isAssured) {
          acc.push({
            // Dont need code because we already checked all columns
            code: '',
            rowIndex: rowIndex,
            element: PartialAssurance({ isAssured: isCompletedAssurance || isAssured, classes: { wrapper: '' } }),
          });
        }
        return acc;
      }, [] as Addon[]);
    }

    return [];
  };

  return { addons: getAddons() };
};
