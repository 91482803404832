import { GroupBase, MenuListProps, components } from 'react-select';
import { Input } from 'reactstrap';

interface CustomOptionMenuListProps {
  setShowCreateModal: (show: boolean) => void;
}

export const CustomOptionMenuList = <OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>>(
  props: MenuListProps<OptionType, IsMulti, Group> & CustomOptionMenuListProps
) => {
  const { setShowCreateModal, isMulti } = props;

  return (
    <components.MenuList {...props}>
      {props.children}
      <div
        className='create-new-option d-flex align-items-center text-ThemeTextDark p-2'
        style={{ cursor: 'pointer' }}
        onClick={() => setShowCreateModal(true)}
      >
        {isMulti ? (
          <Input type='checkbox' checked={false} onChange={() => {}} className='mt-0 me-2 ms-1 flex-shrink-0' />
        ) : null}
        <i className='fal fa-plus mr-1 text-xs fw-bold' /> Create custom option
      </div>
    </components.MenuList>
  );
};
