import { HistoryData, ValueAggregation } from '../types/disaggregation';
import { g17ecoApi, transformResponse } from './g17ecoApi';

export interface DisaggregationResponse {
  valueAggregation: ValueAggregation;
  disaggregatedData: HistoryData[];
}

export const disaggregationApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['disaggregation'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDisaggregationByUtrvId: builder.query<DisaggregationResponse, string>({
        transformResponse,
        query: (utrvId) => ({
          url: `/universal-tracker-values/${utrvId}/disaggregation`,
          method: 'get',
        }),
        providesTags: ['disaggregation'],
      }),
    }),
  });

export const { useLazyGetDisaggregationByUtrvIdQuery, useGetDisaggregationByUtrvIdQuery } = disaggregationApi;
