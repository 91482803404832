import { BenchmarkingTemplate } from '../BenchmarkingTemplate';
import { SubmissionInsightsContainer } from './SubmissionInsightsContainer';
import { SubmissionInsightsFilter } from './SubmissionInsightsFilter';
import { SubmissionInsightsTable } from './SubmissionInsightsTable';

export const SubmissionInsights = () => {
  return (
    <BenchmarkingTemplate>
      <SubmissionInsightsContainer>
        <SubmissionInsightsFilter />
        <SubmissionInsightsTable />
      </SubmissionInsightsContainer>
    </BenchmarkingTemplate>
  );
};
