import { createApi } from '@reduxjs/toolkit/query/react';
import { Action } from '../constants/action';
import { ApiResponse } from '../types/api';
import { CustomMetricOrderType, MetricGroup, Tag } from '../types/metricGroup';
import { axiosBaseQuery } from './axiosQuery';
import { OrderingDirection } from '@g17eco/types/common';

interface MetricGroupsMutationParams {
  initiativeId: string;
  groupId: string;
  initiativeIds: string[];
}

interface OrderTypeMutationParams {
  initiativeId: string;
  groupId: string;
  orderType: CustomMetricOrderType;
  direction?: OrderingDirection;
  utrIds?: string[];
}

interface DeleteCustomTagsParams extends Pick<MetricGroupsMutationParams, 'initiativeId'> {
  ids: Tag['_id'][];
}

type CreateCustomTagsParams = Pick<Tag, 'groupName'> & Pick<MetricGroupsMutationParams, 'initiativeId'>;

export type SelectedTagIdsType = { [key in Action]: Tag['_id'][] };

interface UpdateCustomTagsParams extends Pick<MetricGroupsMutationParams, 'initiativeId'> {
  updatedTagIds: SelectedTagIdsType;
  utrIds: string[];
}

const transformResponse = <T>(response: ApiResponse<T>) => response.data;

export const metricGroupsApi = createApi({
  reducerPath: 'metricGroupsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['metricGroups', 'customTags'],
  endpoints: (builder) => ({
    getMetricGroups: builder.query<MetricGroup[], string>({
      transformResponse,
      query: (initiativeId) => ({
        url: `/initiatives/${initiativeId}/metric-groups`,
        method: 'get',
      }),
      providesTags: ['metricGroups'],
    }),
    addCompaniesToMetricGroup: builder.mutation<ApiResponse, MetricGroupsMutationParams>({
      invalidatesTags: ['metricGroups'],
      query: ({ initiativeId, initiativeIds, groupId }) => ({
        url: `/initiatives/${initiativeId}/metric-groups/${groupId}/share`,
        method: 'patch',
        data: {
          initiativeIds,
        },
      }),
    }),
    getCustomTags: builder.query<Tag[], string>({
      transformResponse,
      query: (initiativeId) => ({
        url: `/initiatives/${initiativeId}/metric-groups/tag`,
        method: 'get',
      }),
      providesTags: ['customTags'],
    }),
    createCustomTag: builder.mutation<Tag, CreateCustomTagsParams>({
      invalidatesTags: ['customTags'],
      transformResponse,
      query: ({ initiativeId, ...data }) => {
        return {
          url: `/initiatives/${initiativeId}/metric-groups/tag`,
          method: 'post',
          data,
        };
      },
    }),
    updateCustomTags: builder.mutation<Tag, UpdateCustomTagsParams>({
      invalidatesTags: ['customTags'],
      transformResponse,
      query: ({ initiativeId, ...data }) => {
        return {
          url: `/initiatives/${initiativeId}/metric-groups/tag`,
          method: 'patch',
          data,
        };
      },
    }),
    deleteCustomTags: builder.mutation<Tag, DeleteCustomTagsParams>({
      invalidatesTags: ['customTags'],
      transformResponse,
      query: ({ initiativeId, ids }) => {
        return {
          url: `/initiatives/${initiativeId}/metric-groups/tag`,
          method: 'delete',
          data: { ids },
        };
      },
    }),
    updateMetricGroupOrderType: builder.mutation<MetricGroup, OrderTypeMutationParams>({
      invalidatesTags: ['metricGroups'],
      transformResponse,
      query: ({ groupId, initiativeId, ...data }) => ({
        url: `/initiatives/${initiativeId}/metric-groups/${groupId}/order`,
        method: 'patch',
        data,
      }),
    }),
  }),
});

export const {
  endpoints,
  useGetMetricGroupsQuery,
  useAddCompaniesToMetricGroupMutation,
  useGetCustomTagsQuery,
  useCreateCustomTagMutation,
  useUpdateCustomTagsMutation,
  useDeleteCustomTagsMutation,
  useUpdateMetricGroupOrderTypeMutation,
} = metricGroupsApi;
