import { SafeUser, SurveyUserRoles, UserMin } from '../../constants/users';
import { SurveyActionData } from '../../model/surveyData';
import { CurrentUserData } from '../../reducers/current-user';
import { OnboardingStatus } from '../../types/onboarding';
import { MassDelegation } from '../../types/survey';
import { UniversalTrackerValuePlain } from '../../types/surveyScope';

export type User = UserMin &
  Pick<SafeUser, 'email' | 'isStaff'> & {
    status?: OnboardingStatus;
    initiativeRoles?: string[];
    surveyRoles?: string[];
    count?: { verified: number; contributed: number };
  };

export type DelegatedUsers = { contributors: User[]; verifiers: User[] };

export enum RoleLevel {
  Initiative = 'initiative',
  Survey = 'survey',
  Utrv = 'utrv',
}

export type DelegationModalSurvey = Pick<SurveyActionData, '_id' | 'initiativeId' | 'roles' | 'stakeholders'>;

export type DelegatedUsersTableProps = {
  canAccessVerification: boolean;
  currentUser: CurrentUserData | undefined;
  users: DelegatedUsers;
  utrvs: Pick<UniversalTrackerValuePlain, '_id' | 'stakeholders' | 'initiativeId' | 'compositeData'>[];
  survey: DelegationModalSurvey;
  initiativeId: string,
  startDelegating: (userRole: SurveyUserRoles) => void;
  handleRemoveUser: ({ role, userId }: Pick<MassDelegation, 'role' | 'userId'>) => void;
  isReadonly: boolean;
};

