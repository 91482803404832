import { Button } from 'reactstrap';
import { SimpleTooltip } from '@g17eco/molecules';
import { Calculation, GridDashboardChartItem } from '../../../../../types/insight-custom-dashboard';
import { getChartDataByValues } from '../../../../../utils/charts';
import NumberFormat from '../../../../../utils/number-format';
import { ChartType, UtrVariable } from '../../../../summary/insights/utils/constants';
import { DISABLED_TARGET_BASELINE_ADDING_TOOLTIP } from '../../../utils';
import { getLatestActualAndTargetSingleUtr, getTextColor } from './utils';
import Chart from 'react-google-charts';
import { getSparklineChartProps } from '../../../../summary/insights/utils/helpers';
import { TakenFromText } from '../common/TakenFromText';
import { Note } from '../common/Note';
import { isNumeric } from '@utils/number';
import { ChartUtrData } from '../../types';

interface Props extends Pick<GridDashboardChartItem, 'variables' | 'note'> {
  utrsData: ChartUtrData[];
  handleSetTarget?: () => void;
  hasSparkLine?: boolean;
  altUnitText: string;
  variableValue: UtrVariable;
  metricUnit: string;
  decimalPlaces?: number;
  readOnly: boolean;
  isSuffix: boolean;
  calculation: Calculation;
}

export const SingleUtrSingleValue = (props: Props) => {
  const {
    utrsData,
    calculation,
    variables,
    note,
    handleSetTarget,
    hasSparkLine,
    altUnitText,
    readOnly,
    variableValue,
    metricUnit,
    decimalPlaces,
    isSuffix,
  } = props;
  const {
    actualValue,
    targetValue,
    effectiveDate = '',
    period,
    variablesWithValues,
  } = getLatestActualAndTargetSingleUtr({ utrsData, calculation, variables });
  const utrData = utrsData.find((data) => data.utr.code === variableValue.code);
  const chartData = getChartDataByValues(utrData?.utrvs, variableValue.valueListCode);

  const showTarget = targetValue !== '' && isNumeric(targetValue);

  const textColor = getTextColor({ actualValue, targetValue });

  return (
    <>
      <div className='w-100 h-100 d-flex flex-column justify-content-center'>
        {isNumeric(actualValue) ? (
          <NumberFormat
            className={`h1 m-0 text-center ${textColor}`}
            value={actualValue}
            suffix={isSuffix ? metricUnit : ''}
            decimalPlaces={decimalPlaces}
          />
        ) : (
          <div className='h1 m-0 text-center text-ThemeHeadingLight'>-</div>
        )}
        {!isSuffix ? <div className='text-sm text-center text-ThemeTextLight'>{metricUnit}</div> : null}
        {altUnitText ? <div className={`text-sm text-center ${textColor}`}>{altUnitText}</div> : null}
        {showTarget ? (
          <div className={`text-xs text-center ${textColor}`}>
            Target: {targetValue} {metricUnit}
          </div>
        ) : !readOnly ? (
          <SimpleTooltip text={!handleSetTarget ? DISABLED_TARGET_BASELINE_ADDING_TOOLTIP : ''} className='text-center'>
            <Button
              color='link'
              disabled={!handleSetTarget}
              onClick={handleSetTarget}
              className='text-xs text-ThemePrimary'
            >
              Set target
            </Button>
          </SimpleTooltip>
        ) : null}
        {note ? (
          <Note note={note} variablesWithValues={variablesWithValues} className='text-center text-ThemeTextLight' />
        ) : null}
      </div>
      {hasSparkLine ? (
        <Chart
          height={'100%'}
          chartType={ChartType.LineChart}
          data={chartData}
          options={getSparklineChartProps({ isRestated: false })}
        />
      ) : null}
      <TakenFromText effectiveDate={effectiveDate} period={period} />
    </>
  );
};
