import { createApi } from '@reduxjs/toolkit/query/react'
import { CalculatorInfo } from './types';
import { axiosBaseQuery } from '@api/axiosQuery';
import { transformResponse } from '@api/g17ecoApi';


export const carbonCalculatorsApi = createApi({
  reducerPath: 'carbonCalculatorsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['carbon-calculators'],
  endpoints: (builder) => ({
    getCarbonCalculatorsList: builder.query<CalculatorInfo[], void>({
      transformResponse,
      query: () => ({
        url: '/o/carbon-calculators',
        method: 'get',
      })
    }),
    getEmissionPartner: builder.query<CalculatorInfo, { partnerCode: string, initiativeId: string }>({
      transformResponse,
      query: ({ initiativeId, partnerCode }) => ({
        url: `/initiatives/${initiativeId}/emissions/${partnerCode}`,
        method: 'get',
      })
    })
  }),
});

export const { useGetCarbonCalculatorsListQuery, useGetEmissionPartnerQuery } = carbonCalculatorsApi;
