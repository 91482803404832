import { useHistory, useParams } from 'react-router-dom';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { generateUrl } from '../../../routes/util';
import { ROUTES } from '../../../constants/routes';
import { RouteParams } from '../types';
import { SURVEY } from '@constants/terminology';

enum CreateType {
  SurveyTemplate = 'template',
  AggregatedTemplate = 'aggregated',
}

const options = [
  {
    label: (
      <span>
        <i className='fas fa-plus-square mr-2' /> Create {SURVEY.SINGULAR} template
      </span>
    ),
    value: CreateType.SurveyTemplate,
  },
  {
    label: (
      <span>
        <i className='fa fa-object-exclude mr-2' /> Create combined {SURVEY.SINGULAR} template
      </span>
    ),
    value: CreateType.AggregatedTemplate,
  },
];

export const TemplateCreateDropdown = () => {
  const { initiativeId } = useParams<RouteParams>();
  const history = useHistory();

  const goTo = (type: CreateType) => {
    return history.push(`${generateUrl(ROUTES.SURVEY_TEMPLATES, { initiativeId })}/${type}/create`);
  };

  return (
    <SelectFactory<CreateType>
      selectType={SelectTypes.SingleSelect}
      placeholder={
        <span>
          <i className='fa fa-bars mr-2' />
          Create new template
        </span>
      }
      options={options}
      onChange={(op) => (op ? goTo(op.value) : undefined)}
      isTransparent
      isFlexibleSize
      isSearchable={false}
      showDropdownIndicator={false}
    />
  );
};
