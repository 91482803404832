import React from 'react';
import { Chart } from 'react-google-charts';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps, chartReadyEvent } from '../GenerateCharts';
import { getDataAsNumberTableSum } from '../../../../routes/summary/insights/utils/helpers';

const WIDTH = 300;
const HEIGHT = 300;

export interface BoardGenderDataSource extends DataSource {
  male?: number;
  female?: number;
}

export const BoardGender = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrCode = 'gri/2020/405-1/a';
  const male = getDataAsNumberTableSum(questionData, utrCode, 'male');
  const female = getDataAsNumberTableSum(questionData, utrCode, 'female');
  const total = female + male;

  const reportData: BoardGenderDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    male: !total ? 0 : 100 * male / total,
    female: !total ? 0 : 100 * female / total,
  };

  const chartData = [
    ['Gender', 'Percentage'],
    ['Female', reportData.female],
    ['Male', reportData.male],
  ];

  return (
    <Chart
      chartType='PieChart'
      data={chartData}
      width={WIDTH}
      height={HEIGHT}
      options={{
        ...CHART_DEFAULTS.OPTIONS,
        chartArea: {
          top: 50,
          bottom: 20,
          left: 20,
          right: 20,
        },
        legend: {
          position: 'top',
        },
      }}
      chartEvents={[chartReadyEvent({ key: 'boardGender', data: reportData, handler: updateData })]}
    />
  );
});
