import { GetSecondaryConnectionsResponse, useGetSecondaryConnectionsQuery } from '@api/utrv';
import { QueryWrapper } from '@components/query/QueryWrapper';
import { QUESTION } from '@constants/terminology';
import { DashboardDivider } from '@g17eco/atoms';
import { getGroup } from '@g17eco/core';
import { UniversalTrackerPlain, UtrType } from '@g17eco/types/universalTracker';
import { CalculationType, CalculationUtr, CalculationIntegrationUtr } from '@g17eco/types/utrv-connections';
import { useToggle } from '@hooks/useToggle';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IntegratedConnection, SurveyConnection, UtrvConnection, VariableData } from './types';
import { SelectedConnection, useConnectionContext } from './ConnectionContext';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';
import { SimpleTooltip } from '@g17eco/molecules';
import { getConnectionConvertedValue, getInputValue, getUtrvConnections } from './utils';
import { getUnitText } from '@utils/units';
import Eyes from '../../../images/eyes.gif';
import { getFormattedSurveyDate } from '@utils/survey';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import { Link } from 'react-router-dom';

type VariableProps = Pick<ConnectionProps, 'connection' | 'utrs' | 'integrationUtrs' | 'initiativeId'> & {
  variable: VariableData;
};

const isCalculationIntegrationUtr = (
  utr: CalculationUtr | CalculationIntegrationUtr
): utr is CalculationIntegrationUtr => utr.type === UtrType.Generated;

const isSurveyConnection = (connection: UtrvConnection): connection is SurveyConnection =>
  Boolean('surveyId' in connection);

type PackInfo = Pick<VariableProps, 'connection'> & {
  utr: CalculationUtr | CalculationIntegrationUtr;
};

const getPackInfo = ({ utr, connection }: PackInfo) => {
  if (connection.group) {
    return {
      logo: connection.group.icon,
      shortName: connection.group.name,
      colour: connection.group.colour,
    };
  }

  if (isCalculationIntegrationUtr(utr)) {
    return utr.provider
      ? { logo: utr.provider.icon ?? utr.provider.logo, shortName: utr.provider.shortName }
      : undefined;
  }
  const standard = utr.type ? getGroup('standards', utr.type) : undefined;
  return standard ? { logo: standard.src, shortName: standard.shortName } : undefined;
};

const iconStyle: CSSProperties = {
  backgroundColor: '#4E7AE8FF',
  width: '16px',
  minWidth: '16px',
  height: '16px',
  borderRadius: '4px',
};

const RenderGroupImage = (props: PackInfo) => {
  const packInfo = getPackInfo(props);
  if (!packInfo) {
    return null;
  }

  const name = <div className='me-auto text-nowrap text-ThemeTextDark'>{packInfo.shortName}</div>;

  if (packInfo.logo) {
    return (
      <>
        <img src={packInfo.logo} alt={packInfo.shortName} height='16px' className='mr-1' />
        {name}
      </>
    );
  }

  if (packInfo.colour) {
    return (
      <>
        <i className='mr-1' style={iconStyle} />
        {name}
      </>
    );
  }

  return name;
};

const getMetricPath = ({
  connection,
  variable,
  initiativeId,
}: Pick<VariableProps, 'connection' | 'variable' | 'initiativeId'>) => {
  if (!isSurveyConnection(connection)) {
    return;
  }

  const surveyPath = generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, {
    initiativeId,
    surveyId: connection.surveyId,
    page: 'question',
  });
  return `${surveyPath}/${variable.utrvId}`;
};

const VariableInfo = ({ connection, variable, utrs, integrationUtrs, initiativeId }: VariableProps) => {
  const utr = (variable.integrationCode ? integrationUtrs : utrs).find((utr) => utr.code === variable.utrCode);
  if (!utr) {
    return null;
  }

  const inputTitle = utr.valueValidation?.table?.columns.find((column) => column.code === variable.valueListCode)?.name;

  const metricPath = getMetricPath({ connection, variable, initiativeId });

  return (
    <div className='mt-1'>
      <div className='d-flex align-items-center'>
        <RenderGroupImage utr={utr} connection={connection} />
        <p className='flex-grow-1 text-right my-0 ms-3 text-truncate text-ThemeTextDark text-sm'>
          <SimpleTooltip text={utr.name}>
            {metricPath ? <Link to={metricPath}>{utr.name}</Link> : utr.name}
          </SimpleTooltip>
        </p>
      </div>
      {inputTitle ? (
        <>
          <p className='mb-0 mt-1 text-ThemeTextMedium text-sm text-right'>{inputTitle}</p>
        </>
      ) : null}
    </div>
  );
};

const VariableCollapse = ({
  connection,
  name,
  variable,
  utrs,
  integrationUtrs,
  initiativeId,
}: VariableProps & {
  name: string;
}) => {
  const [isOpen, toggle] = useToggle(false);
  return (
    <>
      <div className='mt-2 d-flex justify-content-end align-items-center cursor-pointer' onClick={toggle}>
        <p className='mb-0 mr-1 text-ThemeTextMedium text-sm'>
          {QUESTION.CAPITALIZED_SINGULAR} {name.toUpperCase()}
        </p>
        <i
          className={classNames('fa-light text-ThemeIconSecondary', {
            'fa-caret-up': isOpen,
            'fa-caret-down': !isOpen,
          })}
        ></i>
      </div>
      <Collapse isOpen={isOpen}>
        <VariableInfo
          connection={connection}
          variable={variable}
          utrs={utrs}
          integrationUtrs={integrationUtrs}
          initiativeId={initiativeId}
        />
      </Collapse>
    </>
  );
};

// {a} + {b} -> Metric A + Metric B
const transformFormula = (formula: string) => {
  return formula
    .toUpperCase()
    .replace(/{/g, ` ${QUESTION.CAPITALIZED_SINGULAR} `)
    .replace(/}/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();
};

const Variables = ({
  connection,
  utrs,
  integrationUtrs,
  initiativeId,
}: Pick<ConnectionProps, 'connection' | 'utrs' | 'integrationUtrs' | 'initiativeId'>) => {
  switch (connection.type) {
    case CalculationType.Direct: {
      return (
        <VariableInfo
          connection={connection}
          variable={connection.variables[connection.direct]}
          utrs={utrs}
          integrationUtrs={integrationUtrs}
          initiativeId={initiativeId}
        />
      );
    }
    case CalculationType.Formula: {
      const variables = connection.variables;

      return (
        <>
          <div className='d-flex justify-content-between align-items-center'>
            <label className='text-sm my-0 mt-2 text-ThemeTextMedium'>Calculation:</label>
            <p className='mb-0 text-ThemeTextDark text-sm'>{transformFormula(connection.formula)}</p>
          </div>
          {Object.keys(variables).map((key) => (
            <VariableCollapse
              key={key}
              name={key}
              variable={variables[key]}
              utrs={utrs}
              connection={connection}
              integrationUtrs={integrationUtrs}
              initiativeId={initiativeId}
            />
          ))}
        </>
      );
    }
    case CalculationType.Stages:
    default: {
      return <div>Not support yet</div>;
    }
  }
};

type ConnectionProps = Pick<
  ConnectionsGroupProps,
  | 'utr'
  | 'initiativeId'
  | 'displayDetails'
  | 'onClickConnection'
  | 'selectedConnection'
  | 'onPopulateInput'
  | 'integrationUtrs'
  | 'utrs'
> & {
  connection: ConnectionsGroupProps['connections'][0];
  name?: string;
};

const DetailsTooltip = ({
  connection,
  displayDetails,
  utrs,
}: Pick<ConnectionProps, 'connection' | 'displayDetails' | 'utrs'>) => {
  if (!displayDetails || connection.type !== CalculationType.Direct) {
    return null;
  }

  const variable = connection.variables[connection.direct];
  if (!variable) {
    return null;
  }

  const utr = utrs.find((utr) => utr.code === variable.utrCode);
  if (!utr) {
    return null;
  }

  const tooltip = (
    <div className={'text-left'}>
      <span>Direct Calculation: {connection.name}</span>
      <br />
      {connection.description ? <div>{connection.description}</div> : null}
      <span key={variable.utrCode}>
        <h6 className={'text-ThemeTextMedium mb-0'}>Direct Universal Tracker</h6>
        <span>code: {variable.utrCode}</span>
        <br />
        <span>_id: {utr._id}</span>
        <br />
        {variable.valueListCode ? `#${variable.valueListCode} ` : ' '}
        {variable.integrationCode ? `(${variable.integrationCode})` : ''}
      </span>
    </div>
  );

  return (
    <SimpleTooltip text={tooltip}>
      <i className={'fa-light fa-info-circle text-ThemeIconSecondary cursor-pointer'} />
    </SimpleTooltip>
  );
};

const isSelectedConnection = ({
  selectedConnection,
  connection,
}: Pick<ConnectionProps, 'selectedConnection' | 'connection'>) => {
  if (selectedConnection && isSurveyConnection(connection) && isSurveyConnection(selectedConnection)) {
    // 1 connection can have multiple survey connections so we need to check the surveyId
    return selectedConnection?._id === connection._id && selectedConnection?.surveyId === connection.surveyId;
  }

  return selectedConnection?._id === connection._id;
};

const getConnectionUnit = ({ connection, utr }: Pick<ConnectionProps, 'connection' | 'utr'>) => {
  if (!connection.valueListCode) {
    return getUnitText({ valueType: utr.valueType, unit: connection.unit ?? utr.unit, unitType: utr.unitType }, '');
  }

  const column = utr.valueValidation?.table?.columns.find((col) => col.code === connection.valueListCode);
  if (!column) {
    return '';
  }
  return getUnitText({ valueType: column.type, unit: column.unit, unitType: column.unitType }, '');
};

const Connection = (props: ConnectionProps) => {
  const {
    connection,
    name,
    utrs,
    integrationUtrs,
    selectedConnection,
    onClickConnection,
    onPopulateInput,
    initiativeId,
    utr,
  } = props;
  const value = connection.value ?? 0;
  const numberScale = connection.numberScale ?? '';
  const unit = getConnectionUnit({ connection, utr });

  return (
    <div
      className={classNames('p-1', {
        'cursor-pointer': connection.valueListCode,
        'background-ThemeAccentExtralight': isSelectedConnection({ selectedConnection, connection }),
      })}
      onClick={() => onClickConnection(connection)}
    >
      {name ? (
        <label className='text-ThemeTextMedium mb-2 text-sm'>
          CONNECTION {name} <DetailsTooltip {...props} />
        </label>
      ) : null}
      <div key={connection._id}>
        <div className='d-flex justify-content-between align-items-center'>
          <label className='text-sm me-auto my-0 text-ThemeTextMedium'>Answer:</label>
          <p className='mb-0 text-ThemeTextDark fw-bold'>
            {value} {numberScale} {unit}
          </p>
          <i
            className='fa-light fa-arrow-right-to-arc ms-2 text-ThemeAccentMedium cursor-pointer'
            onClick={() => onPopulateInput(connection)}
          />
        </div>
        <Variables connection={connection} utrs={utrs} integrationUtrs={integrationUtrs} initiativeId={initiativeId} />
      </div>
    </div>
  );
};

const WarningModal = ({
  open,
  toggle,
  handleConfirm,
}: {
  open: boolean;
  toggle: () => void;
  handleConfirm: () => void;
}) => {
  return (
    <Modal isOpen={open} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>
        <span className='text-ThemeWarningExtraDark'>Replace answer?</span>
      </ModalHeader>
      <ModalBody>
        <p className='mb-0'>
          This field already has a value/content in it. If you proceed this value will be replaced with the answer you
          have selected in the sidebar.
        </p>
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='transparent' onClick={toggle}>
          Cancel
        </Button>
        <Button color='warning' onClick={handleConfirm}>
          Replace answer
        </Button>
      </ModalFooter>
    </Modal>
  );
};

type ConnectionsGroupProps = Pick<Props, 'utr'> & {
  title: string;
  connections: SurveyConnection[] | IntegratedConnection[];
  displayDetails?: boolean;
  integrationUtrs: CalculationIntegrationUtr[];
  utrs: CalculationUtr[];
  selectedConnection?: SelectedConnection;
  onClickConnection: (connection: ConnectionsGroupProps['connections'][0]) => void;
  onPopulateInput: (connection: ConnectionsGroupProps['connections'][0]) => void;
  initiativeId?: string;
};
const ConnectionsGroup = ({
  title,
  connections,
  displayDetails,
  integrationUtrs,
  utrs,
  utr,
  selectedConnection,
  onClickConnection,
  onPopulateInput,
  initiativeId,
}: ConnectionsGroupProps) => {
  const [isOpen, toggle] = useToggle(false);
  return (
    <>
      <div className='my-2 d-flex justify-content-between align-items-center cursor-pointer' onClick={toggle}>
        <h6 className='mb-0 mr-1 text-ThemeTextLight'>{title}</h6>
        <i
          className={classNames('fa-light text-ThemeIconSecondary', {
            'fa-caret-up': isOpen,
            'fa-caret-down': !isOpen,
          })}
        ></i>
      </div>
      <Collapse isOpen={isOpen}>
        {connections.map((connection, index, connections) => {
          return (
            <React.Fragment key={connection._id}>
              {index ? <DashboardDivider className='my-3' /> : null}
              <Connection
                displayDetails={displayDetails}
                connection={connection}
                name={connections.length === 1 ? undefined : String(index + 1)}
                utrs={utrs}
                integrationUtrs={integrationUtrs}
                selectedConnection={selectedConnection}
                onClickConnection={onClickConnection}
                onPopulateInput={onPopulateInput}
                initiativeId={initiativeId}
                utr={utr}
              />
            </React.Fragment>
          );
        })}
      </Collapse>
    </>
  );
};

interface Props {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'targetDirection' | 'valueValidation' | 'unitType' | 'unit'>;
  utrv: SurveyModelMinimalUtrv;
}

export const Connections = ({ utr, utrv }: Props) => {
  const getSecondaryConnectionsQuery = useGetSecondaryConnectionsQuery({ utrvId: utrv._id });
  const isStaffUser = useAppSelector(isStaff);

  const { connection: selectedConnection, setConnection, setInputData, currentInputData } = useConnectionContext();
  const [showWarning, toggleShowWarning] = useToggle(false);

  const handleClickConnection = (connection: UtrvConnection) => setConnection(connection);

  const handlePopulateInput = (connection: UtrvConnection) => {
    setConnection(connection);

    const currentValue = getInputValue({ utr, currentInputData, valueListCode: connection.valueListCode });
    const convertedCalculationValue = getConnectionConvertedValue({
      currentInputData,
      connection,
      utr,
      utrv,
    });

    if (currentValue === convertedCalculationValue) {
      return;
    }

    if (currentValue === undefined) {
      setInputData(convertedCalculationValue);
      return;
    }

    toggleShowWarning();
  };

  const handleConfirm = () => {
    if (!selectedConnection) {
      setInputData(undefined);
      toggleShowWarning();
      return;
    }

    const convertedCalculationValue = getConnectionConvertedValue({
      currentInputData,
      connection: selectedConnection,
      utr,
      utrv,
    });
    setInputData(convertedCalculationValue);
    toggleShowWarning();
  };

  const onSuccessRender = ({ connections, utrs, integrationUtrs = [], surveys }: GetSecondaryConnectionsResponse) => {
    const { surveyIdToConnectionsMap, integratedConnections, allConnections } = getUtrvConnections(connections);

    if (!allConnections.length) {
      return (
        <div className='d-flex flex-column align-items-center gap-3'>
          <img src={Eyes} alt={'no-connections'} width={120} />
          <p className='text-lg text-ThemeTextMedium text-center'>
            We looked, but this {QUESTION.SINGULAR} currently has no connections
          </p>
        </div>
      );
    }

    return (
      <>
        <p className='text-sm text-ThemeTextLight'>
          Click <i className='fa-light fa-arrow-right-to-arc' /> to populate input field with selected answer
        </p>
        {integratedConnections.length ? (
          <ConnectionsGroup
            title='Emissions Tracker'
            connections={integratedConnections}
            integrationUtrs={integrationUtrs}
            utrs={[]}
            utr={utr}
            selectedConnection={selectedConnection}
            onClickConnection={handleClickConnection}
            onPopulateInput={handlePopulateInput}
            displayDetails={isStaffUser}
          />
        ) : null}
        {surveys.map((survey, index) => {
          const connections = surveyIdToConnectionsMap.get(survey._id) ?? [];
          if (!connections.length) {
            return null;
          }

          return (
            <>
              {index ? <DashboardDivider className='my-3' /> : null}
              <ConnectionsGroup
                key={survey._id}
                title={getFormattedSurveyDate(survey)}
                connections={connections}
                integrationUtrs={[]}
                utrs={utrs}
                utr={utr}
                selectedConnection={selectedConnection}
                onClickConnection={handleClickConnection}
                onPopulateInput={handlePopulateInput}
                displayDetails={isStaffUser}
                initiativeId={survey.initiativeId}
              />
            </>
          );
        })}

        <WarningModal open={showWarning} toggle={toggleShowWarning} handleConfirm={handleConfirm} />
      </>
    );
  };

  return (
    <div>
      <QueryWrapper query={getSecondaryConnectionsQuery} onSuccess={onSuccessRender} />
    </div>
  );
};
