import { TemplateMenuPopover } from '@features/custom-dashboard';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules';
import {
  ADD_DASHBOARD_OPTION,
  getTemplateOptions,
  InsightDashboardOption,
} from '@routes/summary/insights/utils/sidebar';
import classNames from 'classnames';
import { useState } from 'react';

interface Props {
  title: string | JSX.Element;
  currentPage: string;
  options: InsightDashboardOption[];
  handleClickOption: (item: InsightDashboardOption) => void;
  className?: string;
}

export const CustomDashboardTitle = ({ title, currentPage, options, handleClickOption, className = '' }: Props) => {
  const selectedItem = options.find((i) => i.value === currentPage) ?? { label: title, value: null };
  const templateOptions = getTemplateOptions(options);
  // Manually control the dropdown open state so we can keep it open when click the add button to show template menu.
  const [{ menuIsOpen, templateMenuOpen }, setMenuState] = useState({ menuIsOpen: false, templateMenuOpen: false });

  const closeMenu = () => {
    setMenuState({ menuIsOpen: false, templateMenuOpen: false });
  };

  const onSelect = (option: Option | null) => {
    const item = options.find((o) => o.value === option?.value);
    if (!item) {
      return;
    }

    if (item.value === ADD_DASHBOARD_OPTION && templateOptions.length) {
      setMenuState({ menuIsOpen: true, templateMenuOpen: true });
    } else {
      closeMenu();
      handleClickOption(item);
    }
  };

  const handleClickTemplateOption = (item: InsightDashboardOption) => {
    closeMenu();
    handleClickOption(item);
  };

  return (
    <>
      <div className={classNames('d-inline-block d-xxl-none', className)}>
        <SelectFactory
          value={{
            label: <span className='h3'>{selectedItem.label}</span>,
            value: selectedItem.value,
          }}
          // On click will handle this, avoid calling onMenuClose
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          selectType={SelectTypes.SingleSelect}
          placeholder='Select a custom dashboard'
          options={options}
          onChange={(option) => onSelect(option)}
          isSearchable={false}
          isTransparent
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuState({ menuIsOpen: true, templateMenuOpen: false })}
          onMenuClose={() => {
            // To avoid closing dropdown before clicking the template menu options.
            if (!templateMenuOpen) {
              setMenuState({ menuIsOpen: false, templateMenuOpen: false });
            }
          }}
        />
        <TemplateMenuPopover
          open={templateMenuOpen}
          toggle={() => setMenuState((s) => ({ ...s, templateMenuOpen: !s.templateMenuOpen }))}
          target={ADD_DASHBOARD_OPTION}
          onClickOption={handleClickTemplateOption}
          onClickOutside={closeMenu}
          templates={templateOptions}
        />
      </div>
      <div className={classNames('d-none d-xxl-inline-block', className)}>{title}</div>
    </>
  );
};
