import { Button } from 'reactstrap';
import { BulletListItem } from '../types';
import { BulletList } from './BulletList';
import config from '../../../../config';
import './ConsultancyCard.scss';

interface ConsultancyCardProps {
  className?: {
    wrapper?: string;
    img?: string;
  };
  height?: number;
  logo: {
    src: string;
    alt?: string;
  };
  text: string;
  bulletListItems?: BulletListItem[];
}

export const ConsultancyCard = (props: ConsultancyCardProps) => {
  const {
    className = {},
    logo: { src, alt = '' },
    height = 50,
    text,
    bulletListItems = [],
  } = props;
  const { wrapper = '', img = '' } = className;

  return (
    <div className={`consultancy__col ${wrapper}`}>
      <img src={src} alt={alt} height={height} className={img} />
      <div className='text-left text-lg text-ThemeTextDark strong mt-2 mb-3'>{text}</div>
      <BulletList items={bulletListItems} />
      <div className='mt-auto'>
        <Button className='mt-5' outline={true} size='md' onClick={() => window.open(config.brochureURL.consultancy)}>
          Learn more
        </Button>
      </div>
    </div>
  );
};
