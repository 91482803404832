export const theme = {
  'icon': { 'borderColor': '#2971E5', 'width': '20px' },
  'unseenBadge': {
    'backgroundColor': '#2971E5',
    'borderRadius': '50%',
  },
  'header': {
    'backgroundColor': '#2971E5',
    'textColor': '#ffffff',
    'borderRadius': '10px'
  },
  'footer': {
    'backgroundColor': '#2971E5',
    'textColor': '#ffffff',
    'borderRadius': '10px'
  },
  'notification': {
    'default': {
      'textColor': '#26292B',
      'borderRadius': '10px',
      'backgroundColor': '#2971E5'
    },
    'unseen': {
      'backgroundColor': '#2971E5',
      'textColor': '#26292B',
      'borderRadius': '10px'
    },
    'unread': {
      'backgroundColor': '#2971E5',
      'textColor': '#26292B',
      'borderRadius': '10px'
    }
  }
};
