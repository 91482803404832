import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { UtrvType } from '@constants/status';
import { ShowAs, UniversalTrackerModalServiceUtrv } from '../../../../reducers/universal-tracker-modal';
import { useToggle } from '@hooks/useToggle';
import { ProvenanceModal } from './ProvenanceModal';
import { CurrentUserData } from '../../../../reducers/current-user';
import { CalculationModal } from './CalculationModal';
import UniversalTracker from '@models/UniversalTracker';
import { UserMin } from '@constants/users';
import { ExistingEvidenceFile } from '@components/survey/question/questionInterfaces';
import { ROUTES } from '@constants/routes';
import { generateUrl } from '@routes/util';
import { useNavigate } from 'react-router-dom-v5-compat';
import { closeUniversalTrackerModal } from '@actions/universalTrackerModal';
import { useDispatch } from 'react-redux';
import { InitiativePermissions } from '@services/permissions/InitiativePermissions';
import { isAggregatedSurvey } from '@utils/survey';
import { Tab } from '@components/utr-modal/ContentTabs';

interface Props {
  utrValue: UniversalTrackerModalServiceUtrv;
  utr: UniversalTracker;
  utrValueId: string;
  users?: UserMin[];
  documents?: ExistingEvidenceFile[];
  currentUser?: CurrentUserData;
  handleReject: (utrValue: UniversalTrackerModalServiceUtrv) => Promise<void>;
  handleSelectUtrv?: (selectedUtrv: UniversalTrackerModalServiceUtrv, tabId: Tab['navId']) => void;
}

interface UtrvAction {
  icon: string;
  name: string;
  onClick: () => void;
  disabled?: boolean;
}

export const UtrvActions = ({ utrValue, currentUser, utr, handleReject, handleSelectUtrv, ...valueHistoryProps }: Props) => {
  const [openProvenance, toggleProvenance] = useToggle(false);
  const [openCalculation, toggleCalculation] = useToggle(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const surveyId = utrValue.compositeData?.surveyId;
  const initiativeId = utrValue.initiativeId;

  if (!currentUser || !InitiativePermissions.canManageInitiative(currentUser, initiativeId)) {
    return null;
  }

  const onClickEditTargetBaseline = () => {
    if (!handleSelectUtrv) {
      return;
    }
    handleSelectUtrv(utrValue, ShowAs.TargetBaseline);
  };

  const onClickCalculation = () => {
    if (currentUser?.isStaff && utrValue.compositeData && !utrValue.aggregationCount) {
      toggleCalculation();
    }
  };

  const onClickDelete = () => {
    const allowedTypes = [UtrvType.Target, UtrvType.Baseline];
    if (!allowedTypes.includes(utrValue.type as UtrvType)) {
      return null;
    }

    handleReject(utrValue);
  };

  const onClickOpenAnswer = () => {
    const { compositeData, initiativeId, _id } = utrValue;
    if (!initiativeId || !compositeData || !compositeData.surveyId) {
      return;
    }
    dispatch(closeUniversalTrackerModal());
    const rootUrl = generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, {
      initiativeId,
      surveyId: compositeData.surveyId,
      page: 'question',
    });
    const url = `${rootUrl}/${_id}`;
    navigate(url);
  };

  const getTargetBaselineOptions = (type: UtrvType) => [
    {
      icon: 'fa-clipboard-list text-ThemeIconSecondary',
      name: 'View provenance',
      onClick: toggleProvenance,
    },
    {
      icon: 'fa-pencil text-ThemeIconSecondary',
      name: `Edit ${type}`,
      onClick: onClickEditTargetBaseline,
    },
    {
      icon: 'fa-trash text-ThemeDangerMedium',
      name: `Delete ${type}`,
      onClick: onClickDelete,
    },
  ];

  const getActualValueOptions = () => {
    const options = [
      {
        icon: 'fa-clipboard-list text-ThemeIconSecondary',
        name: 'View provenance',
        onClick: toggleProvenance,
      },
    ];
    if (currentUser?.isStaff) {
      options.push({
        icon: 'fa-calculator text-ThemeIconSecondary',
        name: 'View calculation',
        onClick: onClickCalculation,
      });
    }
    options.push({
      icon: 'fa-list text-ThemeIconSecondary',
      name: 'Open answer',
      onClick: onClickOpenAnswer,
    });
    return options;
  };

  const getAggregatedValueOptions = () => [
    {
      icon: 'fa-list text-ThemeIconSecondary',
      name: 'Open answer',
      onClick: onClickOpenAnswer,
    },
  ];

  const getOptions = (): UtrvAction[] => {
    switch (utrValue.type) {
      case UtrvType.Baseline:
      case UtrvType.Target:
        return getTargetBaselineOptions(utrValue.type);
      case UtrvType.Actual:
        return isAggregatedSurvey(utrValue.surveyType) ? getAggregatedValueOptions() : getActualValueOptions();
      default:
        return [];
    }
  };

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle outline color='transparent' className='px-2 border-ThemeNeutralsLight'>
          <i className={'fal fa-bars'}></i>
        </DropdownToggle>
        <DropdownMenu container={'.modal-content'} className='border-ThemeBorderDefault'>
          {getOptions().map(({ icon, name, disabled, onClick }, idx) => (
            <DropdownItem key={idx} onClick={onClick} disabled={disabled}>
              <i className={`fal ${icon} me-1`} /> {name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>

      <ProvenanceModal {...valueHistoryProps} utr={utr} isOpen={openProvenance} toggle={toggleProvenance} />
      {surveyId ? (
        <CalculationModal
          surveyId={surveyId}
          utrCode={utr.getCode()}
          isOpen={openCalculation}
          toggle={toggleCalculation}
        />
      ) : null}
    </>
  );
};
