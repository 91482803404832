import { TagContainer } from '@components/tags/TagContainer';
import { InputOverrideContainer } from '@features/question-configuration';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ConfigurationType, getModalTitle } from '../utils';
import { QUESTION, SURVEY } from '@constants/terminology';
import { MetricOverrideContainer } from '../metric-override/MetricOverrideContainer';
import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import { getNumericSelectedQuestions } from '@components/survey-question-list/utils';

export interface QuestionConfigurationModalProps {
  isOpen: boolean;
  initiativeId: string;
  configType: ConfigurationType;
  selectedQuestions: BulkActionUtr[];
  toggleOpen: () => void;
  handleReload: (props?: { reloadSurvey?: boolean; closeModal?: boolean }) => Promise<void | undefined>;
}

const MULTI_METRIC_SELECTED_DESCRIPTION = `You have selected multiple ${QUESTION.PLURAL}. Changing the config will impact all ${QUESTION.PLURAL} selected.`;

export const getDescription = ({
  configType,
  isMultipleQuestionSelected,
}: {
  configType: ConfigurationType | undefined;
  isMultipleQuestionSelected: boolean;
}) => {
  switch (configType) {
    case ConfigurationType.MetricOverrides: {
      const description = `${QUESTION.CAPITALIZED_SINGULAR} overrides are set globally. All ${SURVEY.PLURAL} created in the future will respect these rules.`;
      if (!isMultipleQuestionSelected) {
        return description;
      }
      return (
        <div>
          {description}
          <div className='mt-2'>{MULTI_METRIC_SELECTED_DESCRIPTION}</div>
        </div>
      );
    }
    case ConfigurationType.InputOverrides: {
      const description = (
        <ul className='pl-4'>
          <li>Input overrides apply globally</li>
          <li>
            Interface: Selections will apply to all newly created {SURVEY.PLURAL} and unanswered {QUESTION.PLURAL}.
          </li>
          <li>
            Exporting/Downloading: When selecting '{QUESTION.SINGULAR} overrides' in your download options, figures entered by users
            in other units will be converted in your downloaded report.
          </li>
        </ul>
      );
      if (!isMultipleQuestionSelected) {
        return description;
      }
      return (
        <div>
          {description}
          <div className='mt-1'>{MULTI_METRIC_SELECTED_DESCRIPTION}</div>
        </div>
      );
    }
    case ConfigurationType.Tags:
      return (
        `Tags are set globally. Any ${SURVEY.PLURAL} created in the future  will be tagged with the below. ` +
        `${QUESTION.CAPITALIZED_PLURAL} already added to scope will not update their tags`
      );
    default:
      return '';
  }
};

export const QuestionConfigurationModal = (props: QuestionConfigurationModalProps) => {
  const { isOpen, toggleOpen, configType, initiativeId, selectedQuestions, handleReload: onReload } = props;

  if (!selectedQuestions.length) {
    return null;
  }

  const handleReload = async (props?: { reloadSurvey?: boolean; closeModal?: boolean }) => {
    if (props?.closeModal) {
      toggleOpen();
    }
    onReload(props);
  };

  const configFormProps = {
    initiativeId,
    selectedQuestions,
    numericSelectedQuestions: getNumericSelectedQuestions(selectedQuestions),
    handleReload,
  };

  const ConfigurationForm = () => {
    switch (configType) {
      case ConfigurationType.MetricOverrides:
        return <MetricOverrideContainer key={ConfigurationType.MetricOverrides} {...configFormProps} handleCloseModal={toggleOpen} />;
      case ConfigurationType.InputOverrides:
        return <InputOverrideContainer key={ConfigurationType.InputOverrides} {...configFormProps} handleCloseModal={toggleOpen} />;
      case ConfigurationType.Tags:
        return <TagContainer key={ConfigurationType.Tags} {...configFormProps} />;
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleOpen} backdrop='static'>
      <ModalHeader toggle={toggleOpen}>{getModalTitle(selectedQuestions)}</ModalHeader>
      <ModalBody className='pb-0'>
        {getDescription({ configType, isMultipleQuestionSelected: selectedQuestions.length > 1 })}
      </ModalBody>
      <ConfigurationForm />
    </Modal>
  );
};
