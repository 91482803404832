import { useCallback } from 'react';
import {
  useCreateUtrvBookmarkMutation,
  useDeleteUtrvBookmarkMutation,
  useGetUtrvBookmarksQuery,
} from '../../../api/bookmarks';
import { Bookmark } from '../../../types/bookmark';

export const useBookmarks = (surveyId: string) => {
  const { data: bookmarks } = useGetUtrvBookmarksQuery({
    surveyId,
  });

  const isBookmarked = useCallback((bookmark: Bookmark['targetId']) => (bookmarks ?? []).includes(bookmark), [bookmarks]);

  const [createUtrvBookmark] = useCreateUtrvBookmarkMutation();
  const [deleteUtrvBookmark] = useDeleteUtrvBookmarkMutation();
  const toggleBookmark = (bookmark: Bookmark['targetId']) =>
    isBookmarked(bookmark) ? deleteUtrvBookmark({ utrvId: bookmark, surveyId }) : createUtrvBookmark({ utrvId: bookmark, surveyId });

  return { bookmarks, isBookmarked, toggleBookmark };
};
