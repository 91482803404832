import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ValueHistoryComponent, { ValueHistoryProps } from '@components/utr-modal/components/value-history-component';


interface Props extends Omit<ValueHistoryProps, 'isVisible'> {
  isOpen: boolean;
  toggle: () => void;
}

export const ProvenanceModal = (props: Props) => {
  const { isOpen, toggle, ...valueHistoryProps } = props;
  
  return (
    <Modal className='modal-lg' isOpen={isOpen} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Provenance</ModalHeader>
      <ModalBody>
        <ValueHistoryComponent {...valueHistoryProps} isVisible={isOpen} />
      </ModalBody>
    </Modal>
  );
};
