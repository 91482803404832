import {
  MaterialitySurveyModelMinData,
  useGenerateScoresQuery,
  useLazyGenerateFinancialPPTXReportQuery,
  useRegenerateScoresMutation,
} from '../../api/materiality-assessment';
import Dashboard, { DashboardSection } from '@components/dashboard';
import { AssessmentResults } from '../assessment/AssessmentResults';
import { QueryWrapper } from '@components/query/QueryWrapper';
import { BasicAlert, NoData, Option, SimpleTooltip } from '@g17eco/molecules';
import { exportToExcel } from '@components/downloads/util/exportToExcel';
import { AssessmentDataRow, assessmentDataColumnMap, getResultByScoreDesc } from '../../utils';
import IconButton from '@components/button/IconButton';
import { DATE, formatDateUTC } from '@utils/date';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { JobStatus } from '../../../../types/background-jobs';
import { AssessmentDropdown } from '../assessment/AssessmentDropdown';
import { useHistory } from 'react-router-dom';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import { useAppDispatch } from '@reducers/index';
import { addSiteAlert, SiteAlertColors } from '@g17eco/slices/siteAlertsSlice';
import { OnChangeValue } from 'react-select';
import { useLazyGetBackgroundReportUrlQuery } from '@api/survey-background-report';

const jobInProgress = [JobStatus.Pending, JobStatus.Processing, JobStatus.WaitForRun];

interface Props {
  initiativeId: string;
  selectedSurvey: MaterialitySurveyModelMinData;
  surveys: MaterialitySurveyModelMinData[];
}

const Mode = {
  Financial: 'financial',
  NonFinancial: 'nonFinancial',
};

const shouldContinuePolling = (status: JobStatus) => jobInProgress.includes(status);
const POLLING_INTERVAL = 10000;

export const AssessmentInsights = (props: Props) => {
  const { initiativeId, selectedSurvey, surveys } = props;
  const [mode, setMode] = useState(Mode.Financial);
  const generateScoreQuery = useGenerateScoresQuery({ initiativeId, surveyId: selectedSurvey._id });
  const [regenerateScoreQuery] = useRegenerateScoresMutation();
  const [generateFinancialPPTXReport] = useLazyGenerateFinancialPPTXReportQuery();
  const [getBackgroundReportUrl] = useLazyGetBackgroundReportUrlQuery();
  const regenerateScores = () => regenerateScoreQuery({ initiativeId, surveyId: selectedSurvey._id });
  const history = useHistory();
  const dispatch = useAppDispatch();
  const hasFinishedScores = generateScoreQuery.data?.status && !shouldContinuePolling(generateScoreQuery.data.status);
  const jobId = generateScoreQuery.data?.jobId;

  useEffect(() => {
    // Define a function to determine if polling should continue
    if (!hasFinishedScores) {
      const intervalId = setInterval(() => {
        generateScoreQuery.refetch();
      }, POLLING_INTERVAL);

      return () => clearInterval(intervalId);
    }
  }, [generateScoreQuery, hasFinishedScores]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get('jobId');
    const taskId = searchParams.get('taskId');
    if (!jobId || !taskId) {
      return;
    }
    getBackgroundReportUrl({ surveyId: selectedSurvey._id, jobId, taskId })
      .then((response) => {
        const { downloadUrl } = response.data || {};
        if (downloadUrl) {
          window.open(downloadUrl, '_blank', '');
        } else {
          dispatch(
            addSiteAlert({
              content: 'Unable to download report. If this occurs again, please generate a new report.',
              color: SiteAlertColors.Danger,
              timeout: 10000,
            })
          );
        }
      })
      .finally(() => history.replace(location.pathname));
  }, [dispatch, getBackgroundReportUrl, history, selectedSurvey._id]);

  const downloadReport = (data: AssessmentDataRow[]) => {
    const rowHeaders = Object.values(assessmentDataColumnMap);
    const rowValues = data.map((row: AssessmentDataRow) => {
      return Object.keys(assessmentDataColumnMap).map((key) => row[key as keyof AssessmentDataRow] ?? '');
    });

    exportToExcel({
      headers: rowHeaders,
      values: rowValues,
      fileName: `${
        selectedSurvey.name ?? formatDateUTC(selectedSurvey.effectiveDate, DATE.YEAR_ONLY)
      } Assessment Results`,
      sheetName: 'assessment-results',
    });
  };

  const downloadPowerpointReport = () => {
    if (!hasFinishedScores || !jobId) {
      return;
    }
    return generateFinancialPPTXReport({ initiativeId, surveyId: selectedSurvey._id, jobId }).then(() => {
      dispatch(
        addSiteAlert({
          content: 'Your report is being prepared. You will receive a notification when it is ready to download.',
          color: SiteAlertColors.Success,
          timeout: 10000,
        })
      );
    });
  };

  const jobStatus = generateScoreQuery.data?.status;
  const isJobPending = generateScoreQuery.data && jobInProgress.includes(generateScoreQuery.data.status);

  const sortedDataByScoreDesc = useMemo(() => {
    const result = generateScoreQuery.data?.result;
    if (!result) {
      return [] as AssessmentDataRow[];
    }
    return getResultByScoreDesc(mode === Mode.Financial ? result.financial : result.nonFinancial);
  }, [mode, generateScoreQuery.data]);

  const onClickItem = (option: OnChangeValue<Option<string>, false>) => {
    if (option?.value === selectedSurvey._id) {
      return;
    }
    history.push(generateUrl(ROUTES.MATERIALITY_TRACKER_INSIGHTS, { initiativeId, surveyId: option?.value }));
  };

  return (
    <Dashboard className='assessmentContainer'>
      <QueryWrapper
        query={generateScoreQuery}
        onError={() => <NoData text='There was an error fetching your results. Please try again.' />}
        onNoData={() => <NoData text='No assessment results currently available.' />}
        onSuccess={() => (
          <DashboardSection>
            <div className='d-flex align-items-center mb-3'>
              <AssessmentDropdown selectedSurvey={selectedSurvey} surveys={surveys} onClickItem={onClickItem} />
              <div className='d-flex ml-auto'>
                <SimpleTooltip
                  text={
                    !hasFinishedScores || !jobId ? 'PPTX report can only be generated after all scores have been calculated' : ''
                  }
                >
                  <IconButton
                    disabled={!hasFinishedScores || !jobId}
                    data-testid={'btn-download-pptx'}
                    onClick={downloadPowerpointReport}
                    outline={false}
                    color='transparent'
                    className='text-ThemeAccentDark'
                    icon='fal fa-file-powerpoint'
                  />
                </SimpleTooltip>
                <IconButton
                  data-testid={'btn-download-excel'}
                  onClick={() => downloadReport(sortedDataByScoreDesc)}
                  outline={false}
                  color='transparent'
                  className='text-ThemeAccentDark'
                  icon='fal fa-file-excel'
                />
                <SimpleTooltip
                  text={
                    isJobPending ? 'Scores are being calculated. Cannot regenerate until complete' : 'Regenerate scores'
                  }
                >
                  <IconButton
                    data-testid={'btn-regenerate'}
                    onClick={() => regenerateScores()}
                    outline={false}
                    color='transparent'
                    className='text-ThemeAccentDark'
                    icon='fal fa-repeat'
                    disabled={isJobPending}
                  />
                </SimpleTooltip>
              </div>
            </div>
            <div>
              <Button color='primary' outline={mode === Mode.NonFinancial} onClick={() => setMode(Mode.Financial)}>
                Financial
              </Button>
              <Button
                className='ml-2'
                color='primary'
                outline={mode === Mode.Financial}
                onClick={() => setMode(Mode.NonFinancial)}
              >
                Non-Financial
              </Button>
            </div>
            <div className='mt-3'>
              <AssessmentResultsWrapper jobStatus={jobStatus} data={sortedDataByScoreDesc} />
            </div>
          </DashboardSection>
        )}
      />
    </Dashboard>
  );
};

const AssessmentResultsWrapper = (props: { jobStatus?: JobStatus; data: AssessmentDataRow[] }) => {
  const { jobStatus, data } = props;

  if (!jobStatus) {
    return null;
  }

  if (jobStatus === JobStatus.Completed) {
    return <AssessmentResults data={data} />;
  }

  if ([JobStatus.Error, JobStatus.Deleted].includes(jobStatus)) {
    return (
      <BasicAlert type='danger'>There was an error calculating the assessment scores. Please try again.</BasicAlert>
    );
  }

  if (jobInProgress.includes(jobStatus)) {
    return (
      <BasicAlert type='info'>
        The assessment scores are still being calculated. Please check back in a few minutes.
      </BasicAlert>
    );
  }
  return null;
};
