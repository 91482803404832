import { useState } from 'react';

export const useTabs = <T>({
  initialTab,
  changeTabCallback,
}: {
  initialTab: T;
  changeTabCallback?: (tab?: T) => void;
}) => {
  const [currentTab, setTab] = useState(initialTab);

  const handleChangeTab = (tab: T) => {
    if (tab === currentTab) {
      return;
    }
    
    changeTabCallback?.(tab);
    setTab(tab);
  };

  return { currentTab, handleChangeTab };
};
