import { InitiativeMinPlain } from '@g17eco/types/initiative';
import { MetricActionType } from '../components/survey/overview/constants';
import { SurveyActionData } from '../model/surveyData';
import { ApiResponse } from '../types/api';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { SurveyInitiative } from '@g17eco/types/survey';

interface SurveyScopeUpdateMutationParams {
  action: MetricActionType;
  surveyId: string;
}

export type SurveyById = Pick<SurveyInitiative, '_id' | 'name' | 'effectiveDate' | 'initiativeId'> & {
  initiative: Pick<InitiativeMinPlain, '_id' | 'name'>;
};

export const SURVEY_DATA_TAG = 'survey';
const UTR_SURVEYS_TAG = 'utr-surveys';

export const surveyScopeUpdateApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['surveyScopeUpdate', SURVEY_DATA_TAG, UTR_SURVEYS_TAG],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      handleScopeUpdate: builder.mutation<ApiResponse, SurveyScopeUpdateMutationParams>({
        invalidatesTags: ['surveyScopeUpdate'],
        query: ({ action, surveyId }) => ({
          url: `/surveys/${surveyId}/blueprint/${action}`,
          method: 'patch',
        }),
      }),
      getSurveyById: builder.query<SurveyActionData, string>({
        transformResponse,
        query: (surveyId) => ({
          url: `/surveys/${surveyId}`,
          method: 'get',
        }),
        providesTags: [SURVEY_DATA_TAG],
      }),
      getSurveysByUtrId: builder.query<SurveyById[], string>({
        transformResponse,
        query: (utrId) => ({
          url: `/surveys/utr/${utrId}`,
          method: 'get',
        }),
        providesTags: [UTR_SURVEYS_TAG],
      }),
    }),
  });

export const { useHandleScopeUpdateMutation, useGetSurveyByIdQuery, useGetSurveysByUtrIdQuery } = surveyScopeUpdateApi;
