import React from 'react'
import { Chart } from 'react-google-charts'
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';

const WIDTH = 300;
const HEIGHT = 300;

export interface BoardGenderDataSource extends DataSource {
  male?: number;
  female?: number;
}

const BoardGender = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrv = questionData.find(d => d.universalTracker.code === 'gri/2020/405-1/a');
  const data = utrv?.valueData?.table?.[0];

  const reportData: BoardGenderDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    male: Number(data?.find(d => d.code === 'male')?.value) ?? 0,
    female: Number(data?.find(d => d.code === 'female')?.value) ?? 0,
  }

  const chartData = [
    ['Gender', 'Percentage'],
    ['Female', reportData.female],
    ['Male', reportData.male],
  ];

  return <Chart
    chartType='PieChart'
    data={chartData}
    width={WIDTH}
    height={HEIGHT}
    options={CHART_DEFAULTS.OPTIONS}
    chartEvents={[
      {
        eventName: 'ready',
        callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
          reportData.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
          updateData('boardGender', reportData);
        }
      }
    ]}
  />
})

export default BoardGender;
