import { IndustrySector } from '../../types/common';

const convertOption = (industrySector: IndustrySector) => ({
  value: industrySector.code,
  label: industrySector.name,
});

export const getSectorOptions = (industrySectors: IndustrySector[]) => {
  const codeLength = 2;
  const filteredIndustrySectors = industrySectors.filter((sector) => {
    return sector.code.length === codeLength;
  });
  return filteredIndustrySectors.map(convertOption);
}

export const getIndustryOptions = (industrySectors: IndustrySector[], selectedSectorText: string | undefined) => {
  const selectedSector = industrySectors.find(item => item.name === selectedSectorText);
  const codeLength = 6;
  const filteredIndustrySectors = industrySectors.filter((sector) => {
    const parentCode = (sector?.code ?? '').substring(0, 2);
    return sector.code.length === codeLength && parentCode === selectedSector?.code;
  });
  return filteredIndustrySectors.map(convertOption);
}