import { DashboardDivider } from '@g17eco/atoms';
import { Button } from 'reactstrap';

interface Props {
  handleCancel: () => void;
  handleSubmit: () => void;
  isDisabled: boolean;
  children: JSX.Element | (JSX.Element | null)[];
}

export const EditorTemplate = ({ handleCancel, isDisabled, handleSubmit, children }: Props) => {
  const actionButtons = (
    <div className='d-flex justify-content-between'>
      <Button color='link-secondary' onClick={handleCancel} className='mr-3'>
        Cancel
      </Button>
      <Button color='primary' disabled={isDisabled} onClick={handleSubmit}>
        Save
      </Button>
    </div>
  );
  return (
    <>
      {actionButtons}
      <DashboardDivider className='mt-4' />
      {children}
      <DashboardDivider className='mt-4 mb-4' />
      {actionButtons}
    </>
  );
};
