import { QUESTION } from '@constants/terminology';

export enum SubmitVerifiedButtonState {
  Show,
  Enable,
}

export const QuestionButtonTooltip = {
  NothingToSubmit: 'Nothing to submit',
  NothingToSubmitOrVerify: 'Nothing to submit or verify',
  UpdateToSubmit: 'Update answer to submit',
  SubmitWithoutVerification: 'Submit answer without verification',
  SubmitAsVerification: 'Submit answer and mark it as verified',
  NoteRequiredOnRestatement: 'You cannot submit until you provide an explanation/note about the restatement',
  UnableToSubmit: `Unable to submit once ${QUESTION.SINGULAR} has been verified. Contact your verifier if you believe the submission to be incorrect`,
  NothingToVerify: 'Nothing to verify',
};
