import { Scope } from '../model/surveyData';
import { ApiResponse } from '../types/api';
import { DataScope, DataShare, DataShareCreateBasic, DataShareWithRequester } from '../types/dataShare';
import { InitiativeDataShare } from '../types/initiative';
import { ScorecardResponse } from '../types/scorecard';
import { g17ecoApi } from './g17ecoApi';

const transformResponse = <T>(response: ApiResponse<T>) => response.data;

export const dataShareApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['dataShare', 'dataShareTemplate', 'initiativeCombinedScope', 'dataShareInsights'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDataShares: builder.query<DataShareWithRequester[], string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `/initiatives/${initiativeId}/data-share`,
          method: 'get',
        }),
        providesTags: ['dataShare'],
      }),
      createDataShareRequest: builder.mutation<DataShare, DataShareCreateBasic>({
        invalidatesTags: ['dataShare'],
        transformResponse,
        query: (createData) => ({
          url: '/data-share',
          method: 'post',
          data: createData,
        }),
      }),
      updateDataShare: builder.mutation<DataShare, { dataShareId: string; dataScope: DataScope }>({
        invalidatesTags: ['dataShare'],
        transformResponse,
        query: ({ dataShareId, dataScope }) => ({
          url: `/data-share/${dataShareId}`,
          method: 'patch',
          data: dataScope,
        }),
      }),
      acceptDataShare: builder.mutation<DataShare, { dataShareId: string; updateDataScope: DataScope | undefined }>({
        invalidatesTags: ['dataShare'],
        transformResponse,
        query: ({ dataShareId, updateDataScope }) => ({
          url: `/data-share/${dataShareId}/accept`,
          method: 'patch',
          data: updateDataScope,
        }),
      }),
      revokeDataShare: builder.mutation<DataShare, string>({
        invalidatesTags: ['dataShare'],
        transformResponse,
        query: (dataShareId) => ({
          url: `/data-share/${dataShareId}/revoke`,
          method: 'patch',
        }),
      }),
      undoRevokeDataShare: builder.mutation<DataShare, string>({
        invalidatesTags: ['dataShare'],
        transformResponse,
        query: (dataShareId) => ({
          url: `/data-share/${dataShareId}/undo-delete`,
          method: 'patch',
        }),
      }),
      removeDataShare: builder.mutation<DataShare, string>({
        invalidatesTags: ['dataShare'],
        transformResponse,
        query: (dataShareId) => ({
          url: `/data-share/${dataShareId}`,
          method: 'delete',
        }),
      }),
      getDataShareTemplate: builder.query<InitiativeDataShare, string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `/initiatives/${initiativeId}/data-share/template`,
          method: 'get',
        }),
        providesTags: ['dataShareTemplate'],
      }),
      updateDataShareTemplate: builder.mutation<
        InitiativeDataShare,
        { initiativeId: string; updateDataShare: InitiativeDataShare }
      >({
        invalidatesTags: ['dataShare', 'dataShareTemplate'],
        transformResponse,
        query: ({ initiativeId, updateDataShare }) => ({
          url: `/initiatives/${initiativeId}/data-share/template`,
          method: 'patch',
          data: updateDataShare,
        }),
      }),
      getCombinedScope: builder.query<Scope, string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `/initiatives/${initiativeId}/data-share/combined-scope`,
          method: 'get',
        }),
        providesTags: ['initiativeCombinedScope'],
      }),
      getScorecardBySurveyId: builder.query<
        ScorecardResponse,
        { initiativeId: string; requesterType: string; requesterId: string; surveyId: string }
      >({
        transformResponse,
        query: ({ initiativeId, requesterType, requesterId, surveyId }) => ({
          url: `/data-share/requester/${requesterType}/${requesterId}/initiative/${initiativeId}/survey/${surveyId}/scorecard`,
          method: 'get',
        }),
        providesTags: ['dataShareInsights'],
      }),
      getScorecardByInitiativeId: builder.query<
        ScorecardResponse,
        { initiativeId: string; requesterType: string; requesterId: string }
      >({
        transformResponse,
        query: ({ initiativeId, requesterType, requesterId }) => ({
          url: `/data-share/requester/${requesterType}/${requesterId}/initiative/${initiativeId}/scorecard`,
          method: 'get',
        }),
        providesTags: ['dataShareInsights'],
      }),
    }),
  });

export const {
  useGetDataSharesQuery,
  useCreateDataShareRequestMutation,
  useUpdateDataShareMutation,
  useAcceptDataShareMutation,
  useRevokeDataShareMutation,
  useRemoveDataShareMutation,
  useGetDataShareTemplateQuery,
  useUpdateDataShareTemplateMutation,
  useUndoRevokeDataShareMutation,
  useGetCombinedScopeQuery,
  useGetScorecardBySurveyIdQuery,
  useGetScorecardByInitiativeIdQuery,
} = dataShareApi;
