import { Document } from 'flexsearch';
import { SurveyStats, UserWithDelegation } from '@api/initiative-stats';
import { getPeriodName } from '@utils/universalTracker';

export const generateSurveysFlexSearchMap = (surveys: SurveyStats[] | undefined) => {
  const indexSearch = new Document({
    tokenize: 'full',
    preset: 'performance',
    document: {
      id: '_id',
      index: ['name', 'period', 'effectiveDate', 'initiativeName'],
    },
  });

  (surveys || []).forEach((survey) => {
    indexSearch.add({
      ...survey,
      period: getPeriodName(survey.period, false),
      id: survey._id,
    });
  });

  return indexSearch;
};

export const generateUsersFlexSearchMap = (users: UserWithDelegation[] | undefined) => {
  const indexSearch = new Document({
    tokenize: 'full',
    preset: 'performance',
    document: {
      id: '_id',
      index: ['firstName', 'surname', 'email', 'fullName'],
    },
  });

  (users || []).forEach((user) => {
    indexSearch.add({
      ...user,
      id: user._id,
      fullName: `${user.firstName ?? ''} ${user.surname ?? ''}`.trim(),
    });
  });

  return indexSearch;
};
