import { InitiativePlain } from '../types/initiative';
import { DataPeriods } from '../types/universalTracker';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import {
  UpgradeDetails,
  UpgradeResult,
  PlanUpgrade,
  UpgradeRequestBase,
  PreviewUpgradeResult
} from '../types/subscriptions';
import { SurveyType } from '../types/survey';

export const initiativeApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['initiative', 'surveys-periods', 'initiative-upgrade'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInitiativeById: builder.query<InitiativePlain, string>({
        transformResponse,
        query: (initiativeId) => {
          return {
            url: `/initiatives/${initiativeId}`,
            method: 'get',
          };
        },
        providesTags: ['initiative'],
      }),
      getAvailablePeriods: builder.query<DataPeriods[], { initiativeId: string; surveyType: SurveyType }>({
        transformResponse,
        query: ({ initiativeId, surveyType }) => ({
          url: `/initiatives/${initiativeId}/surveys-periods/${surveyType}`,
          method: 'get',
        }),
        providesTags: ['surveys-periods'],
      }),
      getUpgradeDetails: builder.query<UpgradeDetails, UpgradeRequestBase>({
        transformResponse,
        query: ({ initiativeId, featureCode }) => {
          return {
            url: `/initiatives/${initiativeId}/subscription/upgrade/${featureCode}`,
            method: 'get',
          };
        },
        providesTags: ['initiative-upgrade'],
      }),
      upgradePlan: builder.mutation<UpgradeResult, PlanUpgrade>({
        transformResponse,
        query: (planUpgrade) => {
          return {
            url: `/initiatives/${planUpgrade.initiativeId}/subscription/upgrade/${planUpgrade.featureCode}`,
            method: 'post',
            data: planUpgrade
          };
        },
        invalidatesTags: ['initiative-upgrade'],
      }),
      previewPlan: builder.mutation<PreviewUpgradeResult, PlanUpgrade>({
        transformResponse,
        query: (planUpgrade) => {
          return {
            url: `/initiatives/${planUpgrade.initiativeId}/subscription/upgrade/${planUpgrade.featureCode}/preview`,
            method: 'post',
            data: planUpgrade
          };
        },
      }),
      addPaymentDetails: builder.mutation<{ url: string }, { initiativeId: string, returnUrl: string }>({
        transformResponse,
        query: ({ initiativeId, returnUrl }) => ({
          url: `/initiatives/${initiativeId}/subscription/add-payment-details`,
          method: 'post',
          data: { returnUrl }
        }),
      }),
    }),
  });

export const {
  useGetInitiativeByIdQuery,
  useGetAvailablePeriodsQuery,
  useUpgradePlanMutation,
  usePreviewPlanMutation,
  useGetUpgradeDetailsQuery,
  useAddPaymentDetailsMutation
} = initiativeApi;
