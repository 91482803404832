import React from 'react'
import { Chart } from 'react-google-charts'
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';

const WIDTH = 300;
const HEIGHT = 300;

export interface GenderSplitPcDataSource extends DataSource {
  femaleEmployeesPc?: number;
  maleEmployeesPc?: number;
  genderRatio?: number;
}

const GenderSplitPc = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const employeesUtr = questionData.find(d => d.universalTracker.code === 'gri/2020/102-7/a');
  const employeesValueData = employeesUtr?.valueData?.table?.[0];

  const femaleCount = Number(employeesValueData?.find(v => v.code === 'org_scale_employees_female')?.value ?? 0);
  const maleCount = Number(employeesValueData?.find(v => v.code === 'org_scale_employees_male')?.value ?? 0);
  const totalCount = femaleCount + maleCount;

  const reportData: GenderSplitPcDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    femaleEmployeesPc: 100 * femaleCount / totalCount,
    maleEmployeesPc: 100 * maleCount / totalCount,
    genderRatio: maleCount / femaleCount
  }

  const chartData = [
    ['Gender', 'Count'],
    ['Female', femaleCount],
    ['Male', maleCount],
  ];

  return <Chart
    chartType='PieChart'
    data={chartData}
    width={WIDTH}
    height={HEIGHT}
    options={{
      ...CHART_DEFAULTS.OPTIONS,
      title: 'Percentage',
    }}
    chartEvents={[
      {
        eventName: 'ready',
        callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
          reportData.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
          updateData('genderSplitPc', reportData);
        }
      }
    ]}
  />
})

export default GenderSplitPc;
