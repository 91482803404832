import { useToggle } from '@hooks/useToggle';
import { ReactNode } from 'react';
import { Button, ButtonProps, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import './PopoverButton.scss';

interface Props {
  id: string;
  title: string;
  children: ReactNode;
  buttonProps?: ButtonProps;
}

export const PopoverButton = (props: Props) => {
  const { title, id, children, buttonProps } = props;
  const [isOpen, toggleOpen, setOpen] = useToggle();

  return (
    <div>
      <Button
        id={`popover-${id}`}
        type='button'
        color='link'
        {...buttonProps}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={toggleOpen}
      >
        <i className='fa fa-info-circle text-ThemeNeutralsDarkest' />
      </Button>
      <Popover
        target={`popover-${id}`}
        placement='bottom'
        isOpen={isOpen}
        className='popover-button'
      >
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{children}</PopoverBody>
      </Popover>
    </div>
  );
};
