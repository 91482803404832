import {
  AlignmentType,
  Document,
  Header,
  PageOrientation,
  Paragraph,
  SectionType,
  Table,
  TableCell,
} from 'docx';
import { blueStyles, numberingStyles } from '../styles';
import { footer, getBorders, heading, heading2, heading3, pagebreak, spacer } from '../document-structure';
import { DATE, formatDateUTC } from '../../../utils/date';
import { SurveyInitiative } from '../../../types/survey';
import { DataSources } from '.';
import { HistoricalReportData, ReportData } from '../../../types/reportData';
import { defaultPrivateQuestionText, shouldDisplayPrivateText } from '../reportData';
import { SurveyModelMinimalUtrv } from '../../../model/surveyData';
import { getLatestNote } from '../group-builder';
import { generateScopeGroups } from './sustainability-renderer';
import { VisibilityStatus } from '../../../types/download';
import { SelectedGroup } from '../../downloads/util/downloadReportHandler';

const H1_SIZE = 120;

export const STYLES = {
  TABLE_WIDTH: 9000,
  TABLE_WIDTH_LANDSCAPE: 13900,
  TABLE_HEADER_SHADING: {
    fill: '#5189BD',
  },
  TABLE_BORDER_COLOUR: '#DDDDDD',
  LINE_SPACING: 300,
};

const MARGINS_MD = {
  left: 150,
  right: 150,
  top: 150,
  bottom: 150,
};

export const paddedTableCell = (paragraph: Paragraph) =>
  new TableCell({
    children: [paragraph],
    margins: {
      left: MARGINS_MD.left,
      right: MARGINS_MD.right,
    },
  });

/**
 * This just select last note, no reason to look at history?
 * @link getLatestNote
 */
export const getComments = (
  reportData: ReportData[],
  utrCode: string,
  visibility: VisibilityStatus,
  emptyText: string = 'No further explanation provided.'
) => {
  const utrv = reportData.find((d) => d.universalTracker.code === utrCode);

  if (!utrv) {
    return emptyText;
  }

  if (shouldDisplayPrivateText(utrv, visibility)) {
    return defaultPrivateQuestionText;
  }

  return getLatestNote(utrv, emptyText);
};

export const HEADER_ROW_STYLES = {
  shading: STYLES.TABLE_HEADER_SHADING,
  margins: MARGINS_MD,
  borders: getBorders(STYLES.TABLE_BORDER_COLOUR),
  style: 'plain',
  textRun: {
    color: '#FFFFFF',
    size: 20,
  },
};

export interface ScopeGroupHistoricalData extends SelectedGroup {
  questionData: HistoricalReportData[];
}

export interface SustainabilityReportGeneratorParams {
  survey: Pick<SurveyInitiative, '_id' | 'effectiveDate' | 'initiative'>;

  /** Chart data sources **/
  dataSources: DataSources;

  /** Historical data **/
  questionData: HistoricalReportData[];

  /** Historical data for included scope packs **/
  scopeGroupHistoricalData: ScopeGroupHistoricalData[];

  /** Latest available target for questionData utrvs **/
  targets: SurveyModelMinimalUtrv[];

  visibilityStatus: VisibilityStatus;

  displayUserInput?: boolean;
}

export const SustainabilityReportGenerator = async (params: SustainabilityReportGeneratorParams): Promise<Document> => {
  const { survey, scopeGroupHistoricalData, targets, visibilityStatus, displayUserInput } = params;

  const periodCovered = formatDateUTC(survey.effectiveDate, DATE.MONTH_YEAR);

  const targetMap = new Map(targets.map((target) => [target.universalTrackerId, target]));

  const contentsPage = [
    heading2('CONTENTS'),
    spacer(),
    ...scopeGroupHistoricalData.map((pack) => heading3(`  ${pack.name}`)),
    spacer(),
  ];

  const graphPage = (children: (Paragraph | Table)[]) => {
    return {
      properties: {
        type: SectionType.NEXT_PAGE,
      },
      headers: {
        default: new Header({
          children: [
            new Paragraph({
              text: 'SUSTAINABILITY REPORT',
              alignment: AlignmentType.LEFT,
            }),
          ],
        }),
      },
      footers: {
        default: footer(),
      },
      children,
    };
  };

  const tablePage = (children: (Paragraph | Table)[]) => {
    return {
      properties: {
        type: SectionType.NEXT_PAGE,
        page: {
          size: {
            orientation: PageOrientation.LANDSCAPE,
          },
        },
      },
      headers: {
        default: new Header({
          children: [
            new Paragraph({
              text: 'SUSTAINABILITY REPORT',
              alignment: AlignmentType.LEFT,
            }),
          ],
        }),
      },
      footers: {
        default: footer(),
      },
      children,
    };
  };

  const document = new Document({
    styles: {
      paragraphStyles: blueStyles,
    },
    numbering: {
      config: numberingStyles,
    },
    sections: [
      {
        properties: { type: SectionType.NEXT_PAGE },
        headers: { default: new Header({ children: [] }) },
        footers: { default: footer() },
        children: [
          new Paragraph({
            text: '',
            spacing: {
              before: 7000,
            },
          }),
          heading2(periodCovered),
          heading2(survey.initiative.name),
          heading('SUSTAINABILITY REPORT', { textRun: { size: H1_SIZE } }),
        ],
      },
      graphPage([...contentsPage, pagebreak()]),
      tablePage([
        ...generateScopeGroups({
          scopeGroupData: scopeGroupHistoricalData,
          targetMap,
          visibilityStatus,
          displayUserInput,
        }),
      ]),
    ],
  });

  return document;
};
