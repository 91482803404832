import { getGroup } from '@g17eco/core';
import { isGroupEnabled } from '../../../constants/groups';
import { PackOption } from '../../../constants/standards-frameworks';
import { useAppSelector } from '../../../reducers';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { PACK } from '@constants/terminology';

interface PackSelectProps {
  pack: string;
  packOptions: PackOption[];
  handleSelectPack: (pack: string) => void;
}

export const PackSelect = (props: PackSelectProps) => {
  const { pack, packOptions, handleSelectPack } = props;
  const scopeConfig = useAppSelector((state) =>
    state.globalData.loaded ? state.globalData.data?.config?.survey.scope : undefined
  );

  const isDisabled = (code: string) => {
    const group = getGroup('standards-and-frameworks', code);
    return !isGroupEnabled(group, scopeConfig);
  };

  return (
    <SelectFactory
      selectType={SelectTypes.SingleSelect}
      placeholder={`Select a standard or framework or custom ${PACK.SINGULAR}`}
      options={packOptions}
      value={packOptions.find((v) => v.value === pack)}
      onChange={(option) => handleSelectPack(option?.value || '')}
      isOptionDisabled={(option) => !option?.value || isDisabled(option.value)}
      isSearchable={false}
    />
  );
};
