import React from 'react'
import Chart from 'react-google-charts';
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { DATE, formatDateUTC } from '../../../../utils/date';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';
import { getComments } from '../sgx-metric-report-generator';
import { getEmissionIntensityData } from '../../reportData';

const WIDTH = 450;
const HEIGHT = 250;

export interface TotalEmissionIntensityType {
  code: string,
  name: string,
  intensity: number | string | undefined,
  numericValue: number;
}

type EmissionIntensityRow = {
  date: string;
  name: string,
  intensity: number | string,
  notes?: {
    scope1: string;
    scope2: string;
    scope3: string;
  }
};

export interface GHGEmissionsIntensityDataSource extends DataSource {
  rows?: EmissionIntensityRow[];
  comments?: string;
}

const emissionUtrCode = 'sgx-custom-56';
const scope1 = 'sgx-custom-57';
const scope2 = 'sgx-custom-58';
const scopeTwoPartial = 'sgx-custom-59';
const scope3 = 'sgx-custom-60';

const GHGEmissionsIntensity = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData, visibility } = props;
  const totals: TotalEmissionIntensityType[] = [];
  questionData.forEach((row) => {
    totals.push(getEmissionIntensityData(row.reportData, emissionUtrCode, totals, visibility))
  });

  const { rows, chartRows } = questionData.reduce((acc, { effectiveDate, reportData }, index) => {

    const date = formatDateUTC(effectiveDate, DATE.MONTH_YEAR_SHORT);
    const { intensity, name, numericValue } = totals[index];
    acc.chartRows.push([date, numericValue])

    acc.rows.push({
      date: date,
      intensity: intensity ?? '',
      name: name ?? '',
      notes: {
        scope1: getComments(reportData, scope1, visibility, ''),
        scope2: getComments(reportData, scope2, visibility, '').concat(getComments(reportData, scopeTwoPartial, visibility, '')),
        scope3: getComments(reportData, scope3, visibility, '')
      }
    })

    return acc;
  }, { rows: [] as EmissionIntensityRow[], chartRows: [] as [string, number][] });


  const data: GHGEmissionsIntensityDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    rows: rows,
    comments: getComments(questionData[0]?.reportData ?? [], emissionUtrCode, visibility)
  }

  const chartData = [
    ['Period', 'Total'],
    ...chartRows
  ];

  return <Chart
    chartType='BarChart'
    data={chartData}
    width={WIDTH}
    height={HEIGHT}
    options={{
      ...CHART_DEFAULTS.OPTIONS,
      isStacked: false,
      chartArea: {
        top: 40,
        bottom: 50,
        left: 100,
        right: 20,
      },
      hAxis: {
        title: 'Emissions Intensity',
      }
    }}
    chartEvents={
      updateData ? [
        {
          eventName: 'ready',
          callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
            data.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
            updateData('ghgEmissionsIntensity', data);
          }
        }
      ] : undefined}
  />
})

export default GHGEmissionsIntensity;
