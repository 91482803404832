import axios from 'axios';
import { useEffect, useState } from 'react';
import config from '../config';

export const useUserGeoLocation = ({ disabled = false }: { disabled?: boolean }) => {
  const [userLocation, setUserLocation] = useState('');

  const reverseGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const geoApiURL = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${config.userLocationApiKey}`
        axios.get(geoApiURL)
          .then(response => {
            setUserLocation(response.data.results[0].components['ISO_3166-1_alpha-2'])
          })
          .catch(error => console.error(error))
      })
    }
  }

  useEffect(() => {
    if (disabled) {
      return;
    }
    reverseGeolocation();
  }, [disabled])

  return userLocation
}
