import { useGetOrganizationMetricGroupsQuery } from '@api/organization';
import { getFrameworksOptions, getStandardsOptions } from '@constants/standards-frameworks';
import { PACK, SURVEY } from '@constants/terminology';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { getCustomGroupOptions } from '@utils/metricGroup';

interface Props {
  initiativeId: string;
  modules: string[];
  limitedOptions?: string[];
  onChange: (value: string[]) => void;
}

const standardsOptions = getStandardsOptions();
const frameworksOptions = getFrameworksOptions();

// TODO: Move this component to @app/common when all related components are moved onto @app
export const ModuleDropdown = ({ initiativeId, onChange, modules, limitedOptions }: Props) => {
  const { data: metricGroups = [] } = useGetOrganizationMetricGroupsQuery(initiativeId);
  const customGroupOptions = getCustomGroupOptions(metricGroups);
  const combinedOptions = [...customGroupOptions, ...standardsOptions, ...frameworksOptions];
  const options = limitedOptions ? combinedOptions.filter((op) => limitedOptions.includes(op.value)) : combinedOptions;

  return (
    <SelectFactory
      selectType={SelectTypes.MultipleSelect}
      placeholder={`Select ${SURVEY.ADJECTIVE} ${PACK.SINGULAR}`}
      options={options}
      onChange={onChange}
      values={modules}
      className='w-100'
    />
  );
};
