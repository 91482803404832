import { SimpleTooltip } from '@g17eco/molecules';

const gdprText = () => {
  return (
    <div style={{ textAlign: 'left', padding: '0.625rem 1rem' }}>
      <p>
        Providing supporting evidence to verify data enhances transparency and will ultimately reduce 3rd party
        verification costs
      </p>
      <p className='mt-2 mb-0'>Suggested evidence:</p>
      <ul className='pl-4'>
        <li>financial statements</li>
        <li>annual reports</li>
        <li>meter readings</li>
        <li>contracts</li>
      </ul>
    </div>
  );
}
export const SupportingEvidenceTooltip = (props: { tooltip?: JSX.Element }) => {
  const { tooltip } = props;
  return (
    <SimpleTooltip text={tooltip ?? gdprText()}>
      <i className='fa-regular fa-circle-info mr-2'></i>
    </SimpleTooltip>
  );
};
