import { RowDataInfo, RowStatus } from '../../question/questionInterfaces';

interface StatusDetails {
  tooltip: string;
  icon: string;
}

const status: Record<string, StatusDetails | undefined> = {
  [RowStatus.removed]: {
    tooltip: 'Row marked for deletion',
    icon: 'fas fa-trash-alt',
  },
  [RowStatus.edited]: {
    tooltip: 'Row have been edited',
    icon: 'fas fa-pencil-alt',
  },
  [RowStatus.added]: {
    tooltip: 'Row not yet saved',
    icon: 'far fa-save',
  }
};

const getStatus = (original: Omit<RowDataInfo, 'data'>, editRowId: number) => {
  if (!original) {
    return '';
  }
  const { id, isRemoved, rowStatus, isEdited, hasChanged } = original;
  if (isRemoved) {
    return RowStatus.removed;
  }

  if (rowStatus === RowStatus.added) {
    return RowStatus.added;
  }

  if (isEdited || hasChanged || editRowId === id) {
    return RowStatus.edited;
  }

  return '';
}

export const getControlIcon = (original: RowDataInfo, editRowId: number) => status[getStatus(original, editRowId)];
export const getClasses = (original: Omit<RowDataInfo, 'data'>, editRowId: number) => `status_${(getStatus(original, editRowId))}`;
