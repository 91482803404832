import { ChartSubType } from '../../../types/insight-custom-dashboard';
import IconButton from '../../../components/button/IconButton';
import { CHART_SUBTYPE } from '../types';
import './style.scss';

interface Props {
  chartSubType: ChartSubType;
  onSubTypeChange: (subType: ChartSubType) => void;
}

type SubTypeOption = {
  value: ChartSubType;
  label: string;
  icon: string;
  isDisabled?: boolean;
};

const subTypeOptions: SubTypeOption[] = [
  {
    value: ChartSubType.SingleValue,
    label: CHART_SUBTYPE[ChartSubType.SingleValue],
    icon: 'fal fa-input-numeric',
  },
  {
    value: ChartSubType.SparkLine,
    label: CHART_SUBTYPE[ChartSubType.SparkLine],
    icon: 'fal fa-chart-line',
  },
  { value: ChartSubType.Line, label: CHART_SUBTYPE[ChartSubType.Line], icon: 'fal fa-chart-line-up' },
  {
    value: ChartSubType.Column,
    label: CHART_SUBTYPE[ChartSubType.Column],
    icon: 'fal fa-chart-simple',
  },
  {
    value: ChartSubType.Bar,
    label: CHART_SUBTYPE[ChartSubType.Bar],
    icon: 'fal fa-chart-simple-horizontal',
  },
  {
    value: ChartSubType.FullPie,
    label: CHART_SUBTYPE[ChartSubType.FullPie],
    icon: 'fal fa-chart-pie',
  },
  {
    value: ChartSubType.Pie, // Donut chart.
    label: CHART_SUBTYPE[ChartSubType.Pie],
    icon: 'fa-duotone fa-spinner-third',
  },
];

export const ChartTypeSwitcher = (props: Props) => {
  const { chartSubType, onSubTypeChange } = props;

  return (
    <div className='d-flex mt-3 gap-2'>
      {subTypeOptions.map((option) => {
        const isActive = chartSubType === option.value;
        return (
          <IconButton
            key={option.label}
            outline={false}
            tooltip={option.label}
            disabled={option.isDisabled}
            color={isActive ? 'link' : 'link-secondary'}
            onClick={() => onSubTypeChange(option.value)}
            icon={option.icon}
            className='chart-type-switcher__button'
          />
        );
      })}
    </div>
  );
};
