import { BootstrapSteps } from '../../dashboard';

interface SurveyGroupTableProps {
  className?: string;
  padding?: BootstrapSteps;
  displayGroupNames: string[];
}

const defaultHeight = '500px';

export const SurveyGroupTable = (props: SurveyGroupTableProps) => {
  const { className, padding = 3, displayGroupNames } = props;

  return (
    <div
      style={{ maxHeight: defaultHeight }}
      className={`survey-group__table whiteBoxContainer overflow-auto d-flex flex-column p-${padding} ${className ?? ''}`}
    >
      <div className={'dashboard-children flex-fill'}>
        {displayGroupNames.map((displayGroupName) => {
          return <div key={`survey-group-${displayGroupName}`} className='survey-group__name'>{displayGroupName}</div>;
        })}
      </div>
    </div>
  );
};
