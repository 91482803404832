import { ApiResponse } from '../types/api';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import {
  NotificationPreferences,
  NotificationPreferencesUpdateRequest,
  NotificationSummariesUpdateRequest,
} from '@g17eco/types/notifications';

const tags = ['userPreferences'];

export const userApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: tags,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMagicBellToken: builder.query<string, void>({
        transformResponse,
        query: () => ({
          url: '/users/current/magic-bell/auth-token',
          method: 'get',
        }),
      }),
      getNotificationPreferences: builder.query<NotificationPreferences, void>({
        transformResponse,
        providesTags: tags,
        query: () => ({
          url: '/users/current/notifications/preferences',
          method: 'get',
        }),
      }),
      setNotificationPreferences: builder.mutation<ApiResponse, NotificationPreferencesUpdateRequest>({
        invalidatesTags: tags,
        transformResponse,
        query: (data) => ({
          url: '/users/current/notifications/preferences',
          method: 'put',
          data,
        }),
      }),
      updateNotificationSummaries: builder.mutation<ApiResponse, NotificationSummariesUpdateRequest>({
        invalidatesTags: tags,
        transformResponse,
        query: (data) => ({
          url: '/users/current/notifications/preferences/summaries',
          method: 'put',
          data,
        }),
      }),
    }),
  });

export const {
  useGetMagicBellTokenQuery,
  useGetNotificationPreferencesQuery,
  useSetNotificationPreferencesMutation,
  useUpdateNotificationSummariesMutation,
} = userApi;
