import { SurveyStats } from '@api/initiative-stats';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SingleValue } from 'react-select';
import './surveys-editing.scss';
import { useState } from 'react';
import { useToggleCompleteSurveys } from '@hooks/useToggleCompleteSurveys';
import Loader from '@components/loader';
import { SurveysTable } from './SurveysTable';
import { QUESTION, SURVEY } from '@constants/terminology';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules';

export enum Action {
  Complete = 'complete',
  Incomplete = 'incomplete',
}

enum CompleteSurveyStatus {
  AllVerified = 'all-verified',
  AllAnswered = 'all-answered',
  All = 'all',
}

enum IncompleteSurveyStatus {
  NotAllVerified = 'not-all-verified',
  OnlyRejected = 'only-rejected',
  OnlyUnanswered = 'only-unanswered',
  All = 'all',
}

interface FilterOption {
  value: CompleteSurveyStatus | IncompleteSurveyStatus;
  label: string;
}

const COMPLETE_FILTER_OPTIONS: FilterOption[] = [
  { value: CompleteSurveyStatus.AllVerified, label: `All ${QUESTION.PLURAL} are verified` },
  { value: CompleteSurveyStatus.AllAnswered, label: `All ${QUESTION.PLURAL} are answered` },
  { value: CompleteSurveyStatus.All, label: `All ${SURVEY.PLURAL}` },
];

const INCOMPLETE_FILTER_OPTIONS: FilterOption[] = [
  { value: IncompleteSurveyStatus.NotAllVerified, label: `Not all ${QUESTION.PLURAL} are verified` },
  { value: IncompleteSurveyStatus.OnlyRejected, label: `Has rejected ${QUESTION.PLURAL} only` },
  { value: IncompleteSurveyStatus.OnlyUnanswered, label: `Has unanswered ${QUESTION.PLURAL} only` },
  { value: IncompleteSurveyStatus.All, label: `All ${SURVEY.PLURAL}` },
];

interface Props {
  surveys: SurveyStats[];
  action: Action;
  handleClose: () => void;
  handleReload: () => void;
}

export const SurveysEditing = (props: Props) => {
  const { surveys, action, handleClose, handleReload } = props;
  const isCompleting = Action.Complete === action;
  const filterOptions = isCompleting ? COMPLETE_FILTER_OPTIONS : INCOMPLETE_FILTER_OPTIONS;
  const defaultFilterOption =
    filterOptions.find(({ value }) => filterSurveys(surveys, value).length) || filterOptions[0];

  const [filter, setFilter] = useState(defaultFilterOption.value);

  const title = `Mark ${SURVEY.PLURAL} as ${isCompleting ? 'completed' : 'in progress'}`;
  const onChangeFilter = (option: SingleValue<Option<FilterOption['value']> | null>) => {
    if (!option || !option.value) {
      return;
    }
    setFilter(option.value);
  };

  const filteredSurveys = filterSurveys(surveys, filter);

  const [selectedSurveys, setSelectedSurveys] = useState<string[]>([]);
  const { isSaving, toggleCompleteSurveys } = useToggleCompleteSurveys({
    handleReload,
    surveys: surveys.filter(({ _id }) => selectedSurveys.includes(_id)),
    isCompleting,
  });

  const onClickMarkBtn = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await toggleCompleteSurveys();
    handleClose();
  };

  if (isSaving) return <Loader />;

  return (
    <Modal isOpen={true} toggle={handleClose} backdrop='static' className='surveys-editing'>
      <ModalHeader toggle={handleClose}>{title}</ModalHeader>
      <ModalBody>
        <div>
          <span className='mr-3'>{SURVEY.CAPITALIZED_SINGULAR} progress</span>
          <SelectFactory
            selectType={SelectTypes.SingleSelect}
            className='d-inline-block'
            classNamePrefix='select'
            defaultValue={defaultFilterOption}
            name='color'
            options={filterOptions}
            onChange={onChangeFilter}
          />
        </div>
        <SurveysTable
          surveys={filteredSurveys}
          selectedSurveys={selectedSurveys}
          setSelectedSurveys={setSelectedSurveys}
        />
      </ModalBody>
      <ModalFooter className='text-right'>
        <Button color='link' size='sm' className='mr-2' onClick={handleClose}>
          Cancel
        </Button>
        <Button size='md' className='btn-sm surveys-editing__mark-btn' onClick={(e) => onClickMarkBtn(e)}>
          Mark as {isCompleting ? 'completed' : 'in progress'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const filterSurveys = (surveys: SurveyStats[], filter: CompleteSurveyStatus | IncompleteSurveyStatus) => {
  return surveys.filter(({ status: { created, updated, rejected } }) => {
    switch (filter) {
      case CompleteSurveyStatus.AllVerified:
        return !created && !updated && !rejected;
      case CompleteSurveyStatus.AllAnswered:
        return !created && !rejected;
      case IncompleteSurveyStatus.NotAllVerified:
        return created || updated || rejected;
      case IncompleteSurveyStatus.OnlyRejected:
        return !created && !updated && rejected;
      case IncompleteSurveyStatus.OnlyUnanswered:
        return created && !updated && !rejected;
      default:
        return true;
    }
  });
};
