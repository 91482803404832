import { useConnectionContext } from './ConnectionContext';

interface Props {
  valueListCode: string;
  children: JSX.Element;
}

export const MappedConnectionWrapper = ({ children, valueListCode }: Props) => {
  const { connection } = useConnectionContext();

  if (!connection || connection.valueListCode !== valueListCode) {
    return children;
  }

  return <div className='border border-4 rounded-1 border-ThemeAccentLight'>{children}</div>;
};
