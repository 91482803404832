import { ReactNode } from 'react';
import { SuggestionDataItem } from 'react-mentions';

export const renderSuggestion = (
  suggestion: SuggestionDataItem,
  search: string,
  highlightedDisplay: ReactNode,
  index: number,
  focused: boolean
): ReactNode => (
  <div className={`user ${focused ? 'focused' : ''}`}>
    {highlightedDisplay}
  </div>
);