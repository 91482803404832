import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  modalText: string;
  actionText: string;
  actionUrl: string;
}

export const ActionModal = ({ isOpen, toggle, modalText, actionText, actionUrl }: Props) => {
  const history = useHistory();

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Action</ModalHeader>
      <ModalBody>
        <p>{modalText}</p>
        <div className='text-right'>
          <Button color='link-secondary' className='mr-3' onClick={toggle}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => history.push(actionUrl)}>
            {actionText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
